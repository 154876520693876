<h4> {{element?.name}} 
  <span class="required_item" *ngIf="element?.required"> *</span>
</h4>
<mat-form-field [ngClass]="{'disabled': element.readOnly}" class="filter-type" appearance="fill">
    <input matInput
    (keyup)="selectValue($event.target?.value.toString(), element)"
    [type]="element.type"
    [disabled]="element?.disabled"
    [value]="prefilledValue"
    [ngModel]="prefilledValue?.value"
    color="primary"/>    
</mat-form-field>
