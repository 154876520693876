import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, startWith, map } from 'rxjs';
import { ModelCreateConsumables } from 'src/app/models/model.create.consumables';
import { ApiConsumablesService } from 'src/app/services/api.consumables.service';
import { ApiPersonnelService } from 'src/app/services/api.personnel.service';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MMM/YYYY ',
  },
  display: {
    dateInput: 'DD/MMM/YYYY ',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface DialogData {
  name: string;
  taskId: string;
  allTaskConsumables: any;
  element: any;
  action: string;
}

@Component({
  selector: 'app-add-consumables',
  templateUrl: './add-consumables.component.html',
  styleUrls: ['./add-consumables.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
  ],
})
export class AddConsumableComponent {
  isLoading = false;
  validForm = false;
  @ViewChild('consumablesInput')
  consumablesInput!: ElementRef<HTMLInputElement>;
  @ViewChild('servicesInput')
  servicesInput!: ElementRef<HTMLInputElement>;

  @Input() element: any;
  @Input() readonlyFields: any;
  @Input() formData: any;
  prefilledValue: any;
  prefilledValueDate: any;
  @Output() elementEvent = new EventEmitter<any>();

  newConsumablesModel: any = new ModelCreateConsumables();
  selectedConsumables = <any>[];
  selectedServices = <any>[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ctrlConsumables = new UntypedFormControl();
  ctrlServices = new UntypedFormControl();
  allConsumables: Observable<any[]> | undefined;
  allServices: Observable<any[]> | undefined;
  availableConsumables: any = [];
  availableServices: any = [];
  invalid = false;
  lookupsList: any;

  services: Observable<any[]>;

  constructor(
    private servicesService: ApiServicesService,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AddConsumableComponent>,
    private personnelService: ApiPersonnelService,
    private consumableService: ApiConsumablesService
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    const tenantId = localStorage.getItem('taskTenantId'); 
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const supplierId = localStorage.getItem('taskSupplierId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      supplierId: supplierId && supplierId.length > 0 ? supplierId : '',
      taskId: localStorage.getItem('taskId'),
      first: 0,
      max: 50,
      sort: 'desc',
      sortField: 'dateCreated',
    };

    this.servicesService.getTaskServices(params).subscribe(
      (result: any) => {
        if (result && result.length) {
          this.processResults(result);
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log('error retrieving task services ' + msg);
      }
    );

    this.consumableService.getAllConsumables(params).subscribe(
      (result: any) => {
        if (result.content && result.content.length > 0) {          
          this.availableConsumables = [...result.content];
        }
        this.isLoading = false;
        this.allConsumables = this.updateFormControl('consumables', this.availableConsumables, this.ctrlConsumables);
      },
      // error
      (msg) => {
        console.log('error retrieving task personnel ' + msg);
        this.isLoading = false;
      }
    );
    // retrieves and stores the personnel when is edit mode
    if (this.dialogData.action == 'edit') {
      this.newConsumablesModel.consumable = [...this.availableConsumables];
      this.newConsumablesModel.expiryDate = this.dialogData.element.expiryDate;
      this.newConsumablesModel.batchNumber = this.dialogData.element.batchNumber;
      this.newConsumablesModel.servicesSet = this.dialogData.element.servicesSet;
      this.prefilledValueDate = new Date(parseInt(this.dialogData.element.expiryDate));
    }
    this.allConsumables = this.updateFormControl('consumables', this.availableConsumables, this.ctrlConsumables);
  }

  /**
   * processes the results to display
   * @param result data array of results
   */
  processResults(result: any) {
    if (result.length > 0) {
      this.allServices = <any>[...result];
      this.availableServices = [...result];
    } else {
      // if no services are available, we retrieve all them for further adding
      this.allServices = this.services;
      this.availableServices = this.services;
    }
    // when is edit mode, retrieves the selected elements to display them as already existing
    if (this.dialogData.action == 'edit') {
      this.dialogData.element.personnelServices.forEach((item: any) => {
        const entryFound = result.filter((option: any) => option.name == item);
        this.selectedServices.push(entryFound[0]);
        const selectedIndex = this.availableServices.findIndex((option: any) => option.name == item);
        this.availableServices.splice(selectedIndex, 1);
      });
    }
    if (Object.keys(this.availableServices).length > 0) {
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }
    this.isLoading = false;
  }

  /**
   * the form control in charge of an element, has to be updated to handle the content
   * @param type the type of element
   * @param availableElems the available array of elements
   * @param ctrl the control itself
   * @returns returns the updated control
   */
  updateFormControl(type: string, availableElems: any, ctrl: UntypedFormControl) {
    return ctrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filterElem(item, type) : availableElems.slice()))
    );
  }

  /**
   * adds an element to the list, when it's clicked
   * @param event event triggered when clicking an element
   */
  addElem(event: MatChipInputEvent, name: string): void {
    const value = (event.value || '').trim();
    const availableElems = name == 'consumables' ? this.availableConsumables : this.availableServices;
    let selectedElems = name == 'consumables' ? this.selectedConsumables : this.selectedServices;
    // Add our service if it exists
    if (value && availableElems.includes(value) && !selectedElems.includes(value)) {
      selectedElems.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.ctrlConsumables.setValue(null);
    this.ctrlServices.setValue(null);
  }

  /**
   * removes an element from the list
   * @param elem element to be removed
   */
  removeElem(elem: any, name: string): void {
    let availableElems = name == 'consumables' ? this.availableConsumables : this.availableServices;
    let selectedElems = name == 'consumables' ? this.selectedConsumables : this.selectedServices;
    let selectedIndex;
    let availableIndex;
    if (name == 'consumables') {
      selectedIndex = selectedElems.findIndex((item: any) => item.id.includes(elem.id));
      availableIndex = availableElems.findIndex((item: any) => item.id.includes(elem.id));
    } else {
      selectedIndex = selectedElems.findIndex((item: any) => item.name == elem.name);
      availableIndex = availableElems.findIndex((item: any) => item.name == elem.name);
    }

    if (selectedIndex >= 0) {
      selectedElems.splice(selectedIndex, 1);
    }
    if (availableIndex == -1) {
      availableElems.push(elem);
    }

    if (name == 'consumables') {
      this.selectedConsumables = [...selectedElems];
      this.availableConsumables = [...availableElems];
      this.allConsumables = <any>[...availableElems];
      this.newConsumablesModel.consumable = [...selectedElems];
      this.allConsumables = this.updateFormControl('personnel', this.availableConsumables, this.ctrlConsumables);
    } else {
      this.selectedServices = [...selectedElems];
      this.availableServices = [...availableElems];
      this.allServices = <any>[...availableElems];
      this.newConsumablesModel.servicesSet = [...selectedElems];
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }

    this.validateForm();
  }

  /**
   * handles the selected element when clicking it
   * @param event event that is triggered while clicking it
   */
  selectedElem(event: MatAutocompleteSelectedEvent, name: string): void {
    let availableElems = name == 'consumables' ? this.availableConsumables : this.availableServices;
    let selectedElems = name == 'consumables' ? this.selectedConsumables : this.selectedServices;
    let availableOpt = [];
    let alreadyExists = [];
    if (name == 'consumables') {
      const searchValue = event.option.value.id;
      availableOpt = availableElems.filter((option: any) => option.id.includes(searchValue));
      alreadyExists = selectedElems.filter((option: any) => option.id.includes(searchValue));

      // Add our service if it exists
      if (availableOpt.length > 0 && alreadyExists.length == 0) {
        selectedElems.push(event.option.value);
        let index = availableElems.findIndex((item: any) => item.id.includes(searchValue));
        availableElems.splice(index, 1);
      }

      this.consumablesInput.nativeElement.value = '';
      this.ctrlConsumables.setValue(null);
    } else {
      const value = event.option.value.name;
      this.servicesInput.nativeElement.value = '';
      this.ctrlServices.setValue(null);
      availableOpt = availableElems.filter((option: any) => option.name == value);
      alreadyExists = selectedElems.filter((option: any) => option.name == value);

      // Add our service if it exists
      if (availableOpt.length > 0 && alreadyExists.length == 0) {
        selectedElems.push(event.option.value);
        let index = availableElems.findIndex((item: any) => item.name == value);
        availableElems.splice(index, 1);
      }
    }

    if (name == 'consumables') {
      this.selectedConsumables = [...selectedElems];
      this.availableConsumables = [...availableElems];
      this.allConsumables = <any>[...availableElems];
      this.newConsumablesModel.consumable = [...selectedElems];
      this.allConsumables = this.updateFormControl('consumables', this.availableConsumables, this.ctrlConsumables);
    } else {
      this.selectedServices = [...selectedElems];;
      this.availableServices = [...availableElems];
      this.allServices = <any>[...availableElems];
      this.newConsumablesModel.servicesSet = [...selectedElems];
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }

    this.validateForm();
  }

  /**
   * filters by the elements that already exist
   * @param value element value to filter
   * @returns returns if is included
   */
  private _filterElem(element: any, name: string): string[] {
    let availableElems = name == 'consumables' ? this.availableConsumables : this.availableServices;
    let returnElems = [];
    if (name == 'consumables' && element) {
      const searchValue = element.id? element.id: element;
      returnElems = availableElems.filter((item: any) => item.consumableType.name.includes(searchValue) ||
      item.description.includes(searchValue));
    }
    if (name == 'services') {
      const name = element.name ? element.name : element;
      returnElems = availableElems.filter((item: any) => item.name.includes(name));
    }
    return returnElems;
  }

  /**
   * checks if an element exists before displaying it
   * @param elem selected element
   * @returns returns if it exists
   */
  checkIfExists(elem: string, name: string) {
    let selectedElems = name == 'consumables' ? this.selectedConsumables : this.selectedServices;
    return selectedElems.includes(elem);
  }

  selectValue(value: string, elemName: string) {
    const storeValue = elemName == 'batchNumber' ? value : Date.parse(value);
    this.newConsumablesModel[elemName] = storeValue;
  }

  /**
   * handles the value change and in case it's not valid, shows the incorrect styles
   * @param value passed value
   */
  valueChange(value: any, name: string) {
    let availableElems = name == 'consumables' ? this.availableConsumables : this.availableServices;
    if (!availableElems.includes(value.trim())) {
      this.invalid = true;
      const elem: any = {};
      // when the entered value doesn't match any valid options, it resets the previous values
      this.elementEvent.emit(elem);
    } else {
      this.invalid = false;
    }
  }

  /**
   * if the inserted text does not match any available entries, shows incorrect styles
   */
  checkContent(name: string) {
    let selectedElems = name == 'consumables' ? this.selectedConsumables : this.selectedServices;
    this.invalid = true;
    if (Object.keys(selectedElems).length > 0) {
      this.invalid = false;
    }
  }

  validateForm() {
    const form = this.newConsumablesModel;
    this.validForm = false;
    if ( this.dialogData.action == 'create' && form.consumable.length > 0 && form.servicesSet.length > 0 && form.batchNumber) {
      this.validForm = true;
    }
    if ( this.dialogData.action == 'edit' && form.servicesSet.length > 0 && form.batchNumber) {
      this.validForm = true;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveChanges() {
    this.isLoading = true;
    const user = localStorage.getItem('username') ? localStorage.getItem('username') : 'admin';
    const taskId = this.dialogData.taskId ? this.dialogData.taskId : localStorage.getItem('taskId');
    this.newConsumablesModel.createdBy = user;
    this.newConsumablesModel.updatedBy = user;
    this.newConsumablesModel.task = { id: taskId };
    const tenantId = localStorage.getItem('taskTenantId');
    const workareaId = localStorage.getItem('taskWorkareaId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      taskId: taskId
    };

    if (this.dialogData.action == 'create') {
      let newForm: any = [];
      let consumableForm: any = {...this.newConsumablesModel};
      this.newConsumablesModel.consumable.forEach((element: any) => {
        consumableForm.consumable = { id: element.id };
        newForm.push(consumableForm);
      });
      newForm.dateCreated = <any>Date.now();
      this.createConsumable(newForm, params);
    } else if (this.dialogData.action == 'edit') {
      delete this.newConsumablesModel.consumable;
      this.newConsumablesModel.consumable = { id: this.dialogData.element.consumable.id };
      this.newConsumablesModel.id = this.dialogData.element.id;
      this.newConsumablesModel.dateCreated = <any>Date.now();
      this.newConsumablesModel.lastUpdated = <any>Date.now();
      this.updateConsumable(this.newConsumablesModel, params);
    }
  }

  updateConsumable(newConsumablesModel: any, params: any) {
    newConsumablesModel.lastUpdated = <any>Date.now();
    this.consumableService.updateTaskConsumable(newConsumablesModel, params).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.closePopup();
        this.componentsCommunication.setUpdateList('consumables_mainlist');
        this.snackBar.open( 'Consumable updated ', '', {duration: 3000});
      },
      // error
      (msg) => {
        if (msg.status == 201 || msg.status == 200) {
          this.closePopup();
          this.componentsCommunication.setUpdateList('consumables_mainlist');
          this.snackBar.open( 'Consumable updated ', '', {duration: 3000});
        } else {
          console.log('error updating the consumable' + msg);
        }
        this.isLoading = false;
      }
    );
  }

  createConsumable(newConsumablesModel: ModelCreateConsumables, params: any) {
    this.consumableService.createTaskConsumable(newConsumablesModel, params).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.closePopup();
        this.componentsCommunication.setUpdateList('consumables_mainlist');
        this.snackBar.open( 'Consumable created ', '', {duration: 3000});
      },
      // error
      (msg) => {
        if (msg.status == 201 || msg.status == 200) {
          this.closePopup();
          this.componentsCommunication.setUpdateList('consumables_mainlist');
          this.snackBar.open( 'Consumable created ', '', {duration: 3000});
        } else {
          console.log('error creating the equipment' + msg);
        }
        this.isLoading = false;
      }
    );
  }
}
