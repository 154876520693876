export class ModelCreatePersonnel {
    id = null;
	taskDto = {};
	servicesDtoSet = [];
	setReport = false;
	createdBy = "admin";
	dateCreated = "2023-05-26T11:34:02.890Z";
	updatedBy: '';
	lastUpdated = null;
	userDtoSet = [];
}