<div class="v-window-header">
  <span *ngIf="dialogData.action == 'create'">Create user</span>
  <span *ngIf="dialogData.action == 'edit'"
    >Edit user - {{ dialogData.firstLastName }}</span
  >
  <div [mat-dialog-close]="true" class="v-window-closebox">
    <mat-icon class="icon action">close</mat-icon>
  </div>
</div>
<mat-spinner *ngIf="isLoading"> loading...</mat-spinner>

<div class="dialogform-container" *ngIf="!isLoading">
  <div class="no-results" *ngIf="!isLoading && !userDetails?.user">
    <h1>No results to show...</h1>
  </div>

  <div mat-dialog-content *ngIf="userDetails?.user">
    <div class="row two-columns">
      <div class="two-cols">
        <h4>
          First name
          <span class="required_item"> *</span>
        </h4>

        <mat-form-field>
          <input
            tabindex="1"
            [readonly]="readonly"
            [disabled]="readonly"
            [ngModel]="userDetails.user.firstName"
            matInput
            required
            type="text"
            (keyup)="
              userDetails.user.firstName = $event.target.value.toString();
              validateFormdata()
            "
            color="primary"
          />
        </mat-form-field>
      </div>

      <div class="two-cols">
        <h4>
          Username
          <span class="required_item" *ngIf="usernameExists"
            >already exists * !</span
          >
          <span *ngIf="!usernameExists" class="required_item"> *</span>
        </h4>

        <mat-form-field [ngClass]="{ invalid: usernameExists }">
          <input
            tabindex="3"
            [readonly]="readonly"
            [disabled]="dialogData.action == 'edit'"
            [ngModel]="userDetails.user.id"
            matInput
            required
            type="text"
            (keyup)="checkIfExists($event.target?.value.toString(), 'username')"
            color="primary"
          />
        </mat-form-field>
      </div>

      <div class="two-cols">
        <!--
                <h4>Role 
                    <span class="required_item"> *</span>
                </h4> 
                
                <mat-form-field appearance="fill">              
                    <input class="role-input"
                    tabindex="5"
                    type="text"
                    matInput
                    #roleInput
                    [formControl]="roleControl"
                    [matAutocomplete]="autoRole"
                    [(ngModel)]="userDetails.privilege.name"
                    (keydown.arrowup)="handleArrowNavigation($event,'role')" 
                    (keydown.arrowdown)="handleArrowNavigation($event,'role')"
                    (matChipInputTokenEnd)="addRole($event)">
                    <mat-icon
                    class="select-arrow"
                    matSuffix>keyboard_arrow_down</mat-icon>
                    <mat-autocomplete #autoRole="matAutocomplete">
                      <ng-container *ngFor="let option of filteredRoles | async" >
                        <mat-option 
                        (onSelectionChange)="addRole($event, option)"
                         [value]="option.name">
                          {{option.name}}
                        </mat-option>
                    </ng-container>
      
                    </mat-autocomplete>
                  </mat-form-field>
                -->
      </div>
      <div class="two-cols">
        <h4>
          Qualification
          <span class="required_item"> *</span>
        </h4>

        <mat-form-field>
          <input
            tabindex="5"
            [readonly]="readonly"
            [disabled]="readonly"
            [ngModel]="userDetails.qualification"
            matInput
            required
            type="text"
            (keyup)="
              userDetails.qualification = $event.target.value.toString();
              validateFormdata()
            "
            color="primary"
          />
        </mat-form-field>
      </div>
    </div>

    <!-- COL 2*********-->
    <div class="row-2 two-columns">
      <div class="two-cols">
        <h4>
          Last name
          <span class="required_item"> *</span>
        </h4>

        <mat-form-field>
          <input
            tabindex="2"
            [readonly]="readonly"
            [disabled]="readonly"
            [ngModel]="userDetails.user.lastName"
            matInput
            required
            type="text"
            (keyup)="
              userDetails.user.lastName = $event.target.value.toString();
              validateFormdata()
            "
            color="primary"
          />
        </mat-form-field>
      </div>

      <div class="two-cols">
        <h4>
          Email
          <span class="required_item" *ngIf="emailExists"
            >already exists * !</span
          >
          <span class="required_item" *ngIf="!validEmail"
            >has an invalid format * !</span
          >
          <span class="required_item"> *</span>
        </h4>

        <mat-form-field [ngClass]="{ invalid: emailExists || !validEmail }">
          <input
            tabindex="4"
            [readonly]="readonly"
            [disabled]="dialogData.action == 'edit'"
            [ngModel]="userDetails.user.email"
            matInput
            required
            type="text"
            (keyup)="checkIfExists($event.target?.value.toString(), 'email')"
            color="primary"
          />
        </mat-form-field>
      </div>

      <div class="two-cols">
        <h4>
          Job title
          <span class="required_item"> *</span>
        </h4>

        <mat-form-field>
          <input
            tabindex="6"
            [readonly]="readonly"
            [disabled]="readonly"
            [ngModel]="userDetails.jobTitle"
            matInput
            required
            type="text"
            (keyup)="
              userDetails.jobTitle = $event.target.value.toString();
              validateFormdata()
            "
            color="primary"
          />
        </mat-form-field>
      </div>

      <div class="two-cols">
        <h4>
          Location
          <span class="required_item"> *</span>
        </h4>

        <mat-form-field appearance="fill"
        [ngClass]="{'disabled': dialogData.action == 'edit'}">
          <input
            class="location-input"
            tabindex="8"
            type="text"
            matInput
            [readonly]="dialogData.action == 'edit'"
            [disabled]="dialogData.action == 'edit'"
            [ngClass]="{'disabled': dialogData.action == 'edit'}"
            #locationInput
            [formControl]="locationControl"
            [matAutocomplete]="autoLocation"
            [(ngModel)]="userDetails.tenant.name"
            (keydown.arrowup)="handleArrowNavigation($event, 'location')"
            (keydown.arrowdown)="handleArrowNavigation($event, 'location')"
            (matChipInputTokenEnd)="addLocation($event)"
          />
          <mat-icon class="select-arrow" matSuffix
            >keyboard_arrow_down</mat-icon
          >
          <mat-autocomplete #autoLocation="matAutocomplete">
            <ng-container *ngFor="let option of filteredLocations | async">
              <mat-option              
                (onSelectionChange)="addLocation($event, option)"
                [value]="option.name"
              >
                {{ option.name }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      tabindex="9"
      *ngIf="dialogData.action == 'create'"
      mat-raised-button
      [ngClass]="{ disabled: !validForm }"
      color="primary"
      (click)="saveChanges()"
    >
      Save
      <mat-icon class="icon action">save</mat-icon>
    </button>
    <button
      tabindex="10"
      *ngIf="dialogData.action == 'edit'"
      mat-raised-button
      [ngClass]="{ disabled: !validForm }"
      color="primary"
      (click)="updateChanges()"
    >
      Update
      <mat-icon class="icon action">save</mat-icon>
    </button>

    <button
      tabindex="11"
      mat-raised-button
      color="primary"
      (click)="closePopup()"
    >
      Close
      <mat-icon class="icon action">close</mat-icon>
    </button>
  </div>
</div>
