import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiEquipmentService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  //api/tenant/equipment/admin/list?firstResult=0&maxResults=100&sort=dateCreated&sort=desc
  getAllEquipments(params: any) {
    const filters = `?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortField}&sort=${params.sort}`;
    //const endpoint = `/api/im/equipment/list` + filters;
    const endpoint = `/api/wm/equipment/list` + filters;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.supplierId, params.workareaId)
    });
  }

  //api/tenant/equipments/{tenantId}/{taskId}/list
  getTaskEquipment(params: any) {
    const filters = `?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortField}&sort=${params.sort}`;
    const endpoint = `/api/wm/task/${params.taskId}/equipments/list` + filters;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  ///api/wm/task/equipment/{equipmentId}/get
  deleteTaskEquipment(params: any) {
    const endpoint = `/api/wm/task/equipment/${params.equipmentId}/get`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/equipment/{tenantId}/add
  createTaskEquipment(body: any, params: any) {
    const endpoint = `/api/wm/equipment/add`;
    return this.http.post(this.baseURL + endpoint, body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //equipment/{tenantId}/update
  updateEquipment(body: any, params: any) {
    const endpoint = `/api/wm/equipment/task/update`;
    return this.http.put(this.baseURL + endpoint, body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }
}
