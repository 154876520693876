<div class="dialogform-container"  [ngClass]="{'preview': dialogData.action =='preview'}">
  <div class="v-window-header">
    <span *ngIf=" dialogData.action =='preview'">Content - {{dialogData.idProcess}}</span>
    <span *ngIf="!isLoading  && statusOk == false">Warnings</span>

    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>
    <mat-spinner *ngIf="isLoadingGeneral"></mat-spinner>
    <mat-card-content *ngIf="!isLoadingGeneral && statusOk == true">

        <span *ngIf="!dialogData?.action" class="status-result">
          <mat-icon role="img"
          class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" 
          aria-hidden="true" data-mat-icon-type="font">close</mat-icon>
          Result
          <hr>
        </span>        
   
        
        <h1 *ngIf="results && results[0]?.result?.toLowerCase().includes('rejected')" class="status-title">Rejected</h1>
        <h1 *ngIf="results && results[0]?.result?.toLowerCase().includes('accepted')" class="status-title accepted">Accepted</h1>
        <div *ngIf="!dialogData?.action" class="table-results">
            <table mat-table *ngIf="!isLoading" [dataSource]="dataSource" class="mat-elevation-z8">
                <br>
          <!-- Number Column -->
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>Number</th>
            <td mat-cell *matCellDef="let element">
            <span *ngIf="element.number"> {{element.number}}</span>  
            <span *ngIf="!element.number">empty</span>  
             </td>
          </ng-container>
    
          <!-- Area Column -->
          <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef>Area</th>
            <td mat-cell *matCellDef="let element">
              {{element.area}}
            </td>
          </ng-container>
  
          <!-- Condition Column -->
          <ng-container matColumnDef="condition">
              <th mat-header-cell *matHeaderCellDef>Condition</th>
              <td mat-cell *matCellDef="let element">
                {{element.conditionDto.name}}
              </td>
            </ng-container>
  
          <!-- Description Column -->
          <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let element">
                {{element.description}}
              </td>
            </ng-container>
  
          <!-- Images Column -->
          <ng-container matColumnDef="images">
              <th mat-header-cell *matHeaderCellDef>Images</th>
              <td mat-cell *matCellDef="let element">
                <mat-icon  *ngIf="element?.urlLastAttachment" (click)="preview(element)">image</mat-icon>
              </td>
            </ng-container>          
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>            
        </div>
        <div class="pdf-viewer-container">

            <pdf-viewer
            [src]="pdfUrl"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="1"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="true">
            </pdf-viewer>
        </div>

        <div mat-dialog-actions>
        
          <button
          *ngIf="!dialogData?.action"
          mat-raised-button color="primary" (click)="releaseToQC(dialogData.workArea.id);">
              Release to QC
              <mat-icon class="icon action">play_lesson</mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="closePopup()">
              Close
              <mat-icon class="icon action">close</mat-icon>
          </button>
        </div>
    </mat-card-content>

    <mat-card-content class="report-items-container" *ngIf="!isLoading  && statusOk == false">

      <hr>
      <div class="columns">

        <div *ngIf="reportItems?.reportItems">
          <div *ngFor="let element of reportItems?.reportItems; let i = 'index+1'">
            {{ element.errors}}
            
          </div>
      
        </div>

        <div *ngIf="invalidTabs && invalidTabs.length > 0">
          <div *ngFor="let item of invalidTabs">            
            <h3 *ngIf="item && item?.key == 'personnel'">Incomplete Personnel</h3>
            <h3 *ngIf="item && item?.key == 'result'">Incomplete Result</h3>
            <h3 *ngIf="item && item?.key == 'services'">Incomplete Services</h3>
            <h3 *ngIf="item && item?.key == 'defects'">Incomplete Defects</h3>
            <h3 *ngIf="item && item?.key == 'repairs'">Incomplete Repairs</h3>
            <h3 *ngIf="item && item?.key == 'equipment'">Incomplete Equipment</h3>
            <h3 *ngIf="item && item?.key == 'consumables'">Incomplete Consumables</h3>

            <div *ngIf="item?.key != 'services' && item?.content?.length > 0">
              <ul *ngFor="let element of item?.content">   
                <li *ngIf="element?.length">{{element}}</li>    
              </ul>

            </div>      
            <div *ngIf="item?.key == 'services' && item?.content?.length > 0">
              <ul *ngFor="let element of item?.content">    
                <li>{{element?.key}} - {{element?.value}}</li>    
              </ul>
            </div>           
            <hr>    
          </div>          
        </div>
      </div>

      <div mat-dialog-actions>
        
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
      </div>
    </mat-card-content>