import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiPersonnelService } from 'src/app/services/api.personnel.service';
import { AddPersonnelComponent } from './add-personnel/add-personnel.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { RolesService } from 'src/app/services/roles.service';


@Component({
  selector: 'app-create-report-personnel',
  templateUrl: './create-report-personnel.component.html',
  styleUrls: ['./create-report-personnel.component.scss']
})
export class CreateReportPersonnelComponent implements OnChanges {
  @Input() roles: any;
  @Input() workareaRoles: any;  
  listUpdates!: Subscription;
  formDataSubscription!: Subscription;
  displayedColumns: string[] = ['signatory', 'name', 'lastname', 'jobtitle', 'services', 'actions'];
  showActions = false;
  isLoading = false;
  noResults = false;
  from: number = 0;
  to: number;
  dataSource: any;
  workareaId: any;
  addNewEnabled = false;
  taskId: any;
  allPersonnel: any = [];
  taskPersonnelList: any = [];
  constructor(
    public rolesService: RolesService,
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private personnelService: ApiPersonnelService) {}
    ngOnChanges(){      
    }
    ngOnInit() {
      this.taskId = localStorage.getItem('taskId');
      this.workareaId = localStorage.getItem('workareaId');
      this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

        if(data && data.id && !this.workareaId) {
          this.workareaId = data.workArea.id;
          this.getData();
        } 
      }); 
      this.listUpdates = this.componentsCommunication.getUpdatedList().subscribe(name => {
        // updates the reports list
        if(name == 'personnel_mainlist') {
          this.getData();
        }
      });
    }
    ngOnDestroy() {
      this.listUpdates.unsubscribe();
      this.formDataSubscription.unsubscribe();
    }

    getData() {
      this.isLoading = true;
      const tenantId = localStorage.getItem('taskTenantId');   
      const workareaId = this.workareaId;
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : ''
      }
      this.personnelService.getTaskPersonnel(params).subscribe(
        (result: any) => {
        this.isLoading = false;
        let data = <any>[];
        this.taskPersonnelList = [...result];
        
        result.forEach((elem:any) => {
          let allServices = <any>[];
          if (elem?.servicesDtoSet) {
            elem.servicesDtoSet.forEach((services:any) => {
              allServices.push(services.name);
            });
          }
          elem.userDto.guid = elem.id;
          elem.userDto.setReport = elem.setReport;
          elem.userDto.personnelServices = [...allServices];
          elem.userDto.personnelServicesStr = allServices.join(', ');
          data.push(elem.userDto);
        });
        
        this.dataSource = [...data];
        this.allPersonnel = data;
        if (result.length == 0) {
          this.dataSource = [];
          this.allPersonnel = <any>[];          
        }
        },
        // error
        (msg) => {
          console.log('error retrieving lookup list ' + msg);
          this.isLoading = false;
      });
    }

    addNewPerson(action: string) {
      const data = {
        taskId: this.taskId,
        action: action
      }
      
      this.dialog.open(AddPersonnelComponent, {
        height: 'auto',
        width: '60%',
        disableClose: true,
        autoFocus: false,
        data: data
      });
    }
    
    editItemDetails (element: any) {
      const data = {
        taskId: this.taskId,
        action: 'edit',
        element: element
      }
      
      this.dialog.open(AddPersonnelComponent, {
        height: 'auto',
        width: 'auto',
        disableClose: true,
        autoFocus: false,
        data: data
      });
    }

    /**
     * deletes the selected service from the list
     * @param element selected element to be removed
     */
    deleteElem(element: any){

      const tenantId = localStorage.getItem('taskTenantId');   
      const workareaId = this.workareaId;
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        personnelId: element.guid
      }
      this.personnelService.deleteTaskPersonnel(params).subscribe(
        () => {
          this.getData();          
        },
        // error
        (msg) => {
          console.log('error deleting the person ' + msg);
          this.isLoading = false;
      });
    }

    /**
     * sets the signature
     * @param element selected element to mark with the signature
     */
    setSignatory(element: any){
      let taskPersonnel = this.taskPersonnelList.filter((option:any) => option.id == element.guid);
      if (taskPersonnel.length) {
        taskPersonnel[0].setReport = true;
      
        const tenantId = localStorage.getItem('taskTenantId');   
        const workareaId = this.workareaId;
        const params = {
          workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
          tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
          personnelId: element.guid,
          body: taskPersonnel[0]
        }
        this.personnelService.setReportSignatory(params).subscribe(
          () => {
            this.getData();
          },
          // error
          (msg) => {
            console.log('error setting the signatory ' + msg);
            this.isLoading = false;
        });
      } else {
        console.log('taskPersonnel to set signatory was not found');
      }
    }

    /**
     * confirmation before applying the change
     * @param element selected elem
     */
      confirmation(element: any, action: string) {      
      element.action = action;
      element.confirmation = false;
      if (action == 'delete') {
        element.confirmationString = 'Delete Personnel ' + element.name + ' ' + element.lastName + ' ' + element.jobTitle + ' ?';
      } else {
        element.confirmationString = 'Set ' + element.firstName + ' ' + element.lastName + ' ' + element.jobTitle + 
        ' as the report signatory? This will change the issued by signature to this person';
      }      
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: 'auto',
        width: 'auto',
        disableClose: true,
        autoFocus: false,
        data: element
      });
  
      dialogRef.afterClosed().subscribe(
        (data) => {
          if (data.confirmation) {
            if (action == 'delete') { 
              this.deleteElem(element);
            } else {
              this.setSignatory(element);
            }

          }
        }
      ); 
    }
}
