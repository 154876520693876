<div class="v-window-header">
  <span>Create task</span>
  <div [mat-dialog-close]="true" class="v-window-closebox">
    <mat-icon class="icon action">close</mat-icon>
  </div>
</div>
<mat-spinner *ngIf="isLoading"> Creating...</mat-spinner>

<div class="dialogform-container" *ngIf="!isLoading">

    <div mat-dialog-content>
      <div [ngClass]="{'two-columns': twoColumns}" class="row">
    
        <h3>Task Details</h3>
       
        <div *ngFor="let element of myJson.formModel.fields; let i = index">
        <!--*****INPUT TYPE*****-->     
        <input-elem class="full-width"
        *ngIf="element.type == 'text'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></input-elem>

        <!--*****DROPDOWN TYPE*****-->
        <select-elem class="full-width"
        *ngIf="element.type == 'dropdown'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></select-elem>

        <!--*****TEXTAREA TYPE*****--> 
        <textarea-elem class="full-width"
        *ngIf="element.type == 'textarea'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></textarea-elem>

        <!--*****DATEPICKER TYPE*****-->
        <datepicker-elem class="full-width"
        *ngIf="element.type == 'datepicker'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></datepicker-elem>
      
      <!--*****CHIPLIST TYPE*****-->
        <chiplist-elem class="full-width"
        *ngIf="element.type == 'chiplist'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></chiplist-elem>
        </div>

      </div>
  
    <div  *ngIf="showSecondColumn"
      [ngClass]="{'two-columns': twoColumns}" class="row-2" >
     
      <div class="grid-title">
          <h3>Asset Details</h3>
      </div>

        <div *ngFor="let element of singleItemJson.formModel.fields; let i = index">
        <!--*****INPUT TYPE*****-->     
        <input-elem class="full-width"
        *ngIf="element.type == 'text'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></input-elem>

        <!--*****DROPDOWN TYPE*****-->
        <select-elem class="full-width"
        *ngIf="element.type == 'dropdown'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></select-elem>

        <!--*****TEXTAREA TYPE*****--> 
        <textarea-elem class="full-width"
        *ngIf="element.type == 'textarea'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></textarea-elem>

        <!--*****DATEPICKER TYPE*****-->
        <datepicker-elem class="full-width"
        *ngIf="element.type == 'datepicker'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></datepicker-elem>
      
      <!--*****CHIPLIST TYPE*****-->
        <chiplist-elem class="full-width" 
        *ngIf="element.type == 'chiplist'"
        (elementEvent)="addSelectValue($event, element)"
        [element]="element"></chiplist-elem>
        </div>

      </div>        
   </div>

    <div mat-dialog-actions>

      <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
        Save
        <mat-icon class="icon action">save</mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="closePopup()">
        Close
        <mat-icon class="icon action">close</mat-icon>
      </button>
    </div>

</div>
