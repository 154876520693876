<div class="dialogform-container create-repair">
  <div class="v-window-header">    
    <span *ngIf="dialogData.type == 'fieldRepair'">Create field Repair</span>
    <span *ngIf="dialogData.type == 'repairRequired'">Create Repair required</span>
    <span *ngIf="dialogData.action == 'edit'">Edit Repair</span>    
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <mat-card-content *ngIf="!isLoading">

        <div mat-dialog-content>
            <div class="columns">
                <!-- NEW COLUMN-->
                <div >            
  
                  <!--*****Area*****-->   
                  <h4> Area
                    <span class="required_item"> *</span>
                  </h4>
                  <mat-form-field appearance="fill">            
                      <input class="condition-input"
                      type="text"
                      matInput
                      #areaInput
                      [formControl]="areaControl"
                      [matAutocomplete]="autoArea"
                      [ngModel]="prefilledArea"
                      (keydown.arrowup)="handleArrowNavigation($event, 'area')" 
                      (keydown.arrowdown)="handleArrowNavigation($event, 'area')"
                      (matChipInputTokenEnd)="addArea($event)">
                      <mat-icon
                      class="select-arrow"
                      matSuffix>keyboard_arrow_down</mat-icon>
                      <mat-autocomplete #autoArea="matAutocomplete">
                        <ng-container *ngFor="let option of filteredOptionsAreas | async" >
                          <mat-option (onSelectionChange)="addArea($event, option)" [value]="option.name">
                            {{option.name}}
                          </mat-option>
                      </ng-container>
        
                      </mat-autocomplete>
                    </mat-form-field>

              
                    <!--*****Condition*****-->     
                    <h4> Repair
                      <span class="required_item"> *</span>
                    </h4>                    
                    <mat-form-field appearance="fill">          
                        <input class="condition-input"
                        type="text"
                        matInput
                        #repairInput
                        [formControl]="repairControl"
                        [matAutocomplete]="autoRepair"
                        [ngModel]="prefilledRepair"
                        (keydown.arrowup)="handleArrowNavigation($event, 'repair')" 
                        (keydown.arrowdown)="handleArrowNavigation($event, 'repair')"
                        (matChipInputTokenEnd)="addRepair($event)">
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #autoRepair="matAutocomplete">
                          <ng-container *ngFor="let option of filteredOptionsRepairs | async" >
                            <mat-option (onSelectionChange)="addRepair($event, option)" [value]="option.name">
                              {{option.name}}
                            </mat-option>
                        </ng-container>
          
                        </mat-autocomplete>
                      </mat-form-field>
                   
                    </div>

                   <!--*****Description*****-->  
                   <h4> Description
                    
                  </h4>
                   <mat-form-field class="filter-type" appearance="fill">                    
                    <textarea 
                    (keyup)="newRepair.description = $event.target?.value; validateForm();"                       
                    [ngModel]="newRepair.description"
                    rows="10"      
                    matInput                                                      
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize">
                    </textarea>  
                </mat-form-field>


            </div>
        </div>
    
        <div mat-dialog-actions>

          <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    