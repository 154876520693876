import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditAssetDialogComponent } from '../edit-asset-dialog/edit-asset-dialog.component';

export interface DialogData {
  element: any;
}
@Component({
  selector: 'file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.scss']
})

export class FilePreviewDialogComponent {
  isLoading = false;
  urlAttachment = '';
  constructor(
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData){}
  
    ngOnInit() {
      const elem = this.dialogData.element;
      if (elem) {
        this.isLoading = false;
        this.urlAttachment = elem.urlAttachment ? elem.urlAttachment : elem.urlLastAttachment;
      }
    }

    closePopup(): void {
      this.dialogRef.close();
    }
  
}
