<div class="dialogform-container personnel">
    <div class="v-window-header">

        <div [mat-dialog-close]="true" class="v-window-closebox">
          <mat-icon class="icon action">close</mat-icon>
        </div>
      </div>

    <mat-spinner *ngIf="loadingRoles || loadingSites || isLoading"></mat-spinner>
    <mat-card-content *ngIf="!loadingSites && !loadingRoles">

        <div mat-dialog-content>
           
            <div class="columns">
                <div class="column">
                    <h4>Site
                        <span class="required_item"> *</span>
                    </h4>
                    <mat-form-field  class="custom-width" appearance="outline">
                        <mat-select (selectionChange)="selectedSite = $event.value; validateFormdata()">
                            <mat-option *ngFor="let elem of allSites"
                            [value]="elem.name">
                                {{elem.name}}    
                            </mat-option> 
                        </mat-select>
                    </mat-form-field>    

                </div>
                
                <div class="column2">
                    <h4>Role
                        <span class="required_item"> *</span>
                    </h4>
                    <mat-form-field  class="custom-width" appearance="outline">
                        <mat-select (selectionChange)="selectedRole = $event.value; validateFormdata()">
                            <mat-option *ngFor="let elem of availableRoles"
                            [value]="elem.displayName">
                                {{elem.displayName}}    
                            </mat-option> 

                        </mat-select>
                    </mat-form-field>

                </div>
      
            </div>
        </div>
    
        <div mat-dialog-actions>
    
        <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    