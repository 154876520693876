import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModelReportUTProbe } from 'src/app/models/model.report.utprobe';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MMM/YYYY ',
  },
  display: {
    dateInput: 'DD/MMM/YYYY ',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface DialogData {
  name: string;
  taskId: string;
  element: any;
  action: string;
  tenantId: string;
}

@Component({
  selector: 'app-add-utprobe',
  templateUrl: './add-utprobe.component.html',
  styleUrls: ['./add-utprobe.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
  ],
})
export class AddUTProbeComponent {
  isLoading = false;
  validForm = false;
  newModel:any = new ModelReportUTProbe();
  title = '';
  constructor(
    private snackBar: MatSnackBar,
    private apiServicesService: ApiServicesService,
    private componentsCommunication: ComponentsCommunicationService,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AddUTProbeComponent>  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.newModel.model.createdBy = localStorage.getItem('username');
    if (this.dialogData.action == 'update_utprobes') {
      this.getData();
    }
  }

  getData() {
    this.isLoading = true;
    const probeId = this.dialogData.element.id;
    const tenantId = this.dialogData.tenantId;   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      probeId: probeId      
    };

    this.apiServicesService.getUTProbeById(params).subscribe(
      (result: any) => {
        this.newModel.model = result;
        this.title = this.newModel.model.serialNumber;
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log('error geting udprobe data ' + msg);
      }
    );
  }

  selectValue(value: string, elemName: string) {
    this.newModel.model[elemName] = value;
  }   
     
  validateForm() {
    const form = this.newModel.model;
    this.validForm = true;

    Object.entries(form).forEach(([key, value]: any) => {
      if (key != 'id' && key != 'title' && key != 'datapointValuesDto' && key != 'description' &&
       value.toString().trim().length == 0) {
        this.validForm = false;
      }
    })

  }

  saveChanges() {
    this.isLoading = true;
  
    const tenantId = this.dialogData.tenantId;   
    const datapointValueId = localStorage.getItem('UTProbeId');
    this.newModel.model.datapointValuesDto.id = datapointValueId;
    this.newModel.model.angle = parseInt(this.newModel.model.angle);
    const workareaId = localStorage.getItem('taskWorkareaId');  
    const params = {
      workareaId: workareaId ? workareaId : '',
      body: this.newModel.model,
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      datapointValueId: datapointValueId
    }
    if (this.dialogData.action == 'update_utprobes') {      
 
      this.apiServicesService.updateDatapointCatalogUTProbe(params).subscribe(
        () => {
          console.log( 'utprobe updated');
          this.componentsCommunication.setUpdateList('update_utprobes');            
          this.closePopup();
          this.snackBar.open( 'UTprobe updated', '', {duration: 3000});          
      },
        // error
        (msg) => {
          this.closePopup();
          console.log( msg);
      });
    }

    if (this.dialogData.action == 'create') {
 
    this.apiServicesService.createDatapointCatalogUTProbe(params).subscribe(
      () => {
        console.log( 'utprobe created');
        this.componentsCommunication.setUpdateList('update_utprobes');          
        this.closePopup();
        this.snackBar.open( 'UTprobe created', '', {duration: 3000});          
      },
        // error
        (msg) => {
          this.closePopup();
          console.log( msg);
      });

    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
