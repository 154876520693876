import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, startWith, map, Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditAssetDialogComponent } from 'src/app/components/dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { ModelCreateDefect } from 'src/app/models/model.create.defect';
import { ModelEditService } from 'src/app/models/model.edit.service';
import { ApiDefectsService } from 'src/app/services/api.defects.service';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { DefectAttachmentComponent } from '../defect-attachments/defect-attachment.component';
import { FilePreviewDialogComponent } from 'src/app/components/dialogs/file-preview-dialog/file-preview-dialog.component';
export interface DialogData {
  name: string;
  id: string;
  area: string;
  action: string;
  description: string;
  conditionDto: any;
  conditionEditable: boolean;
}

@Component({
  selector: 'app-create-manual-defect',
  templateUrl: './create-manual-defect.component.html',
  styleUrls: ['./create-manual-defect.component.scss']
})
export class CreateManualDefectComponent {
  defectsAttachmentsUpdate!: Subscription;
  conditionControl =  new FormControl('');
  @ViewChild('conditionInput')
  conditionInput!: ElementRef<HTMLInputElement>;
  isLoading = false;
  isLoadingAttachments = false;
  editable = true;
  serviceForm: any;
  validForm = false;
  requiredServicesFields: any = new ModelEditService;
  formControlMaterial = new FormControl('');
  formControlSize = new FormControl('');
  filteredMaterials: Observable<any>;
  filteredSizes: Observable<any>;
  filteredOptions: Observable<any[]> | undefined;
  
  displayedColumns: string[] = ['name', 'description', 'actions'];  
  availableOpts: any = [];
  usedOpts:any = [];
  dataSource: any;
  prefilledCondition: any;
  newManualDefect: any = new ModelCreateDefect;
  prefilledArea: any;
  constructor(
    private apiDefectsService: ApiDefectsService,
    private apiFilesService: ApiFilesService,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData){}
    itemDetails = {
      serialNumber:null,
      modelNumber:null,
      description:null,
      material:null,
      size:null
    };

  ngOnInit(){
    this.prefilledArea = 'Manual';    
    if (typeof this.dialogData.conditionEditable != 'undefined') {
      this.editable = this.dialogData.conditionEditable ? this.dialogData.conditionEditable : false;
    }      
    if (this.dialogData.action == 'edit') {
      this.newManualDefect = {...this.dialogData};  
      this.prefilledArea = this.dialogData.area;    
      this.prefilledCondition = this.dialogData.conditionDto.name;
    }
   
    this.defectsAttachmentsUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the attachments list
      if(name == 'update_defect_attachments') {
        this.getAttachments();
      }
    });

    this.getAttachments();
    this.getData();
   
  }

  ngOnDestroy() {
    this.defectsAttachmentsUpdate.unsubscribe();
  }

  getData() {
    this.apiService.getLookupLists('condition').subscribe(
      (result: any) => {
        if(result) {
          this.availableOpts = result.lookups;
        }
        this.filteredOptions = this.conditionControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );

      },
      // error
      (msg) => {        
        console.log('error retrieving data from endpoint ' + msg.error);
    });
  }

  getAttachments() {
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      defectId: this.dialogData.id
    }
    this.apiDefectsService.getDefectAttachments(params).subscribe(
      (result: any) => {
        if(result && result[0].id) {
          this.dataSource = new MatTableDataSource<any>(result);       
        }
      },
      // error
      (msg) => {        
        console.log('error retrieving defect attachments ' + msg.error);
    });

  }
  private _filter(element: any): string[] {    
        
    const searchValue = element.name ? element.name.toLowerCase() : element.toLowerCase();
    let returnElems = this.availableOpts.filter(
      (item:any) => (item.name && item.name.toLowerCase().includes(searchValue)));
  
    return returnElems;
  }

  addCondition(event:any, element?: any) {    
    if (event.isUserInput) {   
      this.newManualDefect.conditionDto = element; 
      this.validateForm();      
    }
  }

  createManualDefect() {
    this.isLoading = true;
    this.newManualDefect.createdBy = localStorage.getItem('username');
    this.newManualDefect.updatedBy = localStorage.getItem('username');

    this.newManualDefect.asset.id = localStorage.getItem('assetId'); 
    this.newManualDefect.task.id = localStorage.getItem('taskId');
    const tenantId = localStorage.getItem('taskTenantId');
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      body: this.newManualDefect,      
      taskId: localStorage.getItem('taskId')
    }
    this.apiDefectsService.createManualDefect(params).subscribe(
      (result: any) => {
        this.dialogRef.close();
        this.snackBar.open( 'defect created ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_defects');
        this.componentsCommunication.setUpdateList('update_results');
      },
      // error
      (msg) => {
        console.log('error creating the defect ' + msg);
    });
  }

  updateManualDefect() {
    this.isLoading = true;
    this.newManualDefect.id = this.dialogData.id;
    this.newManualDefect.updatedBy = localStorage.getItem('username');

    this.newManualDefect.asset.id = localStorage.getItem('assetId'); 
    this.newManualDefect.task.id = localStorage.getItem('taskId');
    
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      body: this.newManualDefect,
      taskId: localStorage.getItem('taskId')
    }
    this.apiDefectsService.updateManualDefect(params).subscribe(
      (result: any) => {
        this.dialogRef.close();
        this.snackBar.open( 'defect updated ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_repairs');
        this.componentsCommunication.setUpdateList('update_defects');
      },
      // error
      (msg) => {
        console.log('error updating the defect ' + msg);
    });
  }

  validateForm() {
    this.validForm = false;
    if (this.newManualDefect.conditionDto && this.newManualDefect.conditionDto.id) {
      this.validForm = true;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveChanges(){
    
    if (this.dialogData.action == 'create') {
      this.createManualDefect();
    } else {
      this.updateManualDefect();
    }
    
  }

  actions(action:string, element: any) {

    if(action =='edit') {
      //element.assetType = this.dialogData.type;
      this.openUploads(action, element);
    }
    if (action == 'delete'){

      this.deleteFile(element);
    }
  }
  openUploads(action: string, element?:any){    

    const data = element ? element : this.dialogData;
    this.dialog.open(DefectAttachmentComponent, {
      height: 'auto',
      width: '800px',
      disableClose: true,
      data: {data: data, action: action}
    });
  }

  confirmation(action: string, elem?: any) {
      
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.actions(action, elem);
        }
      }
    ); 
  }
  
  preview(element: any){
    const tenantId = localStorage.getItem('taskTenantId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',    
      taskId: localStorage.getItem('taskId'),
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }

  deleteFile(element: any) {
    const tenantId = localStorage.getItem('taskTenantId');  
    const workareaId = localStorage.getItem('taskWorkareaId');    
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      attachId: element.id,
      userId: localStorage.getItem('username')
    }

    this.apiFilesService.deleteDefectAttachment(params).subscribe(
      (result) => {
        console.log( 'attachment deleted');
        this.snackBar.open( 'attachment deleted ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_defect_attachments');
    },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log( 'attachment deleted');
          this.snackBar.open( 'attachment deleted ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_defect_attachments');
        }
        console.log( msg.statusText);
    });    
  }

  handleArrowNavigation (event: any){
    let value: any = '';
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }

    event.isUserInput = true;
    this.conditionInput.nativeElement.value = value;
    let selectedOpt = this.availableOpts.find((elem:any) => elem.name.includes(value.trim()));
    this.addCondition(event, selectedOpt);

  }
}
