<h4> {{element?.datapointCatalog?.name}} 
  <span class="required_item" *ngIf="element?.datapointCatalog?.required"> *</span>
</h4>

<mat-form-field [ngClass]="{'disabled': element.datapointCatalog.readOnly, 'invalid': invalid}" class="filter-type" appearance="fill">
  <input
  #selectInput
  (keydown.arrowup)="handleArrowNavigation($event)" 
  (keydown.arrowdown)="handleArrowNavigation($event)"
  aria-expanded="false"
  [ngModel]="prefilledValue?.name"
  type="text"
  matInput
  (keyup)="valueChange($event)"
  [formControl]="myControl"
  [matAutocomplete]="auto">
  <mat-icon
  class="select-arrow"
  matSuffix>keyboard_arrow_down</mat-icon>

  <mat-autocomplete
   #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" 
    [value]="option.name"
    (onSelectionChange)="selectValue(option, $event)">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>