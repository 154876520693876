import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiWorkareasService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  /**
 * Create a new WorkArea
 *
 * @param tenantsId     Tenant and SiteSupplier.
 * @param workAreaDto   The workArea data to create.
 * @return 			    The ResponseEntity object containing the new WorkArea object or a bad request status if the update fails.
 */
// POST: /workarea
  createWorkArea(params: any) {
    const endpoint = `/api/sys/workarea`;
    return this.http.post(this.baseURL + endpoint, params.workArea, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

/**
 * Update a WorkArea
 *
 * @param tenantsId     Tenant and SiteSupplier.
 * @param workAreaDto   The workArea data to update.
 * @return 			    The ResponseEntity object containing the new WorkArea object or a bad request status if the update fails.
 */
// PUT: /workarea/update
  updateWorkArea(params: any) {
    const endpoint = `/api/sys/workarea/update`;
    return this.http.put(this.baseURL + endpoint, params.workAreaDetails, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //GET /roles/workarea
  getRoles(params: any) {
    const endpoint = `/api/sys/roles/workarea`;
    const headers = this.authService.getHeadersWithTenant( params.tenantId, params.workareaId);
    return this.http.get(this.baseURL + endpoint, {
      headers: headers,
    });
  }

/**
     * Deactivate or enabled a WorkArea.
     *
     * @param  id    	the ID of the WorkArea to be deactivated or enabled.
     * @param  enabled  TRUE => enabled; FALSE => deactivate.
     * @return       	the deactivated or enabled WorkArea DTO
     */
 // PUT: /workarea/deactivateEnable
 deactivateEnableWorkArea(params: any) {
    const endpoint = `/api/sys/workarea/deactivateEnable`;
    const urlparam = `${endpoint}?id=${params.workAreaId}&enabled=${params.enabled}`;
    return this.http.put(this.baseURL + urlparam, '', {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //gets list of all Workareas
  //GET: /workarea/all?firstResult=0&maxResults=20&sort=dateCreated,desc
  getWorkareasList(params: any) {
    const endpoint = `/api/sys/workarea/all?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortColumn}&sort=${params.sort}`;
      return this.http.get(this.baseURL + endpoint, {
        headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
      });
  }
  
  //gets list of all Active Workareas
  //GET: /workarea/valid?userLog=userlogged&firstResult=0&maxResults=20&sort=dateCreated,desc
  getActiveList(params: any) {
    const endpoint = `/api/sys/workarea/valid?userSelected=${params.userSelected}&firstResult=0&maxResults=20&sort=dateCreated,desc`;
      return this.http.get(this.baseURL + endpoint, {
        headers: this.authService.getHeadersWithTenant(params.tenantId)
      });
  }

    //gets list of all Workareas
  //GET /user/listWorkAreas/{userId}
  listWorkAreas(params: any) {
    const endpoint = `/api/sys/user/listWorkAreas/${params.elemId}?firstResult=0&maxResults=20&sort=dateCreated,desc`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //gets list of all Workareas
  //GET /workArea/users/list
  getWorkareaUsers(params: any) {
    const endpoint = `/api/sys/user/list?firstResult=0&maxResults=20&sort=dateCreated,desc`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }
  
  /**
   * Get WorkArea by Id.
   *
   * @param tenantsId     Tenant and SiteSupplier.
   * @param  id          The UUID of the WorkArea
   * @return             The ResponseEntity containing the WorkAreaDto
   */
  //GET /workarea/{id}
  getWorkAreaDetails(params: any) {
    const endpoint = `/api/sys/workarea/${params.workAreaId}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }


  //Search Workareas
  // @param tenantsId   Id of the tenants. Can be null if all tenants are needed.
  // @param keyword     Text to find.
  // @param userId      Id of the user, can be null. THIS IS NOT THE LOGGED USED, THIS IS THE USER OF THE USERS LIST (THIS IS FOR ADMIN, WHEN HE/SHE CHECKS ALL WORKAREAS OF A USER).
  //GET: /workarea/search/{keyword}?userId=user&firstResult=0&maxResults=20&sort=dateCreated,desc
  workAreasSearch(params: any) {
    const endpoint = `api/sys/workarea/search/${params.searchTerm}?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sort}`;
    return this.http.get(`${this.baseURL}/${endpoint}`, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
    //userId=${params.userId}&
  }
}
