<main-headerbar ></main-headerbar>   

<mat-card class="newform-card" >
    <mat-card-header>
  
      <mat-card-title>
        <img class="logo" src="../../assets/images/img_paperless-icon.png">
  
        <h1 *ngIf="formData?.title">
        <!--{{'APP.OVERVIEWS.TITLE' | translate }}-->
        {{formData.title}}
        </h1>

        <button 
        *ngIf="roles.isAdmin || rolesService.WMEditor(roles.allRoles) || rolesService.workareaWMEditor(workareaRoles.allWorkareaRoles)"
        mat-raised-button (click)="releaseToQC()" class="right green custom-button-qc">
            <mat-icon class="icon">play_lesson</mat-icon>Release to QC
        </button>
    
      <div class="header-color-divider"></div>      
      </mat-card-title>
    </mat-card-header>
  
    <mat-card-content *ngIf="taskId">


      <mat-spinner *ngIf="isLoading"></mat-spinner>

      <div *ngIf="!isLoading" class="my-tabs-container">
      <mat-tab-group animationDuration="0ms"
      (selectedTabChange)="tabChanged($event)"
      mat-stretch-tabs 
      [selectedIndex]="selectedTabIndex"
      class="example-stretched-tabs mat-elevation-z4"
      mat-stretch-tabs>

        <mat-tab>
            <ng-template mat-tab-label>
                Details
            </ng-template>
           <report-details-tab></report-details-tab>
        </mat-tab>
        
        <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
            <ng-template mat-tab-label>
            Attachments
            </ng-template>
            <app-create-report-attachments [roles]="roles" [workareaRoles]="workareaRoles"></app-create-report-attachments>
        </mat-tab>  

        <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
          <ng-template mat-tab-label>
          Services
          </ng-template>
          <create-report-services [roles]="roles" [workareaRoles]="workareaRoles"></create-report-services>
        </mat-tab>

        <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
            <ng-template mat-tab-label>
            Results
            </ng-template>
            <app-create-report-results [roles]="roles" [workareaRoles]="workareaRoles"></app-create-report-results>
        </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
            Comments
            </ng-template>
            <app-create-report-comments></app-create-report-comments>
          </mat-tab>

          <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
            <ng-template mat-tab-label>
            Defects
            </ng-template>
            <app-create-report-defects [roles]="roles" [workareaRoles]="workareaRoles"></app-create-report-defects>
          </mat-tab>

          <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
            <ng-template mat-tab-label>
            Repairs
            </ng-template>
            <app-create-report-repairs  [roles]="roles" [workareaRoles]="workareaRoles"></app-create-report-repairs>
          </mat-tab>

          <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
            <ng-template mat-tab-label>
            Personnel
            </ng-template>
            <app-create-report-personnel [roles]="roles" [workareaRoles]="workareaRoles"></app-create-report-personnel>
          </mat-tab>

          <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
            <ng-template mat-tab-label>
            Equipment
            </ng-template>
            <app-create-report-equipment [roles]="roles" [workareaRoles]="workareaRoles"></app-create-report-equipment>
          </mat-tab>

          <mat-tab *ngIf="roles.isAdmin || workareaRoles.allWorkareaRoles.length > 0">
            <ng-template mat-tab-label>
            Consumables
            </ng-template>
            <app-create-report-consumables [roles]="roles" [workareaRoles]="workareaRoles"></app-create-report-consumables>
          </mat-tab>                                                                    
      </mat-tab-group>
    </div>

    <div mat-dialog-actions [ngClass]="{'hidden': !showActions}">

      <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
        Save
        <mat-icon class="icon action">save</mat-icon>
      </button>
    </div>
    </mat-card-content>

    <div *ngIf="!taskId" >
      <h1>No active task ID selected</h1>
    </div>