export class ModelDatapointAttachment {
        id = null;
        title = '';
        description = '';
        createdBy = '';
        name = '';
        type = '';
        hash = '';
        deleted = false
        datapointValuesDto = {}
    
}