import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, startWith, map } from 'rxjs';
import { EditAssetDialogComponent } from 'src/app/components/dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ApiRepairsService } from 'src/app/services/api.repairs.service';
import { ModelCreateRepair } from 'src/app/models/model.create.repair';
export interface DialogData {
  name: string;
  id: string;
  area: string;
  action: string;
  description: string;
  conditionDto: {};
  type: string;
  repair: string;
  repairArea: any;
  repairRequiredDto: any;
  fieldRepairDto: any;
  prefilledArea: any;
}

@Component({
  selector: 'app-create-repair',
  templateUrl: './create-repair.component.html',
  styleUrls: ['./create-repair.component.scss']
})
export class CreateRepairComponent {
  areaControl =  new FormControl('');
  repairControl =  new FormControl('');
  @ViewChild('areaInput')
  areaInput!: any;
  @ViewChild('conditionInput')
  conditionInput!: ElementRef<HTMLInputElement>;
  @ViewChild('repairInput')
  repairInput!: any;
  filteredOptionsRepairs: Observable<any[]> | undefined;
  filteredOptionsAreas: Observable<any[]> | undefined;
  newRepair:any = new ModelCreateRepair();
  isLoading = false;
  isLoadingAttachments = false;
  serviceForm: any;
  validForm = false;
  availableAreas:any = [];
  availableRepairs:any = [];
  prefilledArea = null;
  prefilledRepair = null;
  dataSource: any;

  constructor(
    private apiRepairsService: ApiRepairsService,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData){}
    itemDetails = {
      serialNumber:null,
      modelNumber:null,
      description:null,
      material:null,
      size:null
    };

  ngOnInit(){
    if (this.dialogData.action == 'edit') {
      this.newRepair = {...this.dialogData};    
      this.prefilledArea = this.dialogData.repairArea.name;
      this.prefilledRepair = this.dialogData.repairRequiredDto ? this.dialogData.repairRequiredDto.name : this.dialogData.fieldRepairDto.name;
    }
       
    this.getData();   
  }

  ngOnDestroy() {
    //this.defectsAttachmentsUpdate.unsubscribe();
  }

  getData() {
    let type = this.dialogData.type;
    if (Number(type)){
      type = type == '2' ? 'fieldRepair' : 'repairRequired';
    }

    this.apiService.getLookupLists(type).subscribe(
      (result: any) => {
      
        this.availableRepairs = result.lookups;        
        this.filteredOptionsRepairs = this.repairControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '', this.availableRepairs)),
        );   
      },
      // error
      (msg) => {        
        console.log('error retrieving data from endpoint ' + msg.error);
    });
  
    this.apiService.getLookupLists('repairArea').subscribe(
      (result: any) => { 

        this.availableAreas = result.lookups;        
        this.filteredOptionsAreas = this.areaControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '', this.availableAreas)),
        );      
      },
      // error
      (msg) => {        
        console.log('error retrieving data from endpoint ' + msg.error);
    });
  }

  private _filter(element: any, availableItems:any): string[] {    
    const searchValue = element.toLowerCase();         
    let returnElems = availableItems.filter(
      (item:any) => item.name.toLowerCase().includes(searchValue));
  
    return returnElems;
  }

  addRepair(event: any, element?: any): void {    
    if (event.isUserInput) { 
      if (this.dialogData.type == 'repairRequired') {
        this.newRepair.repairRequiredDto = element; 
      } else {
        this.newRepair.fieldRepairDto = element; 
      }
      
      this.validateForm();
    }
  }

  addArea(event: any, element?: any): void {    
    if (event.isUserInput) { 
      this.newRepair.repairArea = element; 
      this.validateForm();
    }
  }

  createRepair(type: string) {
    this.isLoading = true;
    this.newRepair.createdBy = localStorage.getItem('username');
    this.newRepair.updatedBy = localStorage.getItem('username');
    this.newRepair.task.id = localStorage.getItem('taskId');
    
    this.newRepair.asset.id = localStorage.getItem('assetId'); 
    const endpointType = type == 'fieldRepair' ? 'field' : 'required';
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      body: this.newRepair,
      taskId: localStorage.getItem('taskId'),
      type: endpointType
    }
    this.apiRepairsService.createRepair(params).subscribe(
      () => {
        this.dialogRef.close();
        this.snackBar.open( 'repair created ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_repairs');
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log('error creating the repair ' + msg);
        this.isLoading = false;
    });
  }

  updateRepair(type: string) {
    this.isLoading = true;
    const endpointType = type == '2' ? 'field' : 'required';
    this.newRepair.updatedBy = localStorage.getItem('username');
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      body: this.newRepair,    
      type: endpointType
    }
    this.apiRepairsService.updateRepair(params).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close();
        this.snackBar.open( 'repair updated ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_repairs');
        this.componentsCommunication.setUpdateList('update_defects');
      },
      // error
      (msg) => {
        this.isLoading = false;
        console.log('error creating the repair' + msg);
    });
  }

  validateForm() {
    this.validForm = false;
    if (this.newRepair.repairArea && this.newRepair.repairArea.id &&
      ((this.newRepair.repairRequiredDto && this.newRepair.repairRequiredDto.id) || 
      (this.newRepair.fieldRepairDto && this.newRepair.fieldRepairDto.id))) {
      this.validForm = true;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveChanges(){
    
    if (this.dialogData.action == 'create') {
      this.createRepair(this.dialogData.type);
    } else {
      this.updateRepair(this.dialogData.type);
    }
    
  }

  handleArrowNavigation (event: any, elemType: string){
    let value: any = '';
    let selectedOpt: any;
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }
    event.isUserInput = true;
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }

    if (elemType == 'area') {
      this.areaInput.nativeElement.value = value;
      selectedOpt = this.availableAreas.find((elem:any) => elem.name.includes(value.trim()));
      this.addArea(selectedOpt);
    } else {
      this.repairInput.nativeElement.value = value;
      selectedOpt = this.availableRepairs.find((elem:any) => elem.name.includes(value.trim()));
      this.addRepair(event, selectedOpt);
    }

  }

}
