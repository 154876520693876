


<mat-spinner *ngIf="isLoading"></mat-spinner>

<div  *ngIf="!isLoading" class="attachments-container">
  <div class="v-window-header">
    <span *ngIf="dialogData.action.includes('upload')">
       Attachment
    </span>
    <span *ngIf="dialogData.action.includes('update')">
      Edit {{dialogData.name}}
   </span>
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>

    <span class="span-label" *ngIf="dialogData.action == 'upload' || dialogData.action == 'upload_attachment'">Choose a file
      <span class="required_item"> *</span>
    </span>
    <mat-form-field *ngIf="dialogData.action.includes('upload')">
        <button  (click)="input.click()" class="blue" mat-raised-button matPrefix >
          <mat-icon>attach_file</mat-icon>
        </button>
        <input (click)="input.click()" class="file-input" type="text" readonly matInput [formControl]="display" />
        <input 
          type="file"
          multiple="false"
          hidden
          #input
          [accept]="accepts"
          (change)="checkFile(input.files)"/>

      </mat-form-field>

      <span class="span-label" *ngIf="dialogData.action == 'upload_attachment' || dialogData.action == 'update_attachment'" >
        Title
        <span class="required_item"> *</span>
      </span>
      <mat-form-field *ngIf="dialogData.action == 'upload_attachment' || dialogData.action == 'update_attachment'" appearance="outline" >
   
        <input matInput type="text"
        [(ngModel)]="prefilledTitle"
        (keyup)="title = $event.target.value.toString(); validateFormdata()"
        color="primary">
      </mat-form-field>

      <span class="span-label" *ngIf="dialogData.action == 'update'"> Name
          <span class="required_item"> *</span>
      </span>
      <mat-form-field *ngIf="dialogData.action == 'update'" appearance="outline" >        
        <input matInput  type="text"
        [(ngModel)]="prefilledName"
        (keyup)="name = $event.target.value.toString(); validateFormdata()"
        color="primary">
    </mat-form-field>

    <span class="span-label">Description
      <span *ngIf="this.requiredDescription == true" class="required_item"> *</span>
    </span>
    <mat-form-field>
        <textarea
        [(ngModel)]="prefilledDescription"
        rows="5"
        matInput         
        cdkTextareaAutosize
        (keyup)="description = $event.target.value.toString(); validateFormdata()"
        #autosize="cdkTextareaAutosize"></textarea>
    </mat-form-field>   

  <div mat-dialog-actions>

    <button mat-raised-button *ngIf="dialogData.action == 'upload'" [ngClass]="{'disabled': !enableUpload}"
    class="upload right" color="primary" (click)="uploadFile();">
     Upload
     <mat-icon class="icon action">save</mat-icon>
    </button>
    <button mat-raised-button *ngIf="dialogData.action == 'update'"  [ngClass]="{'disabled': !enableUpload}"
    class="upload right" color="primary" (click)="updateFile();">
      Update
      <mat-icon class="icon action">save</mat-icon>
    </button>
      
    <button mat-raised-button *ngIf="dialogData.action == 'upload_attachment'" [ngClass]="{'disabled': !enableUpload}"
      class="upload right" color="primary" (click)="uploadAttachmentsFile();">
      Upload
      <mat-icon class="icon action">save</mat-icon>
    </button>
    <button mat-raised-button *ngIf="dialogData.action == 'update_attachment'"  [ngClass]="{'disabled': !enableUpload}"
    class="upload right" color="primary" (click)="updateAttachmentsFile();">
      Update
      <mat-icon class="icon action">save</mat-icon>
    </button>

    <!--results attachments-->
    <button mat-raised-button *ngIf="dialogData.action == 'upload_result_attachment'" [ngClass]="{'disabled': !enableUpload}"
      class="upload right" color="primary" (click)="uploadResultsAttachments();">
      Upload
      <mat-icon class="icon action">save</mat-icon>
    </button>
    <button mat-raised-button *ngIf="dialogData.action == 'update_results_attachment'"  [ngClass]="{'disabled': !enableUpload}"
    class="upload right" color="primary" (click)="updateResultsAttachments();">
      Update
      <mat-icon class="icon action">save</mat-icon>
    </button>    
  </div>

</div>