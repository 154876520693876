import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateReportDetailsComponent } from './components/forms/create-report-details/create-report-details.component';

import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { InventoryViewComponent } from './components/main/inventory-view/inventory-view.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthGuard } from './modules/auth-guard/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  }, 
  {
    path: 'forgot-password/:token',
    component: ForgotPasswordComponent
  },  
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-report-details',
    component: CreateReportDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory-view',
    component: InventoryViewComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', component: LoginComponent},
 /* {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard]
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
