<h4> {{element?.datapointCatalog?.name}} 
    <span class="required_item" *ngIf="element?.datapointCatalog?.required"> *</span>
</h4>
<mat-form-field [ngClass]="{'disabled': element.readOnly}" class="filter-type" appearance="fill">
    <mat-label> {{element.name}} </mat-label>
    <input matInput
    (keyup)="selectValue($event.target?.value.toString(), element)"
    [type]="element.type"
    [required]="element.required" 
    [disabled]="element?.disabled"
    [value]="prefilledValue"
    [ngModel]="prefilledValue"
    color="primary"/>    
</mat-form-field>
