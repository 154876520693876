import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, startWith, map, flatMap } from 'rxjs';
import { ModelWorkArea } from 'src/app/models/model.workArea';
import { ApiWorkareasService } from 'src/app/services/api.workareas.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

export interface DialogData {
  action: string;
  id: string;
}

@Component({
  selector: 'app-edit-workarea-dialog',
  templateUrl: './edit-workarea-dialog.component.html',
  styleUrls: ['./edit-workarea-dialog.component.scss']
})
export class EditWorkAreaDialogComponent {

  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  roleControl =  new FormControl('');
  clientControl =  new FormControl(''); 
  supplierControl = new FormControl('');
  @ViewChild('clientInput')
  clientInput!: ElementRef<HTMLInputElement>;
  @ViewChild('supplierInput')
  supplierInput!: ElementRef<HTMLInputElement>;
  readonly = false;  
  workAreaDetails: any = new ModelWorkArea;
  validForm = false;
  isLoading = false;
  noResults = false;
  availableClients: any = [];
  availableSuppliers: any = [];
  userName: any = '';
  userEmail: any = '';
  usernameExists = false;
  emailExists = false;
  validEmail = true;  
  prefilledStartDate: any;
  prefilledEndDate: any;
  validStartDate = true;
  validStartDateFormat = true;
  requiredStartDateFormat = false;
  validEndDate = true;
  validEndDateFormat = true;
  requiredEndDateFormat = false;
  startDateIsGreater = false;
  endDateIsLower = false;
  licenseLevelTypes:any = [];
  ownerIsSupplier = false;
  ownerIsClient = false;
  isClientAcceptance = false;
  defaultSelected = 1;

  constructor(
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditWorkAreaDialogComponent>,
    private apiWorkareasService: ApiWorkareasService,
    private snackBar: MatSnackBar,
    private usersService: ApiUsersService,  
    private siteService: ApiSitesService,  
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) { }

  ngOnInit() {
    // Load details in edit mode. 
    if (this.dialogData.action == 'edit') {
      this.getDetails(this.dialogData.id);
    } 

    // Load all sites in both dropdowns: CLient and Supplier.
    this.getSites();
  }

  getDetails(workId: string){
    this.isLoading = true; 
    const params = {
      tenantId: '',
      workAreaId: workId
    };
    this.apiWorkareasService.getWorkAreaDetails(params).subscribe(
      (result: any) => {
        if (result && result) {
          this.workAreaDetails = result;
          this.isClientAcceptance = this.workAreaDetails.clientAcceptance;    
          //highlights what position of radiobutton is the owner      
          if (result?.owner?.id == result?.tenantDto?.id){
            this.defaultSelected = 0;
          }
          
        } else {
          this.workAreaDetails = [];
        }
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving details of workarea ');
        console.log(msg);
        this.isLoading = false;
    });
  }

  getSites() {
    this.isLoading = true; 

    const tenantId = localStorage.getItem('selectedSiteId');   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      username: localStorage.getItem('username'),
      first: 0,
      max: 9999,
      sort: 'asc',
      sortColumn: 'dateCreated',
      type: 'tenant'
    };
    //this.siteService.getLicencedTenants(params).subscribe(
    this.siteService.getTenantsForWorkareas(params).subscribe(
      (result: any) => {
        if(result && result.length > 0) {
          this.availableClients = [...result];
          this.availableSuppliers = [...result];
        }
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error getting site list ' + msg);
        this.isLoading = false;
    });
  }
  
  addClient(event:any, element?: any): void {    
    this.workAreaDetails.tenantDto = element;
    this.validateFormdata();
  }
  
  addSupplier(event:any, element?: any): void {    
    this.workAreaDetails.siteSupplier = element;
    this.validateFormdata();
  }

 
  setOwner(element:any, event: any) {
    this.defaultSelected = event.value;
    if(element == 'supplier') {
      this.workAreaDetails.owner = this.workAreaDetails.siteSupplier;
    } else if (element == 'client') {
      this.workAreaDetails.owner = this.workAreaDetails.tenantDto;
    }
    this.validateFormdata();
  }

  setClientAcceptance(event: any) {
    this.isClientAcceptance = event.checked;
    this.validateFormdata();
  }

  validateFormdata(){
    this.validForm = false;
    if (this.dialogData.action == 'create') {
      if (this.workAreaDetails.tenantDto.id && this.workAreaDetails.siteSupplier.id) {
        this.validForm = true;
      }
    } 
    if (this.dialogData.action == 'edit') {
      if (this.workAreaDetails.clientAcceptance != this.isClientAcceptance) {
        this.validForm = true;
      }
    }
  
  }
  
  closePopup(): void {
    this.dialogRef.close();
  }
  
  saveChanges() {
    const properties = {
      name: 'update_workareas',
      action: this.dialogData.action
    }
    if(this.workAreaDetails.owner.id.length == 0) {
      this.workAreaDetails.owner = this.defaultSelected == 0 ? this.workAreaDetails.tenantDto : this.workAreaDetails.siteSupplier;
    }
    
    this.workAreaDetails.clientAcceptance = this.isClientAcceptance;
    this.workAreaDetails.name = this.workAreaDetails.tenantDto.companyTitle + ' - ' + this.workAreaDetails.siteSupplier.companyTitle;    
    const params = {
      workArea: this.workAreaDetails, 
      tenantId: ''
    }
    this.apiWorkareasService.createWorkArea(params).subscribe(
      (result: any) => {         
        this.snackBar.open('workarea created', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateWorkAreas(properties);
        this.closePopup();
    },
      // error
      (msg) => {
        console.log('Error creating a new workarea: ');
        console.log(msg);
    }); 

  }
  

  
  updateChanges() {       
    const properties = {
      name: 'update_workareas',
      action: this.dialogData.action, 
    }        
    const params = {
      workAreaDetails: this.workAreaDetails,
      tenantId: '', 
    } 
    this.workAreaDetails.clientAcceptance = this.isClientAcceptance;
    this.apiWorkareasService.updateWorkArea(params).subscribe(
      (result: any) => {                                       
        this.snackBar.open('WorkArea updated', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateWorkAreas(properties);
        this.closePopup();
    },
      // error
      (msg) => {
        console.log( msg);
    }); 

  }

}
