
<h4> {{element?.name}} 
    <span class="required_item" *ngIf="element?.required"> *</span>
</h4>
<mat-form-field class="filter-type" appearance="fill">
    <textarea 
    (keyup)="selectValue($event.target?.value.toString(), element)"    
    [disabled]="element?.readOnly"
    rows="5"      
    matInput        
    [value]="prefilledValue"
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize">
    </textarea>  
</mat-form-field>