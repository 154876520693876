import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  //consumable/{tenantId}/update
  //equipment/{tenantId}/update
  updateInventoryAsset(params: any) {
    const type = params.type == 1 ? 'equipment' : 'consumable';
    const endpoint = `/api/im/${type}/update`;
    return this.http.put(this.baseURL + endpoint, params, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //equipment/{tenantId}/add
  createInventoryAsset(params: any) {
    const type = params.body.type == 1 ? 'equipment' : 'consumable';
    const endpoint = `/api/im/${type}/create`;
    return this.http.post(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }

  getTasksList(params: any) {
    const filters = `?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortField}&sort=${params.sort}`;
    const param = `/api/wm/tasks/list` + filters;
    return this.http.get(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId),
    });
  }

  // retrieves the assets list
  getAssets(param: any) {
    const endpoint = `/api/im/asset/list?firstResult=${param.first}&maxResults=${param.max}&sort=${param.sort}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(param.tenantId, param.workareaId)
    });
  }

  // retrieves the assets tasks list
  //asset/{idType}/{tenantId}/Category
  getAssetCategory(params: any) {
    const endpoint = `/api/sys/assetType/${params.id}/category/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  // retrieves the assets search results
  searchAssets(params: any) {
    const endpoint = `/api/im/asset/filter/`;
    const urlParams = `/list?firstResult=${params.first}&maxResults=${params.max}&sort=dateCreated&sort=${params.sort}`;
    const url = `${this.baseURL}${endpoint}${params.keyword}${urlParams}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.tenantId) });
  }

  // retrieves the equipment assets for results
  getAssetsForResults(params: any) {
    /*const endpoint = `/api/im/equipment/list`;
    const urlParams = `?firstResult=${params.first}&maxResults=${params.max}&sort=dateCreated&sort=${params.sort}`;
    const url = `${this.baseURL}${endpoint}${urlParams}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId) });*/
    // const endpoint = `/api/wm/equipment/list`;
    // const urlParams = `?firstResult=${params.first}&maxResults=${params.max}&sort=dateCreated&sort=${params.sort}`;
    const endpoint = `/api/wm/client/equipment/list`;
    const urlParams = `?firstResult=${params.first}&maxResults=${params.max}&sort=dateCreated&sort=${params.sort}`;
    const url = `${this.baseURL}${endpoint}${urlParams}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.supplierId, params.workareaId) });
  }

  //api/tenant/utils/tasks/{tenantId}/search/{field}/{textToSearch}
  searchTasks(params: any) {
    const endpoint = `api/wm/tasks`;
    const urlParams = `?firstResult=${params.first}&maxResults=${params.max}&sort=dateCreated&sort=${params.sort}`;
    const url = `${this.baseURL}/${endpoint}/search/${params.searchField}/text/${params.searchTerm}${urlParams}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId) });
  }

  //retrieves the lookup list like services and standarts
  getLookupLists(name: string) {
    const endpoint = `/api/sys/lookup/${name}/list?fieldValue=name`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeaders(),
    });
  }

  //retrieves the lookup list like services and standarts
  getLookupWorkareas(params: any) {
    const endpoint = `/api/sys/workarea/all?firstResult=0&maxResults=9999`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //asset/{tenantId}/{assetId}/{typeAsset}/details/get
  getItemDetails(params: any) {
    const endpoint = `${this.baseURL}/api/im/asset/${params.id}/${params.type}/details/get`;
    return this.http.get(endpoint, { headers: this.authService.getHeadersWithTenant(params.tenantId)});
  }

  //asset/{tenantId}/{assetId}/attachments/list
  getUploadedImages(params: any) {
    const endpoint = `${this.baseURL}/api/im/asset/${params.assetId}/attachments/list`;
    return this.http.get(endpoint, {  headers: this.authService.getHeadersWithTenant(params.tenantId)});
  }

  //api/tenant/tasks/singleItem/{tenantIt}/{taskId/get}
  getTaskById(params: any) {
    const endpoint = `/api/wm/tasks/${params.taskId}/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //evolve-app-rest/api/tasks/singleItem/{tenantId}/create
  createReport(data: any, params:any) {
    const param = `/api/wm/tasks/singleItem/createRelease`;
    return this.http.post(this.baseURL + param, data, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/release/task/{taskId}/{tenantID}/releaseToQc
  releaseToQC(params: any) {
    const param = `/api/rm/task/${params.taskId}/releaseToQc`;
    return this.http.get(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/report/task/{taskId}/{tenantID}/viewPDF
  getPdfPreview(params: any) {
    const param = `/api/rm/task/${params.taskId}/viewPDF`;
    return this.http.get(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //api/tenant/tasks/delete/{tenantId}/{taskId}/{idProcess}
  deleteTask(params: any) {
    const param = `/api/wm/tasks/${params.taskId}/process/${params.idProcess}/delete`;
    return this.http.delete(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }
  //consumable/{tenantId}/{consumableId}
  deleteInventoryAsset(params: any) {
    const type = params.type == 1 ? 'equipment' : 'consumable';
    const param = `/api/im/${type}/${params.id}/delete`;
    return this.http.delete(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //consumable/{tenantId}/clone/{consumableId}
  cloneInventoryAsset(params: any) {
    const type = params.type == 1 ? 'equipment' : 'consumable';
    const param = `/api/im/${type}/${params.id}/clone`;
    return this.http.get(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //api/tenant//tasks/singleItem/{tenantId}/update
  saveTaskDetails(params: any) {
    const param = `/api/wm/tasks/singleItem/update`;
    return this.http.put(this.baseURL + param, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/{tenantId}/copy/{taskId}
  copyTask(params: any) {
    const param = `/api/wm/task/${params.taskId}/copy`;
    return this.http.get(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/{tenantId}/checkName/{newName}
  checkIfReportNumberExists(params: any) {
    const param = `/api/wm/task/checkName/${params.newName}`;
    return this.http.get(this.baseURL + param, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //measurement/{tenantId}/{min}/{max}/{size}/getValues
  getMeasurements(params: any) {
    const searchValue = encodeURIComponent(params.startsLike);
    const endpoint = `/api/sys/measurement/${params.min}/${params.max}/${params.size}/getValues?startsLike=${searchValue}`;

    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }
  
  ///api/sys/wakeUp
  wakeUpMethod() {
    const param = `/api/sys/wakeUp`;
    return this.http.post(this.baseURL + param, {
      headers: this.authService.getHeadersNoToken(),
    });
  }
  
}
