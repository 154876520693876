import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'textarea-elem',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {
  @Input() element:any;
  @Input() readonlyFields:any;
  @Input() formData:any;   
  prefilledValue: any;
  @Output() elementEvent = new EventEmitter<any>();
  constructor(
    private sharedFunctions: SharedFunctionsService){}

  ngOnInit(){
    // disables readonly when no fields are defined
    if(!this.readonlyFields){
      this.element.readOnly = false;
    }    
    // checks if the form contains any stored data to retrieve and display
    if (this.formData && this.element.id) {
      this.prefilledValue = this.sharedFunctions.retrieveFormData(this.formData, this.element.id);
    }
  }

  selectValue(elem: any, element: any) {    
    const item = {
      value: elem.trim(),
      name: element.id
    };
    
    this.elementEvent.emit(item);
  } 
}
