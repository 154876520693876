
<mat-card-content class="detailstab-container ">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <div mat-dialog-content  *ngIf="formData">
   
        <div class="row three-columns">        
         
            <h2>Task Details</h2>
    
            
            <div class="item" *ngFor="let element of formItems?.formModel?.fields; let i = index">
                <!--*****INPUT TYPE*****-->     
                <input-elem class="full-width"
                *ngIf="element.type == 'text'"
                (elementEvent)="addSelectValue($event, element)"
                [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></input-elem>

                <!--*****DROPDOWN TYPE*****-->
                <select-elem class="full-width"
                *ngIf="element.type == 'dropdown'"
                (elementEvent)="addSelectValue($event, element)"
                [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></select-elem>

                <!--*****TEXTAREA TYPE*****--> 
                <textarea-elem class="full-width"
                *ngIf="element.type == 'textarea'"
                (elementEvent)="addSelectValue($event, element)"
                [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></textarea-elem>

                <!--*****DATEPICKER TYPE*****-->
                <datepicker-elem class="full-width"
                *ngIf="element.type == 'datepicker'"
                (elementEvent)="addSelectValue($event, element)"
                [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></datepicker-elem>
                
                <!--*****CHIPLIST TYPE*****-->
                <chiplist-elem class="full-width"
                *ngIf="element.type == 'chiplist'"
                (elementEvent)="addSelectValue($event, element)"
                [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></chiplist-elem>
            </div>

        </div>
   
        <div class="row three-columns">
    

            <h2>Asset Details</h2>

            <div class="item" *ngFor="let element of singleItemForm?.formModel?.fields; let i = index">
            <!--*****INPUT TYPE*****-->     
            <input-elem class="full-width"
            *ngIf="element.type == 'text'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></input-elem>

            <!--*****DROPDOWN TYPE*****-->
            <select-elem class="full-width"
            *ngIf="element.type == 'dropdown'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></select-elem>

            <!--*****TEXTAREA TYPE*****--> 
            <textarea-elem class="full-width"
            *ngIf="element.type == 'textarea'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></textarea-elem>

            <!--*****DATEPICKER TYPE*****-->
            <datepicker-elem class="full-width"
            *ngIf="element.type == 'datepicker'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></datepicker-elem>
            
            <!--*****CHIPLIST TYPE*****-->
            <chiplist-elem class="full-width" 
            *ngIf="element.type == 'chiplist'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></chiplist-elem>
            </div>

        </div>  

           <div class="row three-columns" >
       

            <h2>Additional Details</h2>


            <div class="item" *ngFor="let element of singleItemAdditionalDetails?.formModel?.fields; let i = index">
            <!--*****INPUT TYPE*****-->     
            <input-elem class="full-width"
            *ngIf="element.type == 'text'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></input-elem>

            <!--*****DROPDOWN TYPE*****-->
            <select-elem class="full-width"
            *ngIf="element.type == 'dropdown'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></select-elem>

            <!--*****TEXTAREA TYPE*****--> 
            <textarea-elem class="full-width"
            *ngIf="element.type == 'textarea'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></textarea-elem>

            <!--*****DATEPICKER TYPE*****-->
            <datepicker-elem class="full-width"
            *ngIf="element.type == 'datepicker'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></datepicker-elem>
            
            <!--*****CHIPLIST TYPE*****-->
            <chiplist-elem class="full-width" 
            *ngIf="element.type == 'chiplist'"
            (elementEvent)="addSelectValue($event, element)"
            [element]="element" [readonlyFields]="readonlyFields" [formData]="formData"></chiplist-elem>
            </div>

        </div> 

    </div>
</mat-card-content>