export class ModelCreateConsumables {
    id = null;
	dateCreated = "2023-05-26T11:34:02.890Z";
	createdBy = "admin";
	updatedBy = null;
	lastUpdated = null;
	task = {};
	consumable = [];
	servicesSet = [];
	expiryDate = null;
	batchNumber = null;
}
