export class ModelCreateTask {
    workarea = '';
    tasknumber = '';
    reporttypefilter = '';
    servicesSet = '';
    specificationSet = '';
    dueDate = '';
    assettype = '';
    connectiontype1 = '';
    connectiontype2 = '';
}