import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AddPersonnelComponent } from 'src/app/components/forms/create-report-personnel/add-personnel/add-personnel.component';
import { ModelCreatePersonnel } from 'src/app/models/model.create.personnel';
import { ApiPersonnelService } from 'src/app/services/api.personnel.service';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ApiWorkareasService } from 'src/app/services/api.workareas.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export interface DialogData {
  name: string;
  taskId: string;
  personnelList: any;
  action: string;
  element: any;
  moduleRoleDtos: any;
  tenantDto: any;
  id: string;
  userDto: any;
  user: any;
  tenant: any;
  workAreaDto: any;
}
@Component({
  selector: 'app-edit-userworkareas-dialog',
  templateUrl: './edit-userworkareas-dialog.component.html',
  styleUrls: ['./edit-userworkareas-dialog.component.scss']
})
export class EditUserworkareasDialogComponent {

  loadingRoles = false;
  loadingWorkareas = false;
  isLoading = false;
  validForm = false;
  @ViewChild('personnelInput')
  personnelInput!: ElementRef<HTMLInputElement>;
  @ViewChild('servicesInput')
  servicesInput!: ElementRef<HTMLInputElement>;
  roleControl =  new FormControl('');
  @Input() element: any;
  @Input() readonlyFields: any;
  @Input() formData: any;
  prefilledValue: any;
  @Output() elementEvent = new EventEmitter<any>();

  newPersonnelModel: any = new ModelCreatePersonnel();
  selectedPersonnel = <any>[];
  selectedServices = <any>[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ctrlPersonnel = new UntypedFormControl();
  ctrlServices = new UntypedFormControl();
  allPersonnel: Observable<any[]> | undefined;
  allServices: Observable<any[]> | undefined;
  availablePersonnel: any = [];
  availableServices: any = [];
  invalid = false;
  lookupsList: any;
  services: Observable<any[]>;
  filteredRoles: any = [];
  availableRoles: any = [];
  roleUpdated = false;
  allWorkareas: any;
  allModules: any;
  selectedWorkarea: any;
  selectedRole: any;
  selectedModule: any;

  constructor(
    private apiUsersService: ApiUsersService,
    private snackBar: MatSnackBar,
    private apiSitesService: ApiSitesService,
    private usersService: ApiUsersService,
    private apiWorkareasService: ApiWorkareasService,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AddPersonnelComponent>,
    private personnelService: ApiPersonnelService
  ) {}

  ngOnInit() {
    this.getWorkareas();
    //this.getModuleRolesWorkareas();
    if (this.dialogData.action == 'edit') {
      this.selectedWorkarea = this.dialogData.workAreaDto;
      this.selectedRole = this.dialogData.moduleRoleDtos[0];
      this.selectedModule = this.selectedRole;
    }
    if (this.dialogData.action == 'delete') {
      this.filteredRoles = this.dialogData.moduleRoleDtos;
    }
  }

  saveChanges(){
    this.isLoading = true;
    const tenantId = localStorage.getItem('selectedSiteId');   
    const userId = this.dialogData?.userDto?.id ? this.dialogData?.userDto?.id :this.dialogData.user.id;
    let params = {      
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      userId: userId,
      roleId: this.selectedRole.id,
      workareaId: this.selectedWorkarea.id

    };

    this.usersService.addUserWorkarea(params).subscribe(
      () => {
        console.log( 'user workareas changes saved');
        this.closePopup();
        this.snackBar.open( 'Changes were saved!', '', {duration: 3000});        
        this.componentsCommunication.setUpdateList('update_users');
        this.componentsCommunication.setUpdateList('update_sites');
        this.componentsCommunication.setUpdateList('update_workareas');
        this.componentsCommunication.setUpdateSites({name:'update_sites'});
        this.componentsCommunication.setUpdateUsers({name:'update_users'});
        this.componentsCommunication.setUpdateWorkAreas({name:'update_workareas'});
        this.isLoading = false;  
    },
      // error
      (msg) => {
        this.isLoading = false;  
        console.log( msg);
    }); 
  
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  validateFormdata(){
    this.validForm = false;
    if(this.dialogData.action != 'delete' && this.selectedWorkarea && this.selectedRole) {
      this.validForm = true;
    }
    if(this.dialogData.action == 'delete' && this.selectedRole) {
      this.validForm = true;
    }
  }
  
  manageSelectedWorkarea(){
    this.selectedModule = null;
    this.selectedRole = null;
    this.getModules();
    this.getModuleRolesWorkareas();
    this.validateFormdata();    
  }

  getWorkareas(){
    this.loadingWorkareas = true;     
    this.allWorkareas = [];
    const tenantId = localStorage.getItem('selectedSiteId');   
    const userId = this.dialogData?.userDto?.id ? this.dialogData?.userDto?.id :this.dialogData.user.id;
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      username: localStorage.getItem('username'),
      userSelected: userId,
    };

    this.apiWorkareasService.getActiveList(params).subscribe(
      (result: any) => {
       
        if(result.content.length > 0) {
          this.allWorkareas = result.content;
          
        } 
        this.loadingWorkareas = false;       
        
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.loadingWorkareas = false;
    });
  }
/*
  /**
   * deletes the selected element from the list
   * @param element selected element to be removed
   */
  deleteWorkareaRole(element: any) {
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      workAreaId: element.workAreaDto.id,
      userId: element.userDto.id,
      roleId: element.moduleRoleDtos[0].id,
    };
    const properties = {
      name: 'update_workareas',
      action: element.action,
    };
    this.apiUsersService.deleteUserWorkarea(params).subscribe(
      (result) => {
        this.closePopup();
        console.log('workarea role removed');
        this.snackBar.open('workarea role removed ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateWorkAreas(properties);
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          this.closePopup();
          console.log('workarea role removed');
          this.snackBar.open('workarea role removed ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateWorkAreas(properties);
        }
        console.log(msg.statusText);
      }
    );
  }

  filterRoles(event: any, element: any){
    if (event.isUserInput) { 
      if (!this.roleUpdated ) {
        if (this.selectedRole) {
          //this.selectedRole.moduleName = '';
        }
        this.filteredRoles = [];
        this.filteredRoles = this.availableRoles.filter(
        (option: any) => option.moduleName == element.name || option.moduleName == element.moduleName);
        this.roleUpdated = true;
      }
    }
  }
  //GET  /module/roles/workarea/list
  getModuleRolesWorkareas() {
    this.loadingRoles = true;
    const workareaId = this.selectedWorkarea?.id ? this.selectedWorkarea?.id : '';
    const tenantId = this.selectedWorkarea?.tenantDto?.id ? this.selectedWorkarea?.tenantDto?.id : '';
    const params = {
      tenantId: tenantId,
      workareaId: workareaId
    };

    this.usersService.getModuleRolesWorkareas(params).subscribe(
      (result: any) => {
       
        if(result.length > 0) {
          this.availableRoles = [...result];
          // filters the roles once loaded and when is edit mode
          if (this.dialogData.action == 'edit') {
            this.roleUpdated = false;
            this.filterRoles({isUserInput:true}, this.selectedModule);
          }
        }     
        this.loadingRoles = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.loadingRoles = false;
    });
          
  }

  //GET /module/list/workarea
  getModules() {
    if(this.selectedWorkarea && this.selectedWorkarea?.id) {
      this.loadingRoles = true;
      const tenantId = localStorage.getItem('selectedSiteId');    
      const workareaId = this.selectedWorkarea;     
      const type = 'privilege';
      const params = {
        workareaId: workareaId?.id ? workareaId?.id : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        lookupName: type
      };
      this.usersService.getModulesListWorkarea(params).subscribe(
        (result: any) => {        
          if(result.length > 0) {
            this.allModules = [...result];
          }     
          this.loadingRoles = false; 
        },
        // error
        (msg) => {
          console.log('error retrieving lookup list ' + msg);
          this.loadingRoles = false;
      });
    }
  }

}
