import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { Subscription } from 'rxjs';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { AvatarsSignaturesDialogComponent } from './avatars-signatures-dialog/avatars-signatures-dialog.component';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { EditUsersitesDialogComponent } from './edit-usersites-dialog/edit-usersites-dialog.component';
import { ApiWorkareasService } from 'src/app/services/api.workareas.service';
import { EditWorkareasDialogComponent } from './edit-workareas-dialog/edit-workareas-dialog.component';
import { EditUserworkareasDialogComponent } from './edit-userworkareas-dialog/edit-userworkareas-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'my-users',
  templateUrl: './my-users.component.html',
  styleUrls: ['./my-users.component.scss'],
})
export class MyUsersComponent {
  @Input() selectedElement: any;
  usersUpdate!: Subscription;
  sitesUpdate!: Subscription;
  getRolesListUpdate!: Subscription;
  @ViewChild('searchInput')
  searchInput!: ElementRef<HTMLInputElement>;
  reducedColumns: string[] = ['users', 'active', 'actions'];
  fullColumns: string[] = [
    'name',
    'email',
    'username',
    'location',
    'active',
    'actions',
  ];
  displayedColumnsSites: string[] = [
    'title',
    'licenseLevel',
    'role',
    'actions',
  ];
  displayedColumnsWorkareas: string[] = [
    'title',
    'site',
    'siteSupplier',
    'role',
    'active',
    'actions',
  ];
  displayedColumnsRoles: string[] = ['role', 'tenants', 'workAreas'];
  displayedColumns: any;
  dataSourceSites: any = [];
  dataSourceWorkareas: any = [];
  dataSourceRoles: any = [];
  isLoading = false;
  isLoadingSites = false;
  contentLoading = false;
  displayDetails = false;
  detailsLoading = false;
  isLoadingRoles = false;
  isLoadingWorkareas = false;
  resetPassClicked = false;
  activeRowId = '';
  dataSource: any;
  searchFilter = '';
  selectedItem: any;
  detailsTab = 0;
  currentUser: any;
  roles = {
    isAdmin: false,
    imReader: false,
    systemRoles: [],
    allRoles: [],
    tenantRoles: [],
  };
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };

  constructor(
    public rolesService: RolesService,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiUsersService: ApiUsersService,
    private apiSitesService: ApiSitesService,
    private apiWorkareasService: ApiWorkareasService,
    private componentsCommunication: ComponentsCommunicationService,
    public sharedFunctions: SharedFunctionsService
  ) {}

  ngOnInit() {
    this.displayedColumns = this.fullColumns;
    this.currentUser = localStorage.getItem('username'); 
    this.getData();
    //this.getSites();
    //this.getWorkareas();
    this.getRolesListUpdate = this.componentsCommunication.getRolesList().subscribe(data => {
      // is admin
      if (data && data.length > 0 &&  data[0].tenantId === null && this.rolesService.systemAdmin(data[0].roles)){
        this.roles.isAdmin = true;      
      }
      if (data && data.length > 1){
        this.roles.systemRoles = data[0].roles;
        this.roles.allRoles = data.slice(1);
      }  
      });

    //used to update the sites tab
    this.sitesUpdate = this.componentsCommunication
      .getUpdateSites()
      .subscribe((params: any) => {
        // updates the list
        if (params && params.name == 'update_sites') {
          if (params.action != 'tenant_update') {
            this.getSites();
          }
          const tenantId = localStorage.getItem('selectedSiteId');

          // when the tenant dropdown triggers a tenant changes, it has to review the roles
          if (
            tenantId &&
            tenantId.length > 0 &&
            params.action == 'tenant_update'
          ) {
            this.resetShowDetails();
          /*  this.roles.tenantRoles = this.rolesService.checkTenantRoles(
              this.roles.allRoles,
              tenantId
            );*/
          }
          // when no tenant is selected, applies the system roles
          if (tenantId?.length == 0) {
           // this.roles.tenantRoles = this.roles.systemRoles;
          }          
        }
      });

    //used to update the workareas tab
    this.sitesUpdate = this.componentsCommunication
      .getUpdateWorkAreas()
      .subscribe((params: any) => {
        // updates the list
        if (params && params.name == 'update_workareas' && this.selectedItem && this.selectedItem?.user && (this.roles.isAdmin ||
        this.rolesService.systemAdmin(this.roles.systemRoles))) {
          this.getWorkareas();
          this.getRoles();
        }
      });

    this.usersUpdate = this.componentsCommunication
      .getUpdateUsers()
      .subscribe((params: any) => {
        // updates the list
        if (params && params.name == 'update_users') {
          if (this.selectedItem?.user && (this.roles.isAdmin ||
            this.rolesService.systemAdmin(this.roles.systemRoles))) {
            this.getRoles();
          }
          this.getData(params.action);
          if (this.selectedItem && params.type && params.type == 'signature' && params.action != 'tenant_update') {
            this.loadSignature(this.selectedItem);
            this.loadDetails(this.selectedItem);
          }
          if (this.selectedItem && params.type && params.type == 'avatar' && params.action != 'tenant_update') {
            this.loadAvatar(this.selectedItem);
            this.loadDetails(this.selectedItem);
          }
        }
      });
  }

  ngOnDestroy() {
    this.usersUpdate.unsubscribe();
    this.sitesUpdate.unsubscribe();
    this.getRolesListUpdate.unsubscribe();
  }
  getWorkareas(action?: string) {
    this.isLoadingWorkareas = true;
    this.dataSourceWorkareas = [];
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      elemId: this.selectedItem.user.id,
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
    };

    this.apiWorkareasService.listWorkAreas(params).subscribe(
      (result: any) => {
        if (result.length > 0) {
          this.dataSourceWorkareas = result;
        }
        this.isLoadingWorkareas = false;
      },
      // error
      (msg) => {
        console.log('error retrieving the list ' + msg);
        this.isLoadingWorkareas = false;
      }
    );
  }

  getRoles() {
    this.isLoadingRoles = true;
    this.dataSourceRoles = [];
    const tenantId = localStorage.getItem('selectedSiteId');
    const userId = localStorage.getItem('username');
    const params = {
      elemId: this.selectedItem?.user ? this.selectedItem?.user?.id : userId,
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
    };

    this.apiUsersService.getRoles(params).subscribe(
      (result: any) => {
       
        if(result.length > 0) {
          this.dataSourceRoles = result;          
        } 
        this.isLoadingRoles = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving the list ' + msg);
        this.isLoadingRoles = false;
      }
    );
  }

  getSites() {
    this.isLoadingSites = true;
    this.dataSourceSites = [];
    const tenantId = localStorage.getItem('selectedSiteId');
    const userId = this.selectedItem
      ? this.selectedItem?.user?.id
      : '';
    const params = {
      elemId: userId,
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
    };

    if (userId){
      this.apiSitesService.getUserSiteslist(params).subscribe(
        (result: any) => {
          if (result.length > 0) {
            this.dataSourceSites = result;
          }
          this.isLoadingSites = false;
        },
        // error
        (msg) => {
          console.log('error retrieving sites list ' + msg);
          this.isLoadingSites = false;
        }
      );
    }
  }

  getData(action?: string) {
    this.isLoading = true;
    this.dataSource = [];
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'asc',
      sortColumn: 'dateCreated',
    };

    this.apiUsersService.getUserList(params).subscribe(
      (result: any) => {
        if (result.content.length > 0) {
          this.dataSource = result.content;
          this.paginatorConfig.length = result.numberOfElements;
          //this updates the current selected item
          if (this.selectedItem && this.selectedItem?.id) {
            this.selectedItem = this.dataSource.find((elem: any)=>this.selectedItem.id == elem.id);
            this.loadAvatar(this.selectedItem);
            this.loadSignature(this.selectedItem);
          }
        
        }
        this.isLoading = false;
        //keeps details visible if they were visible
        if (
          this.displayDetails &&
          result.content.length > 0 &&
          action &&
          action == 'disable'
        ) {
          this.loadDetails(this.selectedItem);
        }
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoading = false;
      }
    );
  }
  createSite() {}
  editWorkarea(element: any) {
    const dialogRef = this.dialog.open(EditWorkareasDialogComponent, {
      height: 'auto',
      width: '40%',
      disableClose: true,
      autoFocus: false,
      data: element,
    });
  }
  //triggers the search
  userSearch(){
    //this.isLoading = true;
    this.contentLoading = true;
    let searchTerm: any;
    searchTerm = encodeURIComponent(this.searchInput.nativeElement.value);
    //searchTerm = searchTerm.trim().length > 0 ? searchTerm.trim() : '*' ;
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      searchTerm: searchTerm,
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'desc',
    };

    if (searchTerm.trim().length > 0) {
      this.apiUsersService.searchUsers(params).subscribe(
        (result: any) => {
          //if (Object.keys(result).length > 0) {
          if (result.content.length > 0) {
            this.dataSource = result.content;
            this.paginatorConfig.length = result.totalElements;
          } else {
            // empty results
            this.dataSource = [];
            this.paginatorConfig.length = 0;
            this.displayDetails = false;
          }
          this.contentLoading = false;
        },
        // error
        (msg) => {
          console.log(msg);
          this.dataSource = [];
          this.isLoading = false;
          this.contentLoading = false;
        }
      );
    } else if (searchTerm.trim().length == 0) {
      this.contentLoading = false;
      this.resetShowDetails();
      this.getData();
    }
  }

  deleteRole(element: any, type: string) {
    if (type == 'delete_siterole'){
      if (element && element.moduleRoleDtos.length > 1) {
        this.editUserSites(element, 'delete');
      }
      if (element && element.moduleRoleDtos.length == 1) {
        this.confirmation(type, element);
      }
    } else if (type == 'delete_workarearole'){
      if (element && element.moduleRoleDtos.length > 1) {
        this.editUserWorkareas(element, 'delete');
      }
      if (element && element.moduleRoleDtos.length == 1) {
        this.confirmation(type, element);
      }
    }

  }

  editUserSites(element: any, action: string) {
    element.action = action;
    const width = action == 'delete' ? '300px' : '60%';
    const dialogRef = this.dialog.open(EditUsersitesDialogComponent, {
      height: 'auto',
      width: width,
      disableClose: true,
      autoFocus: false,
      data: element,
    });
  }

  editUserWorkareas(element: any, action: string) {
    element.action = action;
    const width = action == 'delete' ? '300px' : '60%';
    const dialogRef = this.dialog.open(EditUserworkareasDialogComponent, {
      height: 'auto',
      width: width,
      disableClose: true,
      autoFocus: false,
      data: element,
    });
  }

  preview(element: any) {}

  loadDetails(element: any) {

    if (
      (
        this.roles.isAdmin ||
        this.rolesService.systemAdmin(this.roles.systemRoles) ||
        this.rolesService.supervisor(this.roles.systemRoles)) &&
      (!this.selectedItem || element.id != this.selectedItem.id)
    ) {
      this.displayDetails = true;
      this.detailsLoading = true;
      this.resetPassClicked = false;
      this.displayedColumns = this.reducedColumns;
      this.selectedItem = element;
      this.activeRowId = element.id;

      this.getSites();
      this.getWorkareas();
      this.getRoles();

      // when the element has avatar to be loaded
      if (element) {
        this.loadAvatar(element);
      } else {
        this.detailsLoading = false;
      }

      // when the element has signature to be loaded
      if (element) {
        this.loadSignature(element);
      } else {
        this.detailsLoading = false;
      }
    } else if (this.currentUser == element?.user?.id) {
      this.displayDetails = true;
      this.detailsLoading = true;      
      this.displayedColumns = this.reducedColumns;
      this.selectedItem = element;
      this.activeRowId = element.id;
      this.loadAvatar(element);
      this.loadSignature(element);
    }
  }

  loadSignature(element: any) {
    const params = {
      itemId: element.id,
      tenantId: element.tenant.id,
      type: 'signature',
    };
    this.apiUsersService.getAvatarSignature(params).subscribe(
      (result) => {
        if (result) {
          element.urlSignature = result;
        }
        this.detailsLoading = false;
      },
      // error
      (msg) => {
        console.log(msg);
        this.detailsLoading = false;
      }
    );
  }

  loadAvatar(element: any) {
    const params = {
      itemId: element.id,
      tenantId: element.tenant.id,
      type: 'avatar',
    };
    this.apiUsersService.getAvatarSignature(params).subscribe(
      (result) => {
        if (result) {
          element.urlAvatar = result;
        }
        this.detailsLoading = false;
      },
      // error
      (msg) => {
        console.log(msg);
        this.detailsLoading = false;
      }
    );
  }
  /**
   * resets the persisting data from showing the asset details
   */
  resetShowDetails() {
    this.activeRowId = '';
    this.displayDetails = false;
    this.selectedItem = {};
    this.detailsTab = 0;
    this.displayedColumns = this.fullColumns;
  }

  editSelected(element: any) {
    this.createUser(element.user);
  }

  resetPassword(element: any) {
    this.resetPassClicked = true;
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      userId: element.user.id,
    };
    this.authService.adminResetPassword(params).subscribe(
      (data: any) => {
        //ok
        this.snackBar.open('The email was sent ', '', { duration: 3000 });
        this.resetPassClicked = false;
      },
      (error) => {
        this.resetPassClicked = false;
        this.snackBar.open('Error sending the email', '', { duration: 5000 });
        console.log(error);
      }
    );
  }

  /**
   * deletes the selected element from the list
   * @param element selected element to be removed
   */
  deleteUserWorkarea(element: any) {
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      workAreaId: element.workAreaDto.id,
      userId: element.userDto.id,
      roleId: element.moduleRoleDtos[0].id,
    };
    const properties = {
      name: 'update_workareas',
      action: element.action,
    };
    this.apiUsersService.deleteUserWorkarea(params).subscribe(
      (result) => {
        console.log('workarea removed');
        this.snackBar.open('workarea removed ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateWorkAreas(properties);
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('workarea removed');
          this.snackBar.open('workarea removed ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateWorkAreas(properties);
        }
        console.log(msg.statusText);
      }
    );
  }

  activateUser(element: any) {
    const params = {
      userId: element.id,
      tenantId: element.tenant.id,
    };
    const properties = {
      name: 'update_users',
      action: element.action,
    };
    this.apiUsersService.activateUser(params).subscribe(
      (result) => {
        console.log('User Activated');
        this.snackBar.open('User Activated ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateUsers(properties);
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('User Activated');
          this.snackBar.open('User Activated ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateUsers(properties);
        }
        console.log(msg.statusText);
      }
    );
  }

  editImage(element: any, type: string) {
    element.type = type;
    element.action = 'edit';    
    const dialogRef = this.dialog.open(AvatarsSignaturesDialogComponent, {
      minWidth: '410px',
      disableClose: true,
      autoFocus: false,
      data: element,
    });
  }

  viewLogo(element: any) {}

  confirmation(action: string, elem?: any) {
    elem.action = action;
    elem.confirmation = false;
    if (action == 'disable') {
      elem.confirmationString = elem.active
        ? 'Disable the selected user?'
        : 'Enable the selected user?';
    }
    if (action == 'delete_workarearole') {
      elem.confirmationString = 'Delete the selected workarea?';
    }
    if (action == 'delete_siterole') {
      elem.confirmationString = 'Delete the selected role?';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.confirmation && action == 'disable') {
        this.activateUser(elem);
      }
      if (data.confirmation && action == 'delete_workarearole') {
        this.deleteUserWorkarea(elem);
      }
      if (data.confirmation && action == 'delete_siterole') {
        this.deleteSiteRole(elem);
      }
    });
  }

  deleteSiteRole(element: any) {
    const params = {
      tenantId: element.tenantDto.id,
      userId: element.userDto.id,
      roleId: element.moduleRoleDtos[0].id,
    };
    this.apiUsersService.deleteSiteRole(params).subscribe(
      () => {
        this.snackBar.open('The role was deleted', '', { duration: 3000 });
        this.componentsCommunication.setUpdateList('update_users');
        this.componentsCommunication.setUpdateList('update_sites');
        this.componentsCommunication.setUpdateSites({ name: 'update_sites' });
        this.componentsCommunication.setUpdateUsers({ name: 'update_users' });
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('role was removed');
          this.snackBar.open('The role was deleted', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateList('update_users');
          this.componentsCommunication.setUpdateList('update_sites');
          this.componentsCommunication.setUpdateSites({ name: 'update_sites' });
          this.componentsCommunication.setUpdateUsers({ name: 'update_users' });
        } else {
          console.log(msg);
        }
      }
    );
  }

  tabChanged(tabChangeEvent: any) {
    this.detailsTab = tabChangeEvent.index;
  }

  createUser(user?: any) {
    const params = {
      userId: user?.id ? user.id : '',
      firstLastName: user?.firstName ? user?.firstName + ' ' + user?.lastName  : '',
      action: user ? 'edit' : 'create',
    };

    this.dialog.open(EditUserDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      data: params,
    });
  }

  create(id?: string) {
    const params = {
      userId: id ? id : '',
      action: id ? 'edit' : 'create',
    };

    this.dialog.open(AvatarsSignaturesDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      data: params,
    });
  }

  /**
   * handles the pagination ant the update of the results
   * @param evt click event
   */
  handlePage(evt: any) {
    //this.displayDetails = false;
    this.paginatorConfig.pageIndex = evt.pageIndex;
    this.paginatorConfig.pageSize = evt.pageSize;
    this.getData();
  }
}
