export class ModelResultAttachment {
    id = null;
    taskDto = {};
    assetDto = {};
    taskAssetsResultsDto = {};
    title = '';
    description = '';
    name = '';
    type = '';
    hashName = '';
    deleted = false;
    createdBy = '';
    updatedBy = ''; 
    lastUpdated = null; 
    dateCreated = null; 
}

