import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { RolesService } from './roles.service';
import { ComponentsCommunicationService } from './components-communication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedFunctionsService {
  constructor(
    private router: Router,
    private componentsCommunication: ComponentsCommunicationService,
    private apiService: ApiService,
    public rolesService: RolesService
  ) {}

  /**
   * iterates and retrieves the values from the formData
   * @param form the form to iterate
   * @param elementId id of the element to retrieve it's saved value
   * @returns returns the stored value if found
   */
  retrieveFormData(form: any, elementId: string) {
    let retrievedData = null;
    // iterates the map elements and checks if it has any stored value,
    // after that, compares the element ID with the key, to know what's the stored element's ID
    Object.entries(form).forEach(([key, value]) => {
      if (
        (value &&
          (typeof value == 'string' || typeof value == 'number') &&
          value.toString().length > 0) ||
        (value && typeof value == 'object' && Object.keys(value).length > 0)
      ) {
        if (key.toLowerCase() == elementId) {
          retrievedData = value;
        }
      }
    });
    return retrievedData;
  }
  /**
   *
   * @param formData form to be iterated
   * @param element element to be found
   * @returns
   */
  getModelKeyValue(formData: any, element: any): any {
    let data: any = {
      id: '',
      type: '',
    };
    // iterates and searches for the correct key name, in order to store the data using the key
    Object.entries(formData).forEach(([key, value]) => {
      if (key.toLowerCase().includes(element.id.toLowerCase())) {
        //Special case for connectiontype
        if (
          element &&
          element.id &&
          element.id.toLowerCase() == 'connectiontype'
        ) {
          const connectionTypeIndex = element?.name.substr(
            element?.name.length - 1
          );
          if (
            connectionTypeIndex &&
            key.toLowerCase() == element.id.toLowerCase() + connectionTypeIndex
          ) {
            value = element.id + connectionTypeIndex;
            data.id = key.toString();
            data.type = typeof value;
          }
        } else if (
          element.id == 'drawingNumber' ||
          element.id == 'workOrder' ||
          element.id == 'taskNumber'
        ) {
          data.id = key.toString();
          data.type = 'string';
        } else {
          data.id = key.toString();
          data.type = typeof value;
        }
      }
    });

    return data;
  }

  /**
   * handles the new values and stores it into the form
   * @param form the new form
   * @param element selected element
   * @param selectValue entered value to save
   * @returns return the updated form
   */
  saveFormValue(form: any, element: any, selectValue: any): object {
    let selectedElem: any;
    // iterates and searches for the correct key name, in order to store the data using the key
    selectedElem = this.getModelKeyValue(form, element);
    // stores the data to the formData model map
    if (selectedElem) {
      //const dataType: any = this.sharedFunctions.prepareDataToStore(selectedElem);

      if (
        selectedElem &&
        selectedElem.type == 'object' &&
        !selectValue.removeItem
      ) {
        let value;
        if (
          selectedElem.id == 'servicesSet' ||
          selectedElem.id == 'specificationSet'
        ) {
          form[selectedElem.id as keyof typeof form].push(selectValue);
        } else {
          value = selectValue;
          //used to store dates
          if (selectValue && typeof selectValue.value == 'number') {
            form[selectedElem.id as keyof typeof form] = selectValue.value;
          }
          //used to store special field bodyConnections
          if (selectedElem && selectedElem.id == 'bodyConnections') {
            form[selectedElem.id as keyof typeof form] = parseInt(
              selectValue.name
            );
          }
          if (selectValue && selectValue.id) {
            form[selectedElem.id as keyof typeof form] = value;
          }
        }

        // to remove unselected chiplist options
      } else if (selectValue.removeItem) {
        const index = form[selectedElem.id].findIndex(
          (item: any) => item.name == selectValue.removeItem
        );
        if (index >= 0) {
          form[selectedElem.id].splice(index, 1);
        }
      } else if (selectedElem && selectedElem.id == 'bodyConnections') {
        form[selectedElem.id as keyof typeof form] = parseInt(selectValue.name);
      } else if (
        selectedElem &&
        (selectedElem.type == 'number' ||
          selectedElem.type == 'boolean' ||
          selectedElem.type == 'string')
      ) {
        const saveValue =
          element.type == 'dropdown' ? selectValue : [selectValue];
        form[selectedElem.id as keyof typeof form] =
          typeof selectValue.value != 'undefined'
            ? selectValue.value
            : saveValue;
      }
    } else {
      console.log(
        ' was not able to save form data with element id ' + element.id
      );
    }
    return form;
  }

  /**
   * function used to create a delay effect
   * @param ms time to delay
   */
  async delay(ms: number) {
    await new Promise<void>((resolve) =>
      setTimeout(() => resolve(), ms)
    ).then();
  }

  /**
   * used to call backend and enable the services
   */
  wakeUpMethod(){
    this.apiService.wakeUpMethod().subscribe(
      (result: any) => {
        //console.log('wakeup method called');
      },
      // error
      (msg) => {
        //console.log('error calling wakeup method ' + msg);
    });
  }

  /**
   * randomly generates a string of numbers
   * @param length numer string length
   * @returns returns the generated number
   */
  generateRandomNumber(length: number): any {
    return Math.random().toString().substr(2, length);
  }


  getTenantFiltered():any {
    if(localStorage.getItem('selectedSiteId') == null || typeof(localStorage.getItem('selectedSiteId')) == 'undefined') {
      return '';
    } 
    else {
      return localStorage.getItem('selectedItem');
    }
  } 
  getWorkAreaFiltered():any {
    if(localStorage.getItem('selectedWorkAreaId') == null || typeof(localStorage.getItem('selectedWorkAreaId')) == 'undefined') {
      return '';
    } 
    else {
      return localStorage.getItem('selectedWorkAreaId');
    }
  } 

  applyPermissions(roles:any):any{
  let tenantRoles:any;
    const tenantId = localStorage.getItem('selectedSiteId');
    // when the tenant dropdown triggers a tenant changes, it has to review the roles
    if (
      tenantId &&
      tenantId.length > 0
    ) {
      tenantRoles = this.rolesService.checkTenantRoles(
        roles.allRoles,
        tenantId
      );            
    }
    // when no tenant is selected, applies the system roles
    if (tenantId?.length == 0) {
      tenantRoles = roles.systemRoles;
    }
    return tenantRoles;
  }

  applyPermissionsWorkareas(workareaRoles: any):any{
    let roles: any;
      const workareaId = localStorage.getItem('selectedWorkareaId');
      // when the tenant dropdown triggers a tenant changes, it has to review the roles
      if (
        workareaId &&
        workareaId.length > 0
      ) {
        roles = this.rolesService.checkWorkareaRoles(
          workareaRoles.allWorkareaRoles,
          workareaId
        );            
      }
      // when no workarea is selected, applies the system roles
      if (workareaId?.length == 0) {
        roles = workareaRoles.systemRoles;
      }
      return roles;
    }

  checkPermissions(roles: any){
    debugger
    return this.rolesService.WMEditor(roles);    
  }

}
