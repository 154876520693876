import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'yesno-select-services-results',
  templateUrl: './yesno-select-services-results.component.html',
  styleUrls: ['./yesno-select-services-results.component.scss']
})
export class YesNoSelectServicesResultsComponent implements OnInit {
  @Input() element:any;
  @Input() readonlyFields:any;
  @Input() formData:any;   
  prefilledValue: any;
  selectedElement: any = [];
  @Output() elementEvent = new EventEmitter<string>();
  myControl = new FormControl('');
  lookupsList: any;
  autocomplete = 'on';
  invalid = false;
  lookupName: any;
  filteredOptions: Observable<any>;

  constructor(
    private apiService: ApiService,
    private sharedFunctions: SharedFunctionsService,
    private componentsCommunication: ComponentsCommunicationService){}
  
  ngOnInit(){
    this.lookupName = this.element.datapointCatalog.lookupName;

    this.apiService.getLookupLists(this.lookupName).subscribe(
      (result: any) => {
        this.lookupsList = result.lookups;
        if (result.lookups && result.lookups.length > 0) {
          this.element.options = result.lookups;
        }          
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );

      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
    }); 

    //prefill
    if (this.element.multiValue && this.element.multiValue.length > 0) {
      this.prefilledValue = this.element.multiValue[0];
    }
    

  }

  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();

    return this.element.options.filter((option: any) => (option.name && option.name.toLowerCase().includes(filterValue)));
  }

    /**
   * sets the value for the selected element
   * @param elem with value to set
   * @param element current element
   */
  selectValue(elem: any, element: any, event: any) {    
    if (event.isUserInput) {   
      this.selectedElement = elem;
      this.invalid = false;
      const value = elem.name ? elem.name : elem;
      const elemEvent: any = {
        id :element.id,
        name : value,
        lookupName: element.datapointCatalog.lookupName
      }
      this.elementEvent.emit(elemEvent);  
    }
  }

  /**
   * resets the value for the selected element
   * @param elem with value to set
   * @param element current element
   */
  resetValue(elem: any, element: any) {    
    //*****TODO add endpoint that retrieves th asset category
    if (element.datapointCatalog.id.toLowerCase() == 'assettype') {
      const item = {
        name : 'assetcategory',
        value : elem
      }
      this.componentsCommunication.setUpdateTasks(item);
    } 
    const elemEvent: any = {
      id :element.id,
      name : elem,
      remove: element.id
    }
    this.elementEvent.emit(elemEvent);  
  }

  valueChange(targetElem: any) {
    const exists = this.element.options.find((elem:any) => elem.name.toLowerCase().includes(targetElem.target.value.trim().toLowerCase()));
    if (!exists) {
      this.invalid = true;
      const elem: any = {};
      // when the entered value doesn't match any valid options, it resets the previous values
      this.prefilledValue = '';
      targetElem.target.value = '';
      this.componentsCommunication.setUpdateTasks(elem);
      this.elementEvent.emit(elem);
    } else{
      this.invalid = false;
    }
    
  }
}
