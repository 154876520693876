<div class="dialogform-container personnel">
    <div class="v-window-header">
        <span *ngIf="dialogData.action == 'create'">Assign workarea to {{dialogData.user.firstName}} {{dialogData.user.lastName}}</span>
        <span *ngIf="dialogData.action == 'edit'">Edit workarea</span>
        <span *ngIf="dialogData.action == 'delete'">Delete workarea's role of {{dialogData.userDto.id}}</span>
        <div [mat-dialog-close]="true" class="v-window-closebox">
          <mat-icon class="icon action">close</mat-icon>
        </div>
      </div>

    <mat-spinner *ngIf="loadingRoles || loadingWorkareas || isLoading"></mat-spinner>
    <mat-card-content *ngIf="!loadingWorkareas && !loadingRoles && !isLoading">

        <div mat-dialog-content>
            <div class="columns">

                <div class="column" *ngIf="dialogData.action != 'delete'">
                    <h4>Workarea
                        <span class="required_item"> *</span>
                    </h4>             
                    <mat-form-field  class="custom-width" appearance="outline">
                        <mat-select [placeholder]="selectedWorkarea?.name" (selectionChange)="manageSelectedWorkarea()">
                            <mat-option *ngFor="let elem of allWorkareas"
                            (onSelectionChange)="selectedWorkarea = elem"
                            [value]="elem.name">
                                {{elem.name}}    
                                </mat-option> 
                        </mat-select>
                    </mat-form-field>    

                </div>

                <div class="column" *ngIf="dialogData.action != 'delete'">
                    <h4>Modules
                        <span class="required_item"> *</span>
                    </h4>             
                    <mat-form-field  class="custom-width" appearance="outline">
                        <mat-select 
                        [disabled]="!selectedWorkarea?.name"
                        [placeholder]="selectedRole?.moduleName" 
                        (selectionChange)=" validateFormdata()">
                            <mat-option *ngFor="let elem of allModules"
                            (onSelectionChange)="selectedModule = elem; roleUpdated = false;filterRoles($event, selectedModule)"
                            [value]="elem.name">
                                {{elem.name}}    
                                </mat-option> 
                        </mat-select>
                    </mat-form-field>    

                </div>

                <div class="column">
                    <h4>Role
                        <span class="required_item"> *</span>
                    </h4>                    
                    <mat-form-field  class="custom-width" appearance="outline">
                        <mat-select 
                        [disabled]="!selectedModule && dialogData.action != 'delete'"
                        [placeholder]="selectedRole?.displayName" (selectionChange)="validateFormdata()">
                            <mat-option *ngFor="let elem of filteredRoles"
                            (onSelectionChange)="selectedRole = elem"
                            [value]="elem.displayName">
                            {{elem.displayName}}    
                            </mat-option> 

                        </mat-select>
                    </mat-form-field>

                </div>


                      
            </div>
        </div>
    
        <div mat-dialog-actions>
    
            <button *ngIf="dialogData.action != 'delete'"
            mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
               Save
               <mat-icon class="icon action">save</mat-icon>
           </button>
           <button *ngIf="dialogData.action == 'delete'"
           mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="deleteWorkareaRole(dialogData);">
               Delete
               <mat-icon class="icon action">delete</mat-icon>
           </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    