import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiResultsService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  //api/tenant/equipment/{tenantId}/{taskId}/list
  getTaskEquipment(params: any) {
    const filters = `?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortField}&sort=${params.sort}`;
    const endpoint = `/api/wm/task/${params.taskId}/equipments/list` + filters;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //api/tenant/task/datapointValue/create/{tenantId}/{taskId}/result/{assetId}
  createResultsDatapoint(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/result/asset/${params.assetId}/create`;
    return this.http.post(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/datapointValue/{tenantId}/{taskId}/list
  getAllResults(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/list`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/datapointValue/getResultForms/{tenantId}/{id_process}/{taskId}
  getResultForms(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/process/${params.idProcess}/resultForm/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/datapointValue/{tenantId}/{taskId}/{assetId}/services
  getResultDatapointValuesServices(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/${params.assetId}/services`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/datapointValue/{tenantId}/{taskId}/{assetId}/getResultsValues/{serviceId}
  getResultDatapointValues(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/asset/${params.assetId}/service/${params.serviceId}/results/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/task/datapointValue/{tenantId}/{taskId}/deleteAsset/{assetId}
  deleteResult(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/asset/${params.assetId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/task/observations/{tenantId}/{datapointValueId}/create
  createDatapointCatalogObservation(params: any) {
    const endpoint = `/api/wm/task/observations/${params.datapointValueId}/create`;
    return this.http.post(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)    
    });
  }

  //api/task/observations/{tenantId}/{datapointValueId}/list
  getDatapointCatalogObservations(params: any) {
    const endpoint = `/api/wm/task/observations/${params.datapointValueId}/list`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/task/observations/{tenantId}/updateObservation
  updateDatapointCatalogObservation(params: any) {
    const endpoint = `/api/wm/task/observations/update`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/task/observations/{tenantId}/deleteObservation/{obs_id}
  deleteResultObservation(params: any) {
    const endpoint = `/api/wm/task/observations/${params.observableId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/datapoints/{tenantID}/{datapointVlaueId}/attachments/list
  getresultServiceAttachment(params: any) {
    const endpoint = `/api/wm/datapoints/datapoints/${params.datapointValueId}/attachments/list`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/tasks/result/{tenantId}/{taskId}/{resultId}/attachments/list
  getResultsAttachments(params: any) {
    const endpoint = `/api/wm/tasks/${params.taskId}/result/${params.resultId}/attachments/list`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //equipment/{tenantId}/get/{equipmentId}
  getResultDetails(params: any) {
    const type = params.type == 1 ? 'equipment' : 'consumable';
    const endpoint = `/api/im/${type}/${params.equipmentId}/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  ///task/datapointValue/{tenantId}/{taskId}/{assetId}/update/{servicesId}
  saveResultService(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/asset/${params.assetId}/service/${params.serviceId}/update`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/utils/datapoints/document/{tenantId}/{hashName}/{taskId}/dt/{datapointValueId}/delete
  deleteResultServiceAttachment(params: any) {
    const endpoint = `/api/wm/datapoints/document/${params.hashName}/task/${params.taskId}/dt/${params.datapointValueId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }
}
