import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AddPersonnelComponent } from 'src/app/components/forms/create-report-personnel/add-personnel/add-personnel.component';
import { ModelCreatePersonnel } from 'src/app/models/model.create.personnel';
import { ApiPersonnelService } from 'src/app/services/api.personnel.service';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export interface DialogData {
  name: string;
  taskId: string;
  personnelList: any;
  action: string;
  element: any;
}
@Component({
  selector: 'app-edit-workareas-dialog',
  templateUrl: './edit-workareas-dialog.component.html',
  styleUrls: ['./edit-workareas-dialog.component.scss']
})
export class EditWorkareasDialogComponent {
  loadingRoles = false;
  loadingSites = false;
  isLoading = false;
  validForm = false;
  @ViewChild('personnelInput')
  personnelInput!: ElementRef<HTMLInputElement>;
  @ViewChild('servicesInput')
  servicesInput!: ElementRef<HTMLInputElement>;
  roleControl =  new FormControl('');
  @Input() element: any;
  @Input() readonlyFields: any;
  @Input() formData: any;
  prefilledValue: any;
  @Output() elementEvent = new EventEmitter<any>();

  newPersonnelModel: any = new ModelCreatePersonnel();
  selectedPersonnel = <any>[];
  selectedServices = <any>[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ctrlPersonnel = new UntypedFormControl();
  ctrlServices = new UntypedFormControl();
  allPersonnel: Observable<any[]> | undefined;
  allServices: Observable<any[]> | undefined;
  availablePersonnel: any = [];
  availableServices: any = [];
  invalid = false;
  lookupsList: any;
  services: Observable<any[]>;
  filteredRoles: Observable<any[]> | undefined;
  availableRoles: any = [];
  allSites:any;
  selectedSite: any;
  selectedRole: any;
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };
  constructor(
    private snackBar: MatSnackBar,
    private apiSitesService: ApiSitesService,
    private usersService: ApiUsersService,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AddPersonnelComponent>,
    private personnelService: ApiPersonnelService
  ) {}

  ngOnInit() {
    this.getSites();
    this.getRoles();
  }

  saveChanges(){
    this.isLoading = true;
    let params = {      
      tenantId: this.selectedSite.id,
      roleId: this.selectedRole.id
    };

    this.usersService.updateUserRoles(params).subscribe(
      () => {
        console.log( 'user sites changes saved');
        this.closePopup();
        this.snackBar.open( 'Changes were saved!', '', {duration: 3000});        
        this.isLoading = false;  
        this.componentsCommunication.setUpdateList('update_users');
        this.componentsCommunication.setUpdateList('update_sites');
    },
      // error
      (msg) => {
        this.isLoading = false;  
        console.log( msg);
    }); 
  

  }

  closePopup(): void {
    this.dialogRef.close();
  }

  validateFormdata(){
    this.validForm = false;
    if(this.selectedSite && this.selectedRole) {
      this.validForm = true;
    }
  }

  getSites(){
    this.loadingSites = true;     
    this.allSites = [];
    const type = 'privilege';

    const tenantId = localStorage.getItem('selectedSiteId');   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      lookupName: type,
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'asc',
      sortColumn: 'dateCreated'
    };

    this.apiSitesService.getTenantsList(params).subscribe(
      (result: any) => {
       
        if(result.length > 0) {
          this.allSites = result;
          
        } 
        this.loadingSites = false;       
        
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.loadingSites = false;
    });
  }
  getRoles() {
    this.loadingRoles = true;
    const type = 'privilege';
    const params = {
      lookupName: type
    };


    this.usersService.getLookupList(params).subscribe(
      (result: any) => {
       
        if(result.lookups.length > 0) {
          this.availableRoles = [...result.lookups];
        }     
        this.loadingRoles = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.loadingRoles = false;
    });
          
  }


}
