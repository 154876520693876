<div class="dialogform-container personnel">
    <div class="v-window-header">

        <span *ngIf="dialogData?.action == 'create'">Add Personnel</span>
        <span *ngIf="dialogData?.action == 'edit'">Edit Personnel</span>
        <div [mat-dialog-close]="true" class="v-window-closebox">
          <mat-icon class="icon action">close</mat-icon>
        </div>
      </div>

    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <mat-card-content *ngIf="!isLoading">

        <div mat-dialog-content [ngClass]="{'min-content': dialogData.action == 'edit'}">
            <div class="columns">
                <div class="column" *ngIf="dialogData?.action == 'create'">
                    <h4>Personnel
                        <span class="required_item"> *</span>
                    </h4>
                    <mat-form-field class="filter-type" appearance="fill"  *ngIf="allPersonnel">
                        <input 
                        (blur)="checkContent('personnel')"
                        (keyup)="valueChange($event.target.value, 'personnel')"
                        [matChipInputFor]="chipListPersonnel"
                        #personnelInput
                        [formControl]="ctrlPersonnel"
                        [matAutocomplete]="autoPersonnel" 
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addElem($event, 'personnel'); selectValue($event, 'personnel')"/>
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-chip-grid 
                        required="required" 
                        #chipListPersonnel aria-label="element.name">
                            <mat-chip-row
                            *ngFor="let item of selectedPersonnel"
                            (removed)="removeElem(item, 'personnel'); selectValue($event, 'personnel')">
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <span *ngIf="item?.userDto?.jobTitle">{{item.userDto.displayName}} | {{item.jobTitle}} </span>
                            <span *ngIf="!item?.userDto?.jobTitle">{{item.userDto.displayName}} </span>
                            <span *ngIf="!item?.userDto?.firstName">{{item}}</span> 
                            </mat-chip-row>
                        </mat-chip-grid>
                        <mat-autocomplete #autoPersonnel="matAutocomplete" (optionSelected)="selectedElem($event, 'personnel'); selectValue($event, 'personnel')">
                            <ng-container *ngFor="let elem of allPersonnel | async" >
                                <mat-option *ngIf="!checkIfExists(elem, 'personnel')" [value]="elem">
                                    {{elem?.userDto?.displayName}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                
                <div class="column2">
                    <h4>Services
                        <span class="required_item"> *</span>
                    </h4>
                    <mat-form-field class="filter-type" appearance="fill">
                        <input 
                        (blur)="checkContent('services')"
                        (keyup)="valueChange($event.target.value, 'services')"
                        [matChipInputFor]="chipListServices"
                        #servicesInput
                        [formControl]="ctrlServices"
                        [matAutocomplete]="autoServices" 
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addElem($event, 'services'); selectValue($event, 'services')"/>
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-chip-grid 
                        required="required" 
                        #chipListServices aria-label="element.name">
                            <mat-chip-row
                            *ngFor="let item of selectedServices"
                            (removed)="removeElem(item, 'services'); selectValue($event, 'services')">
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <span *ngIf="item.name">{{item.name}} </span>
                            <span *ngIf="!item.name">{{item}}</span> 
                            </mat-chip-row>
                        </mat-chip-grid>
                        <mat-autocomplete #autoServices="matAutocomplete" (optionSelected)="selectedElem($event, 'services'); selectValue($event, 'services')">
                            <ng-container *ngFor="let elem of allServices | async" >
                                <mat-option *ngIf="!checkIfExists(elem, 'services')" [value]="elem">
                                    {{elem.name}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>


                </div>
      
            </div>
        </div>
    
        <div mat-dialog-actions>
    
        <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    