import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiUsersService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  ///api/sys/lookup/${lookupName}/list?fieldValue=name
  getLookupList(params: any) {
    const endpoint = `/api/sys/lookup/${params.lookupName}/list?fieldValue=name`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeaders(),
    });

  }

  //GET /module/list/tenant
  getModulesListTenant(params: any) {
    const endpoint = `/api/sys/module/list/tenant?fieldValue=name`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }
  //GET /module/list/workarea
  getModulesListWorkarea(params: any) {
    const endpoint = `/api/sys/module/list/workarea?fieldValue=name`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //GET /api/sys/module/roles/tenant/list
  getModuleRoles(params: any) {
    const endpoint = `/api/sys/module/roles/tenant/list?fieldValue=name`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //GET /api/sys/module/roles/tenant/list
  getModuleRolesWorkareas(params: any) {
    const endpoint = `/api/sys/module/roles/workarea/list?fieldValue=name`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //GET /workarea/tenant/{tenantId} 
  getTenantWorkareas(params: any) {
    const endpoint = `/api/sys/workarea/tenant/${params.tenantId}?firstResult=0&maxResults=20&sort=dateCreated&sort=asc`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }

  //gets user list without xtenant
  //user/list/all?firstResult=0&maxResults=20&sort=dateCreated&sort=asc
  getUserList(params: any) {
    const endpoint = `/api/sys/user/list?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortColumn}&sort=${params.sort}`;
    const headers = params.tenantId ? this.authService.getHeadersWithTenant( params.tenantId) : 
    this.authService.getHeadersWithoutTenant();
    return this.http.get(this.baseURL + endpoint, {
      headers: headers,
    });
  }

  //gets user list without xtenant
  ///roles/user?userId=${params.elemId}
  getRoles(params: any) {
    const endpoint = `/api/sys/roles/user?userId=${params.elemId}`;
    const headers = params.tenantId ? this.authService.getHeadersWithTenant(params.tenantId) : 
    this.authService.getHeadersWithoutTenant();
    return this.http.get(this.baseURL + endpoint, {
      headers: headers,
    });
  }

  //gets user list without xtenant
  //GET /user/{userId}
  getUserDetails(params: any) {
    const endpoint = `/api/sys/user/${params.userId}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });

  }

  //api/sys/user/check/username/{username}/email/{email}/duplicate
  checkIfExists(params: any) {
    const endpoint = `/api/sys/user/check/username/${params.username}/email/${params.email}/duplicate`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeaders(),
    });

  }

  ///api/sys/lookup/${lookupName}/list?fieldValue=name
  createUser(params: any) {
    const endpoint = `/api/sys/user/create`;
    return this.http.post(this.baseURL + endpoint, params, {
      headers: this.authService.getHeadersWithTenant( params.tenant.id)
    });

  }
  
  //PUT /user/update
  updateUser(params: any) {
    const endpoint = `/api/sys/user/update`;            
    return this.http.put(this.baseURL + endpoint, params, {
      headers: this.authService.getHeadersWithTenant( params.tenant.id)
    });

  }

    
  //PUT /user/{userId}/tenant/{tenantId}/roles/{roleId}/add
  updateUserRoles(params: any) {
    const endpoint = `/api/sys/user/${params.userId}/tenant/${params.tenantId}/roles/${params.roleId}/add`;            
    return this.http.post(this.baseURL + endpoint, {}, {
      headers: this.authService.getHeadersWithTenantAndRoles(params.tenantId, params.roleId)
    });
  }

/**
 * adds the user to the selected workarea
 * @param params params to assemble the URL
 * @returns the status
 */ 
  //user/{userId}/workArea/{workAreaId}/roles/{roleId}/add
  addUserWorkarea(params: any) {
    const endpoint = `/api/sys/user/${params.userId}/workArea/${params.workareaId}/roles/${params.roleId}/add`;
    return this.http.post(this.baseURL + endpoint, {}, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //DELETE /user/delete/{userId}
  disableUser(params: any) {
    const endpoint = `/api/sys/user/delete/${params.userId}`;            
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant( params.tenantId)
    });
  }
  //PUT /user/activate/{userId}
  activateUser(params: any) {
    const endpoint = `/api/sys/user/activate/${params.userId}`;            
    return this.http.put(this.baseURL + endpoint, {},{
      headers: this.authService.getHeadersWithTenant( params.tenantId)
    });
  }

  //DELETE /user/{userId}/workArea/{workAreaId}/roles/{roleId}/remove
    deleteUserWorkarea(params: any) {
    const endpoint = `/api/sys/user/${params.userId}/workArea/${params.workAreaId}/roles/${params.roleId}/remove`;            
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //DELETE /user/{userId}/tenant/{tenantId}/role/{roleId}/remove
  deleteSiteRole(params: any) {
    const endpoint = `/api/sys/user/${params.userId}/tenant/${params.tenantId}/roles/${params.roleId}/remove`;            
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //GET /user/search/{textToSearch}
  //?firstResult=0&maxResults=20&sort=dateCreated&sort=asc
  searchUsers(params: any) {
    const endpoint = `api/sys/user/search`;
    const urlParams = `?firstResult=${params.first}&maxResults=${params.max}&sort=dateCreated&sort=${params.sort}`;
    const url = `${this.baseURL}/${endpoint}/${params.searchTerm}${urlParams}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.tenantId)});
  }

  //GET /user/type(avatar/signature)/{avatarId}
  getAvatarSignature(params: any) {
    const endpoint = `api/sys/user/${params.type}/${params.itemId}`;    
    const url = `${this.baseURL}/${endpoint}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.tenantId) });
  }

}
