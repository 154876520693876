import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'report-details-tab',
  templateUrl: './report-details-tab.component.html',
  styleUrls: ['./report-details-tab.component.scss']
})
export class ReportDetailsTabComponent implements OnInit {
  readonlyFields = ['workarea', 'assettype', 'reporttypefilter', 'servicesSet', 'assetcategory', 
  'connectiontype', 'bodyconnections'];
  validForm = false;
  isLoading = false;
  measurementsArray: any = [];

    singleItemAdditionalDetails: any = {
      "formModel": {
        "fields": [
          {
            "fieldType": "FormField",
            "id": "workOrder",
            "name": "Work Order",
            "type": "text",
            "value": null,
            "required": false,
            'disabled': false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "FormField",
            "id": "purchaseOrder",
            "name": "Purchase Order",
            "type": "text",
            "value": null,
            "required": false,
            'disabled': false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "FormField",
            "id": "contract",
            "name": "Contract",
            "type": "text",
            "value": null,
            "required": false,
            'disabled': false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "FormField",
            "id": "clientJobReference",
            "name": "Client Job Reference",
            "type": "text",
            "value": null,
            "required": false,
            'disabled': false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "FormField",
            "id": "rig",
            "name": "Rig",
            "type": "text",
            "value": null,
            "required": false,
            'disabled': false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "FormField",
            "id": "locationOfExamination",
            "name": "Location Of Examination",
            "type": "text",
            "value": null,
            "required": false,
            'disabled': false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          }
        ]
      }
    };
     formData: any;

    formItems = {
      "id": "ecc2ab61-c8b5-11ed-981d-00ff6454cc74",
      "name": "Create Work Task",
      "key": "createReportForm",
      "version": 4,
      "formModel": {
        "name": "Create Work Task",
        "key": "createReportForm",
        "version": 0,
        "fields": [
          {
            "fieldType": "OptionFormField",
            "id": "workArea",
            "name": "Work Area",
            "type": "dropdown",
            "value": null,
            "required": true,
            "readOnly": true,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [ 
            ],
            "optionsExpression": null
          },
          {
            "fieldType": "FormField",
            "id": "taskNumber",
            "name": "Task Number",
            "type": "text",
            "value": "10000",
            "required": true,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "OptionFormField",
            "id": "reportTypeFilter",
            "name": "Task Type",
            "type": "dropdown",
            "value": "57513d0f-8455-4baf-a7ac-7897e5147e08",
            "required": true,
            "readOnly": true,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [],
            "optionsExpression": "#{assetServiceBean.getOptionMapForLookup2('reportTypeFilter')}"
          },
          {
            "fieldType": "OptionFormField",
            "id": "specificationSet",
            "name": "Specification",
            "type": "chiplist",
            "value": "d5fad301-ae74-403a-ad44-ae3c7ed5f54a",
            "required": true,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "params": {
              "binding": "asset.bodyconf.borecond",
              "conf": {
                "name": "Body Condition"
              },
              "layout": {
                "row": "1",
                "col": "1"
              },
              "colConf": {
                "title": "Body Datapoiints"
              }
            },
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [],
            "optionsExpression": "#{assetServiceBean.getOptionMapForLookup2('specification')}"
          },
          {
            "fieldType": "FormField",
            "id": "dueDate",
            "name": "Date of Examination",
            "type": "datepicker",
            "value": "d5fad301-ae74-403a-ad44-ae3c7ed5f54a",
            "required": true,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "params": {
              "binding": "asset.bodyconf.borecond",
              "conf": {
                "name": "Date of Examination"
              },
              "layout": {
                "row": "1",
                "col": "1"
              },
              "colConf": {
                "title": "Body Datapoiints"
              }
            }
          },
          {
            "fieldType": "FormField",
            "id": "dateOfNextExamination",
            "name": "Date of Next Examination",
            "type": "datepicker",
            "value": "d5fad301-ae74-403a-ad44-ae3c7ed5f54a",
            "required": false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "params": {
              "binding": "asset.bodyconf.borecond",
              "conf": {
                "name": "Date of Next Examination"
              },
              "layout": {
                "row": "1",
                "col": "1"
              },
              "colConf": {
                "title": "Body Datapoiints"
              }
            }
          },        
          {
            "fieldType": "FormField",
            "id": "diagnosticArea",
            "name": "Area of Examination",
            "type": "dropdown",
            "value": "d5fad301-ae74-403a-ad44-ae3c7ed5f54a",
            "required": false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [
              {
                "id": "36f7faed-24ed-4650-827e-b417ec43774e",
                "name": "Single Item Inspection"
              },
              {
                "id": "ad0fea75-d59e-4544-b4d3-5f9f476cf9b5",
                "name": "Drill Pipe"
              },
              {
                "id": "57513d0f-8455-4baf-a7ac-7897e5147e08",
                "name": "HWDP"
              }
            ],
            "params": {
              "binding": "asset.bodyconf.borecond",
              "conf": {
                "name": "Date of Next Examination"
              },
              "layout": {
                "row": "1",
                "col": "1"
              },
              "colConf": {
                "title": "Body Datapoiints"
              }
            }
          },        
          {
            "fieldType": "FormField",
            "id": "localProcedureNumber",
            "name": "Local Procedure Number",
            "type": "text",
            "value": "10000",
            "required": false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "FormField",
            "id": "testRestriction",
            "name": "Test Restrictions",
            "type": "dropdown",
            "value": "d5fad301-ae74-403a-ad44-ae3c7ed5f54a",
            "required": false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "options": [
              {
                "id": "36f7faed-24ed-4650-827e-b417ec43774e",
                "name": "Single Item Inspection"
              },
              {
                "id": "ad0fea75-d59e-4544-b4d3-5f9f476cf9b5",
                "name": "Drill Pipe"
              },
              {
                "id": "57513d0f-8455-4baf-a7ac-7897e5147e08",
                "name": "HWDP"
              }
            ],          
            "params": {
              "binding": "asset.bodyconf.borecond",
              "conf": {
                "name": "Date of Examination"
              },
              "layout": {
                "row": "1",
                "col": "1"
              },
              "colConf": {
                "title": "Body Datapoiints"
              }
            }
          },
          {
            "fieldType": "FormField",         
            "id": "drawingNumber",
            "name": "Drawing Number",
            "type": "text",
            "value": "10000",
            "required": false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "FormField",
            "id": "surfaceCondition",
            "name": "Surface Condition",
            "type": "dropdown",
            "value": "d5fad301-ae74-403a-ad44-ae3c7ed5f54a",
            "required": false,
            "readOnly": false,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [
              {
                "id": "36f7faed-24ed-4650-827e-b417ec43774e",
                "name": "Single Item Inspection"
              },
              {
                "id": "ad0fea75-d59e-4544-b4d3-5f9f476cf9b5",
                "name": "Drill Pipe"
              },
              {
                "id": "57513d0f-8455-4baf-a7ac-7897e5147e08",
                "name": "HWDP"
              }
            ],          
            "params": {
              "binding": "asset.bodyconf.borecond",
              "conf": {
                "name": "Date of Examination"
              },
              "layout": {
                "row": "1",
                "col": "1"
              },
              "colConf": {
                "title": "Body Datapoiints"
              }
            }
          }
        ],
        "outcomes": []
      },
      "formInstanceId": "a08d6efd-c8b6-11ed-981d-00ff6454cc74",
      "submittedBy": "admin",
      "submittedDate": 1679492222877,
      "taskId": "97d97f69-c8b6-11ed-981d-00ff6454cc74",
      "processInstanceId": "97d70e62-c8b6-11ed-981d-00ff6454cc74",
      "processDefinitionId": "singleItemInspection:4:ecb95c8c-c8b5-11ed-981d-00ff6454cc74",
      "tenantId": ""
    }
    singleItemForm = {
      "id": "ecc2ab60-c8b5-11ed-981d-00ff6454cc74",
      "name": "Single Item Inspection Form",
      "key": "singleItemInspectionForm",
      "version": 4,
      "formModel": {
        "name": "Single Item Inspection Form",
        "key": "singleItemInspectionForm",
        "version": 0,
        "fields": [
          {
            "fieldType": "OptionFormField",
            "id": "assetType",
            "name": "Asset Type",
            "type": "dropdown",
            "value": "d85709fe-079c-4329-b428-d6412993d1ed",
            "required": true,
            "readOnly": true,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [],
            "optionsExpression": "#{assetServiceBean.getOptionMapForLookup2('assetType')}"
          },
          {
            "fieldType": "FormField",
            "id": "assetcategory",
            "name": "Asset Category",
            "type": "text",
            "value": null,
            "required": false,
            'disabled': true,
            "readOnly": true,
            "overrideId": false,
            "placeholder": null,
            "layout": null
          },
          {
            "fieldType": "OptionFormField",
            "id": "connectionType",
            "name": "Connection 1",
            "type": "dropdown",
            "value": "316759df-2e42-4d6e-b82a-f3bf0d5c5597",
            "required": true,
            "readOnly": true,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [],
            "optionsExpression": "#{assetServiceBean.getOptionMapForLookup2('connectionType')}"
          },
          {
            "fieldType": "OptionFormField",
            "id": "connectionType",
            "name": "Connection 2",
            "type": "dropdown",
            "value": "47616bcb-194d-4cda-9edb-36a13c18b26c",
            "required": true,
            "readOnly": true,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [],
            "optionsExpression": "#{assetServiceBean.getOptionMapForLookup2('connectionType')}"
          },
          {
            "fieldType": "OptionFormField",
            "id": "bodyconnections",
            "name": "Body Connections",
            "type": "dropdown",
            "value": "2",
            "required": false,
            "readOnly": true,
            "overrideId": false,
            "placeholder": null,
            "layout": null,
            "optionType": null,
            "hasEmptyValue": true,
            "options": [],
            "optionsExpression": null
          }
        ],
        "outcomes": []
      },
      "formInstanceId": "e4e8a4bd-ca41-11ed-b932-00ff6454cc74",
      "submittedBy": "admin",
      "submittedDate": 1679662098994,
      "taskId": "a100f155-c8b6-11ed-981d-00ff6454cc74",
      "processInstanceId": "97d70e62-c8b6-11ed-981d-00ff6454cc74",
      "processDefinitionId": "singleItemInspection:4:ecb95c8c-c8b5-11ed-981d-00ff6454cc74",
      "tenantId": ""
    };
    constructor (
      private sharedFunctions: SharedFunctionsService,
      private componentsCommunication: ComponentsCommunicationService,
      private apiService: ApiService,
    ) { }

    ngOnInit(){    
      this.isLoading = true;
      const taskId = localStorage.getItem('taskId');
      const workareaId = localStorage.getItem('taskWorkareaId');  
      if (taskId) {
        // TODO, make a request and get the data to edit the clicked task
          console.log ('no data was retrieved');
          const tenantId = localStorage.getItem('taskTenantId');   
          const params = {
            tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
            taskId: taskId,
            workareaId: workareaId ? workareaId: ''
          };
  
          this.apiService.getTaskById(params).subscribe(
            (result: any) => {
              this.isLoading = false;
                if(result && result.id) {          
                  this.formData = {...result};  
                  this.formData.action = 'edit';          
                  this.formData.editMode = true;                      
                  localStorage.setItem('taskBodyConnections',result.bodyConnections);
                  localStorage.setItem('taskConnection1',result.connectionType1.name);
                  localStorage.setItem('taskConnection2',result.connectionType2.name);
                  this.componentsCommunication.setFormData(result);  
                } else {
                  // if no data available
                  this.formData = [];
                }
            },
            // error
            (msg) => {
              this.isLoading = false;
              console.log('error retrieving lookup list ' + msg);
          });
  
      }        

    }

  /**
     * stores the new value
     * @param selectValue value that was selected to be stored
     * @param element selected element
     */
  addSelectValue(selectValue: any, element: any) {
    if (selectValue && (selectValue.name || selectValue.removeItem)) {
      this.formData = {...this.sharedFunctions.saveFormValue(this.formData, element, selectValue)};
    }
    this.validateForm();
  
    this.componentsCommunication.setFormData(this.formData);  
  }

  /**
   * validates the content and enabling the save button
   */
  validateForm(){
    let validForm = true;
    const _this = this;

    Object.keys(this.formData).forEach((element:string) => {
      const selectedElem = _this.formItems.formModel.fields.find(item => element.toLowerCase() == item.id.toLowerCase());
      const singleItemElem = _this.singleItemForm.formModel.fields.find(item => element.toLowerCase() == item.id.toLowerCase());
      const itemValue = _this.formData[element as keyof typeof _this.formData];
      if((typeof itemValue == 'string' || typeof itemValue == 'number') && itemValue.toString().length == 0 && (selectedElem?.required || singleItemElem?.required)){
        validForm = false;
        
      } else if (itemValue && typeof itemValue == 'object' && Object.keys(itemValue).length == 0 && (selectedElem?.required || singleItemElem?.required))     {
        validForm = false;        
      }
    });
    this.validForm = validForm;
  }


}
