export class ModelCreateRepair {
    id = null;
    task = {};
    asset = {};
    type = '1';
    repairArea = {};
    repairRequiredDto = {};
    fieldRepairDto = {};
    description = '';
    createdBy = '';
    updatedBy = null; 
    lastUpdated = null; 
    dataCreated = null; 
}
