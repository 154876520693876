import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiServicesService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  //task/datapointValue/{tenantId}/{taskId}/update/{servicesId}
  saveEditedServiceDatapoints(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/service/${params.serviceId}/update`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/task/{tenantId}/services/add
  saveServices(params: any) {
    const endpoint = `/api/wm/task/services/add`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  deleteService(params: any) {
    const endpoint = `/api/wm/task/${params.taskId}/service/${params.serviceId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //evolve-app-rest/api/task/{tenantId}/services/{taskId}
  getTaskServices(params: any) {
    const endpoint = `/api/wm/task/${params.taskId}/services/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/datapointValue/create/{tenantId}/{taskId}/service/{serviceId}
  createServiceDatapoint(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/service/${params.serviceId}/create`;
    return this.http.post(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/datapointValue/getServiceForm/{tenantId}/{id_process}/{serviceName}
  getServicesFormsFirstTime(params: any) {
    const endpoint = `/api/wm/datapoints/process/${params.idProcess}/form/service/${params.serviceName}/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/task/datapointValue/{tenantId}/{taskId}/getServicesValues/{serviceId}
  getServicesDatapointValues(params: any) {
    const endpoint = `/api/wm/datapoints/task/${params.taskId}/getServicesValues/${params.serviceId}/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/task/utProbes/{tenantId}/{datapointValueId}/create
  createDatapointCatalogUTProbe(params: any) {
    const endpoint = `/api/wm/utprobes/${params.datapointValueId}/create`;
    return this.http.post(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/task/utProbes/{tenantId}/updateUTProbes
  updateDatapointCatalogUTProbe(params: any) {
    const endpoint = `/api/wm/utprobes/update`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }

  //api/task/utProbes/{tenantId}/{datapointValueId}/list
  getDatapointCatalogUTProbes(params: any) {
    const endpoint = `/api/wm/utprobes/${params.datapointValueId}/list`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }

  //api/task/utProbes/{tenantId}/getUTProbes/{probeId}
  getUTProbeById(params: any) {
    const endpoint = `/api/wm/utprobes/${params.probeId}/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }

  //api/task/utProbes/{tenantId}/deleteUTProbes/{probesID}
  deleteUTProbe(params: any) {
    const endpoint = `/api/wm/utprobes/${params.probeId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }
}
