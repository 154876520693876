
<div class="v-window-header">

  <span *ngIf="dialogData.action == 'edit'">Edit Inventory {{editTitle}}</span>
  <span *ngIf="dialogData.action == 'clone'">Cloning Inventory Item</span>
  <span *ngIf="dialogData.action == 'create'">Creating Inventory Item</span>
  <span *ngIf="dialogData.action == 'create_result'">Create Result</span>      
  <div [mat-dialog-close]="true" class="v-window-closebox">
    <mat-icon class="icon action">close</mat-icon>
  </div>
</div>
<div class="edit-asset-item-container">
  
<mat-spinner *ngIf="isLoading"></mat-spinner>

<div class="dialogform-container" *ngIf="!isLoading">

<mat-tab-group  [selectedIndex]="selectedTabIndex" *ngIf="!isLoading" animationDuration="0ms">
  <mat-tab label="Details">

    <div  class="dialogform-container">      

      <div class="type1">

        <div *ngIf="dialogData.action == 'create_result'" rowHeight="120px">

          <h4>Select the Asset
            <span class="required_item"> *</span>
          </h4>

          <!--*****Asset*****-->     
          <!--(keyup)="searchInventoryAsset()"-->
          <mat-form-field appearance="fill">                      
              <input class="asset-input"
              type="text"
              matInput
              required="true"
              #assetsInput              
              [formControl]="assetsControl"
              [matAutocomplete]="autoAssets"
              [ngModel]="prefilledAssets">
              <mat-icon
              class="select-arrow"
              matSuffix>keyboard_arrow_down</mat-icon>
              <mat-autocomplete #autoAssets="matAutocomplete">
                <ng-container *ngFor="let option of filteredOptionsAssets | async" >
                  <mat-option (onSelectionChange)="addAsset($event, option)" [value]="option.name">
                    <span *ngIf="option.model"> {{option.model }}</span>
                    <span *ngIf="option.assetType"> {{' | ' + option.assetType.name }}</span>
                    <span *ngIf="option.consumableType"> {{ option.consumableType.name }}</span>                       
                    <span *ngIf="option?.manufacturer?.name"> {{' | ' + option.manufacturer.name }}</span>   
                  </mat-option>
              </ng-container>

              </mat-autocomplete>
            </mat-form-field>
        
        </div>

        <div *ngIf="dialogData.action == 'edit' || dialogData.action == 'clone'">
          <h4>Inventory Type 
            <span class="required_item"> *</span>
          </h4>
          <!-- Inventory type -->
          <mat-form-field
            [class.disabled-dropdown] = "dialogData.action == 'edit' || dialogData.action == 'clone'">
              <mat-select
              [disabled]="readonly"
             [ngModel]="types[assetModel.type-1].value">            
                <mat-option
                (onSelectionChange)="
                loadModel(type.id, $event); validateFormdata()" 
                *ngFor="let type of types; let id = index"
                [value]="type.value" >
                {{type.value}}
                <mat-icon *ngIf="type.id == 1" class="icon">precision_manufacturing</mat-icon>
                <mat-icon *ngIf="type.id == 2" class="icon">miscellaneous_services</mat-icon>
                </mat-option>
            </mat-select>
          </mat-form-field>      
        </div>

        <div *ngIf="dialogData.action == 'create'">
          <h4>Inventory Type 
            <span class="required_item"> *</span>
          </h4>          
          <!-- Inventory type -->
          <mat-form-field>             
              <mat-select
              [disabled]="readonly"
              > 
                <mat-option
                (onSelectionChange)="loadModel(type.id, $event); validateFormdata()" 
                *ngFor="let type of types; let id = index"
                [value]="type.value" >
                {{type.value}}
                <mat-icon *ngIf="type.id == 1" class="icon">precision_manufacturing</mat-icon>
                <mat-icon *ngIf="type.id == 2" class="icon">miscellaneous_services</mat-icon>
                </mat-option>
            </mat-select>
          </mat-form-field>          
      
        </div>

        <div *ngIf="(assetModel?.type == 1 || assetModel?.type == 2) && dialogData.action != 'create_result'" >
          <h4>Site
            <span class="required_item"> *</span>
          </h4>   
          <!-- Tenant -->
          <mat-form-field>
            <mat-select
            [disabled]="readonly"
            [ngModel]="tenantName"
            (selectionChange)="validateFormdata()">
            <mat-option (click)="assetModel.tenant = {id:''}; assetModel.tenantId = ''; validateFormdata()"> - </mat-option>
              <mat-option (onSelectionChange)="updateTenant(tenant, $event)"
              *ngFor="let tenant of allTenants; let id = index"
              [value]="tenant.name">
              {{tenant.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>    
        </div>  
        
        <div *ngIf="assetModel?.type == 1 ">

          <select-elem class="edit-asset-custom-width"          
          (elementEvent)="addSelectValue($event, assetModel)"
          [element]="assetModelType" [readonlyFields]="readonlyFields"></select-elem>

        </div>

        <div *ngIf="assetModel?.type == 1">
          <input-elem class="edit-asset-custom-width"
          (elementEvent)="addSelectValue($event, assetModel)"
          [element]="assetModelCategory" [readonlyFields]="readonlyFields"></input-elem>
        </div>

        <div *ngIf="assetModel?.type == 1">
          <h4>Serial Number
            <span class="required_item"> *</span>
          </h4>
          <!-- Serial number -->
          <mat-form-field >
            <input 
            [readonly]="readonly"
            [disabled]="readonly"
            [ngModel]="prefilledSerialNumber" matInput type="text"
            (keyup)="assetModel.serialNumber = $event.target?.value.toString(); validateFormdata()"
            color="primary">       
          </mat-form-field>

        </div>     

        <div *ngIf="assetModel?.type == 1" class="two-cols">
          <h4>Model 
            <span class="required_item"> *</span>
          </h4>   
          <!-- report model -->
          <mat-form-field>
            <input 
            [readonly]="readonly"
            [disabled]="readonly"
            [ngModel]="prefilledModel" matInput required type="text"
            (keyup)="assetModel.model = $event.target.value.toString(); validateFormdata()"
            color="primary">
          </mat-form-field>
        </div>

        <div *ngIf="assetModel?.type == 1" class="two-cols">
          <h4>Department</h4>   
          <!-- Department -->
          <mat-form-field>
            <mat-select
            [disabled]="readonly"
            [ngModel]="prefilledDepartment"
            (selectionChange)="validateFormdata()">
            <mat-option (click)="assetModel.department = ''"> - </mat-option>
              <mat-option (onSelectionChange)="assetModel.department = department"
              *ngFor="let department of allDepartments; let id = index"
              [value]="department.name">
              {{department.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>    
        </div>   

        <div *ngIf="assetModel?.type == 2">

          <select-elem class="edit-asset-custom-width"          
          (elementEvent)="addSelectValue($event, assetModel)"
          [element]="consumableType" [readonlyFields]="readonlyFields"></select-elem>
        </div>

        <div *ngIf="assetModel?.type == 2">
          <!-- manufacturer -->           
          <select-elem class="edit-asset-custom-width"          
          (elementEvent)="addSelectValue($event, assetModel)"
          [element]="assetManufacturer" [readonlyFields]="readonlyFields"></select-elem>
        </div>

        <div *ngIf="assetModel?.type == 1">
          <!-- manufacturer -->           
          <select-elem class="edit-asset-custom-width"          
          (elementEvent)="addSelectValue($event, assetModel)"
          [element]="assetManufacturer" [readonlyFields]="readonlyFields"></select-elem>

        </div>

        <div *ngIf="assetModel?.type == 1 || assetModel?.type == 2">
          <h4>Description</h4>            
          <mat-form-field>
            <textarea
            [readonly]="readonly"
            [disabled]="readonly"
            rows="4"
            (keyup)="assetModel.description = $event.target.value.toString(); validateFormdata()"
            [ngModel]="prefilledDescription"
            matInput        
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize">
            </textarea>
          </mat-form-field>      

        </div>  

      </div>
    </div>
    <div mat-dialog-actions>
      <button *ngIf="dialogData.action == 'create_result'" 
      mat-raised-button [ngClass]="{'disabled': !selectedAsset}"
        color="primary" (mousedown)="createResult();">
        Create
        <mat-icon class="icon action">save</mat-icon>
      </button>
      <button *ngIf="dialogData.action != 'create_result'" 
      mat-raised-button [ngClass]="{'disabled': !validForm}"
        color="primary" (mousedown)="saveChanges();">
        Save
        <mat-icon class="icon action">save</mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="closePopup()">
        Close
        <mat-icon class="icon action">close</mat-icon>
      </button>
    </div>
    
  <!-- IMAGES TAB -->
  </mat-tab>  
  <mat-tab *ngIf="dialogData.action == 'edit'" label="Images"> 

    <div *ngIf="!isLoading" class="dialogform-container">
        
      <button  mat-raised-button
      class="upload" color="primary" (click)="openUploads('upload');">
      <mat-icon>file_upload</mat-icon>
      </button>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 full-width">

        <!-- task Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> </th>                              
          <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <!-- standard Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">{{element.description}}</td>
        </ng-container>
          
          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell class="last" width ="40px" *matHeaderCellDef></th>
            <td mat-cell class="last" width ="40px" *matCellDef="let element">

          <!-- pdf list actions menu-->
          <button class="user_menu custom" mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
            <mat-icon>expand_more</mat-icon>
          </button>

          <mat-menu class="menu" x-position="before" #menu="matMenu">

            <button mat-menu-item (click)="actions('update', element)">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-menu-item (click)="preview(element)">
              <mat-icon>preview</mat-icon>
                View 
            </button>                   
            <button mat-menu-item (click)="confirmation('delete', element)">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
              
          </mat-menu>

        </td>
        </ng-container>                        

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>    
    </div>
    
      <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="closePopup()">
          Close
          <mat-icon class="icon action">close</mat-icon>
        </button>
      </div>
  
  </mat-tab>
</mat-tab-group>

</div>