import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { EditAssetDialogComponent } from '../../dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { FilePreviewDialogComponent } from '../../dialogs/file-preview-dialog/file-preview-dialog.component';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'inventory-view',
  templateUrl: './inventory-view.component.html',
  styleUrls: ['./inventory-view.component.scss'],
})
export class InventoryViewComponent implements OnInit {
  getUpdateAssets!: Subscription;
  getTabUpdate!: Subscription;
  getRolesListUpdate!: Subscription;
  @ViewChild(MatPaginator) paginator!: 
  MatPaginator;

  searchFilters = [
    { name: 'Task Number', value: 'report_number' },
    { name: 'Asset Type', value: 'asset_type' },
    { name: 'Asset Category', value: 'asset_category' },
  ];
  selectedTabIndex = 0;
  @ViewChild('searchInput')
  searchInput!: ElementRef<HTMLInputElement>;
  searchFilter = 'report_number';
  displayedColumns: string[] = ['category', 'type', 'number', 'description', 'actions'];
  displayedColumnsTasks: string[] = ['task', 'date', 'standard', 'services', 'comments', 'result'];
  dataSource: any;
  dataSourceTasks: any = {};
  tasksList: any = [];
  contentLoading = false;
  noResults = false;
  displayDetails = false;
  detailsLoading = false;
  detailsTab = 0;
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };
  showZoomedImage = true;
  selectedItem: any;
  type2Elem: any;
  statusDate = '';
  resultsTabServices: any;
  activeRowId = '';
  roles = {
    isAdmin: false,
    imReader: false,
    systemRoles: [],
    allRoles: [],
    tenantRoles: [],
  };
  rolesList: any;
  constructor(
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private apiService: ApiService,
    public sharedFunctions: SharedFunctionsService,
    public rolesService: RolesService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  
  ngOnInit() {
    
    this.getRolesListUpdate = this.componentsCommunication.getRolesList().subscribe(data => {
      // is admin
      if (data && data.length > 0 && data[0].tenantId === null && this.rolesService.systemAdmin(data[0].roles)){
        this.roles.isAdmin = true;      
      }
      if (data && data.length > 1){
        this.roles.systemRoles = data[0].roles;
        this.roles.allRoles = data.slice(1);
      }  
      if (data && data.length > 0) {
        //this.roles.tenantRoles = this.sharedFunctions.applyPermissions(this.roles);
        //this.changeDetectorRef.detectChanges();
        this.searchInventoryAsset(); 
      }
     
    });

    this.getUpdateAssets = this.componentsCommunication.getUpdateAssets().subscribe((params) => {

      // updates the reports list
      if (params === 'update_assets') {
        this.displayDetails = false;
        this.activeRowId = '';
        this.contentLoading = true;
        this.searchInventoryAsset();
      }
      if (params === 'update_attachments') {
        this.contentLoading = true;
        this.searchInventoryAsset();
      }

      // when the tenant dropdown triggers a tenant changes, it has to review the roles
      if ( params && 
        params?.action == 'tenant_update'
      ) {
        this.displayDetails = false;
        this.activeRowId = '';
        this.contentLoading = true;
        //this.roles.tenantRoles = this.sharedFunctions.applyPermissions(this.roles);
        //this.changeDetectorRef.detectChanges();
        this.searchInventoryAsset();
      }
    });

    this.getTabUpdate = this.componentsCommunication.getUpdateTab().subscribe(data => {
      // updates the tab content
      if(data && data.main_tab && this.searchInput && this.searchInput.nativeElement.value.length > 0) {
        this.contentLoading = true;
        this.searchInput.nativeElement.value = '';     
        this.searchInventoryAsset();
      }      
    });
  }

  ngOnDestroy() {
    this.getRolesListUpdate.unsubscribe();
    this.getUpdateAssets.unsubscribe();
    this.getTabUpdate.unsubscribe();
  }

  tabChanged(tabChangeEvent: any) {
    this.detailsTab = tabChangeEvent.index;
  }

  /**
   * retrieves the content to be displayed
   */
  getContent() {
    this.activeRowId = '';
    this.dataSourceTasks.content = [];
    this.contentLoading = true;
    const tenantId = localStorage.getItem('selectedSiteId');   
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'desc',
    };   
    this.apiService.getAssets(params).subscribe(
      (result: any) => {
        this.contentLoading = false;
        if (result) {
          this.dataSource = [...result.content];
          this.paginatorConfig.length = result.totalElements;
        } else {
          this.dataSource = [];
        }
      },
      // error
      (msg) => {
        this.contentLoading = false;
        this.dataSource = [];
        console.log('error retrieving data from endpoint ' + msg.error);
      }
    );
  }

  /**
   * handles the pagination ant the update of the results
   * @param evt click event
   */
  handlePage(evt: any) {
    //this.displayDetails = false;
    this.paginatorConfig.pageIndex = evt.pageIndex;
    this.paginatorConfig.pageSize = evt.pageSize;
    //triggers the search after updating the pagination
    this.searchInventoryAsset();
  }

  /**
   * resets the persisting data from showing the asset details
   */
  resetShowDetails(){
    this.activeRowId = '';
    this.displayDetails = false; 
    this.selectedItem = {};
    this.detailsTab = 0;
  }

  /**
   * shows the details for a specific element
   * @param element the clicked element to show the details
   */
  loadDetails(element: any) {
    if (           
      (!this.selectedItem || element.id != this.selectedItem.id)
    ) {
    //const hasAccess = this.sharedFunctions.checkAccess();
    if (element.type == 2) {
      this.type2Elem = element;
    }
    element.tenantId = element.tenant.id;
    this.activeRowId = element.id;
    this.displayDetails = true; 
    this.selectedItem = {};
    this.detailsLoading = true;
    this.resultsTabServices = [];
    this.apiService.getItemDetails(element).subscribe(
      (result:any) => {
        if (result) {
          this.selectedItem = {...result};
          this.dataSourceTasks = {...result};
          this.statusDate = result?.results && result?.results['Status Date'] ? result.results['Status Date'] : '';

          // iterates the results to get all the services and store them
          if (result?.results) {
            for (let key in result?.results) {
              if (typeof result?.results[key] == 'object') {

                const observations = result.results[key].Observations;
                // mapping properly observations
                if(observations && typeof observations == 'object') {
                  let allObs = result.results[key].Observations;
                  let observations = [];
                  for (let obs in allObs) {
                    observations.push({key: obs, value: allObs[obs]});
                  }
                  result.results[key].observations = [...observations];
                }

                // mapping properly results
                if(result.results[key] && key == 'DIM') {
                  let allRes = result.results[key];
                  let results = [];
                  for (let res in allRes) {
                    if (typeof allRes[res] == 'object') {
                    results.push({key: res, value: allRes[res]});
                    }
                  }
                  result.results[key].results = [...results];
                }
                this.resultsTabServices.push({name: key, result: result?.results[key]});
              }        
            }
            
          }
        } else {
          this.selectedItem = [...this.dataSourceTasks];
        }
        this.detailsLoading = false;
      },
      // error
      (msg) => {
        this.detailsLoading = false;
        console.log('error retrieving the task list for the asset item ' + msg.error);
      }
    );
    }
  }

  preview(element: any){
    const taskId = localStorage.getItem('taskId');
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      taskId: taskId,
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }
  
  /**
   * shows a confirmation popup, before executing a specific action
   * @param action the action to be performed
   * @param elem the selected element
   */
  confirmation(action: string, elem?: any) {
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.confirmation) {
        this.actions(action, elem);
      }
    });
  }

  /**
   * shows the available actions for the element list
   * @param action the action to be performed
   * @param elem the selected element
   */
  actions(action: string, elem?: any) {
    let dialogRef;
    let data;
    let params;
    const height = 'auto';
    const width = 'auto';
    if (elem) {
      elem.action = action;
      const tenantId = elem.tenant.id;
      params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
        id: elem.id,
        type: elem.type
      };
    }
    switch (action) {
      case 'delete':
        this.deleteAsset(params);
        break;
      case 'clone':
        this.cloneAsset(params, elem);
        break;
      case 'edit':
        dialogRef = this.dialog.open(EditAssetDialogComponent, {
          height: height,
          width: width,
          disableClose: true,
          data: elem,
        });
        break;
      case 'create':
        //  this.showFeedback(elem);
        data = {
          action: action,
          type: null,
        };
        dialogRef = this.dialog.open(EditAssetDialogComponent, {
          height: height,
          width: width,
          disableClose: true,
          data: data,
        });
        break;
      default:
        break;
    }
    if (dialogRef) {
      dialogRef.afterClosed().subscribe((data) => {
        if (data.item_toload && data.item_toload.type && data.item_toload.action == 'edit') {
          this.loadDetails(data.item_toload);
        }
      });
    }
  }

  /**
   * performs the clone action, duplicating an element
   * @param params data shared with the endpoint
   * @param elem the selected element
   */
  cloneAsset(params: any, elem?: any) {
    this.apiService.cloneInventoryAsset(params).subscribe(
      (result: any) => {
        result.action = elem.action;
        result.type = elem.type;
        this.dialog.open(EditAssetDialogComponent, {
          height: 'auto',
          width: 'auto',
          disableClose: true,
          data: result ? result : elem,
        });
      },
      // error
      (msg) => {
        this.dialog.open(EditAssetDialogComponent, {
          height: 'auto',
          width: 'auto',
          disableClose: true,
          data: elem,
        });
        console.log('error cloning the asset item' + msg.statusText);
      }
    );
  }

  createReport() {
    this.actions('create');
  }

  /**
   * deletes the selected report
   * @param element element to be deleted
   */
  deleteAsset(params: any) {
    this.apiService.deleteInventoryAsset(params).subscribe(
      (result) => {
        console.log(' task deleted!');
        this.componentsCommunication.setUpdateAssets('update_assets');
      },
      // error
      (msg) => {
        console.log(msg);
      }
    );
  }

  /**
   * performs the search action
   */
  searchInventoryAsset() {
    this.contentLoading = true;    
    let searchTerm = this.searchInput && this.searchInput && this.searchInput.nativeElement.value.trim().length > 0 
    ? this.searchInput.nativeElement.value.trim() : '*';
    searchTerm = encodeURIComponent(searchTerm);

    if (searchTerm.length > 0) {
      const tenantId = localStorage.getItem('selectedSiteId');
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        keyword: searchTerm,        
        first: this.paginatorConfig.pageIndex,
        max: this.paginatorConfig.pageSize,
        sort: 'desc',
      };

      this.apiService.searchAssets(params).subscribe(
        (result:any) => {
          if (Object.keys(result).length > 0) {
            this.noResults = false;
            this.dataSource = [...result.content];
          } else {
            // empty results
            this.noResults = true;
            this.dataSource = [];
          }
          this.paginatorConfig.length = result.totalElements;
          this.contentLoading = false;
        },
        // error
        (msg) => {
          console.log(msg);
          this.contentLoading = false;
          this.noResults = true;
          this.dataSource = [];
        }
      );
    } else {
      this.searchInventoryAsset();
      //this.getContent();
    }
  }
}
