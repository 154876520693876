import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

@Component({
  selector: 'select-elem-services-results',
  templateUrl: './select-elem-services-results.component.html',
  styleUrls: ['./select-elem-services-results.component.scss']
})
export class SelectServicesResultsComponent implements OnInit {
  @ViewChild('selectInput')
  selectInput!: ElementRef<HTMLInputElement>;
  @ViewChild('activeOPT')
  activeOPT!: any;
  @Input() element:any;
  @Input() readonlyFields:any;
  @Input() formData:any;   
  prefilledValue: any;
  selectedElement: any = [];
  @Output() elementEvent = new EventEmitter<string>();
  myControl = new FormControl('');
  lookupsList: any;
  autocomplete = 'on';
  invalid = false;
  lookupName: any;
  filteredOptions: Observable<any>;
  allowedLookups = ['ThreadType', 'Thread Type', 'AcceptReject', 'DressingType', 'BladeType', 'StabilizerType', 'Condition'];
  availableOpts = ['Yes','No', 'N/A'];
  constructor(
    private apiService: ApiService,
    private componentsCommunication: ComponentsCommunicationService){}
    
  ngOnInit(){
    if (this.element.datapointCatalog.tab == 'service' ||
    (this.element.datapointCatalog.tab == 'results' && 
    this.allowedLookups.includes(this.element.datapointCatalog.lookupName))){
      this.lookupName = this.element.datapointCatalog.lookupName;
      
      this.apiService.getLookupLists(this.lookupName).subscribe(
        (result: any) => {
          this.lookupsList = result.lookups;
          if (result.lookups && result.lookups.length > 0) {
            this.element.options = result.lookups;
          }          
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );

        },
        // error
        (msg) => {
          console.log('error retrieving lookup list ' + msg);
      }); 
    } else {
      this.element.options = this.availableOpts;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );  
    }
    
    // disables readonly when no fields are defined
    if(!this.readonlyFields){
      this.element.datapointCatalog.readOnly = false;
    }
    if (this.element.datapointCatalog.action == 'create_result') {
      this.element.datapointCatalog.readOnly = true;
      this.element.datapointCatalog.disabled = true;
    }
    
    //prefill
    if ( this.element.multiValue) {
      this.prefilledValue = this.element.multiValue[0];
    }

  }

  private _filter(value: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();

    return this.element.options.filter((option: any) => (option.name && option.name.toLowerCase().includes(filterValue)));
  }

    /**
   * sets the value for the selected element
   * @param elem with value to set
   * @param element current element
   */
  selectValue(elem: any, event: any) {    
    if (event.isUserInput) {   
      this.selectedElement = elem;
      this.invalid = false;
      if (this.lookupName) {
        elem.lookupName = this.lookupName;
      }
      this.elementEvent.emit(elem);  
    }
  }

  /**
   * resets the value for the selected element
   * @param elem with value to set
   * @param element current element
   */
  resetValue(elem: any, element: any) {    
    //*****TODO add endpoint that retrieves th asset category
    if (element.datapointCatalog.id.toLowerCase() == 'assettype') {
      const item = {
        name : 'assetcategory',
        value : elem
      }
      this.componentsCommunication.setUpdateTasks(item);
    } 
    const elemEvent: any = {
      name :element.id,
      value : elem,
      remove: element.id
    }
    this.elementEvent.emit(elemEvent);  
  }

  valueChange(targetElem: any) {
    const exists = this.element.options.find((elem:any) => elem.name.toLowerCase().includes(targetElem.target.value.trim().toLowerCase()));
    if (!exists) {
      this.invalid = true;
      const elem: any = {};
      // when the entered value doesn't match any valid options, it resets the previous values
      this.prefilledValue = '';
      targetElem.target.value = '';
      this.componentsCommunication.setUpdateTasks(elem);
      this.elementEvent.emit(elem);
    } else{
      this.invalid = false;
    }
  }
  
  handleArrowNavigation (event: any){
    let value: any = '';
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }
    this.selectInput.nativeElement.value = value;
    event.isUserInput = true;
    const selectedOpt = this.element.options.find((elem:any) => elem.name.includes(value.trim()));
    this.selectValue(selectedOpt, event);
  }
}
