<mat-spinner *ngIf="isLoading"></mat-spinner>
<div class="dialogform-container">
  <div class="dialogform-container" *ngIf="!isLoading">
    <div class="v-window-header">
      <span>View Item Details</span>
      <div [mat-dialog-close]="true" class="v-window-closebox">
        <mat-icon class="icon action">close</mat-icon>
      </div>
    </div>


    <mat-card-content *ngIf="!isLoading">
        <div mat-dialog-content>
            <div class="columns">
                <!-- NEW COLUMN-->
                <div class="row two-columns" cols="1" >

                    <!--*****ASSET TYPE*****-->  
                    
                    <h4> Asset Type</h4>
                    <mat-form-field class="filter-type disabled" appearance="fill">                            
                        <input
                        aria-expanded="false"
                        [ngModel]="itemDetails.assetType"
                        type="text"
                        matInput
                        readonly
                        >
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        
                    </mat-form-field>
            
                    <!--*****ASSET Category*****-->  
                    <h4> Asset Category</h4>
                    <mat-form-field class="filter-type disabled " appearance="fill">                            
                        <input
                        aria-expanded="false"
                        [ngModel]="itemDetails.assetCategory"
                        type="text"
                        matInput
                        readonly
                        >
                          <mat-icon
                          class="select-arrow"
                          matSuffix>keyboard_arrow_down</mat-icon>
                        
                    </mat-form-field>

                    <!--*****SERIAL NUMBER*****-->   
                    <h4> Serial Number</h4>  
                    <mat-form-field class="filter-type disabled" appearance="fill">                            
                        <input matInput
                        readonly
                        (keyup)="itemDetails.serialNumber = $event.target?.value; validateForm();"
                        type="text"                            
                        [ngModel]="itemDetails.serialNumber"
                        color="primary"/>    
                    </mat-form-field>
       
                    <!--*****MODEL NUMBER*****-->     
                    <h4> Model Number</h4>
                    <mat-form-field class="filter-type disabled" appearance="fill">                            
                        <input matInput
                        readonly
                        (keyup)="itemDetails.model = $event.target?.value; validateForm();"
                        type="text"
                        [ngModel]="itemDetails.model"
                        color="primary"/>    
                    </mat-form-field>   

                    <!--*****Department*****-->  
                    <h4> Department</h4>
                    <mat-form-field class="filter-type disabled" appearance="fill">                            
                        <input
                        aria-expanded="false"
                        [ngModel]="itemDetails.department"
                        type="text"
                        readonly
                        matInput>
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        
                    </mat-form-field>


                    <!--*****Manufacturer*****-->  
                    <h4> Manufacturer</h4>
                    <mat-form-field class="filter-type disabled" appearance="fill">                            
                        <input
                        readonly
                        aria-expanded="false"
                        [ngModel]="itemDetails.manufacturer"
                        type="text"
                        matInput>
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>

                    </mat-form-field>                        
                
                    <h4> Description</h4>
                    <mat-form-field class="textarea disabled" appearance="fill">                        
                        <textarea 
                        (keyup)="itemDetails.assetDescription = $event.target?.value; validateForm();"                       
                        [ngModel]="itemDetails.assetDescription"
                        rows="5"      
                        matInput   
                        readonly
                        [value]="itemDetails.assetDescription"                                 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize">
                        </textarea>  
                    </mat-form-field>
     
                </div>
   
                <!-- NEW COLUMN-->
                <div *ngIf="!isLoadingAttachments" class="row two-columns">
                    
                    <button  mat-raised-button
                    class="upload" color="primary" (click)="openUploads('upload_result_attachment');">
                      Upload
                      <mat-icon class="icon action">save</mat-icon>
                    </button>
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 full-width">
    
                      <!-- date Column -->
                      <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef>Name</th>
                          <td mat-cell *matCellDef="let element">{{element.name}}</td>
                      </ng-container>
              
                      <!-- standard Column -->
                      <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef>Description</th>
                          <td mat-cell *matCellDef="let element">{{element.description}}</td>
                      </ng-container>
                        
                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell class="last" width ="40px" *matHeaderCellDef></th>
                          <td mat-cell class="last" width ="40px" *matCellDef="let element">
              
                        <!-- pdf list actions menu-->
                        <button class="user_menu custom" mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
                          <mat-icon>expand_more</mat-icon>
                        </button>
              
                        <mat-menu class="menu" x-position="before" #menu="matMenu">
              
                          <button mat-menu-item (click)="actions('update_results_attachment', element)">
                            <mat-icon>edit</mat-icon>
                            Edit
                          </button>
                          <button mat-menu-item (click)="preview(element)">
                            <mat-icon>preview</mat-icon>
                              View 
                          </button>                             
                          <button mat-menu-item (click)="confirmation('delete_result_attachment', element)">
                            <mat-icon>delete</mat-icon>
                            Delete
                          </button>
                            
                        </mat-menu>
              
                      </td>
                      </ng-container>                        
              
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>    
                  </div>

            </div>
        </div>
    
        <div mat-dialog-actions>
    
        <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    