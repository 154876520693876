<mat-card class="results-card">
  <mat-card-header>
    <mat-card-title> </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="my-content-container">
      <mat-toolbar color="primary" class="results-toolbar">
        <mat-form-field
          *ngIf="addNewEnabled"
          class="custom-width"
          appearance="outline"
        >
          <mat-label>Joint from</mat-label>
          <input
            #jointFrom
            matInput
            (keyup)="applyFilter()"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field
          *ngIf="addNewEnabled"
          class="custom-width"
          appearance="outline"
        >
          <mat-label>Joint to</mat-label>
          <input #jointTo matInput (keyup)="applyFilter()" autocomplete="off" />
        </mat-form-field>
        <button
          *ngIf="roles.isAdmin || rolesService.workareaWMEditor(workareaRoles.allWorkareaRoles)"
          [ngClass]="{
            disabled: this.dataSource && this.dataSource.length > 0
          }"
          mat-raised-button
          (click)="addNewItem()"
          class="last right search-menu-button custom-button blue"
          color="primary"
        >
          <mat-icon class="icon">add</mat-icon>
        </button>
      </mat-toolbar>

      <table
        mat-table
        *ngIf="!noResults && !isLoading"
        [dataSource]="dataSource"
        class="mat-elevation-z8"
      >
        <br />

        <!-- joint Column -->
        <ng-container *ngIf="addNewEnabled" matColumnDef="joint">
          <th mat-header-cell *matHeaderCellDef>Joint</th>
          <td mat-cell width="70px" *matCellDef="let element; let id = index">
            {{ id + 1 + from }}
          </td>
        </ng-container>

        <!-- serial number Column -->
        <ng-container matColumnDef="sNumber">
          <th mat-header-cell *matHeaderCellDef>Serial Number</th>
          <td mat-cell *matCellDef="let element">{{ element.serialNumber }}</td>
        </ng-container>

        <!-- model number Column -->
        <ng-container matColumnDef="mNumber">
          <th mat-header-cell *matHeaderCellDef>Model Number</th>
          <td mat-cell *matCellDef="let element">
            {{ element.modelNumber }}
          </td>
        </ng-container>

        <!-- description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            {{ element.assetDescription }}
          </td>
        </ng-container>

        <!-- images Column -->
        <ng-container matColumnDef="images">
          <th mat-header-cell *matHeaderCellDef>Images</th>
          <td mat-cell *matCellDef="let element">
            <div class="icon-preview">
              <mat-icon
                *ngIf="element?.urlLastAttachment"
                class="image-icon"
                (click)="preview(element)"
                >image</mat-icon
              >
            </div>
          </td>
        </ng-container>

        <!-- result Column -->
        <ng-container matColumnDef="result">
          <th mat-header-cell class="results" *matHeaderCellDef>Result</th>
          <td mat-cell class="results" *matCellDef="let element">
            <span *ngIf="element?.result?.toLowerCase().includes('rejected')">
              <button
                mat-raised-button
                class="rejected"
                matBadge=""
                matBadgePosition="before"
                matBadgeColor="accent"
              >
                Rejected
              </button>
            </span>
            <span *ngIf="element?.result?.toLowerCase().includes('accepted')">
              <button mat-raised-button matBadge="" matBadgePosition="before">
                Accepted
              </button>
            </span>
          </td>
        </ng-container>

        <!-- defects Column -->
        <ng-container matColumnDef="defects">
          <th mat-header-cell *matHeaderCellDef>Defects</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element?.hasDefects == true">Yes</span>
            <span *ngIf="element?.hasDefects == false">No</span>
          </td>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell width="40px" *matHeaderCellDef></th>
          <td mat-cell width="40px" *matCellDef="let element">
            <!-- list actions menu-->
            <button
            *ngIf="roles.isAdmin || rolesService.workareaWMEditor(workareaRoles.allWorkareaRoles)"
              class="user_menu custom"
              mat-raised-button
              [mat-menu-trigger-for]="menu"
              fxHide="false"
              fxHide.gt-sm
            >
              <mat-icon>expand_more</mat-icon>
            </button>

            <mat-menu class="menu" x-position="before" #menu="matMenu">
              <button mat-menu-item (click)="editItemDetails(element)">
                <mat-icon>visibility</mat-icon>
                View Item Details
              </button>

              <button
                mat-menu-item
                *ngFor="let service of element.resultDatapointValues"
                (click)="editService(element, service)"
              >
                <mat-icon>edit</mat-icon>
                Edit {{ service.name }}
              </button>

              <button
                *ngIf="addNewEnabled"
                mat-menu-item
                (click)="edit(element)"
              >
                <mat-icon>warning</mat-icon>
                Edit Additional Work
              </button>
              <button
                *ngIf="addNewEnabled"
                mat-menu-item
                (click)="edit(element)"
              >
                <mat-icon>recycling</mat-icon>
                Scrap
              </button>
              <button
                *ngIf="addNewEnabled"
                mat-menu-item
                (click)="edit(element)"
              >
                <mat-icon>edit_document</mat-icon>
                Edit - Tally
              </button>
              <button
                *ngIf="addNewEnabled"
                mat-menu-item
                (click)="edit(element)"
              >
                <mat-icon>content_copy</mat-icon>
                Copy
              </button>
              <button
                *ngIf="!addNewEnabled"
                mat-menu-item
                (click)="createDefect(element)"
              >
                <mat-icon>warning</mat-icon>
                Create Manual Defect
              </button>
              <button
                *ngIf="!addNewEnabled"
                mat-menu-item
                (click)="createRepair(element, 'fieldRepair')"
              >
                <mat-icon>build</mat-icon>
                Create Field Repair
              </button>
              <button
                *ngIf="!addNewEnabled"
                mat-menu-item
                (click)="createRepair(element, 'repairRequired')"
              >
                <mat-icon>build</mat-icon>
                Create Repair Required
              </button>
              <button mat-menu-item (click)="confirmation('delete', element)">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div class="no-results" *ngIf="noResults && !isLoading">
        <h1>No results</h1>
      </div>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <br />
    </div>
  </mat-card-content>
</mat-card>
