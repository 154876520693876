import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { SharedFunctionsService } from "src/app/services/shared-functions.service";
import { CreateManualDefectComponent } from "./dialogs/create-manual-defect/create-manual-defect.component";
import { EditItemDetailsComponent } from "./dialogs/edit-item-details/edit-item-details.component";
import { CreateRepairComponent } from "./dialogs/create-repair/create-repair.component";
import { EditAssetDialogComponent } from "../../dialogs/edit-asset-dialog/edit-asset-dialog.component";
import { Subscription } from "rxjs";
import { ComponentsCommunicationService } from "src/app/services/components-communication.service";
import { ApiResultsService } from "src/app/services/api.results.service";
import { EditResultServiceComponent } from "./dialogs/edit-result-service/edit-result-service.component";
import { ConfirmationDialogComponent } from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FilePreviewDialogComponent } from "../../dialogs/file-preview-dialog/file-preview-dialog.component";
import { RolesService } from "src/app/services/roles.service";

@Component({
  selector: 'app-create-report-results',
  templateUrl: './create-report-results.component.html',
  styleUrls: ['./create-report-results.component.scss']
})

export class CreateReportResultsComponent {
  resultsUpdate!: Subscription;
  formDataSubscription!: Subscription;
  @Input() roles: any;
  @Input() workareaRoles: any;  
  @ViewChild('jointFrom')
  jointFrom!: ElementRef <HTMLInputElement>;
  @ViewChild('jointTo')
  jointTo!: ElementRef <HTMLInputElement>;
  
  displayedColumns: string[] = ['joint', 'sNumber', 'mNumber', 'description', 'images', 'result', 'defects', 'actions'];
  showActions = false;
  isLoading = false;
  noResults = false;
  from: number = 0;
  to: number;
  dataSource:any = [];
  workareaId: any;
  taskId: any;
  addNewEnabled = false;
  constructor(
    public rolesService: RolesService,
    private apiResultsService: ApiResultsService,
    private componentsCommunication: ComponentsCommunicationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.taskId = localStorage.getItem('taskId');
    this.workareaId = localStorage.getItem('workareaId');
    this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

      if(data && data.id && !this.workareaId) {
        this.workareaId = data.workArea.id;
        this.getData();
      } 
    }); 

    this.resultsUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the reports list
      if(name == 'update_results' && this.workareaId) {
        this.getData();
      }
    }); 

    if (!this.addNewEnabled) {
      this.displayedColumns.splice(0,1);
    }
  }

  ngOnDestroy(){
    this.resultsUpdate.unsubscribe();
    this.formDataSubscription.unsubscribe();
  }

  getData() {
    this.isLoading = true;      
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = this.workareaId;
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : '',
      idProcess: localStorage.getItem('idProcess')
    }
    this.dataSource = [];
    this.apiResultsService.getAllResults(params).subscribe(
      (result: any) => {
        if (result && result.length > 0){
          this.dataSource = [...result];
          localStorage.setItem('assetId', result[0].assetDto.id);
          this.getResultDatapointValues();
        } else {
          this.dataSource = []
          this.isLoading = false;   
        }
      },
      // error
      (msg) => {
        console.log('error retrieving result list ' + msg);
        this.isLoading = false;
    });
    
  }

  preview(element: any){
    const tenantId = localStorage.getItem('taskTenantId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      taskId: localStorage.getItem('taskId'),
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }

  /**
   * Gets RESULTS DataPoint Values of one Task Asset and Service
   * to retrieve all the available actions to edit for that specific result
   */
  getResultDatapointValues(){

    this.dataSource.forEach((element:any) => {
      const tenantId = localStorage.getItem('selectedSiteId');   
      const workareaId = this.workareaId;
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        taskId: localStorage.getItem('taskId'),
        assetId: element.assetDto.id
      }
      //Gets datapoint values of a service from a specific asset
      //task/datapointValue/{tenantId}/{taskId}/{assetId}/services
      this.apiResultsService.getResultDatapointValuesServices(params).subscribe(
        (result: any) => {
          if (result){
            element.resultDatapointValues = result;
          }
          this.isLoading = false;
        },
        // error
        (msg) => {
          console.log('error retrieving result datapoint values ' + msg);
          this.isLoading = false;
      });            
    });
  }

  confirmation(action: string, elem?: any) {    
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.deleteResult(elem);
        }
      }
    ); 
  }

  /**
   * deletes the selected service from the list
   * @param element selected element to be removed
   */
  deleteResult(element: any){
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = this.workareaId;
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: localStorage.getItem('taskId'),
      assetId: element.assetDto.id
    }
    this.apiResultsService.deleteResult(params).subscribe(
      () => {
        this.componentsCommunication.setUpdateList('update_results');
        this.snackBar.open( 'Result deleted', '', {duration: 3000});  
      },
      // error
      (msg) => {
        console.log('error deleting the selected result ' + msg);
        this.isLoading = false;
    });            
  
  }

  editService(element: any, service:any){
    element.service = service;
    this.dialog.open(EditResultServiceComponent, {
      height: 'auto',
      width: '90%',
      disableClose: true,
      autoFocus: false,
      data: element
    });
  }

  editItemDetails (element: any) {
    const data = {
      element: element,
      action: 'view-itemdetails'
    }      
      this.dialog.open(EditItemDetailsComponent, {
      height: 'auto',
      width: '90%',
      disableClose: true,
      autoFocus: false,
      data: data
    });
  }

  applyFilter(){

    if (this.jointFrom.nativeElement.value) {
      this.from = parseInt(this.jointFrom.nativeElement.value.trim())-1;
    } else {
      this.from = 0;
    }
    if (this.jointTo.nativeElement.value) {
      this.to = parseInt(this.jointTo.nativeElement.value.trim());
    } else {
      this.to = this.dataSource.length;
    }
    //extracts the start and the end
    this.dataSource.data = [...this.dataSource.slice(this.from, this.to)];
  }

  addNewItem(){
    let dialogRef;
    let data:any = {};
    const height = 'auto'
    const width = 'auto';
    data.action = 'create_result'
    dialogRef = this.dialog.open(EditAssetDialogComponent, {
      height: height,
      width: width,
      disableClose: true,
      data: data
    });

  }

  createDefect(element:any){
    element.action = 'create';
    this.dialog.open(CreateManualDefectComponent, {
      height: 'auto',
      width: '90%',
      disableClose: true,
      autoFocus: false,
      data: element
    });
  }
  createRepair(element: any, type: string) {
    element.type = type;
    element.action = 'create';
    this.dialog.open(CreateRepairComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      data: element
    });
  }
  
  addResults() {
  }

  edit(element: any){
    element.id = '12345';
    /*this.dialog.open(EditServiceDialogComponent, {
      height: '90%',
      width: '90%',
      disableClose: true,
      autoFocus: false,
      data: element
    });*/
  }
}
