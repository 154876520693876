<main-headerbar></main-headerbar>

<mat-card class="main-container projects-card">
  <mat-card-header>
    <mat-card-title>
      <img class="logo" src="../../assets/images/img_paperless-icon.png" />

      <h1>
        <!--{{'APP.OVERVIEWS.TITLE' | translate }}-->
        My Dashboard
      </h1>
      <div class="header-color-divider"></div>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content *ngIf="visibleTabs && !loadingRoles">
    <div class="my-content-container"
    [ngClass]="{'full-width':!roles.isAdmin &&
    !rolesService.IMSupervisor(roles.allRoles) &&
    !rolesService.IMEditor(roles.allRoles)}"
    >
      <mat-tab-group async
        (selectedTabChange)="tabChanged($event)"
        animationDuration="0ms"
        [selectedIndex]="mainTabIndex"
        class="example-stretched-tabs mat-elevation-z4"
        mat-stretch-tabs
      >
        <mat-tab
          tabindex="1"
          *ngIf="
            visibleTabs.includes('Users') ||
            visibleTabs.includes('System') ||
            rolesService.isReader(roles.systemRoles) ||
            rolesService.systemAdmin(roles.systemRoles) ||
            rolesService.isOperator(roles.systemRoles) ||
            rolesService.supervisor(roles.systemRoles)
          "
        >
          <ng-template mat-tab-label> My Users </ng-template>
          <my-users></my-users>
        </mat-tab>

        <mat-tab
          tabindex="1"
          *ngIf="
            visibleTabs.includes('Sites') ||
            visibleTabs.includes('System') ||
            rolesService.isReader(roles.systemRoles) ||
            rolesService.systemAdmin(roles.systemRoles) ||
            rolesService.isOperator(roles.systemRoles) ||
            rolesService.supervisor(roles.systemRoles) 
          "
        >
          <ng-template mat-tab-label> My Sites </ng-template>
          <my-sites></my-sites>
        </mat-tab>

        <mat-tab
          tabindex="2"
          *ngIf="
            visibleTabs.includes('System') ||
            rolesService.isReader(roles.systemRoles) ||
            rolesService.systemAdmin(roles.systemRoles) ||
            rolesService.isOperator(roles.systemRoles) ||
            rolesService.supervisor(roles.systemRoles) 
          "
        >
          <ng-template mat-tab-label> My Workareas </ng-template>
          <my-workareas></my-workareas>
        </mat-tab>

        <!-- TODO my roles-->

        <mat-tab
        tabindex="3"
          *ngIf="
            visibleTabs?.includes('Task') || visibleTabs?.includes('System')
          "
        >
          <ng-template mat-tab-label> My Work </ng-template>
          <app-tasks></app-tasks>
        </mat-tab>

        <mat-tab
        tabindex="4"
          *ngIf="
            visibleTabs?.includes('Inventory') ||
            visibleTabs?.includes('System')
          "
        >
          <ng-template mat-tab-label> My Inventory </ng-template>
          <inventory-view></inventory-view>
        </mat-tab>
      </mat-tab-group>
    </div>    
    <div
      *ngIf="
        roles.isAdmin ||
        rolesService.IMSupervisor(roles.allRoles) ||
        rolesService.IMEditor(roles.allRoles) 
      "
      class="my-notifications-container"
    >
      <mat-tab-group
        animationDuration="0ms"
        [selectedIndex]="selectedTabIndex"
        class="example-stretched-tabs mat-elevation-z4 custom"
        mat-stretch-tabs
      >
        <mat-tab>
          <ng-template mat-tab-label> My Notifications </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div class="notifications-content">
        <br />
      </div>
    </div>
  </mat-card-content>
</mat-card>
