
<main-headerbar></main-headerbar>   

<mat-card class="main-container projects-card">
  <mat-card-header>

    <mat-card-title>
      <img class="logo" src="../../assets/images/img_paperless-icon.png">

      <h1>      
      Reset Password
    </h1>
    <div class="header-color-divider"></div>      
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <div class="content-container">
        <span class="msg">Passwords must contain at least 8 characters, and at least an uppercase and a lowercase letter and a number
        </span>
        <br/>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <form *ngIf="!isLoading" #resetPassForm="ngForm" (ngSubmit)="setNewPassword()">
            
            <h4>Old Password
            </h4>
            <mat-form-field>
              <input matInput [(ngModel)]="oldPassword" name="oldPassword" required
              (keyup)="oldPassword = $event.target.value.toString(); validateForm()">
              <mat-error>            
              </mat-error>
            </mat-form-field>

            <h4>New Password
            </h4>
            <mat-form-field>
              <input matInput type="password" [(ngModel)]="newPassword" name="newPassword" required
              (keyup)="newPassword = $event.target.value.toString(); validateForm()">
            </mat-form-field>
            <span class="required_item" 
            *ngIf="newPassword.length > 0 && invalidNewPassword">
            is invalid!</span>

            <h4>Confirm New Password
            </h4>
            <mat-form-field>
                <input matInput type="password" [(ngModel)]="confirmedNewPassword" name="confirmedPassword" required
                (keyup)="confirmedNewPassword = $event.target.value.toString(); validateForm()"> 
            </mat-form-field>
            <span class="required_item" 
            *ngIf="confirmedNewPassword.length > 0 && invalidConfirmedPassword">
            Does not match!</span>

            <br>
            <br>
            <button mat-raised-button color="primary" 
            [disabled]="!validForm || invalidConfirmedPassword || invalidNewPassword">Save New Password</button>            
                
    
          </form>        
  </div>

  </mat-card-content>
