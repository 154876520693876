
<mat-card class="results-card">
    <mat-card-header>
  
      <mat-card-title>
  
      </mat-card-title>
    </mat-card-header>
  
    <mat-card-content>  
        <div class="my-content-container">

        <mat-toolbar color="primary" class="results-toolbar">
            <mat-form-field  class="custom-width" appearance="outline">
                <mat-select (selectionChange)="applyFilter($event.value)">
                    <mat-option value="-"> </mat-option> 
                    <mat-option value="field_repair">Field Repair</mat-option>
                    <mat-option value="repair_required">Repair Required</mat-option>
                </mat-select>
            </mat-form-field>

        </mat-toolbar>

        <table mat-table *ngIf="!isLoading" [dataSource]="dataSource" class="mat-elevation-z8">
            <br>
    
            <!-- Number Column -->
            <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>Number</th>
            <td mat-cell *matCellDef="let element">
                {{element.number}} </td>
            </ng-container>
    
            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{element.type}}
                </td>
            </ng-container>

            <!-- Area Column -->
            <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef>Area</th>
            <td mat-cell *matCellDef="let element">
                {{element?.repairArea?.name}}
            </td>
            </ng-container>

            <!-- Repair Column -->
            <ng-container matColumnDef="repair">
                <th mat-header-cell *matHeaderCellDef>Repair</th>
                <td mat-cell *matCellDef="let element">
                <span *ngIf="element.repairRequiredDto">{{element?.repairRequiredDto?.name}}</span>
                <span *ngIf="element.fieldRepairDto">{{element?.fieldRepairDto?.name}}</span>
                </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">
                {{element.description}}
                </td>
            </ng-container>
        
            
            <!-- actions -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell width ="40px" *matHeaderCellDef> </th>
                <td mat-cell width ="40px" *matCellDef="let element">
    
                <!-- list actions menu-->
                <button
                *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
                class="user_menu custom" mat-raised-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
                <mat-icon>expand_more</mat-icon>
                </button>
    
                <mat-menu class="menu" x-position="before" #menu="matMenu">

                    <button mat-menu-item (click)="editItemDetails(element)">
                    <mat-icon>edit</mat-icon>
                        Edit 
                    </button>                 
                    <button mat-menu-item (click)="confirmation('delete', element)">
                        <mat-icon>delete</mat-icon>
                        Delete
                    </button>

                </mat-menu>
    
            </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


      <div class="no-results" *ngIf="noResults && !isLoading">
        <h1>No results</h1>
      </div>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <br>
    </div>
  
    </mat-card-content>
  