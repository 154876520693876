export class ModelCreateReport {
    workArea =  '';
    reportNumber =  '';
    reportTypeFilter =  {};
    assetType =  {};
    assetCategory =  {};
    connectionType1 =  {};
    connectionType2 =  {};
    dateCreated = null;
    createdBy =  '';
    active =  true;
    taskNumber =  '';
    title =  '';
    assignee =  '';
    dueDate =  null;
    priority =  '';
    status =  '';
    reference =  '';
    description =  '';
    comment =  '';
    purchaseOrder =  '';
    contract =  '';
    clientJobReference =  '';
    rig =  '';
    locationOfExamination =  '';
    dateOfNextExamination = null;
    localProcedureNumber =  '';
    bodyConnections =  0;
    specificationSet = [];
    servicesSet = [];
}