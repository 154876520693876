
<mat-card class="results-card">
    <mat-card-header>
  
      <mat-card-title>
  
      </mat-card-title>
    </mat-card-header>
  
    <mat-card-content>  
      <div class="my-content-container">

        <mat-toolbar color="primary" class="results-toolbar">

            <button 
            *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
            mat-raised-button (click)="addNewPerson('create')" class="last right search-menu-button custom-button blue" color="primary">
              <mat-icon class="icon">add</mat-icon>
            </button>

        </mat-toolbar>
        
      <table mat-table *ngIf="!noResults && !isLoading" [dataSource]="dataSource" class="mat-elevation-z8">
 
        <br>
  
        <!-- Signatory Column -->

        <ng-container matColumnDef="signatory">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell  width ="40px" *matCellDef="let element">
              <mat-icon width ="40px" class="signatory" *ngIf="element.setReport">star</mat-icon>              
          </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell  *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>
  
        <!-- last Name Column -->
        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef>Last Name</th>
          <td mat-cell *matCellDef="let element">
            {{element.lastName}}
        </td>
        </ng-container>

        <!-- job title Column -->
        <ng-container matColumnDef="jobtitle">
            <th mat-header-cell *matHeaderCellDef>Job Title</th>
            <td mat-cell *matCellDef="let element">
                {{element.jobTitle}}
            </td>
          </ng-container>

          
        <!-- services Column -->
        <ng-container matColumnDef="services">
            <th mat-header-cell *matHeaderCellDef>Services</th>
            <td mat-cell *matCellDef="let element">
                {{element?.personnelServicesStr}}
            </td>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell width ="40px" *matHeaderCellDef> </th>
          <td mat-cell width ="40px" *matCellDef="let element">
  
            <!-- list actions menu-->
            <button
            *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
            class="user_menu custom" mat-raised-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
              <mat-icon>expand_more</mat-icon>
            </button>
  
            <mat-menu class="menu" x-position="before" #menu="matMenu">

                <button mat-menu-item (click)="editItemDetails(element)">
                <mat-icon>edit</mat-icon>
                    Edit 
                </button>
                <button *ngIf="!element.setReport" mat-menu-item (click)="confirmation(element, 'setSignatory')">
                    <mat-icon>edit_document</mat-icon>
                    Set Report Signatory
                </button>                 
                <button mat-menu-item (click)="confirmation(element, 'delete')">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>

            </mat-menu>
  
        </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <br>
    </div>
  
    </mat-card-content>
  