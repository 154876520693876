export class ModelCreateEquipment {
    id = null;
	dateCreated = "";
	createdBy = "admin";
	updatedBy = null;
	lastUpdated = null;
	task = {};
	equipment = {};
	servicesSet = [];
}
