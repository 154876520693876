import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'input-services-results',
  templateUrl: './input-services-results.component.html',
  styleUrls: ['./input-services-results.component.scss']
})
export class InputServicesResultsComponent {
  @Input() element:any;
  @Input() readonlyFields:any;
  @Input() formData:any;  
  @Output() elementEvent = new EventEmitter<any>();
  prefilledValue: any;
  readonly = false;

  ngOnInit(){
    // disables readonly when no fields are defined
    if(!this.readonlyFields){
      this.element.readOnly = false;
    }
    if (this.element.values && this.element.values.length > 0) {
      this.prefilledValue = this.element.values;
    }
  }
  
  selectValue(elem: any, element: any) {  

    const item = {
      value: elem.trim(),
      type: 'values',
      id: element.id
    };
    this.elementEvent.emit(item);
    
  }  
}


