import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddPersonnelComponent } from '../create-report-personnel/add-personnel/add-personnel.component';
import { ApiRepairsService } from 'src/app/services/api.repairs.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { Subscription } from 'rxjs';
import { CreateRepairComponent } from '../create-report-results/dialogs/create-repair/create-repair.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-create-report-repairs',
  templateUrl: './create-report-repairs.component.html',
  styleUrls: ['./create-report-repairs.component.scss']
})
export class CreateReportRepairsComponent {
  repairsUpdate!: Subscription;
  formDataSubscription!: Subscription;
  @Input() roles: any;
  @Input() workareaRoles: any;
  displayedColumns: string[] = ['number', 'type', 'area', 'repair', 'description', 'actions'];
  showActions = false;
  isLoading = false;
  noResults = false;
  dataSource:any = [];
  addNewEnabled = false;
  taskId: any;
  workareaId: any;
  fieldRepairs = [];
  repairsRequired = [];
  constructor(
    public rolesService: RolesService,
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private repairsService: ApiRepairsService) {}

    ngOnInit() {      
      this.taskId = localStorage.getItem('taskId');      
      this.workareaId = localStorage.getItem('workareaId');
      this.repairsUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
        // updates the reports list
        if(name == 'update_repairs') {
          this.dataSource = [];
          this.getDataFieldRepair();
          this.getDataRepairRequired();
        }
      });    

      this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

        if(data && data.id && !this.workareaId) {
          this.workareaId = data.workArea.id;
          this.dataSource = [];
          this.getDataFieldRepair();
          this.getDataRepairRequired();
        } 
      }); 
    }

    ngOnDestroy(){
      this.repairsUpdate.unsubscribe();
      this.formDataSubscription.unsubscribe();
    }
    
    //task repair field
    getDataFieldRepair() {
      this.isLoading = true;
      const tenantId = localStorage.getItem('taskTenantId');
      const workareaId = this.workareaId;
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : '',
        type: 'field'
      }
      this.dataSource = [];
      this.repairsService.getAllrepairsByType(params).subscribe(
        (result: any) => {
          if (result && result.length > 0 && result[0].id != null && result[0].type != null){
            this.dataSource = [...this.dataSource.concat(result)];
          }
          this.isLoading = false;
        },
        // error
        (msg) => {
          console.log('error retrieving lookup list ' + msg);
          this.isLoading = false;
      });
      
    }

    // task repair required
    getDataRepairRequired() {
      this.isLoading = true;
      const tenantId = localStorage.getItem('taskTenantId');
      const workareaId = this.workareaId;
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : '',
        type: 'required'
      }
      this.dataSource = [];
      this.repairsService.getAllrepairsByType(params).subscribe(
        (result: any) => {     
          if (result && result.length > 0 && result[0].id != null && result[0].type != null){
            this.dataSource = [...this.dataSource.concat(result)];
          }
          
          this.isLoading = false;
        },
        // error
        (msg) => {
          console.log('error retrieving lookup list ' + msg);
          this.isLoading = false;
      });
      
    }


    addNew() {
      const data = {
        personnelList: this.dataSource,
        taskId: this.taskId
      }
      
      this.dialog.open(AddPersonnelComponent, {
        height: 'auto',
        width: '60%',
        disableClose: true,
        autoFocus: false,
        data: data
      });
    }
    editItemDetails (element: any) {      
      element.action = 'edit';
      this.dialog.open(CreateRepairComponent, {
        height: 'auto',
        width: 'auto',
        disableClose: true,
        autoFocus: false,
        data: element
      });
    
    }

    applyFilter(value: string){

      if(value == 'field_repair'){
        this.getDataFieldRepair();

      }
      if (value == 'repair_required') {
        this.getDataRepairRequired();

      } if (value == '-') {
        this.getDataFieldRepair();
        this.getDataRepairRequired();

      }

    }

    /**
     * deletes the selected service from the list
     * @param element selected element to be removed
     */
    
/**
 * action needed a confirmation
 * @param action action to be performed
 * @param elem selected element
 */
confirmation(action: string, elem?: any) {
    
  elem.action = action;
  elem.confirmation = false;
  if (action == 'delete') {
    elem.confirmationString = 'Delete the selected element?';
  } 
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    height: '200px',
    width: '440px',
    disableClose: true,
    autoFocus: false,
    data: elem
  });

  dialogRef.afterClosed().subscribe(
    (data) => {
      if (data.confirmation) {
        this.deleteRepair(elem);
      }
    }
  ); 
}

deleteRepair(element: any) {
  const endpointType = element.type == '2' ? 'field' : 'required';
  const tenantId = localStorage.getItem('taskTenantId');   
  const workareaId = this.workareaId;
  const params = {
    workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
    tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
    elemId: element.id,
    type: endpointType
  }

  this.repairsService.deleteRepair(params).subscribe(
    () => {
      console.log( 'repair deleted');
      this.snackBar.open( 'repair deleted ', '', {duration: 3000});
      // sends an update
      this.componentsCommunication.setUpdateList('update_repairs');
  },
    // error
    (msg) => {
      if (msg.status == '200') {
        console.log( 'repair deleted');
        this.snackBar.open( 'repair deleted ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_repairs');
      }
      console.log( msg.statusText);
  });    
}

}


