import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { EditAssetDialogComponent } from '../edit-asset-dialog/edit-asset-dialog.component';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ModelCreateTask } from 'src/app/models/model.create.task';
import { ModelCreateReport } from 'src/app/models/model.create.report';

@Component({
  selector: 'create-form-dialog',
  templateUrl: './create-form-dialog.component.html',
  styleUrls: ['./create-form-dialog.component.scss'],
})
export class CreateFormDialogComponent implements OnInit {

  myJson = {
    id: '',
    name: 'Create Work Task',
    key: 'createReportForm',
    version: 4,
    formModel: {
      name: 'Create Work Task',
      key: 'createReportForm',
      version: 0,
      fields: [
        {
          fieldType: 'OptionFormField',
          id: 'workArea',
          name: 'Work Area',
          type: 'dropdown',
          value: null,
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [
          ],
          optionsExpression: null,
        },
        {
          fieldType: 'FormField',
          id: 'taskNumber',
          name: 'Task Number',
          type: 'text',
          value: '10000',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
        },
        {
          fieldType: 'OptionFormField',
          id: 'reportTypeFilter',
          name: 'Task Type',
          type: 'dropdown',
          value: '',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [],
          optionsExpression: "",
        },
        {
          fieldType: 'OptionFormField',
          id: 'servicesSet',
          name: 'Services',
          type: 'chiplist',
          value: '',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [],
          optionsExpression: "",
        },
        {
          fieldType: 'OptionFormField',
          id: 'specificationSet',
          name: 'Specification',
          type: 'chiplist',
          value: '',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          params: {
            binding: 'asset.bodyconf.borecond',
            conf: {
              name: 'Body Condition',
            },
            layout: {
              row: '1',
              col: '1',
            },
            colConf: {
              title: 'Body Datapoiints',
            },
          },
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [],
          optionsExpression: "",
        },
        {
          fieldType: 'FormField',
          id: 'dueDate',
          name: 'Date of Examination',
          type: 'datepicker',
          value: '',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          params: {
            binding: '',
            conf: {
              name: 'Date of Examination',
            },
            layout: {
              row: '1',
              col: '1',
            },
            colConf: {
              title: 'Body Datapoiints',
            },
          },
        },
      ],
      outcomes: [],
    },
    formInstanceId: '',
    submittedBy: 'admin',
    submittedDate: 1679492222877,
    taskId: '',
    processInstanceId: '',
    processDefinitionId: '',
    tenantId: '',
  };
  singleItemJson = {
    id: '',
    name: 'Single Item Inspection Form',
    key: 'singleItemInspectionForm',
    version: 4,
    formModel: {
      name: 'Single Item Inspection Form',
      key: 'singleItemInspectionForm',
      version: 0,
      fields: [
        {
          fieldType: 'OptionFormField',
          id: 'assetType',
          name: 'Asset Type',
          type: 'dropdown',
          value: '',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [],
          optionsExpression: "",
        },
        {
          fieldType: 'FormField',
          id: 'assetcategory',
          name: 'Asset Category',
          type: 'text',
          value: null,
          required: false,
          disabled: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
        },
        {
          fieldType: 'OptionFormField',
          id: 'connectionType',
          name: 'Connection 1',
          type: 'dropdown',
          value: '',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [],
          optionsExpression: "",
        },
        {
          fieldType: 'OptionFormField',
          id: 'connectionType',
          name: 'Connection 2',
          type: 'dropdown',
          value: '',
          required: true,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [],
          optionsExpression: "",
        },
        {
          fieldType: 'OptionFormField',
          id: 'bodyConnections',
          name: 'Body Connections',
          type: 'dropdown',
          value: '2',
          required: false,
          readOnly: true,
          overrideId: false,
          placeholder: null,
          layout: null,
          optionType: null,
          hasEmptyValue: true,
          options: [],
          optionsExpression: null,
        },
      ],
      outcomes: [],
    },
    formInstanceId: '',
    submittedBy: '',
    submittedDate: '',
    taskId: '',
    processInstanceId: '',
    processDefinitionId: '',
    tenantId: '',
  };

  newReport: any = new ModelCreateReport;
  modelCreateTask: any = new ModelCreateTask;
  twoColumns: boolean = true;
  showSecondColumn: boolean = false;
  reportType = '';
  validForm = false;
  controls: any = {};
  validationsArray: any = [];
  isLoading = false;
  constructor(
    private servicesService: ApiServicesService,
    private sharedFunctions: SharedFunctionsService,
    private componentsCommunication: ComponentsCommunicationService,
    private apiService: ApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditAssetDialogComponent>
  ) {}

  ngOnInit() {
    this.twoColumns = true;
  }
  saveChanges() {
    if (this.validForm) {
      this.isLoading = true;
      this.validForm = false;
      // saves the creation date      
      this.newReport.dateCreated = Number(new Date());      
      this.newReport.createdBy = localStorage.getItem('username');      
      const tenantId = localStorage.getItem('selectedSiteId');    
      const workareaId = this.newReport.workArea.id;  
      localStorage.setItem('taskWorkareaId', workareaId);  
      const params = {
        formData: this.newReport,
        formItems: this.myJson,
        singleItemForm: this.singleItemJson,
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',    
        workareaId: workareaId ? workareaId : ''
      };

      this.apiService.createReport(this.newReport, params).subscribe(
        (result: any) => {
          const delay = 2000;
          const msg = 'The task was created!';

          localStorage.setItem('idProcess', result.idProcess);
          localStorage.setItem('taskId', result.id);
          this.saveServices(this.newReport.servicesSet);

          this.snackBar.open(msg, '', { duration: delay });
          this.sharedFunctions.delay(delay).then((response) => {
            this.isLoading = false;
            this.closePopup();
            this.router.navigateByUrl('/create-report-details');
          });
          console.log(msg);
        },
        // error
        (msg) => {
          this.closePopup();
          this.isLoading = false;
          console.log('error creating the new report ' + msg);
        }
      );
    }
  }

  closePopup(): void {
    this.componentsCommunication.setUpdateTasks([]);
    this.dialogRef.close();
  }
/**
 * saves the services when a task is created
 * @param services services to be saved
 */
  saveServices(services: any) {
    services.forEach((service: any) => {
      const tenantId = localStorage.getItem('selectedSiteId');   
      const workareaId = localStorage.getItem('taskWorkareaId');  
      const param = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        serviceName: service.name,
        idProcess: localStorage.getItem('idProcess'),
        workareaId: workareaId ? workareaId : ''
      };

      //***** Get Service Form from Flowable: SERVICES First time
      //***** task/datapointValue/getServiceForm/{tenantId}/{id_process}/{serviceName}
      this.servicesService.getServicesFormsFirstTime(param).subscribe(
        (response: any) => {
          if (response) {
            response.createdBy = localStorage.getItem('username');
            const workareaId = localStorage.getItem('taskWorkareaId');  
            const params = {     
              tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
              workareaId: workareaId ? workareaId : '',         
              taskId: localStorage.getItem('taskId'),
              serviceId: service.id,
              body: response,
            };

            //***** Create EMPTY DataPointValues For Tab Services
            //***** task/datapointValue/create/{tenantId}/{taskId}/service/{serviceId}
            this.servicesService.createServiceDatapoint(params).subscribe(
              (result: any) => {
                console.log('service datapoint created');
              },
              // error
              (msg) => {
                if (msg.status == '200') {
                  console.log('service datapoint created');
                } else {
                  console.log( msg.statusText);
                }
            });     
            
            //***** Create EMPTY DataPointValues for Tab Results
            //***** api/tenant/task/datapointValue/create/{tenantId}/{taskId}/result/{assetId}
            /**this.apiResultsService.createResultsDatapoint(params).subscribe(
              (result:any) => {
                console.log( 'result datapoint created');;
            },
              // error
              (msg) => {
                if (msg.status == '200') {
                  console.log( 'result datapoint created');          
                } else {
                  console.log( msg.statusText);
                }                
            });  
            */
            
          }
        },
        // error
        (msg) => {
          this.isLoading = false;
          console.log('error loading the result json ' + msg.statusText);
        }
      );
    });
  }

  /**
   * stores the value
   * @param selectValue value that was selected to be stored
   * @param element selected element
   */
  addSelectValue(selectValue: any, element: any) {
    if (selectValue && element.id && Object.keys(selectValue).length > 0) {
      if (element.id.toLowerCase().includes('reporttype')) {
        if (selectValue.name.toLowerCase() == 'single item inspection') {
          this.showSecondColumn = true;
        } else {
          this.showSecondColumn = false;
        }
      }

      this.newReport = { ...this.sharedFunctions.saveFormValue(this.newReport, element, selectValue) };
    }

    this.validateForm();
  }
  /**
   * validates the content and enabling the save button
   */
  validateForm() {
    let validForm = true;
    const _this = this;

    Object.keys(this.newReport).forEach((element: string) => {
      const selectedElem = _this.myJson.formModel.fields.find(
        (item: any) => element.toLowerCase() == item.id.toLowerCase()
      );
      const requiredItem = _this.modelCreateTask.hasOwnProperty(element.toLowerCase());
      const itemValue = _this.newReport[element as keyof typeof _this.newReport];
      if (
        (typeof itemValue == 'string' || typeof itemValue == 'number') &&
        itemValue.toString().length == 0 &&
        (selectedElem?.required || requiredItem)
      ) {
        validForm = false;
      } else if (requiredItem && (itemValue == null || typeof itemValue == 'undefined')){
        validForm = false;
      } else if (
        itemValue &&
        typeof itemValue == 'object' &&
        Object.keys(itemValue).length == 0 &&
        (selectedElem?.required || requiredItem)
      ) {
        validForm = false;
      }
    });
    this.validForm = validForm;
  }
}
