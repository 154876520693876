import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, startWith, map } from 'rxjs';
import { ModelCreatePersonnel } from 'src/app/models/model.create.personnel';
import { ApiPersonnelService } from 'src/app/services/api.personnel.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export interface DialogData {
  name: string;
  taskId: string;
  action: string;
  element: any;
}

@Component({
  selector: 'app-add-personnel',
  templateUrl: './add-personnel.component.html',
  styleUrls: ['./add-personnel.component.scss'],
})
export class AddPersonnelComponent {
  isLoading = false;
  validForm = false;
  @ViewChild('personnelInput')
  personnelInput!: ElementRef<HTMLInputElement>;
  @ViewChild('servicesInput')
  servicesInput!: ElementRef<HTMLInputElement>;

  @Input() element: any;
  @Input() readonlyFields: any;
  @Input() formData: any;
  prefilledValue: any;
  @Output() elementEvent = new EventEmitter<any>();

  newPersonnelModel: any = new ModelCreatePersonnel();
  selectedPersonnel = <any>[];
  selectedServices = <any>[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ctrlPersonnel = new UntypedFormControl();
  ctrlServices = new UntypedFormControl();
  allPersonnel: Observable<any[]> | undefined;
  allServices: Observable<any[]> | undefined;
  availablePersonnel: any = [];
  availableServices: any = [];
  invalid = false;
  lookupsList: any;
  datePipe: DatePipe = new DatePipe('en-GB');
  services: Observable<any[]>;

  constructor(
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AddPersonnelComponent>,
    private personnelService: ApiPersonnelService
  ) {}

  ngOnInit() {
    this.getData();
  }

  /**
   * retrieves the correct data to display
   */
  getData() {
    this.isLoading = true;
    this.getAllAvailableServices();

    const workareaId = localStorage.getItem('taskWorkareaId');  
    const tenantId = localStorage.getItem('taskTenantId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: localStorage.getItem('taskId')
    };

    this.personnelService.getAvailablePersonnel(params).subscribe(
        (result: any) => {
          if (result.length > 0) {
            this.allPersonnel =  <any>[...result];
            this.availablePersonnel = [...result];
          } 
          this.allPersonnel = this.updateFormControl('personnel', this.availablePersonnel, this.ctrlPersonnel);
          this.isLoading = false;
        },
      // error
      (msg) => {
        console.log('error retrieving task personnel ' + msg);
        this.isLoading = false;
      }
    );

    // retrieves and stores the personnel when is edit mode
    if (this.dialogData.action == 'edit') {
      this.newPersonnelModel.userDtoSet = [...this.availablePersonnel];
    }

    this.allPersonnel = this.updateFormControl('personnel', this.availablePersonnel, this.ctrlPersonnel);
  }

  getAllAvailableServices() {
    const workareaId = localStorage.getItem('taskWorkareaId');  
    const tenantId = localStorage.getItem('taskTenantId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: localStorage.getItem('taskId')
    };

    this.personnelService.getAllAvailableServices(params).subscribe(
      (result: any) => {
        if (result && result.length) {
          this.processResults(result);
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log('error retrieving task services ' + msg);
      }
    );

  }

  /**
   * processes the results to display
   * @param result data array of results
   */
  processResults(result: any) {
    if (result.length > 0) {
      this.allServices = <any>[...result];
      this.availableServices = [...result];
    } else {
      // if no services are available, we retrieve all them for further adding
      this.allServices = <any>{...this.services};
      this.availableServices = {...this.services};
    }
    // when is edit mode, retrieves the selected elements to display them as already existing
    if (this.dialogData.action == 'edit') {
      this.dialogData.element.personnelServices.forEach((item: any) => {
        const entryFound = result.filter((option: any) => option.name == item);
        this.selectedServices.push(entryFound[0]);
        const selectedIndex = this.availableServices.findIndex((option: any) => option.name == item);
        this.availableServices.splice(selectedIndex, 1);
      });
    }
    if (Object.keys(this.availableServices).length > 0) {
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }
    this.isLoading = false;
  }

  /**
   * the form control in charge of an element, has to be updated to handle the content
   * @param type the type of element
   * @param availableElems the available array of elements
   * @param ctrl the control itself
   * @returns returns the updated control
   */
  updateFormControl(type: string, availableElems: any, ctrl: UntypedFormControl) {
    return ctrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filterElem(item, type) : availableElems.slice()))
    );
  }

  /**
   * adds an element to the list, when it's clicked
   * @param event event triggered when clicking an element
   */
  addElem(event: MatChipInputEvent, name: string): void {
    const value = (event.value || '').trim();
    const availableElems = name == 'personnel' ? this.availablePersonnel : this.availableServices;
    let selectedElems = name == 'personnel' ? this.selectedPersonnel : this.selectedServices;
    // Add our service if it exists
    if (value && availableElems.includes(value) && !selectedElems.includes(value)) {
      selectedElems.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.ctrlPersonnel.setValue(null);
    this.ctrlServices.setValue(null);
  }

  /**
   * removes an element from the list
   * @param elem element to be removed
   */
  removeElem(elem: any, name: string): void {
    let availableElems = name == 'personnel' ? this.availablePersonnel : this.availableServices;
    let selectedElems = name == 'personnel' ? this.selectedPersonnel : this.selectedServices;
    let selectedIndex;
    let availableIndex;
    if (name == 'personnel') {
      selectedIndex = selectedElems.findIndex(
        (item: any) =>
          item.userDto.firstName.includes(elem.userDto.firstName) ||
          item.userDto.lastName.includes(elem.userDto.lastName)
      );
      availableIndex = availableElems.findIndex(
        (item: any) =>
          item.userDto.firstName.includes(elem.userDto.firstName) ||
          item.userDto.lastName.includes(elem.userDto.lastName)
      );
    } else {
      selectedIndex = selectedElems.findIndex((item: any) => item.name == elem.name);
      availableIndex = availableElems.findIndex((item: any) => item.name == elem.name);
    }

    if (selectedIndex >= 0) {
      selectedElems.splice(selectedIndex, 1);
    }
    if (availableIndex == -1) {
      availableElems.push(elem);
    }

    if (name == 'personnel') {
      this.selectedPersonnel = selectedElems;
      this.availablePersonnel = availableElems;
      this.allPersonnel = availableElems;
      this.newPersonnelModel.userDtoSet = [...selectedElems];
      this.allPersonnel = this.updateFormControl('personnel', this.availablePersonnel, this.ctrlPersonnel);
    } else {
      this.selectedServices = selectedElems;
      this.availableServices = availableElems;
      this.allServices = availableElems;
      this.newPersonnelModel.servicesDtoSet = [...selectedElems];
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }

    this.validateForm();
  }

  /**
   * handles the selected element when clicking it
   * @param event event that is triggered while clicking it
   */
  selectedElem(event: MatAutocompleteSelectedEvent, name: string): void {
    let availableElems = name == 'personnel' ? this.availablePersonnel : this.availableServices;
    let selectedElems = name == 'personnel' ? this.selectedPersonnel : this.selectedServices;
    const elem = event.option.value.userDto;
    let availableOpt = [];
    let alreadyExists = [];
    if (name == 'personnel') {

      availableOpt = availableElems.filter(
        (option: any) =>
          option.userDto.firstName.includes(elem.firstName) || option.userDto.lastName.includes(elem.lastName)
      );
      alreadyExists = selectedElems.filter(
        (option: any) =>
          option.userDto.firstName.includes(elem.firstName) || option.userDto.lastName.includes(elem.lastName)
      );

      // Add our service if it exists
      if (availableOpt.length > 0 && alreadyExists.length == 0) {
        selectedElems.push(event.option.value);
        let index = availableElems.findIndex(
          (item: any) =>
            item.userDto.firstName.includes(elem.firstName) || item.userDto.lastName.includes(elem.lastName)
        );
        availableElems.splice(index, 1);
      }

      this.personnelInput.nativeElement.value = '';
      this.ctrlPersonnel.setValue(null);
    } else {
      const value = event.option.value.name;
      this.servicesInput.nativeElement.value = '';
      this.ctrlServices.setValue(null);
      availableOpt = availableElems.filter((option: any) => option.name == value);
      alreadyExists = selectedElems.filter((option: any) => option.name == value);

      // Add our service if it exists
      if (availableOpt.length > 0 && alreadyExists.length == 0) {
        selectedElems.push(event.option.value);
        let index = availableElems.findIndex((item: any) => item.name == value);
        availableElems.splice(index, 1);
      }
    }

    if (name == 'personnel') {
      this.selectedPersonnel = selectedElems;
      this.availablePersonnel = availableElems;
      this.allPersonnel = availableElems;
      this.newPersonnelModel.userDtoSet = [...selectedElems];
      this.allPersonnel = this.updateFormControl('personnel', this.availablePersonnel, this.ctrlPersonnel);
    } else {
      this.selectedServices = selectedElems;
      this.availableServices = availableElems;
      this.allServices = availableElems;
      this.newPersonnelModel.servicesDtoSet = [...selectedElems];
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }

    this.validateForm();
  }

  /**
   * filters by the elements that already exist
   * @param value element value to filter
   * @returns returns if is included
   */
  private _filterElem(element: any, name: string): string[] {
    let availableElems = name == 'personnel' ? this.availablePersonnel : this.availableServices;
    let returnElems = [];
    if (name == 'personnel') {
      const firstName = element.userDto.firstName ? element.userDto.firstName : element;
      const lastName = element.userDto.lastName ? element.userDto.lastName: element;
      returnElems = availableElems.filter(
        (item: any) =>
          item.userDto.firstName.includes(firstName) || item.userDto.lastName.includes(lastName)
      );
    }
    if (name == 'services') {
      const name = element.name ? element.name : element;
      returnElems = availableElems.filter((item: any) => item.name.includes(name));
    }
    return returnElems;
  }

  /**
   * checks if an element exists before displaying it
   * @param elem selected element
   * @returns returns if it exists
   */
  checkIfExists(elem: string, name: string) {
    let selectedElems = name == 'personnel' ? this.selectedPersonnel : this.selectedServices;
    return selectedElems.includes(elem);
  }

  selectValue(event: any, name: string) {
    /*  
      const elem = {
        name: event.option ? event.option.value.name : null,
        id: event.option ? event.option.value.id : null
      };
      this.elementEvent.emit(elem);*/
  }

  /**
   * handles the value change and in case it's not valid, shows the incorrect styles
   * @param value passed value
   */
  valueChange(value: any, name: string) {
    let availableElems = name == 'personnel' ? this.availablePersonnel : this.availableServices;
    if (!availableElems.includes(value.trim())) {
      this.invalid = true;
      const elem: any = {};
      // when the entered value doesn't match any valid options, it resets the previous values
      this.elementEvent.emit(elem);
    } else {
      this.invalid = false;
    }
  }

  /**
   * if the inserted text does not match any available entries, shows incorrect styles
   */
  checkContent(name: string) {
    let selectedElems = name == 'personnel' ? this.selectedPersonnel : this.selectedServices;
    this.invalid = true;
    if (Object.keys(selectedElems).length > 0) {
      this.invalid = false;
    }
  }

  /**
   * performs the arrays validation
   */
  validateForm() {
    this.validForm = false;
    if ( this.dialogData.action == 'create' && this.newPersonnelModel.userDtoSet.length > 0 && this.newPersonnelModel.servicesDtoSet.length > 0) {
      this.validForm = true;
    }
    if ( this.dialogData.action == 'edit' && this.newPersonnelModel.servicesDtoSet.length > 0) {
      this.validForm = true;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  /**
   * saves and stores the data, also calls the endpoint
   */
  saveChanges() {
    const user = localStorage.getItem('username') ? localStorage.getItem('username') : 'admin';
    this.newPersonnelModel.createdBy = user;
    this.newPersonnelModel.taskDto = { id: this.dialogData.taskId };
    this.isLoading = true;
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');  
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: this.dialogData.taskId
    };

    let selectedServices = this.newPersonnelModel.servicesDtoSet;
    this.newPersonnelModel.servicesDtoSet = [];
    selectedServices.forEach((elem: any) => {
      this.newPersonnelModel.servicesDtoSet.push({ name: elem.name, value: elem.id });
    });

    if (this.dialogData.action == 'create') {
      this.createPersonnel(this.newPersonnelModel, params);
    } else if (this.dialogData.action == 'edit') {
      this.updatePersonnel(this.newPersonnelModel, params);
    }
  }

  updatePersonnel(newPersonnelModel: ModelCreatePersonnel, params: any) {
    const user = localStorage.getItem('username') ? localStorage.getItem('username') : 'admin';
    newPersonnelModel.userDtoSet = <any>[{ id: this.dialogData.element.id }];
    newPersonnelModel.id = this.dialogData.element.guid;
    newPersonnelModel.updatedBy = <any>user;
    newPersonnelModel.lastUpdated = <any>Date.now();    
    this.personnelService.updateTaskPersonnel(newPersonnelModel, params).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.closePopup();
        this.componentsCommunication.setUpdateList('personnel_mainlist');
        this.snackBar.open( 'Personnel updated ', '', {duration: 3000});
      },
      // error
      (msg) => {
        console.log('error updating the personnel' + msg);
        this.isLoading = false;
      }
    );
  }

  createPersonnel(newPersonnelModel: ModelCreatePersonnel, params: any) {
    let newUsers: any = [];
    newPersonnelModel.userDtoSet.forEach((item: any) => {
      newUsers.push({id: item.userDto.id});
    });
    newPersonnelModel.userDtoSet = <any>[...newUsers];
    this.personnelService.createTaskPersonnel(newPersonnelModel, params).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.closePopup();
        this.componentsCommunication.setUpdateList('personnel_mainlist');
        this.snackBar.open( 'Personnel created ', '', {duration: 3000});
      },
      // error
      (msg) => {
        console.log('error creating the personnel' + msg);
        this.isLoading = false;
      }
    );
  }
}
