export class ModelWorkArea {
    id = null;
    tenantDto = {
        id: '',
        companyTitle:''
    };
    siteSupplier = {
        id: '',
        companyTitle:''
    };
    name = '';
    clientAcceptance = false;
    deleted = false;
    owner = {
        id: '',
        companyTitle:''
    };
}
