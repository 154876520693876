import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiFilesService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  //api/tenant/tasks/{tenantId}/{taskId}/attachments/list
  getTaskAttachments(params: any) {
    const endpoint = `${this.baseURL}/api/wm/task/${params.taskId}/attachments/list`;
    return this.http.get(endpoint, {headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId) });
  }

  //line 14 excel
  ///utils/{assetId}
  uploadImage(formData: any, params: any) {
    const endpoint = `${this.baseURL}/api/im/asset/${params.assetId}/uploadFile`;
    const allParams = new HttpParams();
    let headers = this.authService.getHeadersUploadFile(params);
    // removes the unwanted property
    headers.delete('content-type');
    const options: any = {
      params: allParams,
      headers: headers,
      responseType: 'json',
    };
    return this.http.post(endpoint, formData, options);
  }

  //api/tenant/utils/tasks/{tenantId}/{taskId}/upload
  uploadAttachmentsFile(params: any) {
    const endpoint = `${this.baseURL}/api/wm/tasks/${params.taskId}/file/upload`;
    const allParams = new HttpParams();
    let headers = this.authService.getHeadersUploadFile(params);
    // removes the unwanted property
    headers.delete('content-type');
    const options: any = {
      params: allParams,
      headers: headers,
      responseType: 'json',
    };
    return this.http.post(endpoint, params.form, options);
  }

  //api/tenant/utils/tasks/{tenantId}/results/upload
  uploadResultAttachment(params: any) {
    const endpoint = `${this.baseURL}/api/wm/tasks/results/upload`;
    const allParams = new HttpParams();
    let headers = this.authService.getHeadersUploadFile(params);
    // removes the unwanted property
    headers.delete('content-type');
    const options: any = {
      params: allParams,
      headers: headers,
      responseType: 'json',
    };
    return this.http.post(endpoint, params.form, options);
  }

  //api/tenant/task/datapointValue/{tenantId}/{taskId}/upload
  uploadResultServiceAttachment(params: any) {
    const endpoint = `${this.baseURL}/api/wm/datapoints/task/${params.taskId}/upload`;
    const allParams = new HttpParams();
    let headers = this.authService.getHeadersUploadFile(params);
    // removes the unwanted property
    headers.delete('content-type');
    const options: any = {
      params: allParams,
      headers: headers,
      responseType: 'json',
    };
    return this.http.post(endpoint, params.form, options);
  }

  uploadDefectAttachment(formData: any, assetId: string, taskId: any) {
    ///api/tenant/task/defects/files/{tenantId}/{taskId}/upload
    const endpoint = `${this.baseURL}/api/wm/defects/task/${taskId}/files/upload`;
    const allParams = new HttpParams();
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const tenantId = localStorage.getItem('selectedSiteId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : ''
    }
    let headers = this.authService.getHeadersUploadFile(params);
    // removes the unwanted property
    headers.delete('content-type');
    const options: any = {
      params: allParams,
      headers: headers,
      responseType: 'json',
    };
    return this.http.post(endpoint, formData, options);
  }

  uploadAvatarsSignatures(params: any) {
    const type = params.type == 'sitelogo' ? 'logo' : params.type;
    //api/sys/user/create
    const endpoint = `${this.baseURL}/api/sys/${params.imgType}/${type}`;
    const allParams = new HttpParams();
    let headers = this.authService.getHeadersWithTenant(params.tenantId, 'uploadFile');
    // removes the unwanted property
    headers.delete('content-type');
    const options: any = {
      params: allParams,
      headers: headers,
      responseType: 'json',
    };
    return this.http.post(endpoint, params.form, options);
  }

  updateDefectAttachment(formData: any, attachId: string) {
    //api/tenant/task/defects/files/{tenantId}/update/{attachId}
    const endpoint = `${this.baseURL}/api/wm/defects/files/${attachId}/update`;
    const allParams = new HttpParams();
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const tenantId = localStorage.getItem('selectedSiteId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : ''
    }
    let headers = this.authService.getHeadersUploadFile(params);
    // removes the unwanted property
    headers.delete('content-type');
    const options: any = {
      params: allParams,
      headers: headers,
      responseType: 'json',
    };
    return this.http.put(endpoint, formData, options);
  }

  //api/tenant/utils/document/update/datapoint/{tenantID}
  updateResultServiceAttachment(params: any) {
    const endpoint = `/api/wm/datapoints/document/update/update`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersUploadFile(params)
    });
  }

  //api/tenant/utils/document/update/asset/tenant
  updateImage(params: any) {
    const endpoint = `/api/im/asset/document/update`;
    return this.http.put(this.baseURL + endpoint, params, {
      headers: this.authService.getHeaders('uploadFile'),
    });
  }

  //api/tenant/utils/document/update/task/{tenantId}
  updateAttachmentsFile(params: any) {
    const endpoint = `/api/wm/task/document/update/`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersUploadFile(params)
    });
  }

  //api/tenant/utils/document/update/result/{tenantId}
  updateResultAttachment(params: any) {
    const endpoint = `/api/wm/tasks/document/result/update`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersUploadFile(params)
    });
  }

  //api/tenant/task/defects/files/{tenantId}/{attachId}/{userID}
  deleteDefectAttachment(params: any) {
    const endpoint = `/api/wm/defects/files/${params.attachId}/user/${params.userId}`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersUploadFile(params)
    });
  }

  deleteImage(params: any) {
    const endpoint = `/api/im/asset/${params.assetId}/document/${params.hashName}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersUploadFile(params)
    });
  }

  //api/tenant/utils/document/{tenantId}/{hashName}/{taskId}/{resultId}/delete
  deleteResultsAttachments(params: any) {
    const endpoint = `/api/wm/task/${params.taskId}/document/${params.hashName}/result/${params.resultId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersUploadFile(params)
    });
  }

  //api/tenant/utils/tasks/document/{tenantId}/{hashName}/{taskId}/delete
  deleteAttachment(params: any) {
    const endpoint = `/api/wm/task/${params.taskId}/document/${params.hashName}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersUploadFile(params)
    });
  }
}
