
<mat-card class="results-card">
    <mat-card-header>
  
      <mat-card-title>
  
      </mat-card-title>
    </mat-card-header>
  
    <mat-card-content>  
      <div class="my-content-container">

      <table mat-table *ngIf="!noResults && !isLoading" [dataSource]="dataSource" class="mat-elevation-z8">
        <br>
  
        <!-- Number Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>Number</th>
          <td mat-cell *matCellDef="let element">
          <span *ngIf="element.number"> {{element.number}}</span>  
          <span *ngIf="!element.number">empty</span>  
           </td>
        </ng-container>
  
        <!-- Area Column -->
        <ng-container matColumnDef="area">
          <th mat-header-cell *matHeaderCellDef>Area</th>
          <td mat-cell *matCellDef="let element">
            {{element.area}}
          </td>
        </ng-container>

        <!-- Condition Column -->
        <ng-container matColumnDef="condition">
            <th mat-header-cell *matHeaderCellDef>Condition</th>
            <td mat-cell *matCellDef="let element">
              {{element.conditionDto.name}}
            </td>
          </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">
              {{element.description}}
            </td>
          </ng-container>

        <!-- Images Column -->s
        <ng-container matColumnDef="images">
            <th mat-header-cell *matHeaderCellDef>Images</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element?.urlLastAttachment" class="image-icon" (click)="preview(element)">image</mat-icon>
            </td>
          </ng-container>          
          
        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell width ="40px" *matHeaderCellDef> </th>
          <td mat-cell width ="40px" *matCellDef="let element">

          <!-- list actions menu-->
          <button 
          *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
          class="user_menu custom" mat-raised-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
            <mat-icon>expand_more</mat-icon>
          </button>

          <mat-menu class="menu" x-position="before" #menu="matMenu">

            <button mat-menu-item (click)="editItemDetails(element)">
            <mat-icon>edit</mat-icon>
                Edit 
            </button>             
            <button *ngIf="element.canDelete == true" mat-menu-item (click)="confirmation('delete', element)">
                <mat-icon>delete</mat-icon>
                Delete
            </button>

          </mat-menu>

        </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


      <div class="no-results" *ngIf="noResults && !isLoading">
        <h1>No results</h1>
      </div>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <br>
    </div>
  
    </mat-card-content>
  