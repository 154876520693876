import { Component } from '@angular/core';
import {FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent {
  validForm = false;
  validToken = false;
  invalidConfirmedPassword = false;
  invalidNewPassword = false;
  isLoading = false;
  missingToken = false;
  newPassword = '';
  confirmedNewPassword = '';
  resetPassForm: FormGroup;
  token: any;
  constructor(
    private sharedFunctions: SharedFunctionsService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private authService: AuthService) {
  }
  
  ngOnInit(){           
    this.componentsCommunication.setRolesList([]);  
    this.componentsCommunication.setRolesWorkareas([]);   
    localStorage.clear();

    this.sharedFunctions.wakeUpMethod();
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];      
      if (this.token) {
        this.userTokenCheck();
      } else {
        this.missingToken = true;
      }
    });
  
  }

  userTokenCheck(){
    const tenantId = localStorage.getItem('selectedSiteId');  
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      token: this.token
    };
    this.isLoading = true;
    this.authService.userTokenCheck(params).subscribe(
      (response: any) => {
        this.isLoading = false;
      //status of the token
      this.validToken = response;
      this.missingToken = false;
      },
      error => {
        this.validToken = false;
        this.isLoading = false;
        this.snackBar.open('Invalid token', '', {duration: 5000});
      console.log(error);
    });       
  }

  setNewPassword(){
    const params = {
      token: this.token ?  this.token : '',
      body:  btoa(this.newPassword)
    };
    this.newPassword = '';
    this.confirmedNewPassword = '';
    this.authService.setNewPassword(params).subscribe(
      (data: any) => {
      //ok
      this.logout();
      this.snackBar.open('The new password was saved', '', {duration: 5000});
      console.log('password was saved'); 
      this.router.navigateByUrl('/login');
      },
      error => {
        if (error.status == '200') {
          this.snackBar.open('The new password was saved', '', {duration: 5000});
          console.log('password was saved');   
          this.router.navigateByUrl('/login');                 
        } else {
          this.snackBar.open('Reset password failed', '', {duration: 5000});
          console.log(error);
        }

    });        
  }

  logout(): void {
    // todo
    localStorage.clear()  
    this.router.navigateByUrl('/login');
  }

  validateForm(){

    const regexp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[a-zA-Z0-9]{8,}$');
    const matches = regexp.test(this.newPassword);
    this.validForm = false;
    this.invalidConfirmedPassword = false;
    this.invalidNewPassword = false;
    if (this.newPassword.length > 0 && !matches) {
      this.invalidNewPassword = true;
    }

    if (this.newPassword.length > 0 && 
      this.newPassword == this.confirmedNewPassword){
      this.validForm = true;
    }
    if (this.confirmedNewPassword.length > 0 && 
      this.newPassword != this.confirmedNewPassword){
      this.invalidConfirmedPassword = true;
      this.validForm = false;
    }

  }
}
