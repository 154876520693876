export class ModelReportUTProbe {
    model = {
        id : null,
        serialNumber: '',
        type: '',
        angle: '',
        crystalSize: '',
        frequency: '',
        referenceSensitivityLevel: '',
        transferCorrection: '',
        scanningSensitivity: '',
        createdBy: '',
        datapointValuesDto : {
            id : null,
            datapointCatalog: null,
            task : null,
            asset : null,
            values : null,
            multiValue: null,
            listName: null,
            createdBy: null,
            updatedBy: null,
            dateCreated: null,
            lastUpdated: null,
            hasObservations: null,
            hasAttachments: null,
            hasProbes: null,
            datapointCatalogObservationDtoList: null,
            datapointCatalogAttachDtoList: null,
            utProbesDtoList: null
        }
    }
}

