import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { RolesService } from 'src/app/services/roles.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  getLoggedUser!: Subscription;
  getTabUpdate!: Subscription;
  getRolesListUpdate!: Subscription;
  isAuthenticated = false;
  loggedUserName: any;
  respuesta: any = [];
  mainTabIndex: any;
  selectedTabIndex = 0;
  visibleTabs: any = [];
  isAdmin = false;  
  loadingRoles = false;
  roles = {
    rolesRequested: false,
    isAdmin: false,
    imReader: false,
    systemRoles: [],
    allRoles: [],
    tenantRoles: [],
    allWorkareaRoles: [],
    currentWrkareaRoles: []
  };
  workareaRoles = {
    workareaAdmin: false,
    systemRoles: [],
    allWorkareaRoles: [],
    currentWrkareaRoles: []
  };
  constructor(
    private apiService: ApiService,
    public rolesService: RolesService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    public sharedFunctions: SharedFunctionsService,
    private componentsCommunication: ComponentsCommunicationService) {
      
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          if (this.roles && !this.roles.rolesRequested && this.roles.allRoles.length == 0) {
            this.getRoles();
            this.getWorkareaRoles();
          }
        }
      }
    );

  }

  ngOnInit() {
    if (this.roles && !this.roles.rolesRequested && this.roles.allRoles.length == 0) {
      this.getRoles();
      this.getWorkareaRoles();
    }
    
    this.getRolesListUpdate = this.componentsCommunication.getRolesList().subscribe(data => {
      // is admin
      if (data && data.length > 0 &&  data[0].tenantId === null && this.rolesService.systemAdmin(data[0].roles)){
        this.roles.isAdmin = true;      
      }
      if (data && data.length > 1){
        this.roles.systemRoles = data[0].roles;
        this.roles.allRoles = data.slice(1);
      }          
    });
  
    
    this.loggedUserName = localStorage.getItem('username');
    this.visibleTabs = localStorage.getItem('visibleTabs');
    
    if (this.visibleTabs && this.visibleTabs.length == 0) {
      this.visibleTabs = ['Inventory', 'Task', 'System', 'Sites', 'Users'];
    }
    if(this.loggedUserName && this.visibleTabs) {
      
      this.getLoggedUser = this.componentsCommunication.getLoggedUser().subscribe(params => {
        // updates the reports list
        if(params && params.username) {
          this.loggedUserName = params.username;
        }
      })

      this.getTabUpdate = this.componentsCommunication.getUpdateTab().subscribe(data => {
        // updates the maintab
        if(data && data.main_tab && this.tabGroup) {
          this.tabGroup.selectedIndex = data.main_tab;        
        }      
      });
    } else {
      this.router.navigateByUrl('/login');
    }

    //to fire the destroy method when reloading
    //window.onbeforeunload = () => this.ngOnDestroy();

 
  }
  async ngOnDestroy () {    
    if(this.getLoggedUser) {
      this.getLoggedUser.unsubscribe();
      this.getTabUpdate.unsubscribe();
      this.getRolesListUpdate.unsubscribe();      
    }
  }

  getRoles(){
    this.roles.rolesRequested = true;
    this.loadingRoles = true;
    // Your code here for when the page is refreshed          
    this.authService.getRoles([]).subscribe(
      (data: any) => {
        if (data && data.length > 0) {

          this.componentsCommunication.setRolesList(data);     
          if ( data.length == 1){
            //console.log('isAdmin: true');
          } 
          if (data && data.length > 1){
           // console.log('generic roles: ' + data[0].roles);
          }
        }    
        this.loadingRoles = false;
        this.loadActiveTab();  
      },
      error => {              
        this.snackBar.open('Your credentials haven\'t been recognised', '', {duration: 5000});
    }); 
  }

  loadActiveTab(): void {
      let activeTab = 0;
    // updates the maintab
    if (localStorage.getItem('main_tab')){
      const mainTab = localStorage.getItem('main_tab');
      this.mainTabIndex = mainTab && mainTab.length > 0 ? mainTab : activeTab;
      
    } else {
      this.mainTabIndex = activeTab;
    }
    if (this.tabGroup){
      this.tabGroup.selectedIndex  = this.mainTabIndex;
    }    
    //console.log('loading tab ---' + this.mainTabIndex)
  }

  getWorkareaRoles(){
    this.roles.rolesRequested = true;
    // Your code here for when the page is refreshed          
    this.authService.getWorkareaRoles([]).subscribe(
      (data: any) => {
        if (data && data.length > 0) {          
          //this.workareaRoles.allWorkareaRoles = data.slice(1);
          this.componentsCommunication.setRolesWorkareas(data);     
        }                
      },
      error => {              
      //console.log(error);
    }); 
  }

  tabChanged($event: any){    
    this.componentsCommunication.setUpdateTab({'main_tab': $event.index});
    localStorage.setItem('main_tab', $event.index);
  }
  logout(): void {
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }


}
