import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentsDialogComponent } from '../../dialogs/attachments-dialog/attachments-dialog.component';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { FilePreviewDialogComponent } from '../../dialogs/file-preview-dialog/file-preview-dialog.component';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-create-report-attachments',
  templateUrl: './create-report-attachments.component.html',
  styleUrls: ['./create-report-attachments.component.scss']
})
export class CreateReportAttachmentsComponent {
  attachmentsUpdate!: Subscription;
  formDataSubscription!: Subscription;
  @Input() roles: any;
  @Input() workareaRoles: any;  
  displayedColumns: string[] = ['name', 'title', 'description', 'actions'];
  formData: any;
  showActions = false;
  isLoading = false;
  noResults = false;
  addNewEnabled = false;
  workareaId: any;
  tenantId: any;
  taskId: any;
  dataSource: any = [];
  constructor(
    public rolesService: RolesService,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private apiFilesService: ApiFilesService,
    private dialog: MatDialog) {}

    ngOnInit() {      
      this.taskId = localStorage.getItem('taskId');
      this.workareaId = localStorage.getItem('workareaId');
      this.attachmentsUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
        // updates the reports list
        if(name == 'update_attachments') {
          this.getData();
        }
      }); 
      this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

        if(data && data.id && !this.workareaId) {
          this.workareaId = data.workArea.id;
          this.tenantId = data.workArea.tenantDto.id;
          localStorage.setItem('taskTenantId', this.tenantId);
          this.getData();
        } 
      });   

    }

    ngOnDestroy(){
      this.attachmentsUpdate.unsubscribe();
      this.formDataSubscription.unsubscribe();
    }
    
    preview(element: any){
      const tenantId = this.tenantId;
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',   
        taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : '',
        element: element
      }

      this.dialog.open(FilePreviewDialogComponent, {
        height: 'auto',
        width: 'auto',
        disableClose: true,
        data: params
      });
      
    }

    getData() {
      this.isLoading = true;
      const tenantId = this.tenantId;
      const workareaId = this.workareaId;
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        taskId: this.taskId
      }
      this.apiFilesService.getTaskAttachments(params).subscribe(
        (result: any) => {
          if(result) {
            this.isLoading = false;
            this.dataSource = [...result];
          } else {
            this.dataSource = [];
          }
        },
        // error
        (msg) => {       
          this.isLoading = false; 
          console.log('error retrieving data from endpoint ' + msg.error);
      });
    }
    
    addNew(action: string) {
      const tenantId = this.tenantId;
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',   
        taskId: this.taskId,
        action: action
      }

      this.dialog.open(AttachmentsDialogComponent, {
        height: 'auto',
        width: '800px',
        disableClose: true,
        data: params
      });
    }

    editItemDetails (element: any) {
      const tenantId = this.tenantId;
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',      
        taskId: this.taskId,
        action: 'update_attachment',
        element: element
      }      
      this.dialog.open(AttachmentsDialogComponent, {
        height: 'auto',
        width: '800px',
        disableClose: true,
        data: params
      });
    }

    confirmation(action: string, elem?: any) {
      
      elem.action = action;
      elem.confirmation = false;
      if (action == 'delete') {
        elem.confirmationString = 'Delete the selected element?';
      } 
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        width: '440px',
        disableClose: true,
        autoFocus: false,
        data: elem
      });
  
      dialogRef.afterClosed().subscribe(
        (data) => {
          if (data.confirmation) {            
            this.deleteElem(elem);
          }
        }
      ); 
    }

    /**
     * deletes the selected service from the list
     * @param element selected element to be removed
     */
    deleteElem(element: any){
      const tenantId = this.tenantId;
      const workareaId = localStorage.getItem('taskWorkareaId');   
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
        hashName: element.hash,
        taskId: element.task.id
      }
  
      this.apiFilesService.deleteAttachment(params).subscribe(
        () => {
          console.log( 'attachment deleted');
          this.snackBar.open( 'attachment deleted ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_attachments');
      },
        // error
        (msg) => {
          if (msg.status == '200') {
            console.log( 'image deleted');
            this.snackBar.open( 'attachment deleted ', '', {duration: 3000});
            // sends an update
            this.componentsCommunication.setUpdateList('update_attachments');
          }
          console.log( msg.statusText);
      }); 
    }
}
