import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, map, startWith } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddPersonnelComponent } from 'src/app/components/forms/create-report-personnel/add-personnel/add-personnel.component';
import { ModelCreatePersonnel } from 'src/app/models/model.create.personnel';
import { ApiPersonnelService } from 'src/app/services/api.personnel.service';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export interface DialogData {
  name: string;
  taskId: string;
  personnelList: any;
  action: string;
  element: any;
  moduleRoleDtos: any;
  tenantDto: any;
  id: string;
  user: any;
  tenant: any;
  userDto: any;
}
@Component({
  selector: 'app-edit-usersites-dialog',
  templateUrl: './edit-usersites-dialog.component.html',
  styleUrls: ['./edit-usersites-dialog.component.scss']
})
export class EditUsersitesDialogComponent {
  loadingRoles = false;
  loadingSites = false;
  isLoading = false;
  validForm = false;
  @ViewChild('personnelInput')
  personnelInput!: ElementRef<HTMLInputElement>;
  @ViewChild('servicesInput')
  servicesInput!: ElementRef<HTMLInputElement>;
  roleControl =  new FormControl('');
  @Input() element: any;
  @Input() readonlyFields: any;
  @Input() formData: any;
  prefilledValue: any;
  @Output() elementEvent = new EventEmitter<any>();

  newPersonnelModel: any = new ModelCreatePersonnel();
  selectedPersonnel = <any>[];
  selectedServices = <any>[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ctrlPersonnel = new UntypedFormControl();
  ctrlServices = new UntypedFormControl();
  allPersonnel: Observable<any[]> | undefined;
  allServices: Observable<any[]> | undefined;
  availablePersonnel: any = [];
  availableServices: any = [];
  invalid = false;
  lookupsList: any;
  services: Observable<any[]>;
  allRoles: any = [];
  filteredRoles: any = [];
  availableRoles: any = [];
  roleUpdated = false;
  allSites: any;
  allModules: any;
  selectedSite: any;
  selectedRole: any;
  selectedModule: any;
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };
  constructor(
    private dialog: MatDialog,
    private apiUsersService: ApiUsersService,
    private snackBar: MatSnackBar,
    private apiSitesService: ApiSitesService,
    private usersService: ApiUsersService,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AddPersonnelComponent>,
    private personnelService: ApiPersonnelService
  ) {}

  ngOnInit() {
    this.getSites();
    //this.getModuleRoles();
    if (this.dialogData.action == 'edit') {
      this.selectedSite = this.dialogData.tenantDto;
      this.selectedRole = this.dialogData.moduleRoleDtos[0];
      this.selectedModule = this.selectedRole;
    }
    if (this.dialogData.action == 'delete') {
      //this.filteredRoles = this.dialogData.moduleRoleDtos;
      this.allRoles = this.dialogData.moduleRoleDtos; 
      this.filteredRoles = this.allRoles.filter((role: any) => !role.displayName.includes('-guest'));
    }
  }

  saveChanges(){
    this.isLoading = true;
    let params = {      
      tenantId: this.selectedSite.id,
      roleId: this.selectedRole.id,
      userId: this.dialogData.action == 'edit' ? this.dialogData.userDto.id : this.dialogData.user.id,
      body: {
        id: this.selectedRole.id,
        user: {
          id: this.dialogData.action == 'edit' ? this.dialogData.userDto.id : this.dialogData.user.id
        }        
      }
    };

    this.usersService.updateUserRoles(params).subscribe(
      () => {
        console.log( 'user sites changes saved');
        this.closePopup();
        this.snackBar.open( 'Changes were saved!', '', {duration: 3000});        
        this.componentsCommunication.setUpdateList('update_users');
        this.componentsCommunication.setUpdateList('update_sites');
        this.componentsCommunication.setUpdateSites({name:'update_sites'});
        this.componentsCommunication.setUpdateUsers({name:'update_users'});
        this.isLoading = false;  
    },
      // error
      (msg) => {
        this.isLoading = false;  
        console.log( msg);
    }); 
  
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  validateFormdata(){
    this.validForm = false;
    if(this.dialogData.action != 'delete' && this.selectedSite && this.selectedRole?.id) {
      this.validForm = true;
    }
    if(this.dialogData.action == 'delete' && this.selectedRole?.id) {
      this.validForm = true;
    }
  }

  manageSelectedSite(){
    this.selectedModule = null;
    this.selectedRole = null;
    this.getModules();
    this.getModuleRoles(this.selectedSite);
    this.validateFormdata();    
  }

  getSites(){
    this.loadingSites = true;     
    this.allSites = [];
    const type = 'privilege';
    const tenantId = localStorage.getItem('selectedSiteId');   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      lookupName: type,
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'asc',
      sortColumn: 'dateCreated'
    };

    this.apiSitesService.getTenantsList(params).subscribe(
      (result: any) => {
       
        if(result.content.length > 0) {
          this.allSites = result.content;
          
        } 
        this.loadingSites = false;       
        
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.loadingSites = false;
    });
  }

  confirmation(action: string, elem?: any) {    
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete_site') {
      elem.confirmationString = 'Delete the selected role?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (action == 'delete_site') {
          this.deleteSite(elem);
        }
      }
    ); 
  }

  deleteSite(element: any){
    this.isLoading = true;
    const params = {
      tenantId: element.tenantDto.id,      
      userId: element.userDto.id,
      roleId: this.selectedRole.id
    }
    this.apiUsersService.deleteSiteRole(params).subscribe(    
      () => {
        this.closePopup();
        this.snackBar.open( 'The role was deleted', '', {duration: 3000});        
        this.componentsCommunication.setUpdateList('update_users');
        this.componentsCommunication.setUpdateList('update_sites');
        this.componentsCommunication.setUpdateSites({name:'update_sites'});
        this.componentsCommunication.setUpdateUsers({name:'update_users'});
        this.isLoading = false;  
    },
      // error
      (msg) => {
        this.isLoading = false;  
        if (msg.status == '200') {
          console.log( 'role was removed');
          this.closePopup();
          this.snackBar.open( 'The role was deleted', '', {duration: 3000});    
          // sends an update
          this.componentsCommunication.setUpdateList('update_users');
          this.componentsCommunication.setUpdateList('update_sites');
          this.componentsCommunication.setUpdateSites({name:'update_sites'});
          this.componentsCommunication.setUpdateUsers({name:'update_users'});
        } else {
          console.log( msg);
        }    
    });   
  }

  filterRoles(event:any, element: any){
    if (event.isUserInput) { 
      if (!this.roleUpdated ) {
        if (this.selectedRole) {
          this.selectedRole = [];
          this.selectedRole.name = '';
        }
        this.filteredRoles = [];
        this.filteredRoles = this.availableRoles.filter(
        (option: any) => option.moduleName == element.name || option.moduleName == element.moduleName);
        this.roleUpdated = true;
      }
    }
  }
  //GET /api/sys/module/roles/tenant/list
  getModuleRoles(element: any) {
    this.loadingRoles = true;
    this.filteredRoles = [];
    const tenantId = element.id;
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
    };

    this.usersService.getModuleRoles(params).subscribe(
      (result: any) => {
       
        if(result.length > 0) {
          this.availableRoles = [...result];
          // filters the roles once loaded and when is edit mode
          if (this.dialogData.action == 'edit') {
            this.roleUpdated = false;
            this.filterRoles({isUserInput:true},this.selectedModule);
          }
        }     
        this.loadingRoles = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.loadingRoles = false;
    });
          
  }

  //GET /module/list/tenant
  getModules() {
    if(this.selectedSite && this.selectedSite?.id) {
      this.loadingRoles = true;
      const type = 'privilege';
      const params = {
        lookupName: type,
        tenantId: this.selectedSite?.id
      };

      this.usersService.getModulesListTenant(params).subscribe(
        (result: any) => {
        
          if(result.length > 0) {
            this.allModules = [...result];
          }     
          this.loadingRoles = false; 
        },
        // error
        (msg) => {
          console.log('error retrieving lookup list ' + msg);
          this.loadingRoles = false;
      });
    }    
  }

}
