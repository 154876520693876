import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddEquipmentComponent } from './add-equipment/add-equipment.component';
import { ApiEquipmentService } from 'src/app/services/api.equipment.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-create-report-equipment',
  templateUrl: './create-report-equipment.component.html',
  styleUrls: ['./create-report-equipment.component.scss']
})
export class CreateReportEquipmentComponent {
  @Input() roles: any;
  @Input() workareaRoles: any;  
  listUpdates!: Subscription;
  formDataSubscription!: Subscription;
  displayedColumns: string[] = ['sNumber', 'mNumber', 'description', 'services', 'actions'];
  showActions = false;
  isLoading = false;
  noResults = false;
  dataSource: any = [];
  workareaId: any;
  addNewEnabled = false;
  taskId: any;
  allTaskEquipment: any;
  
  constructor(
    public rolesService: RolesService,
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private equipmentService: ApiEquipmentService) {}

  ngOnInit() {

    this.taskId = localStorage.getItem('taskId');
    this.workareaId = localStorage.getItem('workareaId');
    this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

      if(data && data.id && !this.workareaId) {
        this.workareaId = data.workArea.id;
        this.getData();
      } 
    });  

    this.listUpdates = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the reports list
      if(name == 'equipment_mainlist') {
        this.getData();
      }
    });
  }

  ngOnDestroy() {
    this.listUpdates.unsubscribe();
    this.formDataSubscription.unsubscribe();
  }

  getData() {
    //this.isLoading = true;
    const workareaId = this.workareaId;
    const tenantId = localStorage.getItem('taskTenantId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : '',
      first: 0,
      max: 100,
      sort: 'desc',
      sortField: 'dateCreated',
    }
    
    this.equipmentService.getTaskEquipment(params).subscribe(
      (result: any) => {
        this.isLoading = false;
       
        let data = <any>[];
        result.content.forEach((elem:any) => {
          let allServices = <any>[];
          elem.servicesSet.forEach((services:any) => {
            allServices.push(services.name);
          });

          elem.personnelServices = [...allServices];
          elem.personnelServicesStr = allServices.join(', ');
          data.push(elem);
        });
        this.dataSource = [...data];
        this.allTaskEquipment = [...data];

        if (result.content && result.content.length == 0) {
          this.dataSource = new MatTableDataSource<any>([]);
          this.allTaskEquipment = [];
        }
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.dataSource = [];
        this.allTaskEquipment = [];
        this.isLoading = false;
    });
  }

  addNew(action: string, element?: any) {
    const width = action == 'create' ? '60%' : 'auto';
    const data = {
      allTaskEquipment: this.allTaskEquipment,
      taskId: this.taskId,
      action: action,
      element: element
    }
    
    this.dialog.open(AddEquipmentComponent, {
      height: 'auto',
      width: width,
      disableClose: true,
      autoFocus: false,
      data: data
    });
  }

  editItemDetails (element: any) {
    this.addNew('edit', element);
  }

  /**
   * deletes the selected service from the list
   * @param element selected element to be removed
   */
  deleteElem(element: any){
    this.isLoading = true;
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = this.workareaId;
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      equipmentId: element.id
    };
    this.equipmentService.deleteTaskEquipment(params).subscribe(
      () => {
        this.getData();
      },
      // error
      (msg) => {
      if (msg.status == 200) {
        this.getData();
      } else {
        console.log('error deleting the equipment' + msg);
      }      
    });
  }

      /**
   * confirmation before applying the change
   * @param element selected elem
   */
  confirmation(element: any, action: string) {      
    element.action = action;
    element.confirmation = false;
    if (action == 'delete') {
      element.confirmationString = 'Delete Equipment ' + element.equipment.description + ' ?';
    }    
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      data: element
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.deleteElem(element);
        }
      }
    ); 
  }

}
