<mat-card class="projects-card">
  <mat-card-content>
    <mat-toolbar
      color="primary"
      class="adf-app-layout-toolbar mat-elevation-z6"
    >
      <button
        mat-raised-button
        (click)="taskSearch()"
        class="green custom-button"
      >
        <mat-icon class="icon">search</mat-icon>
      </button>

      <form class="form-search full-width">
        <mat-form-field class="filter-type full-width" appearance="outline">
          <mat-select #selectInput placeholder="Search by">
            <mat-option
              (mousedown)="searchFilter = elem.value"
              *ngFor="let elem of searchFilters"
              [value]="elem.value"
            >
              {{ elem.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="example-full-width searchbar full-width"
          appearance="outline"
        >
          <mat-label>Enter search term</mat-label>
          <input
            #searchInput
            (keyup.enter)="taskSearch()"
            matInput
            autocomplete="off"
          />
        </mat-form-field>
      </form>

      <button
        *ngIf="roles.isAdmin || workareaRoles.workareaAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles, true)"
        mat-raised-button
        (click)="createTask()"
        class="last search-menu-button custom-button"
        color="primary"
      >
        <mat-icon class="icon">add</mat-icon>
      </button>
    </mat-toolbar>
    <div class="content-container">
      <div
        class="custom-width full-width"
        *ngIf="!isLoading && dataSource?.length > 0"
      >
      
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

        <!-- report number Column -->
        <ng-container matColumnDef="rep_number">
          <th mat-header-cell *matHeaderCellDef>Task Number</th>
          <td mat-cell *matCellDef="let element">{{ element?.taskNumber }}</td>
        </ng-container>

        <!-- serial number Column -->
        <ng-container matColumnDef="serial_num">
          <th mat-header-cell *matHeaderCellDef>Serial Number(s)</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element?.serialNumber">{{
              element?.serialNumber
            }}</span>
          </td>
        </ng-container>

        <!-- report type Column -->
        <ng-container matColumnDef="rep_type">
          <th mat-header-cell *matHeaderCellDef>Task Type</th>
          <td mat-cell *matCellDef="let element">Single Item Inspection</td>
        </ng-container>

        <!-- assert type Column -->
        <ng-container matColumnDef="asset_type">
          <th mat-header-cell *matHeaderCellDef>Asset Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.assetType?.name }}
          </td>
        </ng-container>

        <!-- inspection Column -->
        <ng-container matColumnDef="inspection">
          <th mat-header-cell *matHeaderCellDef>Task Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dueDate | date : "dd-MMM-YYY" }}
          </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell class="last" width="40px" *matHeaderCellDef></th>
          <td mat-cell class="last" width="40px" *matCellDef="let element">
            <!--  actions menu-->
            <button
              *ngIf="
                roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles, true) 
                || rolesService.workareaWMEditor(workareaRoles.allWorkareaRoles, true)
              "
              class="user_menu custom"
              mat-raised-button
              [mat-menu-trigger-for]="menu"
              fxHide="false"
              fxHide.gt-sm
            >
              <mat-icon>expand_more</mat-icon>
            </button>

            <mat-menu class="menu" x-position="before" #menu="matMenu">
              <button
                *ngIf="
                  roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles, true) 
                  || rolesService.workareaWMEditor(workareaRoles.allWorkareaRoles, true)
                "
                mat-menu-item
                (click)="editTask(element)"
              >
                <mat-icon>edit</mat-icon>
                Edit
              </button>
              <button
                *ngIf="
                  element.status != 'In Progress' && element.status != 'New'
                "
                mat-menu-item
                (click)="releaseToQC(element, 'preview')"
              >
                <mat-icon>preview</mat-icon>
                View
              </button>
              <button
                *ngIf="
                  roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles, true)
                "
                mat-menu-item
                (click)="createCopy(element)"
              >
                <mat-icon>file_copy</mat-icon>
                Copy
              </button>
              <button
                *ngIf="
                  roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles, true)
                "
                mat-menu-item
                (click)="releaseToQC(element)"
              >
                <mat-icon>play_lesson</mat-icon>
                Release to QC
              </button>
              <button
                *ngIf="
                  roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles, true)
                "
                mat-menu-item
                (click)="confirmation(element, 'delete')"
              >
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        *ngIf="!isLoading"
        (page)="handlePage($event)"
        [length]="paginatorConfig.length"
        [pageIndex]="paginatorConfig.pageIndex"
        [pageSize]="paginatorConfig.pageSize"
        [pageSizeOptions]="paginatorConfig.pageSizeOptions"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>

    <div class="no-results" *ngIf="dataSource?.length == 0 && !isLoading">
      <h1>No results to show...</h1>
    </div>
    <mat-spinner *ngIf="isLoading"></mat-spinner>

    </div>
  </mat-card-content>
</mat-card>
