import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  loginValid = true;
  forgotPasswordForm = false;
   username: any;
   password: any;
   emailSent = false;

  email = '';
  validEmail: any;
  rememberMe: any;
  constructor(
    private sharedFunctions: SharedFunctionsService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private _router: Router,
    public componentsCommunication: ComponentsCommunicationService,
    private authService: AuthService
    ) {}

  ngOnInit(): void {
    this.sharedFunctions.wakeUpMethod();
    const rememberMe = sessionStorage.getItem('rememberMe');
    this.rememberMe = rememberMe && rememberMe === 'true' ? true : false;
    if(this.rememberMe){
      this.username = sessionStorage.getItem('username') ? sessionStorage.getItem('username') : '';
      this.password = sessionStorage.getItem('password') ? sessionStorage.getItem('password') : '';
    }
  }

  login() {
    localStorage.clear();
    const params =  {
      username: this.username,
      password: this.password
    }

    this.authService.performLogin(params).subscribe(
      (data: any) => {
      if (data){
        localStorage.setItem('username', this.username);
        sessionStorage.setItem('username', this.username);
        sessionStorage.setItem('password', this.password);
        /*******TODO ITERATE OBJECT AND GET TABS ************/
        localStorage.setItem('visibleTabs', data);
        localStorage.setItem('token', 'Basic ' + btoa(params.username + ':' + params.password));

        this._router.navigateByUrl('/main');
        this.snackBar.dismiss();
        const user = {username: this.username, password: this.password};
      } else {
        this.snackBar.open('Your credentials haven\'t  been recognised', '', {duration: 5000});
      }
    },
      error => {
        this.snackBar.open('Your credentials haven\'t been recognised', '', {duration: 5000});
      //console.log(error);
    });    
    
  }

  checkRememberMe(){
    sessionStorage.setItem('rememberMe', String(!this.rememberMe));
  }
  
  validateFormdata(){
    this.validEmail = this.regexp.test(this.email);
  }

  sendEmail(){
    this.emailSent = true;    
    const tenantId = localStorage.getItem('selectedSiteId');  
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      userId: this.email      
    };
    this.authService.sendEmail(params).subscribe(
      (data: any) => {
        this.snackBar.open('The email was sent, please check your inbox', '', {duration: 5000});
        console.log('email was sent');
      },
      error => {
        if (error.status == '200') {
          this.snackBar.open('The email was sent, please check your inbox', '', {duration: 5000});
          console.log('email was sent');                    
        } else {
          this.snackBar.open('Reset password failed', '', {duration: 5000});
          console.log(error);
        }
        
    });           
  }

}
