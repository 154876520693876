
<div class="copytask-container">
  <div class="v-window-header">
    <span>Copy - {{this.dialogData.element.taskNumber}}</span>
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>

  <br />
  <h4> Task Number 
    <span class="required_item" *ngIf="taskNumberExists">already exists in work area* !</span>
    <span *ngIf="!taskNumberExists" class="required_item"> *</span>
  </h4>
  <mat-form-field [ngClass]="{'invalid': taskNumberExists}" class="filter-type" appearance="fill">
    <input matInput
    (keyup)="selectTaskNumber($event.target?.value.toString())"
    type="text"                     
    [value]="taskNumber"
    color="primary"/>    

</mat-form-field>

<div mat-dialog-actions>
    <button  mat-raised-button 
    class="upload right" color="primary" [ngClass]="{'disabled': !validForm}" (click)="createTaskCopy()">
     Confirm
     <mat-icon class="icon action">done</mat-icon>
   </button>
    <button mat-raised-button color="primary" (click)="closePopup()">
      Cancel
      <mat-icon class="icon action">close</mat-icon>
    </button>
  </div>

</div>