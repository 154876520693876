import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

export interface DialogData {
  element:any;
}

@Component({
  selector: 'copy-task-dialog',
  templateUrl: './copy-task-dialog.component.html',
  styleUrls: ['./copy-task-dialog.component.scss']
})
export class CopyTaskDialogComponent {
  taskNumber: any;
  validForm = false;
  taskNumberExists = false;
  constructor(
    private componentsCommunication: ComponentsCommunicationService,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private servicesService: ApiServicesService,
    public dialogRef: MatDialogRef<CopyTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {}

    ngOnInit() {
      if (this.dialogData.element) {
        this.taskNumber = this.dialogData.element.taskNumber + ' - Copy';
        this.validateForm();
      }
    }
  closePopup(): void {
    this.dialogRef.close(this.dialogData);
  }
  
  createTaskCopy(){
    const tenantId = localStorage.getItem('selectedSiteId');    
    const workareaId = this.dialogData?.element?.workArea?.id;    
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: this.dialogData.element.id,
      workareaId: workareaId ? workareaId: ''
    };
    
    this.apiService.copyTask(params).subscribe(
      (result: any) => {
        //create task
        result.taskNumber = this.taskNumber;
        result.createdBy = localStorage.getItem('username');
        this.apiService.createReport(result, params).subscribe(
          (result: any) => {
            // for each service calls the creation endpoint            
            this.saveServices(result);
            this.componentsCommunication.setUpdateTasks({name:'update_tasks', action: 'tenant_update'});
            //create copied task    
            const msg = 'The task was copied!';
            this.snackBar.open(msg, '',  { duration: 3000 });               
            this.closePopup();
        },
          // error
          (msg) => {
            console.log( msg);
        }); 
    },
      // error
      (msg) => {
        console.log( msg);
    }); 
  }

  selectTaskNumber(value: string) {
    this.validForm = false;
    this.taskNumber = value.trim();

    if(this.taskNumber.length > 0){
      this.taskNumberExists = false;
      if (this.taskNumber == this.dialogData.element.taskNumber) {
        this.taskNumberExists = true;
      }
      const tenantId = localStorage.getItem('selectedSiteId');   
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
        newName: this.taskNumber
      };
      
      this.apiService.checkIfReportNumberExists(params).subscribe(
        (result: any) => {
          this.taskNumberExists = result;
          this.validateForm();
      },
        // error
        (msg) => {
          console.log( msg);
      }); 
    }
  }

  validateForm(){
    if (this.taskNumber.trim().length > 0 && !this.taskNumberExists) {
      this.validForm = true;
    }
  }

  /**
 * saves the services when a task is copied
 * @param copiedTask task to be copied
 */
  saveServices(copiedTask: any) {
    
    copiedTask.servicesSet.forEach((service: any) => {
      const tenantId = localStorage.getItem('selectedSiteId');   
      const workareaId = copiedTask.workArea.id;  
      const param = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        serviceName: service.name,
        idProcess: localStorage.getItem('idProcess'),
        workareaId: workareaId && workareaId.length > 0 ? workareaId : ''
      };

      //***** Get Service Form from Flowable: SERVICES First time
      //***** task/datapointValue/getServiceForm/{tenantId}/{id_process}/{serviceName}
      this.servicesService.getServicesFormsFirstTime(param).subscribe(
        (response: any) => {
          if (response) {
            response.createdBy = localStorage.getItem('username');
            const workareaId = copiedTask.workArea.id;  
            const params = {     
              tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
              workareaId: workareaId ? workareaId : '',         
              taskId: copiedTask.id,
              serviceId: service.id,
              body: response,
            };

            //***** Create EMPTY DataPointValues For Tab Services
            //***** task/datapointValue/create/{tenantId}/{taskId}/service/{serviceId}
            this.servicesService.createServiceDatapoint(params).subscribe(
              (result: any) => {
                console.log('service datapoint created');
              },
              // error
              (msg) => {
                if (msg.status == '200') {
                  console.log('service datapoint created');
                } else {
                  console.log( msg.statusText);
                }
            });     
          }
        },
        // error
        (msg) => {
          //this.isLoading = false;
          console.log('error loading the result json ' + msg.statusText);
        }
      );
    });
  }
}
