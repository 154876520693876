
<div class="v-window-header">
  <span *ngIf="dialogData.action == 'upload'"> Upload {{dialogData.name}}</span>
  <span *ngIf="dialogData.action == 'edit'">Edit {{dialogData.name}}</span>
  <div [mat-dialog-close]="true" class="v-window-closebox">
    <mat-icon class="icon action">close</mat-icon>
  </div>
</div>

<mat-spinner *ngIf="isLoading"></mat-spinner>

<div  *ngIf="!isLoading" class="attachments-container">  

  <span *ngIf="dialogData.action == 'upload'" class="span-label">Choose a file
    <span class="required_item"> *</span>
  </span>
    <mat-form-field *ngIf="dialogData.action == 'upload'">

      <button class="blue" (click)="input.click()" mat-raised-button matPrefix >
        <mat-icon>attach_file</mat-icon>
      </button>
      <input (click)="input.click()" class="file-input" type="text" readonly matInput [formControl]="display" />
      <input 
        type="file"
        multiple="false"
        hidden
        #input
        accept="image/png, image/jpeg"
        (change)="checkFile(input.files)"/>
    </mat-form-field>

      <span class="span-label">Description</span>
    <mat-form-field>
        <textarea
        [(ngModel)]="prefilledDescription"
        rows="5"
        matInput        
        cdkTextareaAutosize
        (keyup)="description = $event.target.value.toString(); validateFormdata()"
        #autosize="cdkTextareaAutosize"></textarea>
    </mat-form-field>   

  <div mat-dialog-actions>
    <button  mat-raised-button *ngIf="dialogData.action == 'upload'" [ngClass]="{'disabled': !enableUpload}"
    class="upload right" color="primary" (click)="uploadFile();">
     Upload
     <mat-icon class="icon action">save</mat-icon>
   </button>
   <button  mat-raised-button *ngIf="dialogData.action == 'edit'"  [ngClass]="{'disabled': !enableUpload}"
   class="upload right" color="primary" (click)="updateFile();">
    Update
    <mat-icon class="icon action">save</mat-icon>
  </button>
    <button mat-raised-button color="primary" (click)="closePopup()">
      Close
      <mat-icon class="icon action">close</mat-icon>
    </button>
  </div>

</div>