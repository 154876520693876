<!-- login.component.html -->
<div class="login-container">
    <mat-card>
      <mat-card-content>
        <form *ngIf="!forgotPasswordForm"  #loginForm="ngForm" (ngSubmit)="login()">
          <img class="header-logo" src="../../assets/images/img_paperless-logo.png">
          <mat-error *ngIf="!loginValid">
            The username and password were not recognized
          </mat-error>
          <mat-form-field>
            <input matInput placeholder="Username" [(ngModel)]="username" name="username" required>
            <mat-error>
              Please provide a valid username
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="password" placeholder="Password" [(ngModel)]="password" name="password" required>
            <mat-error>
              Please provide a valid password
            </mat-error>
          </mat-form-field>
          <div class="form-group">
            <label class="remember-me"><input type="checkbox" [checked]="rememberMe" (click)="checkRememberMe()"
              class="form-control">Remember me</label>
          </div>
          <br>
          <br>
          <button mat-raised-button color="primary" [disabled]="!loginForm.form.valid">Login</button>
          <br>
          <br>
          <a class="forgot-password" (click)="forgotPasswordForm = true; emailSent = false">Forgot your password?</a>
          <br>
          <br>
          <h5 class="help-text">Need help? Contact support@evolve.ltd.uk</h5>
  
        </form>
   

        <form *ngIf="forgotPasswordForm" #resetForm="ngForm" (ngSubmit)="sendEmail()">
  
          <img class="header-logo" src="../../assets/images/img_paperless-logo.png">
          <br>
          <br>
          <span class="msg">Forgot your password?
          </span>
          <br>
          <br>
          <span class="msg">Confirm your email and we'll send you instructions
          </span>
          <br>
          <br>
          <br>
          <mat-form-field>
            <input matInput placeholder="Email" [(ngModel)]="email" name="email" required
            (keyup)="email = $event.target.value.toString(); validateFormdata()">
          </mat-form-field>
          <span class="required_item" 
            *ngIf="email.length > 0 && !validEmail">
            Please provide a valid email
            <br>
            <br>
          </span>
  
          <button mat-raised-button color="primary" [disabled]="!validEmail || emailSent == true">Recover Password</button>
          <br>
          <br>
          <a class="forgot-password"  (click)="forgotPasswordForm = false">Return to login</a>
          <br>
          <br>
          <h5 class="help-text">Need help? Contact support@evolve.ltd.uk</h5>
  
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  