import { Component } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

@Component({
  selector: 'app-create-report-comments',
  templateUrl: './create-report-comments.component.html',
  styleUrls: ['./create-report-comments.component.scss']
})
export class CreateReportCommentsComponent {
  formDataSubscription!: Subscription;
  formData: any;
  prefilledValue: string = '';
  constructor(private componentsCommunication: ComponentsCommunicationService){}
  
  ngOnInit() {
    this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {
      // updates the reports list
      if(data && data.id) {
        this.prefilledValue = data.comment;
        this.formData = {...data};
      }
    });
  } 
  saveValue(value: string){
    this.formData.comment = value;
    this.componentsCommunication.setFormData(this.formData);  
  }

  ngOnDestroy(){
    this.componentsCommunication.setFormData([]);  
    this.formDataSubscription.unsubscribe();
  }
}
