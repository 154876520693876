import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs/internal/Observable';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'chiplist-services-results',
  templateUrl: './chiplist-services-results.component.html',
  styleUrls: ['./chiplist-services-results.component.scss']
})

export class ChiplistServicesResultsComponent {
  @ViewChild('elementsInput')
  elementsInput!: ElementRef<HTMLInputElement>;
  @Input() element:any;
  @Input() readonlyFields:any;
  @Input() formData:any;   
  prefilledValue: any;
  @Output() elementEvent = new EventEmitter<any>();

  selectedElems = <any>[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  elementCtrl = new UntypedFormControl();
  filteredElements: Observable<any[]> | undefined;
  availableElements: any = [];
  invalid = false;
  lookupsList: any;
  invalidMultiselect = false;
  constructor(
    private apiService: ApiService  ) {}

  ngOnInit(){
   
      const lookupName = this.element.datapointCatalog.lookupName.toLowerCase();

      this.apiService.getLookupLists(lookupName).subscribe(
        (result: any) => {
          this.availableElements = result.lookups;
          this.filteredElements = result.lookups;
          
          //removes the already used elements from the list
          if(this.prefilledValue) {
            this.prefilledValue.forEach((elem:any) => {
              let index = this.availableElements.findIndex((item:any) => item.name == elem.name);      
              this.availableElements.splice(index, 1);
            });

          }
          this.filteredElements = this.elementCtrl.valueChanges.pipe(
            startWith(null),
            map((item: string | null) => (item ? this._filterElem(item) : this.availableElements.slice())),
          );
         },
        // error
        (msg) => {
          console.log('error retrieving lookup list ' + msg);
      });
    // disables readonly when no fields are defined
    if(!this.readonlyFields){
      this.element.readOnly = false;
    }
    // checks if the form contains any stored data to retrieve and display
    if (this.element.multiValue) {
      this.prefilledValue = this.element.multiValue;
      if(this.prefilledValue) {
        this.prefilledValue.forEach((elem:any) => {
        this.selectedElems.push(elem);
      });
    }}

  }

  /**
   * adds an element to the list, when it's clicked
   * @param event event triggered when clicking an element
   */
  addElem(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our service if it exists
    if (value && this.availableElements.includes(value) && !this.selectedElems.includes(value)) {
      this.selectedElems.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.elementCtrl.setValue(null);
  }

  /**
   * removes an element from the list
   * @param elem element to be removed
   */
  removeElem(elem: any): void {
    const index = this.selectedElems.findIndex((item:any) => item.name == elem.name);
    if (index >= 0) {
      this.selectedElems.splice(index, 1);
      this.availableElements.push(elem);   
    }
  }

  /**
   * handles the selected element when clicking it
   * @param event event that is triggered while clicking it
   */
  selectedElem(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value.name;    
    this.elementsInput.nativeElement.value = '';
    this.elementCtrl.setValue(null);

    const availableOpt = this.availableElements.filter((option:any) => option.name == value);
    const alreadyExists = this.selectedElems.filter((option:any) => option.name == value);

    // Add our service if it exists
    if (value && availableOpt.length > 0 && alreadyExists.length == 0) {
      this.selectedElems.push(event.option.value);  
      let index = this.availableElements.findIndex((item:any) => item.name == value);      
      this.availableElements.splice(index, 1);
    }
  }

  /**
   * filters by the elements that already exist
   * @param value element value to filter
   * @returns returns if is included
   */
  private _filterElem(value: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase(); 
    
    return this.availableElements.filter((element:any) => element.name.toLowerCase().includes(filterValue));
  }

  /**
   * checks if an element exists before displaying it
   * @param elem selected element
   * @returns returns if it exists
   */
  checkIfExists(elem: string){    
    return this.selectedElems.includes(elem);
  }

  selectValue(event:any, element:any, action?: string) {    
    let removeItem;    
    this.invalidMultiselect = false;
    if (action && action == 'remove'){
      removeItem = event.name;
    }
    const elem = {
      name: event.option ? event.option.value.name : null,
      id: event.option ? event.option.value.id : null,
      removeItem: removeItem
    };
    if(elem.id || elem.removeItem) {
      this.elementEvent.emit(elem);
    }

    if (elem.id) {
      const containsAccept = this.selectedElems.filter((elem:any) => elem.name.includes('Accept')) 
      // when accept is selected and contains more options
    if (containsAccept.length > 0 && this.selectedElems.length > 1) {
        this.invalidMultiselect = true;
      }
    }
    
  }

  /**
   * handles the value change and in case it's not valid, shows the incorrect styles
   * @param value passed value
   */
  valueChange(value: any) {
    if (value.trim().length > 0 && !this.availableElements.includes(value.trim())) {
      this.invalid = true;
      const elem: any = {};
      // when the entered value doesn't match any valid options, it resets the previous values
      this.elementEvent.emit(elem);
    } else {
      this.invalid = false;
    }
  }
  
  /**
   * if the inserted text does not match any available entries, shows incorrect styles
   */
  checkContent() {
    this.invalid = true;
    if (Object.keys(this.selectedElems).length > 0) {
      this.invalid = false;
    }
  }
}
