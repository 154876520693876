import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiSitesService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  ///api/sys/lookup/${lookupName}/list?fieldValue=name
  getLookupList(params: any) {
    const endpoint = `/api/sys/lookup/${params.lookupName}/list?fieldValue=name`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeaders(),
    });

  }

  //gets list without xtenant
  //GET: /tenant/list?firstResult=0&maxResults=7
  getTenantsList(params: any) {
    const endpoint = `/api/sys/tenant/list?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortColumn}&sort=${params.sort}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

    //GET: /tenant/list?firstResult=0&maxResults=7
  //api/sys/tenant/licenced?firstResult=0&maxResults=20&sort=dateCreated&sort=asc
  getTenantsForWorkareas(params: any) {
    const endpoint = `/api/sys/tenant/licenced?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortColumn}&sort=${params.sort}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });

  }

    //gets list without xtenant
  //GET: /tenant/list?firstResult=0&maxResults=7
  getLicencedTenants(params: any) {
    const endpoint = `/api/sys/tenant/licenced`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });

  }
  
  //http://localhost:8082/api/sys/user/listSites/coyote?firstResult=0&maxResults=20&sort=dateCreated,desc
  //gets list without xtenant
  //GET: /user/listSites/{userId}  
  getUserSiteslist(params: any) {
    const endpoint = `/api/sys/user/listSites/${params.elemId}?firstResult=0&maxResults=20&sort=dateCreated,desc`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),   
    });

  }
  
  //GET /user/{tenantId}
  getSiteDetails(tenantId: string) {
    const endpoint = `/api/sys/tenant/${tenantId}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(tenantId),
    });

  }

  //GET /tenant/users/list
  getSiteUsers(params: any) {
    const endpoint = `/api/sys/tenant/users/list?firstResult=0&maxResults=20&sort=dateCreated,desc`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId),
    });
  }

  ///api/sys/tenant
  createSite(params: any) {
    const endpoint = `/api/sys/tenant`;
        return this.http.post(this.baseURL + endpoint, params, {
      headers: this.authService.getHeadersWithoutTenant()
    });

  }
  
  //PUT /user/update
  updateSite(params: any) {
    const endpoint = `/api/sys/tenant/update`;            
    return this.http.put(this.baseURL + endpoint, params, {
      headers: this.authService.getHeadersWithTenant( params.id)
    });

  }

  //DELETE /tenant/delete?id=tenantId   
  deleteSite(params: any) {
    const endpoint = `/api/sys/tenant/delete`;   
    const urlparam = `?id=${params.tenantId}`;
    return this.http.delete(this.baseURL + endpoint + urlparam, {
      headers: this.authService.getHeadersWithTenant( params.tenantId)
    });
  }

  // DEACTIVATE OR ENABLE TENANT   /tenant/deactivateEnable?id=XXX&enabled=true
  disableSite(params: any) {
    const endpoint = `/api/sys/tenant/deactivateEnable`;  
    const urlparam = `${endpoint}?id=${params.tenantId}&enabled=${params.enabled}`;
    return this.http.put(this.baseURL + urlparam,'', {
      headers: this.authService.getHeadersWithTenant(params.tenantId)
    });
  }

  //GET /roles/tenant
  getRoles(params: any) {
    const endpoint = `/api/sys/roles/tenant`;
    const headers = params.elemId ? this.authService.getHeadersWithTenant( params.elemId) : this.authService.getHeadersWithoutTenant();
    return this.http.get(this.baseURL + endpoint, {
      headers: headers,
    });
  }

  //GET /tenant/search/{textToSearch}
  //?firstResult=0&maxResults=20&sort=dateCreated&sort=asc
  searchSites(params: any) {
    const endpoint = `api/sys/tenant/search`;
    const urlParams = `?firstResult=${params.first}&maxResults=${params.max}&sort=dateCreated&sort=${params.sort}`;
    const url = `${this.baseURL}/${endpoint}/${params.searchTerm}${urlParams}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.tenantId) });
  }

  //GET /user/type(avatar/signature)/{avatarId}
  getSiteLogo(params: any) {
    const endpoint = `api/sys/tenant/logo/get`;    
    const url = `${this.baseURL}/${endpoint}`;
    return this.http.get(url, { headers: this.authService.getHeadersWithTenant(params.tenantId) });
  }

}
