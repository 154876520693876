<div class="dialogform-container-files "  [ngClass]="{'display-pdf': dialogData.element.type  == 'pdf'}">
    <div class="dialogform-container" *ngIf="!isLoading">
        <div class="v-window-header">
            <span *ngIf="dialogData?.element?.title"> {{dialogData.element.title}}</span>
            <span *ngIf="dialogData?.element?.name"> {{dialogData.element.name}}</span>
            <div [mat-dialog-close]="true" class="v-window-closebox">
                <mat-icon class="icon action">close</mat-icon>
            </div>
        </div>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <mat-card-content *ngIf="!isLoading">
        
        <img *ngIf="urlAttachment && !dialogData?.element?.type?.includes('pdf')" 
        class="preview" onContextMenu="return false;" [src]="'data:image/'+ dialogData.element.type +';base64,' +urlAttachment" />

        <div *ngIf="dialogData?.element?.type?.includes('pdf')" class="pdf-viewer-container">

            <pdf-viewer
            [src]="'data:application/pdf;base64,'+ urlAttachment"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="0.98"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="true">
            </pdf-viewer>
        </div>
        <div mat-dialog-actions>
        
            <button mat-raised-button color="primary" (click)="closePopup()">
                Close
                <mat-icon class="icon action">close</mat-icon>
            </button>
        </div>
    </mat-card-content>
</div>