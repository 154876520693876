import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ApiConsumablesService } from 'src/app/services/api.consumables.service';
import { AddConsumableComponent } from './add-consumables/add-consumables.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-create-report-consumables',
  templateUrl: './create-report-consumables.component.html',
  styleUrls: ['./create-report-consumables.component.scss']
})
export class CreateReportConsumablesComponent {
  @Input() roles: any;
  @Input() workareaRoles: any;  
  listUpdates!: Subscription;
  formDataSubscription!: Subscription;
  displayedColumns: string[] = ['type', 'bNumber', 'description', 'services', 'actions'];
  showActions = false;
  isLoading = false;
  noResults = false;
  dataSource:any = [];
  workareaId: any;
  addNewEnabled = false;
  taskId: any;
  allTaskConsumables: any;

  constructor(
    public rolesService: RolesService,
    private componentsCommunication: ComponentsCommunicationService,
    private consumableService: ApiConsumablesService,
    private dialog: MatDialog) {}

    ngOnInit() {
      
      this.taskId = localStorage.getItem('taskId');      
      this.workareaId = localStorage.getItem('workareaId');
      if (this.taskId) {
        this.getData();
      } else {
        console.log('no task id detected');
        this.isLoading = false;
      }
      this.listUpdates = this.componentsCommunication.getUpdatedList().subscribe(name => {
        // updates the reports list
        if(name == 'consumables_mainlist') {
          this.getData();
        }
      });

      this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

        if(data && data.id && !this.workareaId) {
          this.workareaId = data.workArea.id;
          this.getData();
        } 
      }); 
    }

    ngOnDestroy(){
      this.listUpdates.unsubscribe();
      this.formDataSubscription.unsubscribe();
    }

    getData() {
      if (this.workareaId){
        //this.isLoading = true;
        const tenantId = localStorage.getItem('taskTenantId');   
        const workareaId = this.workareaId;
        const params = {
          workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
          tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
          taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : '',
          first: 0,
          max: 50,
          sort: 'desc',
          sortField: 'dateCreated',
        }
        
        this.consumableService.getTaskConsumables(params).subscribe(
          (result: any) => {
            this.isLoading = false;
            //this.dataSource = result.content;
            //this.allTaskConsumables = result.content;

            let data = <any>[];
            result.content.forEach((elem:any) => {
              let allServices = <any>[];
              elem.servicesSet.forEach((services:any) => {
                allServices.push(services.name);
              });
    
              elem.personnelServices = [...allServices];
              elem.personnelServicesStr = allServices.join(', ');
              data.push(elem);
            });
            this.dataSource = [...data];
            this.allTaskConsumables = [...data];

            if (result.length == 0) {
              this.dataSource = [];
              this.allTaskConsumables = [];
            }
          },
          // error
          (msg) => {
            console.log('error retrieving lookup list ' + msg);
            this.dataSource = [];
            this.allTaskConsumables = [];
            this.isLoading = false;
        });
      }
    }
  
    addNew(action: string, element?: any) {
      const width = action == 'create' ? '60%' : 'auto';
      const data = {
        allTaskConsumables: [...this.allTaskConsumables],
        taskId: this.taskId,
        action: action,
        element: element
      }
      
      this.dialog.open(AddConsumableComponent, {
        height: 'auto',
        width: width,
        disableClose: true,
        autoFocus: false,
        data: data
      });
    }
  
    editItemDetails (element: any) {
      this.addNew('edit', element);
    }
  
    /**
     * deletes the selected service from the list
     * @param element selected element to be removed
     */
    deleteElem(element: any){
      this.isLoading = true;
      const tenantId = localStorage.getItem('taskTenantId');   
      const workareaId = localStorage.getItem('taskWorkareaId');
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        consumableId: element.id
      };
      this.consumableService.deleteTaskConsumable(params).subscribe(
        () => {
          this.getData();
        },
        // error
        (msg) => {
          if (msg.status == 200) {
            this.getData();
          } else {
            console.log('error deleting the consumable' + msg);
          } 
      });
    }
  
        /**
     * confirmation before applying the change
     * @param element selected elem
     */
    confirmation(element: any, action: string) {      
      element.action = action;
      element.confirmation = false;
      if (action == 'delete') {
        element.confirmationString = 'Delete Consumable ' + element.consumable.consumableType.name + ' ?';
      }    
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: 'auto',
        width: 'auto',
        disableClose: true,
        autoFocus: false,
        data: element
      });
  
      dialogRef.afterClosed().subscribe(
        (data) => {
          if (data.confirmation) {
            this.deleteElem(element);
          }
        }
      ); 
    }
  
  }
  