import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

@Component({
  selector: 'select-elem',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  getAssetUpdate!: Subscription;
  @ViewChild('selectInput')
  selectInput!: ElementRef<HTMLInputElement>;
  @ViewChild('activeOPT')
  activeOPT!: any;
  @Input() element:any;
  @Input() readonlyFields:any;
  @Input() formData:any;   
  prefilledValue: any;
  selectedElement: any = [];
  @Output() elementEvent = new EventEmitter<string>();
  types = [1,2,3,4,5];
  skippedTypes:any = ['bodyconnections'];
  myControl = new FormControl('');
  lookupsList: any;
  autocomplete = 'on';
  invalid = false;
  lookupName: any;
  filteredOptions: Observable<any>;
  bodyConnections = [{name:'1'}, {name:'2'},{name:'3'}, {name:'4'}, {name:'5'}];
  fieldsToRetrieve = ['workArea', 'reportTypeFilter', 'tasknumber', 'reportTypeCategory', 'servicesSet', 'specificationSet',
   'dateofexamination', 'assetType', 'assetCategory', 'connectionType1', 'connectionType2', 'bodyConnections'];
  fieldsToQuery = ['diagnosticArea', 'testrestriction', 'surfacecondition'];  
  constructor(
    private apiService: ApiService,
    private componentsCommunication: ComponentsCommunicationService){}
  
  ngOnInit(){
    const action = this.element.action;

    // Executes the initial loads
    this.initialLoads();
    const name = this.element.id ? this.element.id.toLowerCase().trim() : this.element.lookupName.toLowerCase().trim();
    if (this.readonlyFields && this.readonlyFields.includes(name) && this.element.action != 'create' &&
    this.element.action != 'edit') {
      this.element.readonly = true;
    }
    // To process different actions and to retrieve lookups when needed
    if (this.element.lookupName && (action == 'edit' || action == 'clone' || action == 'create_result' || this.element.readOnly)) {
      this.processEditCloneResultActions();

    // Does an initial load of lookups when using a 'create' action normally
    } else if (this.element.type && !this.formData && this.lookupName){
      if ( this.lookupName != 'bodyConnections' && this.lookupName != 'workArea') {
        this.loadLookupsOnCreate();
      } else if (this.lookupName == 'workArea'){
        this.loadLookupWorkarea();
      }
      

      // checks if the form contains any stored data to retrieve and display
    } else if (this.formData && this.element.id) {    
      this.retrieveStoredData();
    }

    this.getAssetUpdate = this.componentsCommunication.getAssetUpdate().subscribe(data => {
      // updates the asset type prefilled
      if(data && data == 'reset_assettype') {
        this.prefilledValue = null;
      }
    });
  }

  ngOnDestroy(){
    this.getAssetUpdate.unsubscribe();
  }

  initialLoads() {
    // disables readonly when no fields are defined
    if(!this.readonlyFields){
      this.element.readOnly = false;
    }
    if (this.element.action == 'create_result') {
      this.element.readOnly = true;
      this.element.disabled = true;
    }
    this.lookupName = null;
    this.autocomplete = this.element.readOnly ? 'off' : 'on';
    if( this.element.params && this.element.params.lookupType) {
      this.lookupName = this.element.params.lookupType;
    } else if (this.element.id == null && (this.element.type == 1 || this.element.type == 2)) {
      if (!this.element.lookupName && this.element.name.toLowerCase().includes('asset type')) {
        this.lookupName = 'assetType';
        this.prefilledValue = this.element['assetType'];
      } else {
        this.lookupName = this.element.lookupName;  
      }
      this.lookupName = this.element.lookupName;
    }else {
      this.lookupName = this.element.id;
    }
    if (this.element.id && this.element.id.toLowerCase() == 'bodyconnections') {
      this.element.options = this.bodyConnections;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')));
    }
  }

  loadLookupsOnCreate() {
    this.apiService.getLookupLists(this.lookupName).subscribe(
      (result: any) => {
        this.lookupsList = result.lookups;
        if (result.lookups && result.lookups.length > 0) {
          this.element.options = result.lookups;
        }          
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || ''))
        );
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
    }); 
  }

  loadLookupWorkarea() {
    const tenantId = localStorage.getItem('selectedSiteId');
    const workareaId = localStorage.getItem('selectedWorkareaId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : ''
    }
    this.apiService.getLookupWorkareas(params).subscribe(
      (result: any) => {
        this.lookupsList = result.content;
        if (result.content && result.content.length > 0) {
          this.element.options = result.content;
        }          
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || ''))
        );
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
    }); 
  }

  processEditCloneResultActions() {
    this.prefilledValue = this.element[this.element.lookupName];      
    this.lookupName = this.lookupName == 'reportType' ? 'reportTypeFilter' : this.lookupName;
    this.lookupName = this.lookupName.includes('connectionType') ? 'connectionType' : this.lookupName;
    const exists = this.skippedTypes.find((elem:any) => this.lookupName == elem);
    if (this.lookupName == 'bodyConnections')  {
      this.element.options = this.bodyConnections;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    }
    if (!this.element.readOnly) {
      if (!exists) {
        this.getLookups(this.lookupName);
      }
    } 
  }

  retrieveStoredData() {
          
    Object.entries(this.fieldsToRetrieve).forEach(([key, value]) => {
      if(value.toLowerCase().includes(this.element.id.toLowerCase()) && this.formData[value]) {
        if(this.element.id.includes('connectionType') && this.element.name.slice(this.element.name.length - 1) == 1){
          this.prefilledValue = this.formData['connectionType1'];
        }
        if(this.element.id.includes('connectionType') && this.element.name.slice(this.element.name.length - 1) == 2){
          this.prefilledValue = this.formData['connectionType2'];
        } else if(!this.element.id.includes('connectionType')) {
          this.prefilledValue = this.formData[value]
          this.element.options = [this.formData[value]];
        }
        if (this.element.id == 'assetType' && this.formData[value].id) {
          const tenantId = localStorage.getItem('selectedSiteId');   
          const workareaId = localStorage.getItem('taskWorkareaId');     
          const params = {
            workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
            tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
            id: this.formData[value].id
          };

          this.apiService.getAssetCategory(params).subscribe(
            (result: any) => {
              const item:any = {
                id: result.id,
                name: result.name,
                category: 'assetcategory'
              }
              if (this.element.id) {
                this.componentsCommunication.setUpdateTasks(item);
              }else {
                // when creating new item
                this.elementEvent.emit(item);
              }                  
            },
            // error
            (msg) => {
              console.log('error retrieving the asset category ' + msg);
          });
        }            

        if (this.element.options.name ) {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );
        }  
     } if (value.toLowerCase().includes(this.element.id.toLowerCase()) && value == 'reportTypeFilter') {
        if (this.formData.reportTypeFilter) {
          this.prefilledValue = this.formData.reportTypeFilter;
        } else {
          this.prefilledValue = {name :this.formData.type, id:this.formData.id};
        }
      
     }
     if (value.toLowerCase().includes(this.element.id.toLowerCase()) && value == 'bodyConnections') {
      this.prefilledValue = {name: this.formData.bodyConnections};          
    }
    });
  // when new items have to be queried and retrieve the values
  if (this.fieldsToQuery.find(element => element.toLowerCase() == this.element.id.toLowerCase())) {
    this.getLookups(this.element.id);
    this.prefilledValue = this.formData[this.element.id];
  }
      
  }

  getLookups(lookupName: string){
    this.apiService.getLookupLists(lookupName).subscribe(
      (result: any) => {
        this.lookupsList = result.lookups;
        if (result.lookups && result.lookups.length > 0) {
          this.element.options = result.lookups;
        }          
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );

      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
    });
  
  }

  private _filter(value: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
    let returnElems = this.element.options.filter(
      (option: any) => (option.name && option.name.toLowerCase().includes(filterValue)));
    return returnElems;
  }

  handleArrowNavigation(event: any){
    let value: any = '';
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }
    this.selectInput.nativeElement.value = value;
    event.isUserInput = true;
    const selectedOpt = this.element.options.find((elem:any) => elem.name.includes(value.trim()));
    this.selectValue(selectedOpt, this.element, event);
  }

    /**
   * sets the value for the selected element
   * @param elem with value to set
   * @param element current element
   */
  selectValue(elem: any, element: any, event: any) {   
    
    if (event.isUserInput) {   
      this.selectedElement = elem;
      this.invalid = false;

      if ((element.id && element.id.toLowerCase() == 'assettype') || this.lookupName.toLowerCase() == 'assettype') {
        const workareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
        const tenantId = localStorage.getItem('selectedSiteId');   
        const params = {
          workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
          tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
          id: elem.id
        };
        this.apiService.getAssetCategory(params).subscribe(
          (result: any) => {
            const item:any = {
              id: result.id,
              name: result.name,
              category: 'assetcategory'
            }
            if (element.id) {
              this.componentsCommunication.setUpdateTasks(item);
            }else {
              // when creating new item
              this.elementEvent.emit(item);
            }
          },
          // error
          (msg) => {
            console.log('error retrieving the asset category ' + msg);
        });
        
      } 
      if (element.type == 2 && element.name.toLowerCase() == 'consumable type' || element.name.toLowerCase() == 'manufacturer') {
        elem.category = element.name;       
      }

      this.elementEvent.emit(elem);
    }
  }

  /**
   * resets the value for the selected element
   * @param elem with value to set
   * @param element current element
   */
  resetValue(elem: any, element: any) {    
    //*****TODO add endpoint that retrieves th asset category
    if (element.id.toLowerCase() == 'assettype') {
      const item = {
        name : 'assetcategory',
        value : elem
      }
      this.componentsCommunication.setUpdateTasks(item);
    } 
    const elemEvent: any = {
      name :element.id,
      value : elem,
      remove: element.id
    }
    this.elementEvent.emit(elemEvent);  
  }
/*
  valueChange(targetElem: any) {
    const exists = this.element.options.find((elem:any) => elem.name.toLowerCase().includes(targetElem.target.value.trim().toLowerCase()));
    if (!exists) {
      this.invalid = true;
      const elem: any = {};
      // when the entered value doesn't match any valid options, it resets the previous values
      this.prefilledValue = '';
      targetElem.target.value = '';
      this.componentsCommunication.setUpdateReports(elem);
      this.elementEvent.emit(elem);
    } else{
      this.invalid = false;
    }
    
  }*/
}
