import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import  packageJson  from '../../../../../package.json';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { filter } from 'rxjs/internal/operators/filter';
import { Subscription } from 'rxjs';
import { RolesService } from 'src/app/services/roles.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'main-headerbar',
  templateUrl: './main-headerbar.component.html',
  styleUrls: ['./main-headerbar.component.scss']
})
export class MainHeaderbarComponent {
  workareasUpdate!: Subscription;
  idleTimerSubscription!: Subscription;
  idleTimeoutSubscription!: Subscription;
  public version: string = packageJson.version;
  getRolesListUpdate!: Subscription;
  title = 'Home';
  selectedSite: any ={companyTitle: '-'};  
  allSites = [];
  selectedWorkarea: any = {name: '-'};
  allWorkareas = [];
  loggedUserName:any;
  workareasList: any;
  sitesList: any;
  isLoading = false;
  taskWorkareaId: any = null;
  roles = {
    isAdmin: false,
    imReader: false,
    systemRoles: [],
    allRoles: [],
    tenantRoles: [],
  };
  selectedSiteString = '-';
  selectedWorkareaString ='-';
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };
  constructor(
    private matDialog: MatDialog,
    private userIdle: UserIdleService,
    private apiUsersService: ApiUsersService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private rolesService: RolesService,
    private componentsCommunication: ComponentsCommunicationService,
    private router: Router,
    private apiSitesService: ApiSitesService){
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          // Your code here for when the page is refreshed     
          const selectSiteId = localStorage.getItem('selectedSiteId');    
          const selectedWorkarea = localStorage.getItem('selectedWorkareaId'); 
          if (selectSiteId) {         
            this.getAllTenants('selectActive');
          }
          if (selectedWorkarea) {         
            this.getAllWorkareas('selectActive');
          }
        }
      });

  }

  ngOnInit(){
    this.loggedUserName = localStorage.getItem('username');   
    const workareaId = localStorage.getItem('taskWorkareaId');  
    this.taskWorkareaId = workareaId && workareaId?.length > 0 ? workareaId : null;
    this.getRolesListUpdate = this.componentsCommunication.getRolesList().subscribe(data => {
      // is admin
      if (data && data.length > 0 &&  data[0].tenantId === null && this.rolesService.systemAdmin(data[0].roles)){
        this.roles.isAdmin = true;      
      }
      if (data && data.length > 1){
        this.roles.systemRoles = data[0].roles;
        //this.roles.tenantRoles = data.slice(1);
      }    
    });

    if(!this.loggedUserName) {
      //this.logout();
    } else {

      const selectSiteId = localStorage.getItem('selectedSiteId');    
      const selectedWorkarea = localStorage.getItem('selectedWorkareaId'); 
      if (selectSiteId) {         
        this.getAllTenants('selectActive');
      } else {
        this.getAllTenants();
      }
      if (selectedWorkarea) {         
        this.getAllWorkareas('selectActive');
      } else {
        
      this.getAllWorkareas();
      }
    }    

    this.workareasUpdate = this.componentsCommunication.getUpdateWorkAreas().subscribe((params:any) => {
      // updates the list
      if(params && params.name != 'update_workareas') {
        this.getAllWorkareas();
      }   
    }); 
    
    if(this.idleTimerSubscription){
      this.idleTimerSubscription.unsubscribe();
      this.idleTimeoutSubscription.unsubscribe();
    }
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().subscribe(count => console.log(count));
    this.idleTimerSubscription = this.userIdle.onTimerStart().subscribe();
      
    // Start watch when time is up.
    this.idleTimeoutSubscription = this.userIdle.onTimeout().subscribe(() => 
    {
      console.log('Session Expired... Redirecting...');
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.logout();
      this.router.navigateByUrl('/login');
    });

  }

  ngOnDestroy(){
    if(this.idleTimerSubscription){
      this.idleTimerSubscription.unsubscribe();
      this.idleTimeoutSubscription.unsubscribe();
    }
    if(this.getRolesListUpdate) {
      this.getRolesListUpdate.unsubscribe();
      this.workareasUpdate.unsubscribe();
    }


    //cleans up the subscription avoiding residual propagation
    this.componentsCommunication.setUpdateUsers({});
    this.componentsCommunication.setUpdateSites({});
    this.componentsCommunication.setUpdateWorkAreas({});
    this.componentsCommunication.setUpdateAssets({});
    this.componentsCommunication.setUpdateTasks({});

  }

  getAllWorkareas(param?: string){
    this.workareasList = [];    
    const tenantId = localStorage.getItem('selectedSiteId');    
    if (tenantId && tenantId.length > 0) {
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        first: 0,
        max: 9999,
        sort: 'asc',
        sortColumn: 'dateCreated'
      };
      
      this.apiUsersService.getTenantWorkareas(params).subscribe(
        (result: any) => {
        
          if(result.content.length > 0) {
            this.workareasList = result.content;          
            //when has to retrieve and select the active site after a refresh
            if (param && param == 'selectActive') {
              const activeWorkareaId = localStorage.getItem('selectedWorkareaId');
              this.selectedWorkarea = this.workareasList.find((elem:any) => activeWorkareaId == elem.id);
                this.selectedWorkareaString = this.selectedWorkarea.name;
            }
          } 
        },
        // error
        (msg) => {
          console.log('error retrieving the list ' + msg);
          this.isLoading = false;
      });

    }

  }

  getAllTenants(param?: string) {
    this.isLoading = true;
    this.sitesList = [];
    const type = 'privilege';     
    const params = {
      tenantId: '',  
      lookupName: type,
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'asc',
      sortColumn: 'dateCreated'
    };

    this.apiSitesService.getTenantsList(params).subscribe(
      (result: any) => {
      
        if(result.content&& result.content.length > 0) {
          this.sitesList = result.content;
          //when has to retrieve and select the active site after a refresh
          if (param && param == 'selectActive') {
            const activeSiteId = localStorage.getItem('selectedSiteId');
           const activeSite = this.sitesList.find((elem:any) => activeSiteId == elem.id);              
           if (activeSite){
            this.selectedSite = activeSite;
           }
          }
        } 
        this.isLoading = false; 

      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoading = false;
      });
  }

  selectSite(elem: any) {
    this.selectedSite = elem && elem.id ? elem : {companyTitle: '-'};
    const selectedWorkarea = localStorage.getItem('selectedWorkareaId');
    if (this.selectedSite.id) {
      localStorage.setItem('selectedSiteId', elem.id);
      this.getAllWorkareas();
    } else {
      localStorage.setItem('selectedSiteId', '');
      this.selectedWorkarea =  {name: '-'};

    }
    
    if(selectedWorkarea && selectedWorkarea.length > 0) {
      this.selectWorkarea('');
    }
    
    this.componentsCommunication.setUpdateUsers({name: 'update_users', action: 'tenant_update'});
    this.componentsCommunication.setUpdateSites({name: 'update_sites', action: 'tenant_update'});
    this.componentsCommunication.setUpdateWorkAreas({name: 'update_workareas', action: 'tenant_update'});
    this.componentsCommunication.setUpdateAssets({name:'update_assets', action: 'tenant_update'});
    this.componentsCommunication.setUpdateTasks({name:'update_tasks', action: 'tenant_update'});
  }

  selectWorkarea(elem: any) {
    this.selectedWorkarea = elem && elem.id ? elem : {name: '-'};
    if (this.selectedWorkarea.id) {
      localStorage.setItem('selectedWorkareaId', elem.id);      
    } else {
      localStorage.setItem('selectedWorkareaId', '');      
    }
    this.componentsCommunication.setUpdateWorkAreas({name: 'update_workareas', action: 'workarea_update'});
    this.componentsCommunication.setUpdateTasks({name:'update_tasks', action: 'workarea_update'});
  }

  logout() {
    this.matDialog.closeAll();
    localStorage.clear();
    //forces the roles broadcast to empty everything
    this.componentsCommunication.setRolesList([]);
    this.componentsCommunication.setRolesWorkareas([]);
    this.router.navigateByUrl('/login');
  }

  updateMaintab(){
    this.componentsCommunication.setUpdateTab({'main_tab': '0'});
  }

  resetPassword(){
    const tenantId = localStorage.getItem('selectedSiteId');  
    const userId = localStorage.getItem('username');  
    
    const params = {
      tenantId: tenantId && tenantId?.length > 0 ? tenantId : '',
      userId: userId && userId?.length > 0 ? userId : ''
    };
    
    this.authService.resetPassword(params).subscribe(
      (data: any) => {
        this.snackBar.open('The email was sent, please check your inbox', '', {duration: 5000});
        console.log('email was sent');
      },
      error => {
      if (error.status == '200') {
        this.snackBar.open('The email was sent, please check your inbox', '', {duration: 5000});
        console.log('email was sent');                    
      } else {
        this.snackBar.open('Reset password failed', '', {duration: 5000});
        console.log(error);
      }
        
    });           
  }

}

