<div class="dialogform-container create-manual-defect">
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <div class="dialogform-container" *ngIf="!isLoading">
    <div class="v-window-header">      
      <span *ngIf="dialogData.action == 'create'">Create Manual Defect</span>
      <span *ngIf="dialogData.action == 'update'">Update Manual Defect</span>
      <span *ngIf="dialogData.action == 'edit'">Edit Manual Defect</span>      
      <div [mat-dialog-close]="true" class="v-window-closebox">
        <mat-icon class="icon action">close</mat-icon>
      </div>
    </div>


    <mat-card-content *ngIf="!isLoading">

        <div mat-dialog-content>
            <div class="columns">
              <div  class="row two-columns" >
                <!-- NEW COLUMN-->
                <div>
                          
                  <!--*****Area*****-->    
                  <h4> Area
                    <span class="required_item"> *</span>
                  </h4>
                  <mat-form-field class="filter-type" appearance="fill">
                      <input matInput
                      type="text"
                      [readonly]="true"
                      disabled="true"
                      [ngModel]="prefilledArea"                          
                      color="primary"/>    
                  </mat-form-field>

                  <!--*****Condition*****-->                         
                  <h4> Condition
                    <span class="required_item"> *</span>
                  </h4>          
  
                  <mat-form-field 
                  [ngClass]="{'disabled': !editable}"
                  appearance="fill">           
                    <input class="condition-input"
                    type="text"
                    matInput
                    #conditionInput
                    [formControl]="conditionControl"
                    [matAutocomplete]="auto"
                    (keydown.arrowup)="handleArrowNavigation($event)" 
                    (keydown.arrowdown)="handleArrowNavigation($event)"
                    [ngModel]="prefilledCondition"
                    (matChipInputTokenEnd)="addCondition($event)">
                    <mat-icon
                    class="select-arrow"
                    matSuffix>keyboard_arrow_down</mat-icon>
                    
                    <mat-autocomplete #auto="matAutocomplete">
                      <ng-container *ngFor="let option of filteredOptions | async" >
                        <mat-option (onSelectionChange)="addCondition($event, option)" [value]="option.name">
                          {{option.name}}
                        </mat-option>
                    </ng-container>
      
                    </mat-autocomplete>
                  </mat-form-field>
                          
                  </div>
                            
                  <!--*****Description*****-->  
                  <h4>Description</h4>    
                  <mat-form-field class="filter-type" appearance="fill">                      
                    <textarea 
                    (keyup)="newManualDefect.description = $event.target?.value; validateForm();"                       
                    [ngModel]="newManualDefect.description"
                    rows="10"      
                    matInput   
                    [value]="newManualDefect.description"                                 
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize">
                    </textarea>  
                </mat-form-field>
              </div>
                <!-- NEW COLUMN-->
                <div *ngIf="!isLoadingAttachments" [ngClass]="{'disabled readonly': dialogData.action == 'create'}" class="row two-columns">
                  
                    <button  mat-raised-button
                    class="upload" color="primary" (click)="openUploads('upload');">
                      Upload
                      <mat-icon class="icon action">save</mat-icon>
                    </button>
              
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 full-width">
    
                      <!-- date Column -->
                      <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef>Name</th>
                          <td mat-cell *matCellDef="let element">{{element.name}}</td>
                      </ng-container>
              
                      <!-- standard Column -->
                      <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef>Description</th>
                          <td mat-cell *matCellDef="let element">{{element.description}}</td>
                      </ng-container>
                        
                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell class="last" width ="40px" *matHeaderCellDef></th>
                          <td mat-cell class="last" width ="40px" *matCellDef="let element">
              
                        <!-- pdf list actions menu-->
                        <button class="user_menu custom" mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
                          <mat-icon>expand_more</mat-icon>
                        </button>
              
                        <mat-menu class="menu" x-position="before" #menu="matMenu">
              
                          <button mat-menu-item (click)="actions('edit', element)">
                            <mat-icon>edit</mat-icon>
                            Edit
                          </button>
                          <button mat-menu-item (click)="preview(element)">
                            <mat-icon>preview</mat-icon>
                              View 
                          </button>                              
                          <button mat-menu-item (click)="confirmation('delete', element)">
                            <mat-icon>delete</mat-icon>
                            Delete
                          </button>
                            
                        </mat-menu>
              
                      </td>
                      </ng-container>                        
              
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>    
                  </div>

            </div>
        </div>
    
        <div mat-dialog-actions>

          <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    