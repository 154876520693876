import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

@Component({
  selector: 'input-elem',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  getUpdateAssetType!: Subscription;  
  getAssetUpdate!: Subscription;  
  @Input() element:any;
  @Input() readonlyFields:any;
  @Input() formData:any;  
  @Output() elementEvent = new EventEmitter<any>();
  prefilledValue: any = {};
  readonly = false;
  constructor(
    private componentsCommunication: ComponentsCommunicationService){

  }

  ngOnInit(){
    // disables readonly when no fields are defined
    if(!this.readonlyFields){
      this.element.readOnly = false;
    }

    if ( this.element.assetCategory && (this.element.action == 'edit' || this.element.action == 'clone' ||
     this.element.action == 'create_result')) {
      this.prefilledValue.value = this.element.assetCategory.name;
  
    }
    // checks if the form contains any stored data to retrieve and display
    if (this.formData && this.element.id) {
      if (this.element.id.toLowerCase() == 'tasknumber') {
        this.prefilledValue.value = this.formData.taskNumber ? this.formData.taskNumber : 'null';
      } else {
        this.prefilledValue.value = this.formData[this.element.id];
      }
      
    }

    this.getUpdateAssetType = this.componentsCommunication.getUpdateTasks().subscribe(data => {
      // updates the asset category
      if(data.name && this.element.id == 'assetcategory' && data.category == 'assetcategory') {
        if(!this.prefilledValue) {
          this.prefilledValue = {value: data.name};
        } else {
          this.prefilledValue.value = data.name;
        }
        // saves the selected assec category
        const item = {
          id : data.id,
          name: data.name
      }
      this.elementEvent.emit(item);
      }
    });

    this.getAssetUpdate = this.componentsCommunication.getAssetUpdate().subscribe(data => {
      // updates the asset category
      if(data && data.name && data.category == 'assetcategory') {
        this.prefilledValue.value = data.name;
      }
    });
  }

  ngOnDestroy(){
    this.getUpdateAssetType.unsubscribe();
    this.getAssetUpdate.unsubscribe();
  }
  
  selectValue(elem: any, element: any) {  
    const item = {
      value: elem.trim(),
      name: element.id
    };
    this.elementEvent.emit(item);

  }  
}


