import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { Subscription, share } from 'rxjs';
import { CreateFormDialogComponent } from '../../dialogs/create-form-dialog/create-form-dialog.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ReleaseToQcDialogComponent } from '../../dialogs/release-to-qc-dialog/release-to-qc-dialog.component';
import { CopyTaskDialogComponent } from '../../dialogs/copy-task-dialog/copy-task-dialog.component';
import { SharedFunctionsService } from '../../../services/shared-functions.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit, OnDestroy {
  getRolesListUpdate!: Subscription;
  getUpdateReports!: Subscription;
  getRolesWorkareas!: Subscription;
  getContentLoading!: Subscription;
  displayedColumns: string[] = [
    'rep_number',
    'serial_num',
    'rep_type',
    'asset_type',
    'inspection',
    'status',
    'actions',
  ];
  searchFilters = [
    { name: ' ', value: '*' },
    { name: 'Task Number', value: 'report_number' },
    { name: 'Asset Type', value: 'asset_type' },
    { name: 'Asset Category', value: 'asset_category' },
    { name: 'Description', value: 'description' },
    { name: 'Model Number', value: 'model_number' },
    { name: 'Task Type', value: 'report_type' },
    { name: 'Serial Number', value: 'serial_number' },
    { name: 'Status', value: 'status' },
    // { name: 'Tubular Connection', value: 'tubular_connection' },
    // { name: 'Tubular Size', value: 'tubular_size' },
    // { name: 'Work Area', value: 'work_area' },
    { name: 'Work Order', value: 'work_order' },
  ];

  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };

  dataSource: any;
  activeTab = 0;
  selectedIndex = 0;
  filePath = 'evolve-poc/26.pdf';
  fileURL: any;

  @ViewChild('selectInput')
  selectInput!: any;
  @ViewChild('searchInput')
  searchInput!: ElementRef<HTMLInputElement>;
  searchFilter = '*';
  showActions = false;
  isLoading = false;

  roles = {
    isAdmin: false,
    imReader: false,
    systemRoles: [],
    allRoles: [],
    tenantRoles: []
  };

  workareaRoles = {
    workareaAdmin: false,
    systemRoles: [],
    allWorkareaRoles: [],
    currentWorkareaRoles: []
  };
  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private componentsCommunication: ComponentsCommunicationService,
    private apiService: ApiService,
    private sharedFunctions: SharedFunctionsService,
    public rolesService: RolesService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    
    this.getRolesWorkareas = this.componentsCommunication
      .getRolesWorkareas()
      .subscribe((data) => {
        if (data && data.length > 0 && data[0].workAreaId === null && this.rolesService.systemAdmin(data[0].roles)){
          this.workareaRoles.workareaAdmin = true;      
        }
        if (data && data.length > 1){
          this.workareaRoles.systemRoles = data[0].roles;
          this.workareaRoles.allWorkareaRoles = data.slice(1);

          this.workareaRoles.currentWorkareaRoles = this.sharedFunctions.applyPermissionsWorkareas(
            this.workareaRoles
          );
        } 
      });

    this.getRolesListUpdate = this.componentsCommunication
      .getRolesList()
      .subscribe((data) => {
        // is admin
        if (
          data &&
          data.length > 0 &&
          data[0].tenantId === null &&
          this.rolesService.systemAdmin(data[0].roles)
        ) {
          this.roles.isAdmin = true;
        }
        if (data && data.length > 1) {
          this.roles.systemRoles = data[0].roles;
          this.roles.allRoles = data.slice(1);
        }
        if (data && data.length > 0) {
         // this.changeDetectorRef.detectChanges();
         /* this.roles.tenantRoles = this.sharedFunctions.applyPermissions(
            this.roles
          );*/
          this.dataLoad();
        }
      });

    this.getUpdateReports = this.componentsCommunication
      .getUpdateTasks()
      .subscribe((params) => {
        // updates the reports list
        if (params && params?.name === 'update_tasks') {
          this.dataLoad();
        }
        // when the tenant dropdown triggers a tenant changes, it has to review the roles
        if (params && params.action == 'tenant_update') {
         // this.changeDetectorRef.detectChanges();
        /*  this.roles.tenantRoles = this.sharedFunctions.applyPermissions(
            this.roles
          );*/
        }

        if (params && params.action == 'workarea_update') {
         // this.changeDetectorRef.detectChanges();
          this.workareaRoles.currentWorkareaRoles = this.sharedFunctions.applyPermissionsWorkareas(
            this.workareaRoles
          );
        }

      });

    this.getContentLoading = this.componentsCommunication
      .getContentLoading()
      .subscribe((params) => {
        // gets the loading content status

        if (params && typeof params.content_loading != 'undefined') {
          this.isLoading = params.content_loading;
        }
      });
  }

  ngOnDestroy() {
    this.getUpdateReports.unsubscribe();
    this.getContentLoading.unsubscribe();    
    this.getRolesListUpdate.unsubscribe();
    this.getRolesWorkareas.unsubscribe();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.activeTab = tabChangeEvent.index;
    if (this.activeTab === 1) {
      // do stuff for page 1
    }
  }

  /**
   *
   * handles the pagination ant the update of the results
   * @param evt click event
   */
  handlePage(evt: any) {
    this.paginatorConfig.pageIndex = evt.pageIndex;
    this.paginatorConfig.pageSize = evt.pageSize;
    //triggers the search after updating the pagination
    this.dataLoad();
  }

  createCopy(element: any) {
    const data = { element: element };
    this.dialog.open(CopyTaskDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      data: data,
    });
  }

  /**
   * triggers the search
   */
  taskSearch() {
    this.isLoading = true;
    let searchTerm: any;
    searchTerm = encodeURIComponent(this.searchInput.nativeElement.value);
    //searchTerm = searchTerm.trim().length > 0 ? searchTerm.trim() : '*' ;
    const tenantId = localStorage.getItem('selectedSiteId');
    const workareaId = localStorage.getItem('selectedWorkareaId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      searchTerm: searchTerm,
      searchField: this.searchFilter,
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'desc',
    };

    if (searchTerm.trim().length > 0) {
      this.apiService.searchTasks(params).subscribe(
        (result: any) => {
          if (result && result.content && result.content.length > 0) {
            this.dataSource = result.content;
            this.paginatorConfig.length = this.dataSource.totalElements;
          } else {
            // empty results
            this.dataSource = [];
            this.paginatorConfig.length = 0;
          }
          this.isLoading = false;
        },
        // error
        (msg) => {
          console.log(msg);
          this.isLoading = false;
        }
      );
    } else if (searchTerm.trim().length == 0) {
      this.allSearch();
    }
  }

  allSearch() {
    let searchTerm = this.searchInput.nativeElement.value;
    if (searchTerm.trim().length == 0) {
      this.dataLoad();
    }
  }

  /**
   * displays the preview
   * @param element selected element
   */
  releaseToQC(element: any, action?: string) {
    element.origin = 'task';
    element.action = action;
    this.dialog.open(ReleaseToQcDialogComponent, {
      height: '100vh',
      width: '90%',
      disableClose: true,
      autoFocus: false,
      data: element,
    });
  }

  /**
   * opens the popup with the selected element to be edited
   * @param element pdf element
   */
  editTask(element: any) {
    /*
    element.editMode = true;
    element.action = 'edit';
    this.componentsCommunication.setFormData(element);*/
    localStorage.setItem('taskId', element.id);
    localStorage.setItem('idProcess', element.idProcess);
    localStorage.setItem('taskWorkareaId', element.workArea.id);
    /* Save the supplier for some list in the task. */
    localStorage.setItem('taskSupplierId', element.workArea.siteSupplier.id);
    this.router.navigateByUrl('/create-report-details');
    //this.createReport(true, report);
  }

  /**
   * opens the new form dialog
   */
  createTask(editMode?: boolean, report?: any) {
    //resets values from previous
    this.componentsCommunication.setUpdateTasks([]);
    let data = {
      report_number: '',
      serial_number: '',
      service: '',
      standard: '',
      date_inspection: '',
      examinationDate: '',
      connection_1: '',
      connection_2: '',
      body_connections: '',
      pdf: null,
      editMode: editMode ? true : false,
    };
    let elementData = report && editMode ? report : data;
    this.componentsCommunication.setFormData(elementData);

    this.dialog.open(CreateFormDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
    });
  }

  confirmation(element: any, action: string) {
    element.action = action;
    element.confirmation = false;
    if (action == 'delete') {
      element.confirmationString = 'Delete Task ' + element.taskNumber + ' ?';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      data: element,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.confirmation) {
        this.deleteTask(element);
      }
    });
  }

  /**
   * deletes the selected report
   * @param element element to be deleted
   */
  deleteTask(element: any) {
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: element.id,
      idProcess: element.idProcess,
    };

    this.apiService.deleteTask(params).subscribe(
      (result) => {
        console.log(' task deleted!');
        this.componentsCommunication.setUpdateTasks({
          name: 'update_tasks',
          action: 'tenant_update',
        });
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log(' task deleted');
          this.snackBar.open(' task deleted ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateTasks({
            name: 'update_tasks',
            action: 'tenant_update',
          });
        } else {
          console.log(msg);
        }
      }
    );
  }

  dataLoad() {
    this.isLoading = true;
    const tenantId = localStorage.getItem('selectedSiteId');
    const workareaId = localStorage.getItem('selectedWorkareaId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'desc',
      sortField: 'dateCreated',
    };
    // retrieves the tasklist for the initial load
    this.apiService.getTasksList(params).subscribe(
      (result: any) => {
        if (result && result!.content.length > 0) {
          this.dataSource = [...result.content];
          this.paginatorConfig.length = result.totalElements;
        } else {
          this.dataSource = [];
          this.paginatorConfig.length = 0;
          console.log('no tasks loaded');
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log('error retrieving the tasklist ' + msg.message);
        //this.dataSource = [];
        this.dataSource = [];
        this.isLoading = false;
      }
    );
  }
}
