import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ApiWorkareasService } from 'src/app/services/api.workareas.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditWorkAreaDialogComponent } from './edit-workarea-dialog/edit-workarea-dialog.component';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'my-workareas',
  templateUrl: './my-workareas.component.html',
  styleUrls: ['./my-workareas.component.scss'],
})
export class MyWorkareasComponent {
  getRolesListUpdate!: Subscription;
  workareasUpdate!: Subscription;
  @ViewChild('searchInput')
  searchInput!: ElementRef<HTMLInputElement>;
  reducedColumns: string[] = ['title', 'active', 'actions'];
  fullColumns: string[] = [
    'title',
    'site',
    'siteSupplier',
    'clientAcceptance',
    'active',
    'actions',
  ];
  displayedColumnsUsers: string[] = [
    //'username',
    'name',
    'email',
    'active',
    'actions',
  ];
  displayedColumnsSites: string[] = [
    'title',
    'licenseLevel',
    'email',
    'phone',
    'url',
    'active',
    'actions',
  ];
  displayedColumnsRoles: string[] = ['role', 'users'];
  displayedColumns: any;
  isLoading = false;
  contentLoading = false;
  displayDetails = false;
  detailsLoading = false;
  isLoadingRoles = false;
  isLoadingUsers = false;
  activeRowId = '';
  dataSource: any = [];
  searchFilter = '';
  selectedItem: any;
  detailsTab = 0;
  dataSourceUsers: any;
  dataSourceSites: any = [];
  dataSourceRoles: any = [];
  roles = {
    isAdmin: false,
    imReader: false,
    systemRoles: [],
    allRoles: [],
    tenantRoles: [],
  };
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };

  constructor(
    public rolesService: RolesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiUsersService: ApiUsersService,
    private apiWorkareasService: ApiWorkareasService,
    private componentsCommunication: ComponentsCommunicationService
  ) {}

  ngOnInit() {
    this.displayedColumns = this.fullColumns;
    this.getData();

    this.getRolesListUpdate = this.componentsCommunication.getRolesList().subscribe(data => {
      // is admin
      if (data && data.length > 0 &&  data[0].tenantId === null && this.rolesService.systemAdmin(data[0].roles)){
        this.roles.isAdmin = true;      
      }
      if (data && data.length > 1){
        this.roles.systemRoles = data[0].roles;
        this.roles.allRoles = data.slice(1);
      }  
    });

    this.workareasUpdate = this.componentsCommunication
      .getUpdateWorkAreas()
      .subscribe((params: any) => {
        // updates the list
        if (params && params.name == 'update_workareas') {
          if (params.action != 'tenant_update' && this.selectedItem && this.selectedItem?.id) {
            this.getRoles();
          }
          if (params && params.action != 'workarea_update') {
          this.getData(params.action);
          }
          const tenantId = localStorage.getItem('selectedSiteId');

          // when the tenant dropdown triggers a tenant changes, it has to review the roles
          if (
            tenantId &&
            tenantId.length > 0 &&
            params.action == 'tenant_update'
          ) {
            this.resetShowDetails();
           /* this.roles.tenantRoles = this.rolesService.checkTenantRoles(
              this.roles.allRoles,
              tenantId
            );*/
          }
          // when no tenant is selected, applies the system roles
          if (tenantId?.length == 0) {
          //  this.roles.tenantRoles = this.roles.systemRoles;
          }
        }
      });
  }

  ngOnDestroy() {
    this.workareasUpdate.unsubscribe();
    this.getRolesListUpdate.unsubscribe();
  }

  getData(action?: string) {
    this.isLoading = true;
    this.dataSource = [];
    const tenantId = localStorage.getItem('selectedSiteId');
    const workareaId = localStorage.getItem('selectedWorkareaId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      username: localStorage.getItem('username'),
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'asc',
      sortColumn: 'dateCreated',
    };

    this.apiWorkareasService.getWorkareasList(params).subscribe(
      (result: any) => {       
        if(result && result?.content && result?.content?.length > 0) {
          this.dataSource = result.content;
          this.paginatorConfig.length = result.totalElements;

          //this updates the current selected item
          if (this.selectedItem && this.selectedItem?.id) {
            this.selectedItem = this.dataSource.find((elem: any)=>this.selectedItem.id == elem.id);
          }
        } 
        this.isLoading = false; 
        /*
        if (this.displayDetails && result.content.length > 0 && action && action == 'disable') {
          this.loadDetails(this.selectedItem);
        }*/
      },
      // error
      (msg) => {
        console.log('error retrieving the list ' + msg);
        this.isLoading = false;
        this.dataSource = [];
        this.paginatorConfig.length = 0;
      }
    );
  }

  getUsers(action?: string) {
    this.isLoadingUsers = true;
    this.dataSourceUsers = [];
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      workareaId: this.selectedItem.id,
    };

    this.apiWorkareasService.getWorkareaUsers(params).subscribe(
      (result: any) => {
        if (result.content && result.content.length > 0) {
          this.dataSourceUsers = result.content;
        }
        this.isLoadingUsers = false;
        this.detailsLoading = false;
      },
      // error
      (msg) => {
        console.log('error retrieving the list ' + msg);
        this.isLoadingUsers = false;
        this.detailsLoading = false;
      }
    );
  }

  createWorkArea(id?: string) {
    const params = {
      userId: id ? id : '',
      action: id ? 'edit' : 'create',
    };

    this.dialog.open(EditWorkAreaDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      data: params,
    });
  }

  //#region "Search WorkAreas"

  workAreaSearch() {
    //this.isLoading = true;
    this.contentLoading = true;

    let searchTerm: any;
    searchTerm = encodeURIComponent(this.searchInput.nativeElement.value);
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      userId: '',
      searchTerm: searchTerm,
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'dateCreated,desc',
    };

    if (searchTerm.trim().length > 0) {
      this.apiWorkareasService.workAreasSearch(params).subscribe(
        (result: any) => {
          if (result.content.length > 0) {
            this.dataSource = result.content;
            this.paginatorConfig.length = result.totalElements;
          } else {
            // empty results
            this.dataSource = [];
            this.paginatorConfig.length = 0;
            this.displayDetails = false;
          }
          this.contentLoading = false;
        },
        // error
        (msg) => {
          console.log(msg);
          this.isLoading = false;
          this.contentLoading = false;
          this.dataSource = [];
        }
      );
    } else if (searchTerm.trim().length == 0) {
      this.contentLoading = false;
      this.resetShowDetails();
      this.getData();
    }
  }

  //#endregion

  actions() {}

  preview(element: any) {}

  loadDetails(element: any) {
    if (
      (
        this.roles.isAdmin ||
        this.rolesService.systemAdmin(this.roles.systemRoles) ||
        this.rolesService.supervisor(this.roles.systemRoles)) &&
      (!this.selectedItem || element.id != this.selectedItem.id)
    ) {
      this.displayDetails = true;
      this.detailsLoading = true;
      this.displayedColumns = this.reducedColumns;
      this.selectedItem = element;
      this.activeRowId = element.id;
      this.getUsers();
      this.getRoles();

      //sites are the same and they load from the selected element
      this.dataSourceSites = [];
      this.dataSourceSites.push(element.owner);
      this.dataSourceSites.push(element.siteSupplier);
    }
  }

  /**
   * resets the persisting data from showing the asset details
   */
  resetShowDetails() {
    this.activeRowId = '';
    this.displayDetails = false;
    this.selectedItem = {};
    this.detailsTab = 0;
    this.displayedColumns = this.fullColumns;
  }

  /**
   * deactivate or enable the selected element from the list
   * @param element selected element to be deactivated or enabled
   */
  deactivateEnableWorkArea(element: any) {
    const params = {
      tenantId: '',
      workAreaId: element.id,
      enabled: element.deleted ? false : true,
    };
    const properties = {
      name: 'update_workareas',
      action: element.action,
    };

    this.apiWorkareasService.deactivateEnableWorkArea(params).subscribe(
      (result) => {
        const message = !params.enabled
          ? 'WorkArea disabled'
          : 'WorkArea enabled';
        console.log(message);
        this.snackBar.open(message, '', { duration: 3000 });

        // sends an update
        this.componentsCommunication.setUpdateWorkAreas(properties);
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          const message = !params.enabled
            ? 'WorkArea disabled'
            : 'WorkArea enabled';
          console.log(message);
          this.snackBar.open(message, '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateWorkAreas(properties);
        }
        console.log(msg.statusText);
      }
    );
  }

  editUser(element: any) {}

  editWorkArea(element: any) {
    element.action = 'edit';
    const dialogRef = this.dialog.open(EditWorkAreaDialogComponent, {
      minWidth: '410px',
      disableClose: true,
      autoFocus: false,
      data: element,
    });
  }

  getRoles() {
    this.isLoadingRoles = true;
    this.dataSourceRoles = [];
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      workareaId: this.selectedItem.id,
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
    };

    this.apiWorkareasService.getRoles(params).subscribe(
      (result: any) => {
        if (result.length > 0) {
          this.dataSourceRoles = result;
        }
        this.isLoadingRoles = false;
      },
      // error
      (msg) => {
        console.log('error retrieving the list ' + msg);
        this.isLoadingRoles = false;
      }
    );
  }

  confirmation(action: string, elem?: any) {
    elem.action = action;
    elem.confirmation = false;
    if (action == 'disable') {
      const message = !elem.deleted
        ? 'Disable the selected Workarea?'
        : 'Enable the selected Workarea?';
      elem.confirmationString = message;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data.confirmation) {
        this.deactivateEnableWorkArea(elem);
      }
    });
  }

  tabChanged(tabChangeEvent: any) {
    this.detailsTab = tabChangeEvent.index;
  }

  /**
   * handles the pagination ant the update of the results
   * @param evt click event
   */
  handlePage(evt: any) {
    //this.displayDetails = false;
    this.paginatorConfig.pageIndex = evt.pageIndex;
    this.paginatorConfig.pageSize = evt.pageSize;
    //triggers the search after updating the pagination
    this.getData();
  }
}
