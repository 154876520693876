<h4> {{element?.name}} 
    <span class="required_item" *ngIf="element?.required"> *</span>
</h4>
<mat-form-field [ngClass]="{'invalid ng-invalid mat-form-field-invalid': invalid}" class="filter-type" appearance="fill">    
    <input matInput
    type="datetime"
    [matDatepicker]="picker"
    [value]="prefilledValue"
    (dateInput)="selectValue($event.target.value, element)"
    [required]="element.required" 
    [disabled]="element?.readOnly"
    (keyup)="valueChange($event.target.value)">
    
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>