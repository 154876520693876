<div class="dialogform-container">
  <div class="v-window-header">
    <span>Edit Service - {{dialogData.serviceName}}</span>
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>
    <mat-spinner *ngIf="isLoading"></mat-spinner>

    <mat-card-content *ngIf="!isLoading">        

        <div *ngIf="dialogData.serviceName != 'UT'" mat-dialog-content>
            <div  class="columns" *ngFor="let element of elements; let i = 'index+1'">
                
                <div class="row four-columns" *ngFor="let elem of element; let i = 'index+1'">

                    <select-elem-services-results class="full-width"
                    *ngIf="elem?.datapointCatalog?.datapointType?.name == 'Dropdown'"
                    (elementEvent)="addSelectValue($event, elem)"
                    [element]="elem"></select-elem-services-results>

                    <input-services-results class="full-width"
                    *ngIf="elem?.datapointCatalog?.datapointType?.name == 'Text'"
                    (elementEvent)="addSelectValue($event, elem)"
                    [element]="elem"></input-services-results>
                 
                </div>
            </div>
        </div>

        <div *ngIf="dialogData.serviceName == 'UT'" mat-dialog-content class="edit-ut-service display-block">
            <div class="rows" *ngFor="let element of elements; let i = 'index+1'">

              <div *ngIf="i == 2" class="row-title">
                <h3>Indicate equipment checks which have been completed</h3>
              </div>
              <br>
                <div class="row" *ngFor="let elem of element">

                    <select-elem-services-results class="full-width"
                    *ngIf="elem?.datapointCatalog?.datapointType?.name == 'Dropdown'"
                    (elementEvent)="addSelectValue($event, elem)"
                    [element]="elem"></select-elem-services-results>

                    <input-services-results class="full-width"
                    *ngIf="elem?.datapointCatalog?.datapointType?.name == 'Text'"
                    (elementEvent)="addSelectValue($event, elem)"
                    [element]="elem"></input-services-results>
                 
                </div>
            </div>

            <h4>Probes</h4>
            <mat-toolbar color="primary" class="results-toolbar">

              <button mat-raised-button (click)="addNew('create')" class="last right search-menu-button custom-button green" color="primary">
                <mat-icon class="icon">add</mat-icon>
              </button>
  
          </mat-toolbar>
            <table mat-table *ngIf="!isLoading" [dataSource]="dataSource" class="mat-elevation-z8">
                <br>
          
                <!-- serialNumber Column -->
                <ng-container matColumnDef="serialNumber">
                  <th mat-header-cell *matHeaderCellDef>Serial Number</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.serialNumber}} </td>
                </ng-container>
          
                <!-- type Column -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef>Type</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.type}}
                  </td>
                </ng-container>
        
                <!-- angle Column -->
                <ng-container matColumnDef="angle">
                    <th mat-header-cell *matHeaderCellDef>Angle</th>
                    <td mat-cell *matCellDef="let element">
                      {{element.angle}}
                    </td>
                </ng-container>
                <!-- crystalSize Column -->
                <ng-container matColumnDef="crystalSize">
                  <th mat-header-cell *matHeaderCellDef>Crystal Size (mm)</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.crystalSize}} </td>
                </ng-container>
          
                <!-- frequency Column -->
                <ng-container matColumnDef="frequency">
                  <th mat-header-cell *matHeaderCellDef>Frequency (mHz)</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.frequency}}
                  </td>
                </ng-container>
        
                <!-- Reference Column -->
                <ng-container matColumnDef="referenceSensitivityLevel">
                    <th mat-header-cell *matHeaderCellDef>Reference Sensitivity Level (dB)</th>
                    <td mat-cell *matCellDef="let element">
                      {{element.referenceSensitivityLevel}}
                  </td>

                </ng-container>
                <!-- transferCorrection Column -->
                <ng-container matColumnDef="transferCorrection">
                  <th mat-header-cell *matHeaderCellDef>Transfer Correction (dB)</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.transferCorrection}} </td>
                </ng-container>

              <!-- scanning sensitivity Column -->
              <ng-container matColumnDef="scanningSensitivity">
                <th mat-header-cell *matHeaderCellDef>Scanning Sensitivity (dB)</th>
                <td mat-cell *matCellDef="let element">
                  {{element.scanningSensitivity}} </td>
              </ng-container>

                <!-- actions -->
                <ng-container matColumnDef="actions">
                  <th class="actions" mat-header-cell width ="40px" *matHeaderCellDef> </th>
                  <td class="actions" mat-cell width ="40px" *matCellDef="let element">
          
                    <!-- pdf list actions menu-->
                    <button class="user_menu custom" mat-raised-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
                      <mat-icon>expand_more</mat-icon>
                    </button>
          
                    <mat-menu class="menu" x-position="before" #menu="matMenu">
                       <button mat-menu-item (click)="editDetails(element)">
                      <mat-icon>edit</mat-icon>
                          Edit 
                      </button>            
                      <button mat-menu-item (click)="confirmation('delete', element)">
                          <mat-icon>delete</mat-icon>
                          Delete
                      </button>
        
                    </mat-menu>
          
                </td>
                </ng-container>
          
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>            

        </div>

        <div mat-dialog-actions>
    
        <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    