export class ModelCreateAssetConsumable {    
  id =  null;
  dateCreated =  '';
  createdBy =  'admin';
  lastUpdated =  '';
  updatedBy =  'admin';
  active =  true;
  description = '';
  dateCommission = '';
  dateDecommission = '';
  dateDisposal =  '';
  assetType = {id: null};
  owner = {id: 'admin'};
  controller = {};
  dateManufacture = '';
  manufacturer = {};
  consumableType = {};
  type = 2;
}
