<div class="dialogform-container-files ">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <div class="dialogform-container" *ngIf="!isLoading">
        <div class="v-window-header">            
            <span *ngIf="dialogData?.id && dialogData?.action == 'edit'">Upload </span>
            <span *ngIf="dialogData?.id && dialogData?.type == 'avatar'">avatar - {{dialogData.user.firstName}} {{dialogData.user.lastName}}</span>
            <span *ngIf="dialogData?.id && dialogData?.type == 'signature'">signature - {{dialogData.user.firstName}} {{dialogData.user.lastName}}</span>
            <span *ngIf="dialogData?.type == 'sitelogo'">Logo - {{dialogData.companyTitle}}</span>

            <div [mat-dialog-close]="true" class="v-window-closebox">
                <mat-icon class="icon action">close</mat-icon>
            </div>
        </div>
  
        <mat-form-field>
            <button  (click)="input.click()" class="blue" mat-raised-button matPrefix >
            Upload <mat-icon>attach_file</mat-icon>
            </button>
            <input (click)="input.click()" class="hidden-input" type="text" readonly matInput [formControl]="display" />
            <input 
            class="hidden-input"
            type="file"
            [accept]="acceptFormats"
            multiple="false"
            hidden
            #input    
            (change)="checkFile(input.files)"/>
    
        </mat-form-field>
          
        <img *ngIf="urlImage"
        class="preview" onContextMenu="return false;" [src]="urlImage" />
        <mat-icon *ngIf="!urlImage && dialogData.type == 'avatar'" class="default_avatar">person</mat-icon>
        <mat-icon *ngIf="!urlImage  && dialogData.type == 'signature'" class="default_avatar">history_edu</mat-icon>
        <mat-icon *ngIf="!urlImage  && dialogData.type == 'sitelogo'" class="default_avatar">perm_media</mat-icon>

    <div mat-dialog-actions>
            <button
             mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="upload();">
              Upload
              <mat-icon class="icon action">save</mat-icon>
            </button>       
            <button mat-raised-button color="primary" (click)="closePopup()">
                Close
                <mat-icon class="icon action">close</mat-icon>
            </button>
    </div>

    </div>
</div>