<mat-toolbar class="sites-toolbar">
  <button
    mat-raised-button
    (click)="userSearch()"
    class="green custom-button search"
  >
    <mat-icon class="icon">search</mat-icon>
  </button>

  <form class="form-search full-width">
    <mat-form-field
      class="example-full-width searchbar full-width"
      appearance="outline"
    >
      <mat-label>Enter search term</mat-label>
      <input
        #searchInput
        (keyup.enter)="userSearch()"
        matInput
        autocomplete="off"
      />
    </mat-form-field>
  </form>

  <button
    *ngIf="
      roles.isAdmin
    "
    mat-raised-button
    (click)="createUser()"
    class="last search-menu-button custom-button"
    color="primary"
  >
    <mat-icon class="icon">add</mat-icon>
  </button>
</mat-toolbar>

<mat-spinner *ngIf="contentLoading || isLoading"></mat-spinner>

<div class="content-container">
  <div
    class="custom-width"
    *ngIf="!contentLoading && dataSource?.length > 0"
    [ngClass]="{ 'full-width': displayDetails }"
  >
    <table
      *ngIf="displayDetails"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 full-width details-table"
    >
      <!-- date Column -->
      <ng-container matColumnDef="users">
        <th mat-header-cell *matHeaderCellDef>Users</th>
        <td
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          mat-cell
          *matCellDef="let element"
          (click)="loadDetails(element)"
        >
          {{ element?.user?.firstName }} {{ element?.user?.lastName }}
          <br />
          {{ element?.user?.email }}
        </td>
      </ng-container>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th width="50px" mat-header-cell *matHeaderCellDef>Active</th>
        <td
          width="50px"
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >        
          <span *ngIf="element?.deleted == false && element?.active">
            <mat-icon class="icon">done</mat-icon>
          </span>
          <span
            *ngIf="element?.deleted || element?.active == null"
          >
            <mat-icon class="icon">close</mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell class="last" width="40px" *matHeaderCellDef>
          <button
            *ngIf="              
              displayDetails == true &&
              detailsLoading == false &&
              contentLoading == false
            "
            mat-raised-button
            (click)="resetShowDetails()"
            class="right hide-details search-menu-button custom-button blue"
            color="primary"
          >
            <mat-icon class="icon">arrow_forward</mat-icon>
          </button>
        </th>
        <td
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          mat-cell
          class="last"
          width="40px"
          *matCellDef="let element"
        >
          <!-- actions menu-->
          <button
            *ngIf="
              roles.isAdmin ||
                (currentUser == element?.user?.id)
            "
            class="user_menu custom"
            mat-button
            [mat-menu-trigger-for]="menu"
            fxHide="false"
            fxHide.gt-sm
          >
            <mat-icon>expand_more</mat-icon>
          </button>

          <mat-menu class="menu" x-position="before" #menu="matMenu">
            <button
              *ngIf="
                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles) ||
                (currentUser == element?.user?.id)
              "
              mat-menu-item
              (click)="editSelected(element)"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              (click)="editImage(element, 'avatar')"
            >
              <mat-icon>account_box</mat-icon>
              Avatar
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              (click)="editImage(element, 'signature')"
            >
              <mat-icon>edit_document</mat-icon>
              Signature
            </button>
            <button
              *ngIf="
                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles)
              "
              mat-menu-item
              (click)="editUserSites(element, 'add')"
            >
              <mat-icon>hub</mat-icon>
              Sites
            </button>
            <button
              *ngIf="
                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles)
              "
              mat-menu-item
              (click)="editUserWorkareas(element, 'create')"
            >
              <mat-icon>engineering</mat-icon>
              Workarea
            </button>
            <button
              *ngIf="
                (
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                (element?.active || element.active) && 
                element?.privilege?.name != 'evolve-sys-admin'
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>delete</mat-icon>Disable
            </button>
            <button
              *ngIf="
                (
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                ((!element?.active && !element?.active) || !element.active)
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>recycling</mat-icon>Enable
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              [disabled]="resetPassClicked"
              (click)="resetPassword(element)"
            >
              <mat-icon>lock_reset</mat-icon>
              Reset Password
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table
      *ngIf="displayDetails == false"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 full-width details-table"
    >

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.user?.displayName">
            {{ element?.user?.displayName }}</span
          >
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.user?.email"> {{ element?.user?.email }}</span>
        </td>
      </ng-container>
      
      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.user?.id"> {{ element?.user?.id }}</span>
        </td>
      </ng-container>
      <!-- Role Column 
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.privilege?.name">
            {{ element?.privilege?.name }}
          </span>
        </td>
      </ng-container> -->

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.tenant?.name">
            {{ element?.tenant?.name }}
          </span>
        </td>
      </ng-container>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th width="50px" mat-header-cell *matHeaderCellDef>Active</th>
        <td
          width="50px"
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
        <span *ngIf="element?.deleted == false && element?.active">
          <mat-icon class="icon">done</mat-icon>
        </span>
        <span
          *ngIf="element?.deleted || element?.active == null"
        >
          <mat-icon class="icon">close</mat-icon>
        </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell class="last" width="40px" *matHeaderCellDef></th>
        <td
          mat-cell
          class="last"
          width="40px"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          *matCellDef="let element"
        >
          <!--  actions menu-->
          <button
            *ngIf="

              roles.isAdmin ||
              rolesService.systemAdmin(roles.systemRoles) ||
              (currentUser == element?.user?.id)
            "
            class="user_menu custom"
            mat-button
            [mat-menu-trigger-for]="menu"
            fxHide="false"
            fxHide.gt-sm
          >
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu class="menu" x-position="before" #menu="matMenu">
            <button
              *ngIf="
                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles) ||
                (currentUser == element?.user?.id)
              "
              mat-menu-item
              (click)="editSelected(element)"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              (click)="editImage(element, 'avatar')"
            >
              <mat-icon>account_box</mat-icon>
              Avatar
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              (click)="editImage(element, 'signature')"
            >
              <mat-icon>edit_document</mat-icon>
              Signature
            </button>
            <button
              *ngIf="

                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles)
              "
              mat-menu-item
              (click)="editUserSites(element, 'add')"
            >
              <mat-icon>hub</mat-icon>
              Sites
            </button>
            <button
              *ngIf="

                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles)
              "
              mat-menu-item
              (click)="editUserWorkareas(element, 'create')"
            >
              <mat-icon>engineering</mat-icon>
              Workarea
            </button>
            <button
              *ngIf="
                (
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                (element?.active || element.active) && 
                element?.privilege?.name != 'evolve-sys-admin'
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>delete</mat-icon>Disable
            </button>
            <button
              *ngIf="
                (
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                ((!element?.active && !element?.active) || !element.active) 
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>recycling</mat-icon>Enable
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              [disabled]="resetPassClicked"
              (click)="resetPassword(element)"
            >
              <mat-icon>lock_reset</mat-icon>
              Reset Password
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      *ngIf="!contentLoading"
      (page)="handlePage($event)"
      [length]="paginatorConfig.length"
      [pageIndex]="paginatorConfig.pageIndex"
      [pageSize]="paginatorConfig.pageSize"
      [pageSizeOptions]="paginatorConfig.pageSizeOptions"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>

  <div
    class="no-results"
    *ngIf="dataSource?.length == 0 && !contentLoading && !isLoading"
  >
    <h1>No results to show...</h1>
  </div>

  <div
    *ngIf="displayDetails && !contentLoading && !isLoading"
    class="sidebar-container"
  >
    <h4
      class="item-title"
      *ngIf="
        !detailsLoading &&
        !selectedItem?.serialNumber &&
        !selectedItem?.consumableType
      "
    >
      <strong>
        <!--{{type2Elem?.consumableType?.name}}-->
      </strong>
    </h4>
    <h4
      class="item-title"
      *ngIf="displayDetails && selectedItem?.serialNumber && !contentLoading"
    >
      <strong>
        {{ selectedItem?.type }} - {{ selectedItem?.model }} -
        {{ selectedItem?.serialNumber }}
      </strong>
    </h4>

    <div class="my-tabs-container custom">
      <mat-tab-group
        animationDuration="0ms"
        (selectedTabChange)="tabChanged($event)"
        mat-stretch-tabs
        [selectedIndex]="detailsTab"
        class="example-stretched-tabs mat-elevation-z4 transparent"
        mat-stretch-tabs
      >
        <mat-tab>
          <ng-template mat-tab-label> Details </ng-template>
          <mat-spinner
            *ngIf="detailsLoading && !contentLoading && !isLoading"
          ></mat-spinner>
          <div class="details-container" *ngIf="!detailsLoading">
            <div class="item-details">
              <div>
                <strong>First Name: </strong>
                {{ selectedItem?.user?.firstName }}
              </div>
              <div>
                <strong>Last Name: </strong> {{ selectedItem?.user?.lastName }}
              </div>
              <div>
                <strong>Username: </strong>
                {{ selectedItem?.user?.id }}
              </div>
              <div><strong>Email: </strong>{{ selectedItem?.user?.email }}</div>
              <div></div>
              <br/>
              <br/>
              <div>
                <strong>Qualification: </strong
                >{{ selectedItem?.qualification }}
              </div>
              <div>
                <strong>Job Title: </strong>{{ selectedItem?.jobTitle }}
              </div>
              <div>
                <strong>Location: </strong>{{ selectedItem?.tenant?.companyTitle }}
              </div>
              <div></div>
              <br />
            </div>
            <div class="item-preview">
              <!--*ngIf="selectedItem?.urlAttachment"-->
              <div class="avatar_container">
                <h4>Avatar</h4>
                <mat-icon
                  *ngIf="!selectedItem?.urlAvatar?.fileString"
                  class="default_avatar"
                  >person</mat-icon
                >
                <img
                  *ngIf="selectedItem?.urlAvatar"
                  class="preview"
                  (click)="preview(selectedItem)"
                  onContextMenu="return false;"
                  [src]="
                    'data:' +
                    selectedItem.urlAvatar.type +
                    ';base64,' +
                    selectedItem.urlAvatar.fileString
                  "
                />
              </div>

              <!--[src]="'data:image/'+ selectedItem.typeFile +';base64,' + selectedItem.urlAttachment" -->
              <div class="avatar_container">
                <h4>Signature</h4>
                <mat-icon
                  *ngIf="!selectedItem?.urlSignature?.fileString"
                  class="default_avatar"
                  >history_edu</mat-icon
                >
                <img
                  *ngIf="selectedItem?.urlSignature?.fileString"
                  class="preview"
                  (click)="preview(selectedItem)"
                  onContextMenu="return false;"
                  [src]="
                    'data:' +
                    selectedItem.urlSignature.type +
                    ';base64,' +
                    selectedItem.urlSignature.fileString
                  "
                />
              </div>

            </div>

          </div>
        </mat-tab>

        <mat-tab 
        *ngIf="roles.isAdmin ||
        rolesService.systemAdmin(roles.systemRoles)">
          <ng-template mat-tab-label> Sites </ng-template>
          <mat-spinner
            *ngIf="isLoadingSites && !contentLoading && !isLoading"
          ></mat-spinner>
          <div class="details-container" *ngIf="!isLoadingSites">
            <br />

            <div
              class="no-results"
              *ngIf="
                dataSourceSites &&
                dataSourceSites?.length == 0 &&
                !isLoadingSites &&
                !isLoading
              "
            >
              <h1>No results to show...</h1>
            </div>

            <table
              mat-table
              *ngIf="dataSourceSites && dataSourceSites?.length > 0"
              [dataSource]="dataSourceSites"
              class="mat-elevation-z8 full-width"
            >
              <!-- Title Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.tenantDto?.companyTitle">
                    {{ element?.tenantDto?.companyTitle }}</span
                  >
                </td>
              </ng-container>

              <!-- License Level Column -->
              <ng-container matColumnDef="licenseLevel">
                <th mat-header-cell *matHeaderCellDef>License Level</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.tenantDto?.licenseLevel?.name">
                    {{ element?.tenantDto?.licenseLevel?.name }}</span
                  >
                </td>
              </ng-container>

              <!-- role Column -->
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Roles</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.moduleRoleDtos[0]?.displayName">
                    <span *ngFor="let role of element?.moduleRoleDtos">
                      {{ role.displayName }}
                    <br/></span>
                  </span>
                </td>
              </ng-container>

              <!-- Telephone Column -->
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>Telephone</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.tenantDto?.telephone">
                    {{ element?.tenantDto?.telephone }}
                  </span>
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th
                  mat-header-cell
                  class="last"
                  width="40px"
                  *matHeaderCellDef
                ></th>
                <td
                  mat-cell
                  class="last"
                  width="40px"
                  *matCellDef="let element"
                >
                  <!-- list actions menu   && (!element.mainLocation && element.moduleRoleDtos.length>0)  -->
                  <button
                    *ngIf="roles.isAdmin && ((element.mainLocation && element.moduleRoleDtos.length > 1) || (!element.mainLocation))"
                    class="user_menu custom"
                    mat-button
                    [mat-menu-trigger-for]="menu"
                    fxHide="false"
                    fxHide.gt-sm
                  >
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  <mat-menu class="menu" x-position="before" #menu="matMenu">
                    <button
                      *ngIf="roles.isAdmin"
                      mat-menu-item
                      (click)="deleteRole(element, 'delete_siterole')"
                    >
                      <mat-icon>delete</mat-icon>
                      Delete
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSites"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsSites"
              ></tr>
            </table>
          </div>
        </mat-tab>

        <mat-tab
        *ngIf="roles.isAdmin ||
        rolesService.systemAdmin(roles.systemRoles)">
          <ng-template mat-tab-label> Work Areas </ng-template>
          <mat-spinner
            *ngIf="isLoadingWorkareas && !contentLoading && !isLoading"
          ></mat-spinner>
          <div class="details-container" *ngIf="!isLoadingWorkareas">
            <br />

            <div
              class="no-results"
              *ngIf="
                dataSourceWorkareas &&
                dataSourceWorkareas?.length == 0 &&
                !isLoadingWorkareas &&
                !isLoading
              "
            >
              <h1>No results to show...</h1>
            </div>

            <table
              mat-table
              *ngIf="dataSourceWorkareas && dataSourceWorkareas?.length > 0"
              [dataSource]="dataSourceWorkareas"
              class="mat-elevation-z8 full-width"
            >
              <!-- title Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.workAreaDto?.name">
                    {{ element?.workAreaDto?.name }}</span
                  >
                </td>
              </ng-container>

              <!-- Site Column -->
              <ng-container matColumnDef="site">
                <th mat-header-cell *matHeaderCellDef>Client</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.workAreaDto?.tenantDto?.name">
                    {{ element?.workAreaDto?.tenantDto?.name }}</span
                  >
                </td>
              </ng-container>

              <!-- Site Supplier Column -->
              <ng-container matColumnDef="siteSupplier">
                <th mat-header-cell *matHeaderCellDef>Supplier</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.workAreaDto?.siteSupplier?.name">
                    {{ element?.workAreaDto?.siteSupplier?.name }}</span
                  >
                </td>
              </ng-container>

              <!-- Role Column -->
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Roles</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.moduleRoleDtos[0]?.displayName">
                    <span *ngFor="let role of element?.moduleRoleDtos">
                      {{ role.displayName }}<br
                    /></span>
                  </span>
                </td>
              </ng-container>

              <!-- Active Column -->
              <ng-container matColumnDef="active">
                <th width="50px" mat-header-cell *matHeaderCellDef>Active</th>
                <td
                  width="50px"
                  mat-cell
                  class="tddescription"
                  *matCellDef="let element"
                >
                  <span *ngIf="element?.deleted && element.deleted">
                    <mat-icon class="icon">close</mat-icon>
                  </span>
                  <span
                    *ngIf="
                      !element?.deleted ||
                      (element?.deleted && !element.deleted)
                    "
                  >
                    <mat-icon class="icon">done</mat-icon>
                  </span>
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th
                  mat-header-cell
                  class="last"
                  width="40px"
                  *matHeaderCellDef
                ></th>
                <td
                  mat-cell
                  class="last"
                  width="40px"
                  [ngClass]="{
                    'active-row':
                      element?.id == activeRowId && activeRowId.length > 0
                  }"
                  *matCellDef="let element"
                >
                  <!-- pdf list actions menu-->
                  <button
                    *ngIf="roles.isAdmin"
                    class="user_menu custom"
                    mat-button
                    [mat-menu-trigger-for]="menu"
                    fxHide="false"
                    fxHide.gt-sm
                  >
                <mat-icon>expand_more</mat-icon>
                  </button>
                  <mat-menu class="menu" x-position="before" #menu="matMenu">
                      <!--  <button
                      *ngIf="roles.isAdmin"
                      mat-menu-item
                      (click)="editUserWorkareas(element, 'edit')"
                    >
                      <mat-icon>edit</mat-icon>
                      Edit
                    </button>-->
                    <button
                      *ngIf="roles.isAdmin"
                      mat-menu-item
                      (click)="deleteRole(element, 'delete_workarearole')"
                    >
                      <mat-icon>delete</mat-icon>Delete
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsWorkareas"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsWorkareas"
              ></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab
        *ngIf="roles.isAdmin ||
        rolesService.systemAdmin(roles.systemRoles)">
          <ng-template mat-tab-label> Roles </ng-template>
          <mat-spinner
            *ngIf="isLoadingRoles && !contentLoading && !isLoading"
          ></mat-spinner>
          <div class="details-container" *ngIf="!isLoadingRoles">
            <br />

            <div
              class="no-results"
              *ngIf="
                dataSourceRoles &&
                dataSourceRoles?.length == 0 &&
                !isLoadingRoles &&
                !isLoading
              "
            >
              <h1>No results to show...</h1>
            </div>

            <table
              mat-table
              *ngIf="dataSourceRoles && dataSourceRoles?.length > 0"
              [dataSource]="dataSourceRoles"
              class="mat-elevation-z8 full-width"
            >
              <!-- Role Column -->
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Roles</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.role">{{ element?.role }}</span>
                </td>
              </ng-container>

              <!-- Sites Column -->
              <ng-container matColumnDef="tenants">
                <th mat-header-cell *matHeaderCellDef>Sites</th>
                <td mat-cell *matCellDef="let element">
                  <div *ngFor="let site of element?.tenants; let i = index">
                    <span>{{ site }}</span>
                  </div>
                </td>
              </ng-container>

              <!-- Workareas Column -->
              <ng-container matColumnDef="workAreas">
                <th mat-header-cell *matHeaderCellDef>Workareas</th>
                <td class="align-left" mat-cell *matCellDef="let element">
                  <div
                    *ngFor="let workarea of element?.workAreas; let i = index"
                  >
                    <span>{{ workarea }}</span>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsRoles"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsRoles"
              ></tr>
            </table>
          </div>
        </mat-tab
        >
      </mat-tab-group>
    </div>
  </div>
</div>
