import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ReleaseToQcDialogComponent } from '../../dialogs/release-to-qc-dialog/release-to-qc-dialog.component';
import { RolesService } from 'src/app/services/roles.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'create-report-details',
  templateUrl: './create-report-details.component.html',
  styleUrls: ['./create-report-details.component.scss']
})
export class CreateReportDetailsComponent {
  formDataSubscription!: Subscription;
  loggedUserName = 'admin';
  selectedTabIndex = 0;
  validForm = true;
  activeTab = 1;
  showActions = true;
  formData: any;
  isLoading = false;
  taskId: any;
  roles = {
    isAdmin: false,
    imReader: false,
    systemRoles: [],
    allRoles: [],
    tenantRoles: []
  };
  workareaRoles = {
    workareaAdmin: false,
    systemRoles: [],
    allWorkareaRoles: [],
    currentWrkareaRoles: []
  };
  constructor(
    public rolesService: RolesService,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private componentsCommunication: ComponentsCommunicationService){}

  ngOnInit(){    
    this.taskId = localStorage.getItem('taskId'); 
    if (this.taskId){
      this.isLoading = true;
      this.authService.getRoles([]).subscribe(
        (data: any) => {
          if (data && data.length > 0 &&  data[0].tenantId === null && this.rolesService.systemAdmin(data[0].roles)){
            this.roles.isAdmin = true;      
          }
          if (data && data.length > 1){
            this.roles.systemRoles = data[0].roles;
            this.roles.allRoles = data.slice(1);
          } 
      },
      error => {      
        console.log(error + 'roles can\'t be retrieved');
      });


      this.authService.getWorkareaRoles([]).subscribe(
        (data: any) => {
          if (data && data.length > 0 &&  data[0].tenantId === null && this.rolesService.systemAdmin(data[0].roles)){
            this.roles.isAdmin = true;      
          }
          if (data && data.length > 1){
            this.workareaRoles.systemRoles = data[0].roles;
            this.workareaRoles.allWorkareaRoles = data.slice(1);
          } 
          this.isLoading = false;
      },
      error => {      
        console.log(error + 'roles can\'t be retrieved');
        this.isLoading = false;
      });

      this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {
        // updates the reports list
        if(data && data.id) {
          this.formData = {...data};
          localStorage.setItem('taskWorkareaId', data.workArea.id);
        } 
      });    
    } else {
      console.log('no task Id detected');
      this.isLoading = false;
    }
  }

  ngOnDestroy () {
    localStorage.setItem('taskId','');
    localStorage.setItem('taskWorkareaId', '');  
    this.componentsCommunication.setFormData([]);  
    if (this.formDataSubscription) {
      this.formDataSubscription.unsubscribe();    
    }
    
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.activeTab = tabChangeEvent.index + 1;
    this.showActions = this.activeTab == 1 || this.activeTab == 5 ? true : false;
    if (this.activeTab === 1 ) {
    // do stuff for page 1
    }
  }
  
  releaseToQC(){    
    this.formData.origin = 'details';
    delete this.formData.action;
    this.dialog.open(ReleaseToQcDialogComponent, {
      height: '95vh',
      width: '98%',
      disableClose: true,
      autoFocus: false,
      data: this.formData
    });
  }

  saveChanges(): void {

    if (this.activeTab == 1 || this.activeTab == 5) {
      //**
      //**********TODO WORKAROUND WORKORDER IS EXPECTING AND OBJECT */
      this.formData.workOrder = null;
      const tenantId = localStorage.getItem('taskTenantId');   
      const workAreaId = localStorage.getItem('taskWorkareaId');
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        workareaId: workAreaId && workAreaId.length > 0 ? workAreaId : '',
        taskId: localStorage.getItem('taskId'),
        body: this.formData        
      }
    
      this.apiService.saveTaskDetails(params).subscribe(
        (result) => {
          console.log( 'data was saved ');
          this.snackBar.open( 'data was saved', '', {duration: 3000});
      },
        // error
        (msg) => {
          if (msg.status == '200' || msg.status == '201') {
            console.log( 'data was saved');
            this.snackBar.open( 'data was saved ', '', {duration: 3000});
          }
          console.log( msg.statusText);
      }); 

    }

  }

}
