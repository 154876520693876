import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ModelDefectAttachment } from 'src/app/models/model.defect.attachment';
export interface DialogData {
  data: any;
  assetType: string;
  name: string;
  id: string;
  action: string;
}
@Component({
  selector: 'app-defect-attachment',
  templateUrl: './defect-attachment.component.html',
  styleUrls: ['./defect-attachment.component.scss']
})
export class DefectAttachmentComponent implements OnInit {
  fileToUpload: any;
  prefilledName = '';
  prefilledDescription = '';
  display: FormControl = new FormControl("", Validators.required);
  enableUpload = false;
  newModelDefectAttachment: any = new ModelDefectAttachment;
  name: string;
  description: string;
  action: string;
  isLoading = false;
  constructor(
    private dialogRef: MatDialogRef<DefectAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private apiFilesService: ApiFilesService
    ){}
  
  ngOnInit(){    
    if (this.dialogData.action == 'edit') {
      this.prefilledName = this.dialogData.data.name;
      this.prefilledDescription = this.dialogData.data.description;
      this.action = this.dialogData.action;
    }

  }  

  checkFile(files: any): void {
    
    if (files.length) {
      this.fileToUpload = files[0];
      this.validateFormdata();
      this.display.patchValue(`${this.fileToUpload.name}`);
    } else {
      this.display.patchValue('');
      this.validateFormdata();
    }
  }

  uploadFile(){
    this.isLoading = true;
    this.enableUpload = false;
    const formData = new FormData();  
    formData.append('file', this.fileToUpload); 

    this.newModelDefectAttachment.taskDefectsDto.id = this.dialogData.data.id;
    this.newModelDefectAttachment.description = this.description;
    this.newModelDefectAttachment.createdBy = localStorage.getItem('username');
    this.newModelDefectAttachment.updatedBy = localStorage.getItem('username');
    formData.append('defectsAttachment', new Blob([
      JSON.stringify(this.newModelDefectAttachment)
    ], {
      type: "application/json"
    }));
    const taskId = localStorage.getItem('taskId');
    const assetId = this.dialogData.data.id;
    this.apiFilesService.uploadDefectAttachment(formData, assetId, taskId).subscribe(
      (result) => {

        console.log( 'attachment uploaded');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open( 'attachment uploaded ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_defect_attachments');        
        this.componentsCommunication.setUpdateList('update_defects');
    },
      // error
      (msg) => {
  
        if (msg.status == '200') {
          console.log( 'attachment uploaded');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open( 'attachment uploaded ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_defect_attachments');
          this.componentsCommunication.setUpdateList('update_defects');
        }
        console.log( msg.statusText);
    });

  }

  closePopup(): void {
    this.dialogRef.close();
  }

  updateFile(){
    this.isLoading = true;
    this.newModelDefectAttachment.taskDefectsDto.id = this.dialogData.data.id;
    this.newModelDefectAttachment.description = this.description;
    this.newModelDefectAttachment.createdBy = localStorage.getItem('username');
    this.newModelDefectAttachment.updatedBy = localStorage.getItem('username');
    this.newModelDefectAttachment.name = this.dialogData.data.name;
    this.newModelDefectAttachment.type = this.dialogData.data.type;
    
    const attachId = this.dialogData.data.id;
    this.apiFilesService.updateDefectAttachment(this.newModelDefectAttachment, attachId).subscribe(
      (result) => {
        this.isLoading = false;
        console.log( 'attachment updated');
        this.closePopup();
        this.snackBar.open( 'attachment updated ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_defect_attachments');
        this.componentsCommunication.setUpdateList('update_defects');
    },
      // error
      (msg) => {
        this.isLoading = false;
        if (msg.status == '200') {
          console.log( 'attachment updated');
          this.closePopup();
          this.snackBar.open( 'attachment updated ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_defect_attachments');
          this.componentsCommunication.setUpdateList('update_defects');
        }
        console.log( msg.statusText);
    });
    
  }

  validateFormdata(){
    if (this.dialogData.action == 'upload') {
      if ( this.fileToUpload) {
        this.enableUpload = true;
      } else {
        this.enableUpload = false;
      }
    } else if (this.dialogData.action == 'edit') {
      this.enableUpload = true;
      if ((this.name && this.name.trim().length > 0 && this.name != this.prefilledName) || 
      (this.description && this.description.trim().length > 0 && this.description != this.prefilledDescription)) {
        this.enableUpload = false;
      }
    }
  }
}
