export class ModelDefectAttachment {
    id = null;
    taskDefectsDto = {};
    name = '';
    description = '';
    type = null;
    hash = null;
    isDeleted = false;
    createdBy = true;
    dateCreated = null;
    lastUpdated = null;
    updatedBy = ''; 
}
