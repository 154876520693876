import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EditAssetDialogComponent } from '../edit-asset-dialog/edit-asset-dialog.component';
import { ModelEditService } from 'src/app/models/model.edit.service';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AddUTProbeComponent } from './add-utprobe/add-utprobe.component';
import { Subscription } from 'rxjs';

export interface DialogData {
  serviceName: string;
  serviceId: string;
  service: any;
  element:any;
  tenantId: string;
}

@Component({
  selector: 'edit-service-dialog',
  templateUrl: './edit-service-dialog.component.html',
  styleUrls: ['./edit-service-dialog.component.scss']
})
export class EditServiceDialogComponent {
  getUTProbesUpdate!: Subscription;
  isLoading = false;
  serviceForm: any;
  validForm = false;
  requiredServicesFields: any = new ModelEditService;
  newServiceForm: any;
  elements: any = [];
  idProcess: any;
  taskId: any;
  serviceId: any;
  serviceSaveFormBody: any;
  allFormFields: any;
  dataSource: any;
  displayedColumns = ['serialNumber', 'type', 'angle', 'crystalSize', 'frequency', 'referenceSensitivityLevel',
   'transferCorrection', 'scanningSensitivity', 'actions'];
constructor(
  private dialog: MatDialog,
  private componentsCommunication: ComponentsCommunicationService,
  private snackBar: MatSnackBar,
  private apiServicesService: ApiServicesService,
  public dialogRef: MatDialogRef<EditAssetDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public dialogData: DialogData){}

  ngOnInit() {
    this.isLoading = true;
    const tenantId = this.dialogData.tenantId;   
    localStorage.setItem('serviceName', this.dialogData.serviceName);
    localStorage.setItem('serviceId', this.dialogData.serviceId);

    this.getUTProbesUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the list
      if(name == 'update_utprobes') {
        this.getUTProbes();
      }
    }); 
    const workareaId = localStorage.getItem('taskWorkareaId');  
    const param = {
      workareaId: workareaId ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: localStorage.getItem('taskId'),      
      serviceId: this.dialogData.serviceId
    }
    //Get SERVICES DataPoint Values of one Task and Service
      //api/tenant/task/datapointValue/{tenantId}/{taskId}/getServicesValues/{serviceId}
    this.apiServicesService.getServicesDatapointValues(param).subscribe(
      (result: any) => {
        this.serviceSaveFormBody = result;
        if (this.dialogData.serviceName != 'UT' && result && result?.length > 0) {
          result.forEach((elem:any, index:any) => {
            for (let i = 1; i < 5; i++){
              if (elem?.datapointCatalog?.place == 'col'+ i && elem?.datapointCatalog?.tab == 'service') {
                if(!this.elements[i-1]) {
                  this.elements[i-1] = [];
                }
                this.elements[i-1].push(elem);
              }         
            } 
          });
        }

        if (this.dialogData.serviceName == 'UT' && result && result?.length > 0) {
     
          result.forEach((elem:any, index:any) => {
            //stores the datapointValueId
            if (elem && elem.datapointCatalog && elem.datapointCatalog.dataType && elem.datapointCatalog.dataType == 'probes') {
              localStorage.setItem('datapointValueId', elem.id);
            }
            for (let i = 1; i < 5; i++){
              if (elem?.datapointCatalog?.place == 'col'+ i && elem?.datapointCatalog?.tab == 'service') {
                if(!this.elements[i-1]) {
                  this.elements[i-1] = [];
                }
                this.elements[i-1].push(elem);
              }         
            } 

          });
          // used to insert empty row and draw the requested title
          this.elements.splice(1, 0, []);
          localStorage.setItem('UTProbeId', result[result.length-1].id);
          //get utprobes
          this.getUTProbes();
        }

        this.isLoading = false;
      },
      // error
      (msg) => {
        this.isLoading = false;        
        console.log(msg.statusText);
    });
  }

  ngOnDestroy(){
    this.getUTProbesUpdate.unsubscribe();
  }

  getUTProbes(){
    const tenantId = this.dialogData.tenantId;   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      datapointValueId: localStorage.getItem('UTProbeId')  
    };
    
    this.apiServicesService.getDatapointCatalogUTProbes(params).subscribe(
      (result) => {
        this.dataSource = result;                              
    },
      // error
      (msg) => {
        console.log( msg);
    }); 
  }
  /**
 * stores the value
 * @param selectValue value that was selected to be stored
 * @param element selected element
 */
  addSelectValue(selectValue: any, element: any) {

    if (selectValue && selectValue.id) {
               
      this.serviceSaveFormBody.filter((item: any) => {
        if (item.datapointCatalog.flowableKey == element.datapointCatalog.flowableKey){
          if(item.id && !selectValue.type) {
            item.multiValue = [selectValue];
          } else if (selectValue.type && selectValue.type == 'values'){
            item.values = selectValue.value;
          }
        }
      });           
    }
    this.validateForm();
  
  }

  validateForm(){
    let validForm = true;
    let dataList = <any>this.serviceSaveFormBody;
    for (let data in dataList) {

      if (dataList[data].datapointCatalog.required) {
        let dataValue = dataList[data];
        let value = dataValue.values ? dataValue.values : dataValue.multiValue;
        if ((typeof value == 'string' || typeof value == 'number') && value.toString().trim().length == 0) {
          validForm = false;
          break;
        } else if (!value || (typeof value == 'object' && Object.keys(value).length == 0)){
          validForm = false;
          break;
        }
      }
    
    }
    this.validForm = validForm;
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveChanges(){
    this.isLoading = true;
    const tenantId = this.dialogData.tenantId;   
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      serviceId: this.dialogData.serviceId,
      taskId: localStorage.getItem('taskId'),
      body: this.serviceSaveFormBody
    };

    this.apiServicesService.saveEditedServiceDatapoints(params).subscribe(
      (result) => {
        console.log( 'services changes saved');
        this.componentsCommunication.setUpdateList('update_services');
        this.closePopup();
        this.snackBar.open( 'Changes were saved!', '', {duration: 3000});        
        this.isLoading = false;  
    },
      // error
      (msg) => {
        console.log( msg);
        this.isLoading = false;  
    }); 
  }

  
  editDetails (element: any) {
    this.addNew('update_utprobes', element);
  }

  confirmation(action: string, elem?: any) {    
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.deleteElem(elem);
        }
      }
    ); 
  }
  
  /**
   * deletes the selected service from the list
   * @param element selected element to be removed
   */
  deleteElem(element: any){
    const tenantId = this.dialogData.tenantId;   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',    
      hashName: element.hash,
      probeId: element.id
    }
  
    //api/task/observations/{tenantId}/deleteObservation/{obs_id} 
    this.apiServicesService.deleteUTProbe(params).subscribe(
      (result) => {
        console.log( 'Element deleted');
        this.snackBar.open( 'Element deleted ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_utprobes');
    },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log( 'Element deleted');
          this.snackBar.open( 'Element deleted ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_utprobes');
        }
        console.log( msg.statusText);
    }); 
    
  }

  addNew(action: string, element?: any) {
    const elem = action == 'update_utprobes' ? element : this.dialogData.element;
    const data = {      
      taskId: this.taskId,
      action: action,
      element: elem
    }
    
    this.dialog.open(AddUTProbeComponent, {
      height: 'auto',
      width: '60%',
      disableClose: true,
      autoFocus: false,
      data: data
    });
  }

}
