export class ModelCreateDefect{
    id = null;
    task = {};
    asset = {};
    repairArea = {};
    conditionDto = {};
    description = '';
    defectsAttachmentsDtoSet = null;
    active = true;
    isDeleted = false; 
    createdBy = '';
    updatedBy = ''; 
}
