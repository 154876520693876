import { NgModule  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { AngularMaterialModule } from './material/angular-material/angular-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { InventoryViewComponent } from './components/main/inventory-view/inventory-view.component';
import { ApiService } from './services/api.service';
import { ApiFilesService } from './services/api.files.service';
import { EditAssetDialogComponent } from './components/dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { TasksComponent } from './components/main/tasks/tasks.component';
import { CreateFormDialogComponent } from './components/dialogs/create-form-dialog/create-form-dialog.component';
import { ChiplistComponent } from './components/forms/form-components/chiplist/chiplist.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { InputComponent } from './components/forms/form-components/input/input.component';
import { SelectComponent } from './components/forms/form-components/select/select.component';
import { TextareaComponent } from './components/forms/form-components/textarea/textarea.component';
import { DatepickerComponent } from './components/forms/form-components/datepicker/datepicker.component';
import { MainHeaderbarComponent } from './components/main/main-headerbar/main-headerbar.component';
import { CreateReportDetailsComponent } from './components/forms/create-report-details/create-report-details.component';
import { ReportDetailsTabComponent } from './components/forms/create-report-details/report-details-tab/report-details-tab.component';
import { CreateReportServicesComponent } from './components/forms/create-report-services/create-report-services.component';
import { AttachmentsDialogComponent } from './components/dialogs/attachments-dialog/attachments-dialog.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from './services/auth.service';
import { CreateReportResultsComponent } from './components/forms/create-report-results/create-report-results.component';
import { CreateReportAttachmentsComponent } from './components/forms/create-report-attachments/create-report-attachments.component';
import { CreateReportCommentsComponent } from './components/forms/create-report-comments/create-report-comments.component';
import { CreateReportDefectsComponent } from './components/forms/create-report-defects/create-report-defects.component';
import { CreateReportRepairsComponent } from './components/forms/create-report-repairs/create-report-repairs.component';
import { CreateReportPersonnelComponent } from './components/forms/create-report-personnel/create-report-personnel.component';
import { CreateReportEquipmentComponent } from './components/forms/create-report-equipment/create-report-equipment.component';
import { CreateReportConsumablesComponent } from './components/forms/create-report-consumables/create-report-consumables.component';
import { CreateManualDefectComponent } from './components/forms/create-report-results/dialogs/create-manual-defect/create-manual-defect.component';
import { ApiServicesService } from './services/api.services.service';
import { ApiPersonnelService } from './services/api.personnel.service';
import { AddPersonnelComponent } from './components/forms/create-report-personnel/add-personnel/add-personnel.component';
import { AddConsumableComponent } from './components/forms/create-report-consumables/add-consumables/add-consumables.component';
import { AddEquipmentComponent } from './components/forms/create-report-equipment/add-equipment/add-equipment.component';
import { ApiEquipmentService } from './services/api.equipment.service';
import { ApiConsumablesService } from './services/api.consumables.service';
import { ApiDefectsService } from './services/api.defects.service';
import { ApiRepairsService } from './services/api.repairs.service';
import { ApiResultsService } from './services/api.results.service';
import { EditItemDetailsComponent } from './components/forms/create-report-results/dialogs/edit-item-details/edit-item-details.component';
import { DefectAttachmentComponent } from './components/forms/create-report-results/dialogs/defect-attachments/defect-attachment.component';
import { CreateRepairComponent } from './components/forms/create-report-results/dialogs/create-repair/create-repair.component';
import { EditResultServiceComponent } from './components/forms/create-report-results/dialogs/edit-result-service/edit-result-service.component';
import { ResultServiceAttachmentsComponent } from './components/forms/create-report-results/dialogs/edit-result-service/result-service-attachments/result-service-attachments.component';
import { ResultServiceObservationsComponent } from './components/forms/create-report-results/dialogs/edit-result-service/result-service-observations/result-service-observations.component';
import { SelectServicesResultsComponent } from './components/forms/form-components/select-services-results/select-elem-services-results.component';
import { ResultObservationsAttachmentsComponent } from './components/forms/create-report-results/dialogs/edit-result-service/dialogs/result-observations-attachments/result-observations-attachments';
import { InputServicesResultsComponent } from './components/forms/form-components/input-services-resuts/input-services-results.component';
import { AddUTProbeComponent } from './components/dialogs/edit-service-dialog/add-utprobe/add-utprobe.component';
import { EditServiceDialogComponent } from './components/dialogs/edit-service-dialog/edit-service-dialog.component';
import { ChiplistServicesResultsComponent } from './components/forms/form-components/chiplist-services-results/chiplist-services-results.component';
import { YesNoSelectServicesResultsComponent } from './components/forms/form-components/yesno-select-services-results/yesno-select-services-results.component';
import { EditVisualDialogComponent } from './components/forms/create-report-services/dialogs/edit-visual/edit-visual.component';
import { ReleaseToQcDialogComponent } from './components/dialogs/release-to-qc-dialog/release-to-qc-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FilePreviewDialogComponent } from './components/dialogs/file-preview-dialog/file-preview-dialog.component';
import { CopyTaskDialogComponent } from './components/dialogs/copy-task-dialog/copy-task-dialog.component';
import { SelectMeasurementsComponent } from './components/forms/form-components/select-measurements/select-measurements.component';
import { MySitesComponent } from './components/main/my-sites/my-sites.component';
import { MyUsersComponent } from './components/main/my-users/my-users.component';
import { EditUserDialogComponent } from './components/main/my-users/edit-user-dialog/edit-user-dialog.component';
import { AvatarsSignaturesDialogComponent } from './components/main/my-users/avatars-signatures-dialog/avatars-signatures-dialog.component';
import { EditSiteDialogComponent } from './components/main/my-sites/edit-site-dialog/edit-site-dialog.component';
import { MyWorkareasComponent } from './components/main/my-workareas/my-workareas.component';
import { EditWorkAreaDialogComponent } from './components/main/my-workareas/edit-workarea-dialog/edit-workarea-dialog.component';
import { EditUsersitesDialogComponent } from './components/main/my-users/edit-usersites-dialog/edit-usersites-dialog.component';
import { EditWorkareasDialogComponent } from './components/main/my-users/edit-workareas-dialog/edit-workareas-dialog.component';
import { EditUserworkareasDialogComponent } from './components/main/my-users/edit-userworkareas-dialog/edit-userworkareas-dialog.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { provideUserIdleConfig } from 'angular-user-idle';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    InventoryViewComponent,
    EditAssetDialogComponent,
    TasksComponent,
    CreateFormDialogComponent,
    ChiplistComponent,
    InputComponent,
    SelectComponent,
    InputServicesResultsComponent,
    SelectServicesResultsComponent,
    TextareaComponent,
    DatepickerComponent,
    CreateReportDetailsComponent,
    MainHeaderbarComponent,
    ReportDetailsTabComponent,
    CreateReportServicesComponent,
    AttachmentsDialogComponent,
    ConfirmationDialogComponent,
    CreateRepairComponent,
    DefectAttachmentComponent,
    CreateReportResultsComponent,
    CreateReportAttachmentsComponent,
    CreateReportCommentsComponent,
    CreateReportDefectsComponent,
    CreateReportRepairsComponent,
    CreateReportPersonnelComponent,
    CreateReportEquipmentComponent,
    CreateReportConsumablesComponent,
    CreateManualDefectComponent,
    ResultServiceAttachmentsComponent,
    ResultServiceObservationsComponent,
    EditItemDetailsComponent,
    EditResultServiceComponent,
    AddPersonnelComponent,
    AddEquipmentComponent,
    AddConsumableComponent,
    ResultObservationsAttachmentsComponent,
    AddUTProbeComponent,
    EditServiceDialogComponent,
    ChiplistServicesResultsComponent,
    YesNoSelectServicesResultsComponent,
    EditVisualDialogComponent,
    ReleaseToQcDialogComponent,
    FilePreviewDialogComponent,
    CopyTaskDialogComponent,
    SelectMeasurementsComponent,
    MySitesComponent,
    MyUsersComponent,
    EditUserDialogComponent,
    AvatarsSignaturesDialogComponent,
    EditSiteDialogComponent,
    MyWorkareasComponent,
    EditWorkAreaDialogComponent,
    EditUsersitesDialogComponent,
    EditWorkareasDialogComponent,
    EditUserworkareasDialogComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent
  ],
  imports: [
    PdfViewerModule,
    AngularMaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    TranslateModule.forRoot()
  ],
  providers: [
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    provideUserIdleConfig({ idle: 1800, timeout: 5, ping: 10 }),
    {provide : LocationStrategy , useClass: HashLocationStrategy}, // used to fix  404 error on refresh
    ApiService,ApiFilesService,AuthService, ApiServicesService, ApiPersonnelService,
    ApiEquipmentService, ApiConsumablesService, ApiDefectsService, ApiRepairsService,
    ApiResultsService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
