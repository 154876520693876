import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

export interface DialogData {
  taskId: string;
  tenantId: string;
  serviceId:string;
  serviceName:string;
}
@Component({
  selector: 'edit-visual',
  templateUrl: './edit-visual.component.html',
  styleUrls: ['./edit-visual.component.scss']
})
export class EditVisualDialogComponent {
  comments: string;
  prefilledComments: string;
  validForm = false;
  serviceSaveFormBody: any;
  isLoading = false;
  constructor(
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private apiServicesService: ApiServicesService,
    public dialogRef: MatDialogRef<EditVisualDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private dialog: MatDialog) {}

ngOnInit(){
  this.isLoading = true;
  const tenantId = this.dialogData.tenantId;   
  const workareaId = localStorage.getItem('taskWorkareaId');  
  const param = {
    workareaId: workareaId ? workareaId : '',
    tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
    taskId: this.dialogData.taskId,      
    serviceId: this.dialogData.serviceId
  }
    //Get SERVICES DataPoint Values of one Task and Service
      //api/tenant/task/datapointValue/{tenantId}/{taskId}/getServicesValues/{serviceId}
      this.apiServicesService.getServicesDatapointValues(param).subscribe(
        (result: any) => {
          this.serviceSaveFormBody = result;
          if (result && result?.length > 0 && result[0].values && result[0].values.length > 0 ) {
            this.prefilledComments =  this.serviceSaveFormBody[0].values;
          }
          this.isLoading = false;
        },
        // error
        (msg) => {
          this.isLoading = false;        
          console.log(msg.statusText);
      })

    }

    closePopup(): void {
      this.dialogRef.close(this.dialogData);
    }

    saveComments(){      
      this.isLoading = true;
      if (this.serviceSaveFormBody && this.serviceSaveFormBody.length > 0) {
        this.serviceSaveFormBody[0].values = this.comments;      
        this.serviceSaveFormBody[0].lastUpdated = <any>Date.now();
        const tenantId = this.dialogData.tenantId;   
        const workareaId = localStorage.getItem('taskWorkareaId');   
        const params = {
          workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
          tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
          serviceId: this.dialogData.serviceId,
          taskId: localStorage.getItem('taskId'),
          body: this.serviceSaveFormBody
        };
    
        this.apiServicesService.saveEditedServiceDatapoints(params).subscribe(
          (result) => {
            console.log( 'services saved');
            this.componentsCommunication.setUpdateList('update_services');
            this.closePopup();
            this.snackBar.open( 'Changes were saved!', '', {duration: 3000});       
            this.isLoading = false;   
        },
          // error
          (msg) => {
            this.isLoading = false;  
            console.log( msg);
        }); 
      }
    }

    validateFormdata(){
      this.validForm = false;
      if (this.comments.length > 0) {
        this.validForm = true;
      }
    }
}
