<div class="v-window-header">
    <span *ngIf="dialogData.action == 'create'">Create workarea</span>
    <span *ngIf="dialogData.action == 'edit'">Edit workarea - {{workAreaDetails.tenantDto.companyTitle}} - {{workAreaDetails.siteSupplier.companyTitle}}</span>
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
</div>

<mat-spinner *ngIf="isLoading"> loading...</mat-spinner>
  
<div class="dialogform-container" *ngIf="!isLoading">

    <div class="no-results" *ngIf="!isLoading && !workAreaDetails">
        <h1>No results to show...</h1>
    </div>
    
    <div mat-dialog-content *ngIf="!isLoading && workAreaDetails" >

        <div  class="row two-columns">
            <!-- Owner is the Client or Supplier -->
       
            <h4>Owner                                     
            </h4> 

            <mat-radio-group 
            [disabled] = "dialogData.action == 'edit'"
            [value]="defaultSelected"
            aria-labelledby="Owner"
            class="radio-group">
                <mat-radio-button class="radio-button" name="owner" 
                (change)="setOwner('client', $event)"            
                [value]="0">
                </mat-radio-button>
                <mat-radio-button class="radio-button" name="supplier"
                (change)="setOwner('supplier', $event)"            
                [value]="1">              
                </mat-radio-button>
    
            </mat-radio-group>            
        
        </div>
        
        <div  class="row-2 two-columns">
    
            <h4>Client                                                   
            </h4> 
            
            <mat-form-field class="filter-typefull-width" appearance="outline">
                <mat-select #selectInput [placeholder]="workAreaDetails.tenantDto.companyTitle" 
                [disabled] = "dialogData.action == 'edit'"
                [ngModel]="workAreaDetails.tenantDto.companyTitle">
                    <mat-option
                    *ngFor="let option of availableClients"
                    (onSelectionChange)="addClient($event, option)"                  
                    [value]="option">
                    {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <h4>Supplier                                                  
            </h4> 
            
            <mat-form-field class="filter-type  full-width" appearance="outline">
                <mat-select #selectInput [placeholder]="workAreaDetails.siteSupplier.companyTitle" 
                [disabled] = "dialogData.action == 'edit'"
                [ngModel]="workAreaDetails.siteSupplier.companyTitle">
                    <mat-option
                    *ngFor="let option of availableSuppliers"
                    (onSelectionChange)="addSupplier($event, option)"
                    [value]="option">
                    {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <section class="example-section">
                <mat-checkbox
                [ngModel]="workAreaDetails.clientAcceptance"
                [disabled] = "dialogData.action == 'create'"
                    (change)="setClientAcceptance($event)">Client Acceptance 
                </mat-checkbox>
            </section>     
        
        </div>
        
        <div mat-dialog-actions>
            <button
            *ngIf="dialogData.action == 'create'"
            mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
            </button>
            <button
            *ngIf="dialogData.action == 'edit'"
            mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="updateChanges();">
            Update
            <mat-icon class="icon action">save</mat-icon>
            </button>      
            <button mat-raised-button color="primary" (click)="closePopup()">
                Close
                <mat-icon class="icon action">close</mat-icon>
            </button>
        </div>

    </div>
</div>
  