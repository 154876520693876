import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, startWith, map, Subscription } from 'rxjs';
import { AttachmentsDialogComponent } from 'src/app/components/dialogs/attachments-dialog/attachments-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditAssetDialogComponent } from 'src/app/components/dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { FilePreviewDialogComponent } from 'src/app/components/dialogs/file-preview-dialog/file-preview-dialog.component';
import { ModelEditService } from 'src/app/models/model.edit.service';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ApiResultsService } from 'src/app/services/api.results.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';


export interface DialogData {
  element: any;
  action: string;
}

@Component({
  selector: 'app-edit-item-details',
  templateUrl: './edit-item-details.component.html',
  styleUrls: ['./edit-item-details.component.scss']
})
export class EditItemDetailsComponent {
  resultsAttachmentsUpdate!: Subscription;
  isLoading = false;
  isLoadingAttachments = false;
  serviceForm: any;
  validForm = false;
  requiredServicesFields: any = new ModelEditService;
  formControlMaterial = new FormControl('');
  formControlSize = new FormControl('');
  filteredMaterials: Observable<any>;
  filteredSizes: Observable<any>;

  allMaterials:any = [{id: null, name: 'mat 1'}, {id: null, name: 'mat 2'}];
  allSizes:any = [{id: null, name: 'size 1'}, {id: null, name: 'size 2'}];
  displayedColumns: string[] = ['name', 'description', 'actions'];
  dataSource: any;
  constructor(
    private apiResultsService: ApiResultsService,
    private apiFilesService: ApiFilesService,
    private sharedFunctions: SharedFunctionsService,
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData){}
    itemDetails = {
      serialNumber:null,
      modelNumber:null,
      assetDescription:null,
      assetType: null,
      assetCategory: null,
      department: null,
      manufacturer: null,
      model: null
    };

    ngOnInit(){
      this.getData();

      if(this.dialogData.element.id) {
        localStorage.setItem('resultId', this.dialogData.element.id);
      }      
      const tenantId = this.dialogData.element.assetDto.tenant.id;
      const workareaId = localStorage.getItem('taskWorkareaId');   
      const params = {        
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
        equipmentId: this.dialogData?.element?.assetDto?.id,
        type: this.dialogData?.element?.assetDto?.type
      }
      this.loadDetails(params);
      this.resultsAttachmentsUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
        // updates the reports list
        if(name == 'update_results_attachments') {
          this.getData();
        }
      }); 

      this.filteredMaterials = this.formControlMaterial.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '', this.allMaterials)),
      );
      this.filteredSizes = this.formControlSize.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '', this.allSizes)),
      );
    }

    ngOnDestroy(){
      this.resultsAttachmentsUpdate.unsubscribe();
    }

    private _filter(value: any, object:any): string[] {
      const filterValue = value.toLowerCase();
  
      return object.filter((option: any) => option.name.toLowerCase().includes(filterValue));
    }
  
    /**
   * sets the value for the selected element
   * @param option with value to set
   */
  selectValue(option: any, type: string) {    
    /*
    this.selectedElement = elem;
    this.invalid = false;

    this.elementEvent.emit(elem);
  */
  }
  valueChange(element: any, type: string) {
    element.target.value.trim().toLowerCase();
    //this.elementEvent.emit( element.target);
  }

  /**
   * shows the details for a specific element
   * @param element the clicked element to show the details
   */
  loadDetails(element: any) {
    this.isLoading = true;    
    this.apiResultsService.getResultDetails(element).subscribe(
      (result:any) => {
        if (result) {
          this.itemDetails.assetType = result.assetType.name;
          this.itemDetails.assetCategory = result.assetType.assetCategory.name;
          this.itemDetails.assetDescription = result.description;
          this.itemDetails.department = result.department ? result.department.name : null;       
          this.itemDetails.manufacturer = result.manufacturer ? result.manufacturer.name : null;       
          this.itemDetails.model = result.model ? result.model : null;       
          this.itemDetails.serialNumber = result.serialNumber ? result.serialNumber : null;       
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        this.isLoading = false;
        console.log('error retrieving result details ' + msg.error);
      }
    );
  }

  preview(element: any){
    const tenantId = localStorage.getItem('taskTenantId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',   
      taskId: localStorage.getItem('taskId'),
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }

  getData() {
    this.isLoading = true;      
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const tenantId = localStorage.getItem('taskTenantId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '', 
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: localStorage.getItem('taskId'),
      resultId: this.dialogData.element.id
    }
    this.dataSource = [];
    this.apiResultsService.getResultsAttachments(params).subscribe(
      (result: any) => {
        if (result && result.length > 0){
          this.dataSource = [...result];

        } else {
          this.dataSource = []     
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log('error retrieving result list ' + msg);
        this.isLoading = false;
    });
    
  }

  /**
 * stores the value
 * @param selectValue value that was selected to be stored
 * @param element selected element
 */
  addSelectValue(selectValue: any, element: any) {

    if (selectValue && (selectValue.name || selectValue.removeItem)) {
      
      this.serviceForm = {...this.sharedFunctions.saveFormValue(this.serviceForm, element, selectValue)};
    }  
  }
  
  validateForm(){}
  saveChanges(){}

  closePopup(): void {
    this.dialogRef.close();
  }

  actions(action:string, element: any) {

    if(action == 'update_results_attachment') {
      //element.assetType = this.dialogData.type;
      this.openUploads(action, element);
    }
    if (action == 'delete_result_attachment'){

      this.deleteFile(element);
    }
  }
  openUploads(action: string, element?:any){    

    const data = element ? element : this.dialogData;
    this.dialog.open(AttachmentsDialogComponent, {
      height: 'auto',
      width: '800px',
      disableClose: true,
      data: {data: data, action: action}
    });
  }

  confirmation(action: string, elem?: any) {
      
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete_result_attachment') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.actions(action, elem);
        }
      }
    ); 
  }
  
  deleteFile(element: any) {
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');       
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      hashName: element.hashName,
      taskId:  localStorage.getItem('taskId'),
      resultId: localStorage.getItem('resultId')
    }

    this.apiFilesService.deleteResultsAttachments(params).subscribe(
      (result) => {
        console.log( 'image deleted');
        this.snackBar.open( 'image deleted ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_results_attachments');
        this.componentsCommunication.setUpdateAssets('update_results');
    },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log( 'image deleted');
          this.snackBar.open( 'image deleted ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_results_attachments');
          this.componentsCommunication.setUpdateAssets('update_results');
        }
        console.log( msg.statusText);
    });    
  }
}
