<mat-card class="services-card">
  <mat-card-header>
    <mat-card-title> </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="my-content-container">
      <mat-toolbar
        *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
        class="services-toolbar"
      >
        <span><mat-icon>add</mat-icon> Add Service</span>
        <form class="services">
          <mat-form-field appearance="fill">
            <input
              class="services-input"
              type="text"
              matInput
              #serviceInput
              [formControl]="servicesControl"
              [matAutocomplete]="auto"
              (keydown.arrowup)="handleArrowNavigation($event)"
              (keydown.arrowdown)="handleArrowNavigation($event)"
              (matChipInputTokenEnd)="addService($event)"
            />
            <mat-icon class="select-arrow" matSuffix
              >keyboard_arrow_down</mat-icon
            >
            <mat-autocomplete #auto="matAutocomplete">
              <ng-container *ngFor="let option of filteredOptions | async">
                <mat-option
                  (onSelectionChange)="addService($event, option)"
                  *ngIf="!checkIfExists(option)"
                  [value]="option.name"
                >
                  {{ option.name }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </mat-toolbar>

      <table
        mat-table
        *ngIf="!noResults && isLoading == false"
        [dataSource]="dataSource"
        class="mat-elevation-z8"
      >
        <br />
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- report number Column -->
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            class="hidden"
            style="display: none"
            *matHeaderCellDef
          ></th>
          <td mat-cell class="services-name" *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="alerts">
          <th
            mat-header-cell
            class="hidden"
            style="display: none"
            *matHeaderCellDef
          ></th>
          <td
            mat-cell
            class="alerts-column"
            width="40px"
            *matCellDef="let element"
          >
            <mat-icon *ngIf="element?.showWarningIcon">warning</mat-icon>
          </td>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            class="hidden"
            style="display: none"
            width="40px"
            *matHeaderCellDef
          ></th>
          <td mat-cell width="40px" *matCellDef="let element">
            <!-- list actions menu-->
            <button
              *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
              class="user_menu custom"
              mat-raised-button
              [mat-menu-trigger-for]="menu"
              fxHide="false"
              fxHide.gt-sm
            >
              <mat-icon>expand_more</mat-icon>
            </button>

            <mat-menu class="menu" x-position="before" #menu="matMenu">
              <button
                *ngIf="element?.hasForm"
                mat-menu-item
                (click)="edit(element)"
              >
                <mat-icon>edit</mat-icon>
                Edit
              </button>
              <button mat-menu-item (click)="confirmation('delete', element)">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div class="no-results" *ngIf="noResults && !isLoading">
        <h1>No results</h1>
      </div>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <br />
    </div>
  </mat-card-content>
</mat-card>
