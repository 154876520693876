<div class="container-asset-view">
  <mat-card class="asset-view-card">
    <mat-card-content>
      <mat-toolbar
        color="primary"
        class="adf-app-layout-toolbar mat-elevation-z6"
      >
        <button
          mat-raised-button
          (click)="searchInventoryAsset()"
          class="custom-button green"
          color="primary"
        >
          <mat-icon class="icon">search</mat-icon>
        </button>

        <form class="form-search full-width">
          <mat-form-field class="full-width searchbar" appearance="outline">
            <mat-label>Enter search term</mat-label>
            <input
              #searchInput
              matInput
              autocomplete="off"
              (keyup.enter)="searchInventoryAsset()"
            />
          </mat-form-field>
        </form>

        <button
          *ngIf="
            roles.isAdmin ||            
            rolesService.IMEditor(roles.allRoles)
          "
          mat-raised-button
          (click)="actions('create')"
          class="last search-menu-button custom-button blue"
          color="primary"
        >
          <mat-icon class="icon">add</mat-icon>
        </button>
      </mat-toolbar>


      <div class="content-container">
        <div
          class="custom-width"
          *ngIf="!contentLoading && dataSource?.length > 0"
          [ngClass]="{ 'full-width': displayDetails }"
        >
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8 full-width assets-table"
          >
            <!-- Identification Column -->
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef>Category</th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'active-row':
                    element?.id == activeRowId && activeRowId.length > 0
                }"
                (click)="loadDetails(element)"
              >
                <span *ngIf="element.consumableType">
                  {{ element?.consumableType?.name }}</span
                >
                <span *ngIf="element.assetType">
                  {{ element?.assetType?.name }}</span
                >
              </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell class="type-column" *matHeaderCellDef>
                Type
              </th>
              <td
                mat-cell
                class="type-column"
                *matCellDef="let element"
                [ngClass]="{
                  'active-row':
                    element?.id == activeRowId && activeRowId.length > 0
                }"
                (click)="loadDetails(element)"
              >
                <mat-icon *ngIf="element.type == 1" class="icon"
                  >precision_manufacturing</mat-icon
                >
                <mat-icon *ngIf="element.type == 2" class="icon"
                  >miscellaneous_services</mat-icon
                >
              </td>
            </ng-container>

            <!-- Number Column -->
            <ng-container matColumnDef="number">
              <th mat-header-cell *matHeaderCellDef>Number</th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'active-row':
                    element?.id == activeRowId && activeRowId.length > 0
                }"
                (click)="loadDetails(element)"
              >
                <span *ngIf="element?.type == 1">
                  {{ element?.serialNumber }} - {{ element?.model }} 
                </span>
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td
                mat-cell
                class="tddescription"
                *matCellDef="let element"
                [ngClass]="{
                  'active-row':
                    element?.id == activeRowId && activeRowId.length > 0
                }"
                (click)="loadDetails(element)"
              >
                <span *ngIf="element?.description">
                  {{ element?.description }}
                </span>
              </td>
            </ng-container>
            
  
            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell class="last" width="40px" *matHeaderCellDef>               
                <button
                  *ngIf="
                    (roles.isAdmin ||
                      rolesService.IMSupervisor(roles.allRoles) ||
                      rolesService.IMEditor(roles.allRoles)) &&
                    displayDetails == true &&
                    detailsLoading == false &&
                    contentLoading == false
                  "
                  mat-raised-button
                  (click)="resetShowDetails()"
                  class="right hide-details search-menu-button custom-button blue"
                  color="primary"
                >
                  <mat-icon class="icon">arrow_forward</mat-icon>
                </button>
              </th>
              <td
                mat-cell
                class="last"
                width="40px"
                [ngClass]="{
                  'active-row':
                    element?.id == activeRowId && activeRowId.length > 0
                }"
                *matCellDef="let element"
              >
                <!-- list actions menu-->
                <button
                  *ngIf="
                    roles.isAdmin ||
                    rolesService.IMSupervisor(roles.allRoles) ||
                    rolesService.IMEditor(roles.allRoles)
                  "
                  class="user_menu custom"
                  mat-button
                  [mat-menu-trigger-for]="menu"
                  fxHide="false"
                  fxHide.gt-sm
                >
                  <mat-icon>expand_more</mat-icon>
                </button>

                <mat-menu class="menu" x-position="before" #menu="matMenu">
                  <button
                    *ngIf="
                      roles.isAdmin ||
                      rolesService.IMEditor(roles.allRoles)
                    "
                    mat-menu-item
                    (click)="actions('edit', element)"
                  >
                    <mat-icon>edit</mat-icon>
                    Edit
                  </button>
                  <button
                    *ngIf="
                      roles.isAdmin ||
                      rolesService.IMEditor(roles.allRoles)
                    "
                    mat-menu-item
                    (click)="confirmation('clone', element)"
                  >
                    <mat-icon>difference</mat-icon>
                    Clone
                  </button>
                  <button
                    *ngIf="
                      roles.isAdmin ||
                      rolesService.IMSupervisor(roles.allRoles)
                    "
                    mat-menu-item
                    (click)="confirmation('delete', element)"
                  >
                    <mat-icon>delete</mat-icon>
                    Delete
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            *ngIf="!contentLoading"
            (page)="handlePage($event)"
            [length]="paginatorConfig.length"
            [pageIndex]="paginatorConfig.pageIndex"
            [pageSize]="paginatorConfig.pageSize"
            [pageSizeOptions]="paginatorConfig.pageSizeOptions"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>

        <div
          class="no-results"
          *ngIf="dataSource?.length == 0 && !contentLoading"
        >
          <h1>No results to show...</h1>
        </div>
        <mat-spinner *ngIf="contentLoading"></mat-spinner>
        <div
          *ngIf="displayDetails && !contentLoading"
          class="sidebar-container"
        >
          <h4
            class="item-title"
            *ngIf="
              !detailsLoading &&
              !selectedItem?.serialNumber &&
              !selectedItem?.consumableType &&
              type2Elem
            "
          >
            <strong>
              {{ type2Elem?.consumableType?.name }}
            </strong>
          </h4>
          <h4
            class="item-title"
            *ngIf="
              displayDetails && selectedItem?.serialNumber && !contentLoading
            "
          >
            <strong>
              {{ selectedItem?.type }} - {{ selectedItem?.model }} -
              {{ selectedItem?.serialNumber }}
            </strong>
            <!-- comment for current phase
            <span *ngIf="selectedItem?.results?.Status.includes('Accepted')">
              <button mat-raised-button  class="accepted"
                matBadge="" matBadgePosition="before" matBadgeColor="accent">
                Accepted
              </button>
            </span>
            <span *ngIf="selectedItem?.results?.Status == 'Reserved'">
              <button mat-raised-button  class="reserved"
                matBadge="" matBadgePosition="before" matBadgeColor="accent">
                Reserved
              </button>
            </span>
            <span *ngIf="selectedItem?.results?.Status == 'New'">
              <button mat-raised-button
                matBadge="" matBadgePosition="before" matBadgeColor="accent">
                New
              </button>
            </span>
            <span *ngIf="selectedItem?.results?.Status.includes('Rejected')">
              <button mat-raised-button class="rejected"
                matBadge="" matBadgePosition="before">
                Rejected
              </button>
            </span>
          --></h4>
          <mat-spinner *ngIf="detailsLoading"></mat-spinner>
          <mat-card *ngIf="!detailsLoading">
            <mat-card-content>
              <!-- ************** DETAILS TABS ***********-->

              <div class="my-tabs-container">
                <mat-tab-group
                  animationDuration="0ms"
                  (selectedTabChange)="tabChanged($event)"
                  mat-stretch-tabs
                  [selectedIndex]="detailsTab"
                  class="example-stretched-tabs mat-elevation-z4"
                  mat-stretch-tabs
                >
                  <mat-tab>
                    <ng-template mat-tab-label> Details </ng-template>
                    <div class="details-container">
                      <div class="item-details">
                        <span *ngIf="selectedItem?.type == 1"
                          ><strong>Type: </strong> Equipment
                        </span>
                        <span *ngIf="selectedItem?.type == 2"
                          ><strong>Type: </strong> Consumable</span
                        >
                        <span *ngIf="selectedItem?.owner"
                          ><strong>Owner: </strong
                          >{{ selectedItem?.owner }}</span
                        >
                        <span *ngIf="selectedItem?.assetTypeName"
                          ><strong>Asset Type: </strong
                          >{{ selectedItem?.assetTypeName }}</span
                        >
                        <span *ngIf="selectedItem?.assetCategoryName"
                          ><strong>Asset Category: </strong
                          >{{ selectedItem?.assetCategoryName }}</span
                        >
                        <span *ngIf="selectedItem?.consumableType"
                          ><strong>Consumable Type: </strong
                          >{{ selectedItem?.consumableType }}</span
                        >
                        <span *ngIf="selectedItem?.serialNumber"
                          ><strong>Serial Number: </strong>
                          {{ selectedItem?.serialNumber }}</span
                        >
                        <span *ngIf="selectedItem?.model"
                          ><strong>Model: </strong>
                          {{ selectedItem?.model }}</span
                        >
                        <span *ngIf="selectedItem?.manufacturer"
                          ><strong>manufacturer: </strong>
                          {{ selectedItem?.manufacturer }}</span
                        >
                        <span *ngIf="selectedItem?.description"
                          ><strong>Description: </strong
                          >{{ selectedItem?.description }}
                        </span>
                        <br />
                        <span *ngIf="selectedItem?.connection1"
                          ><strong>Connection 1: </strong>
                          {{ selectedItem?.connection1 }}</span
                        >
                        <span *ngIf="selectedItem?.connection2"
                          ><strong>Connection 2: </strong>
                          {{ selectedItem?.connection2 }}</span
                        >
                        <span *ngIf="selectedItem?.bodyThreads"
                          ><strong>Body Threads: </strong>
                          {{ selectedItem?.bodyThreads }}</span
                        >
                        <span *ngIf="selectedItem?.blades"
                          ><strong>Blades: </strong
                          >{{ selectedItem?.blades }}</span
                        >
                        <span *ngIf="selectedItem?.material"
                          ><strong>Material: </strong>
                          {{ selectedItem?.material }}</span
                        >
                        <br />
                        <span *ngIf="selectedItem?.controller"
                          ><strong>Controller: </strong
                          >{{ selectedItem?.controller }}</span
                        >
                      </div>
                      <div class="item-preview">
                        <img
                          *ngIf="selectedItem?.urlAttachment"
                          class="preview"
                          (click)="preview(selectedItem)"
                          onContextMenu="return false;"
                          [src]="
                            'data:image/' +
                            selectedItem.typeFile +
                            ';base64,' +
                            selectedItem.urlAttachment
                          "
                        />
                      </div>
                    </div>
                  </mat-tab>

                  <mat-tab>
                    <ng-template mat-tab-label> Status </ng-template>

                    <div class="status-container">
                      <div class="status-details">
                        <span
                          ><strong>Status: </strong>
                          <span
                            *ngIf="
                              selectedItem?.results?.Status == 'ACC - Accepted'
                            "
                          >
                            Accepted
                          </span>
                          <span
                            *ngIf="selectedItem?.results?.Status == 'Reserved'"
                          >
                            Reserved
                          </span>
                          <span *ngIf="selectedItem?.results?.Status == 'New'">
                            New
                          </span>
                          <span
                            *ngIf="
                              selectedItem?.results?.Status == 'REJ - Rejected'
                            "
                          >
                            Rejected
                          </span>
                        </span>
                        <span
                          ><strong>Location: </strong>
                          {{ selectedItem?.results?.Location }}</span
                        >
                        <span
                          ><strong>Status Date: </strong> {{ statusDate }}</span
                        >

                        <br />
                        <span><strong>Latest services </strong></span>
                        <div *ngFor="let elem of resultsTabServices">
                          <div class="service-bar">
                            <span
                              ><strong>{{ elem?.name }}: </strong>Task Number:
                              {{
                                selectedItem?.taskAssetDetailsDtos[0]
                                  ?.taskNumber
                              }}
                              Date: {{ elem?.result?.Date }}</span
                            >

                            <span *ngIf="elem?.result?.Status == 'Accepted'">
                              <button
                                mat-raised-button
                                class="accepted"
                                matBadge=""
                                matBadgePosition="before"
                                matBadgeColor="accent"
                              >
                                Accepted
                              </button>
                            </span>
                            <span *ngIf="elem?.result?.Status == 'Reserved'">
                              <button
                                mat-raised-button
                                class="reserved"
                                matBadge=""
                                matBadgePosition="before"
                                matBadgeColor="accent"
                              >
                                Reserved
                              </button>
                            </span>
                            <span *ngIf="elem?.result?.Status == 'New'">
                              <button
                                mat-raised-button
                                matBadge=""
                                matBadgePosition="before"
                                matBadgeColor="accent"
                              >
                                New
                              </button>
                            </span>
                            <span
                              *ngIf="elem?.result?.Status.includes('Rejected')"
                            >
                              <button
                                mat-raised-button
                                class="rejected"
                                matBadge=""
                                matBadgePosition="before"
                              >
                                Rejected
                              </button>
                            </span>
                          </div>

                          <div
                            *ngIf="
                              !elem?.result?.observations &&
                              elem?.result?.Observations
                            "
                          >
                            <br />
                            <strong>{{ elem?.result?.Observations }}</strong>
                            <br />
                            <br />
                          </div>

                          <!-- OBSERVATIONS -->
                          <div *ngIf="elem?.result?.observations?.length > 0">
                            <br />
                            <span><strong>Observations:</strong></span>
                            <div
                              class="observation-content"
                              *ngFor="let key of elem?.result.observations"
                            >
                              <span
                                ><strong> {{ key.key }}</strong> -
                                {{ key.value }}
                              </span>
                            </div>
                            <br />
                          </div>

                          <!-- RESULTS -->
                          <div *ngIf="elem?.result?.results?.length > 0">
                            <br />
                            <div
                              *ngFor="let resultKey of elem?.result?.results"
                            >
                              <span
                                ><strong>{{ resultKey.key }}: </strong>
                                {{ resultKey?.value[0]?.name }}
                              </span>
                            </div>
                            <br />
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </mat-tab>

                  <mat-tab>
                    <ng-template mat-tab-label> Tasks </ng-template>
                    <div class="tasks-container">
                      <br />
                      <table
                        mat-table
                        [ngClass]="{ 'full-width': !displayDetails }"
                        [dataSource]="dataSourceTasks.taskAssetDetailsDtos"
                        class="mat-elevation-z8 full-width"
                      >
                        <!-- task Column -->
                        <ng-container matColumnDef="task">
                          <th mat-header-cell *matHeaderCellDef>Task</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.taskNumber }}
                          </td>
                        </ng-container>

                        <!-- date Column -->
                        <ng-container matColumnDef="date">
                          <th mat-header-cell *matHeaderCellDef>Date</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.date | date : "dd-MMM-YYY" }}
                          </td>
                        </ng-container>

                        <!-- standard Column -->
                        <ng-container matColumnDef="standard">
                          <th mat-header-cell *matHeaderCellDef>Standard</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.standard }}
                          </td>
                        </ng-container>

                        <!-- Services Column -->
                        <ng-container matColumnDef="services">
                          <th mat-header-cell *matHeaderCellDef>Services</th>
                          <td mat-cell *matCellDef="let element">
                            <span class="cut-text"
                              >{{ element.services }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- Comments Column -->
                        <ng-container matColumnDef="comments">
                          <th mat-header-cell *matHeaderCellDef>Comments</th>
                          <td
                            mat-cell
                            class="cut-text"
                            *matCellDef="let element"
                          >
                            <span class="cut-text"
                              >{{ element.comments }}
                            </span>
                          </td>
                        </ng-container>

                        <!-- Result Column -->
                        <ng-container matColumnDef="result">
                          <th mat-header-cell class="last" *matHeaderCellDef>
                            Result
                          </th>
                          <td mat-cell class="last" *matCellDef="let element">
                            <!--{{element.result}}-->
                            <span *ngIf="element.result == 'ACC - Accepted'">
                              <button
                                mat-raised-button
                                matBadge=""
                                matBadgePosition="before"
                                matBadgeColor="accent"
                              >
                                Accepted
                              </button>
                            </span>
                            <span *ngIf="element.result == 'Reserved'">
                              <button
                                mat-raised-button
                                class="reserved"
                                matBadge=""
                                matBadgePosition="before"
                                matBadgeColor="accent"
                              >
                                Reserved
                              </button>
                            </span>
                            <span *ngIf="element.result == 'New'">
                              <button
                                mat-raised-button
                                matBadge=""
                                matBadgePosition="before"
                                matBadgeColor="accent"
                              >
                                New
                              </button>
                            </span>
                            <span *ngIf="element.result == 'REJ - Rejected'">
                              <button
                                mat-raised-button
                                class="rejected"
                                matBadge=""
                                matBadgePosition="before"
                              >
                                Rejected
                              </button>
                            </span>
                            <span *ngIf="element.result == 'REJ - Accepted'">
                              <button
                                mat-raised-button
                                class="accepted"
                                matBadge=""
                                matBadgePosition="before"
                                matBadgeColor="accent"
                              >
                                Accepted
                              </button>
                            </span>
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayedColumnsTasks"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayedColumnsTasks"
                        ></tr>
                      </table>
                    </div>
                  </mat-tab>

                  <mat-tab>
                    <ng-template mat-tab-label> Certificates </ng-template>
                  </mat-tab>

                  <mat-tab>
                    <ng-template mat-tab-label> Reference </ng-template>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
