import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditAssetDialogComponent } from '../edit-asset-dialog/edit-asset-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ModelAttachment } from 'src/app/models/model.attachment';
import { ModelResultAttachment } from 'src/app/models/model.result.attachment';
export interface DialogData {
  data: any;
  assetType: string;
  name: string;
  id: string;
  action: string;
  element: any;
  tenantId: string;
}
@Component({
  selector: 'app-attachments-dialog',
  templateUrl: './attachments-dialog.component.html',
  styleUrls: ['./attachments-dialog.component.scss'],
})
export class AttachmentsDialogComponent implements OnInit {
  fileToUpload: any;
  prefilledTitle = '';
  prefilledName = '';
  //prefilledSupplier = '';
  prefilledDescription = '';
  display: FormControl = new FormControl('', Validators.required);
  enableUpload = false;
  //supplier: string;
  name: string;
  title: string;
  description: string;
  action: string;
  isLoading = false;
  requiredDescription = false;
  newAttachment = new ModelAttachment();
  newResultAttachment = new ModelResultAttachment();
  acceptsImage = 'image/png, image/jpeg';
  acceptsAll = 'image/png, image/jpeg, application/pdf';
  accepts: any;
  constructor(
    private dialogRef: MatDialogRef<EditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private apiFilesService: ApiFilesService
  ) {}

  ngOnInit() {
    this.accepts = this.acceptsAll;
    if (this.dialogData.action == 'update') {
      this.prefilledName = this.dialogData.data.name;
      //this.prefilledSupplier = this.dialogData.data.supplier;
      this.prefilledDescription = this.dialogData.data.description;
      this.action = this.dialogData.action;
    }
    if (
      this.dialogData.action == 'upload_attachment' ||
      this.dialogData.action == 'upload_result_attachment'
    ) {
      this.requiredDescription = true;
    }
    if (this.dialogData.action == 'update_attachment') {
      this.title = this.dialogData.element.title;
      this.description = this.dialogData.element.description;
      this.prefilledDescription = this.dialogData.element.description;
      this.prefilledTitle = this.dialogData.element.title;
    }
    if (this.dialogData.action == 'update_results_attachment') {
      this.description = this.dialogData.data.description;
      this.prefilledDescription = this.dialogData.data.description;
    }
  }

  ngOnDestroy(){
    this.componentsCommunication.setUpdateAssets(null);
  }

  checkFile(files: any): void {
    if (files.length) {
      this.fileToUpload = files[0];
      this.validateFormdata();
      this.display.patchValue(`${this.fileToUpload.name}`);
    } else {
      this.display.patchValue('');
      this.validateFormdata();
    }
  }
  uploadResultsAttachments() {
    let fileType = 'avatarInfo';
    if (this.dialogData.action == 'upload_result_attachment'){
      fileType = 'taskResultAttachment';
    }
    const taskId = this.dialogData.data.element.taskDto.id;
    const assetId = this.dialogData.data.element.assetDto.id;
    const taskAssetResultId = localStorage.getItem('resultId');
    this.isLoading = true;
    this.enableUpload = false;
    const formData = new FormData();
    formData.append('file', this.fileToUpload);

    this.newResultAttachment.name = this.fileToUpload.name;
    this.newResultAttachment.type = this.fileToUpload.type;
    this.newResultAttachment.taskDto = { id: taskId };
    this.newResultAttachment.assetDto = { id: assetId };
    this.newResultAttachment.taskAssetsResultsDto = { id: taskAssetResultId };
    this.newResultAttachment.dateCreated = <any>Date.now();
    this.newResultAttachment.lastUpdated = <any>Date.now();
    this.newResultAttachment.description = this.description;
    this.newResultAttachment.createdBy = <any>localStorage.getItem('username');

    formData.append(
      fileType,
      new Blob([JSON.stringify(this.newResultAttachment)], {
        type: 'application/json',
      })
    );

    const tenantId = this.dialogData.tenantId;
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      form: formData,
      taskId: taskId,
    };
    this.apiFilesService.uploadResultAttachment(params).subscribe(
      (result) => {
        console.log('file uploaded');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open('file uploaded ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateList(
          'update_results_attachments'
        );
        this.componentsCommunication.setUpdateList(
          'update_results'
        );        
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('file uploaded');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open('file uploaded ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateList(
            'update_results_attachments'
          );
          this.componentsCommunication.setUpdateList(
            'update_results'
          );          
        }
        console.log(msg.statusText);
      }
    );
  }

  uploadAttachmentsFile() {
    const taskId = localStorage.getItem('taskId');
    this.isLoading = true;
    this.enableUpload = false;
    const formData = new FormData();
    formData.append('file', this.fileToUpload);

    this.newAttachment.name = this.fileToUpload.name;
    this.newAttachment.type = this.fileToUpload.type;
    this.newAttachment.task = { id: taskId };
    this.newAttachment.dataCreated = <any>Date.now();
    this.newAttachment.lastUpdated = <any>Date.now();
    this.newAttachment.title = this.title;
    this.newAttachment.description = this.description;
    this.newAttachment.createdBy = <any>localStorage.getItem('username');

    formData.append(
      'taskAttachment',
      new Blob([JSON.stringify(this.newAttachment)], {
        type: 'application/json',
      })
    );
    const tenantId = this.dialogData.tenantId;    
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      form: formData,
      taskId: taskId,
    };
    this.apiFilesService.uploadAttachmentsFile(params).subscribe(
      (result) => {
        console.log('file uploaded');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open('file uploaded ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateList('update_attachments');
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('file uploaded');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open('file uploaded ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateList('update_attachments');
        }
        console.log(msg.statusText);
      }
    );
  }

  updateResultsAttachments() {
    this.isLoading = true;
    this.enableUpload = false;
    this.newResultAttachment.type = this.dialogData.data.type;
    this.newResultAttachment.hashName = this.dialogData.data.hashName;
    this.newResultAttachment.name = this.dialogData.data.name;
    this.newResultAttachment.id = this.dialogData.data.id;
    this.newResultAttachment.taskDto = { id: this.dialogData.data.taskDto.id };
    this.newResultAttachment.taskAssetsResultsDto = {
      id:localStorage.getItem('resultId'),
      taskDto: { id: this.dialogData.data.taskDto.id },
      assetDto: { id: this.dialogData.data.assetDto.id },
    };
    this.newResultAttachment.assetDto = {
      id: this.dialogData.data.assetDto.id,
    };
    this.newResultAttachment.lastUpdated = <any>Date.now();
    this.newResultAttachment.description = this.description;
    this.newResultAttachment.createdBy = this.dialogData.data.createdBy;
    this.newResultAttachment.title = this.dialogData.data.title;
    this.newResultAttachment.updatedBy = <any>localStorage.getItem('username');

    const tenantId = this.dialogData.tenantId;
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      body: this.newResultAttachment,
    };
    this.apiFilesService.updateResultAttachment(params).subscribe(
      (result) => {
        console.log('file updated');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open('file updated ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateList(
          'update_results_attachments'
        );
        this.componentsCommunication.setUpdateList(
          'update_results'
        );
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('file updated');
          this.closePopup();

          this.snackBar.open('file updated ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateList(
            'update_results_attachments'
          );
          this.componentsCommunication.setUpdateList(
            'update_results'
          );          
        }
        this.isLoading = false;
        console.log(msg.statusText);
      }
    );
  }

  updateAttachmentsFile() {
    const taskId = localStorage.getItem('taskId');
    this.isLoading = true;
    this.enableUpload = false;
    this.newAttachment.type = this.dialogData.element.type;
    this.newAttachment.hash = this.dialogData.element.hash;
    this.newAttachment.name = this.dialogData.element.name;
    this.newAttachment.id = this.dialogData.element.id;
    this.newAttachment.task = {id: taskId};
    this.newAttachment.dataCreated = this.dialogData.element.dateCreated;
    this.newAttachment.lastUpdated = <any>Date.now();
    this.newAttachment.title = this.title;
    this.newAttachment.description = this.description;
    this.newAttachment.createdBy = this.dialogData.element.createdBy;
    this.newAttachment.updatedBy = <any>localStorage.getItem('username');

    const tenantId = this.dialogData.tenantId;
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      body: this.newAttachment,
    };
    this.apiFilesService.updateAttachmentsFile(params).subscribe(
      (result) => {
        console.log('file updated');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open('file updated ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateList('update_attachments');
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('file updated');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open('file updated ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateList('update_attachments');
        }
        console.log(msg.statusText);
      }
    );
  }

  uploadFile() {
    this.isLoading = true;
    this.enableUpload = false;
    const formData = new FormData();
    let tenantId;
    formData.append('file', this.fileToUpload);
    const assetAttachment = {
      id: null,
      dateCreated: <any>Date.now(),
      createdBy: localStorage.getItem('username'),
      lastUpdated: '',
      updatedBy: '',
      asset: {
        id: this.dialogData.data.id,
      },
      supplier: '',
      description: this.description,
      name: 'motor',
      type: 'png',
      hash: '',
      certificationDate: ' ',
      expiryDate: ' ',
    };

    if(this.dialogData.action == 'upload') {
      tenantId = this.dialogData.data.tenant.id  
    } else {
      tenantId = this.dialogData.tenantId;  
    }
    
    const params = {
      workareaId:'',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      assetId: this.dialogData.data.id
    };
    formData.append(
      'assetAttachment',
      new Blob([JSON.stringify(assetAttachment)], {
        type: 'application/json',
      })
    );

    this.apiFilesService
      .uploadImage(formData, params)
      .subscribe(
        (result) => {
          console.log('image uploaded');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open('image uploaded ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateAssets('update_attachments');
        },
        // error
        (msg) => {
          if (msg.status == '200') {
            console.log('image uploaded');
            this.closePopup();
            this.isLoading = false;
            this.snackBar.open('image uploaded ', '', { duration: 3000 });
            // sends an update
            this.componentsCommunication.setUpdateAssets('update_attachments');
          } else {
            console.log(msg.statusText);
          }
        }
      );
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  updateFile() {
    const name = this.name ? this.name : this.dialogData.data.name;
    const description = this.description
      ? this.description
      : this.dialogData.data.description;
    // const supplier = this.supplier ? this.supplier : this.dialogData.data.supplier;

    const json = {
      id: this.dialogData.data.id,
      dateCreated: <any>Date.now(),
      createdBy: localStorage.getItem('username'),
      lastUpdated: <any>Date.now(),
      updatedBy: localStorage.getItem('username'),
      asset: {
        id: this.dialogData.data.asset.id,
      },
      supplier: '',
      description: description,
      name: name,
      type: this.dialogData.data.assetType.toString(),
      hash: this.dialogData.data.hash,
      certificationDate: '2023-05-19',
      expiryDate: '2023-05-19',
      deleted: false,
    };

    this.apiFilesService.updateImage(json).subscribe(
      (result) => {
        console.log('image updated');
        this.closePopup();
        this.snackBar.open('image updated ', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateAssets('update_attachments');
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('image updated');
          this.closePopup();
          this.snackBar.open('image updated ', '', { duration: 3000 });
          // sends an update
          this.componentsCommunication.setUpdateAssets('update_attachments');
        }
        console.log(msg.statusText);
      }
    );
  }

  validateFormdata() {
    this.enableUpload = false;
    if (this.dialogData.action == 'upload') {
      if (this.fileToUpload) {
        this.enableUpload = true;
      } else {
        this.enableUpload = false;
      }
    } else if (this.dialogData.action == 'update') {
      this.enableUpload = true;
      if (
        (this.name &&
          this.name.trim().length > 0 &&
          this.name != this.prefilledName) ||
        (this.description &&
          this.description.trim().length > 0 &&
          this.description != this.prefilledDescription)
      ) {
        this.enableUpload = false;
      }
    } else if (this.dialogData.action == 'upload_attachment') {
      if (
        this.title &&
        this.title.trim().length > 0 &&
        this.fileToUpload &&
        this.description &&
        this.description.trim().length > 0
      ) {
        this.enableUpload = true;
      }
    } else if (this.dialogData.action == 'upload_result_attachment') {
      if (
        this.fileToUpload &&
        this.description &&
        this.description.trim().length > 0
      ) {
        this.enableUpload = true;
      }
    } else if (this.dialogData.action == 'update_results_attachment') {
      if (this.description && this.description.trim().length > 0) {
        this.enableUpload = true;
      }
    } else if (this.dialogData.action == 'update_attachment') {
      if (
        this.title &&
        this.title.trim().length > 0 &&
        this.description &&
        this.description.trim().length > 0
      ) {
        this.enableUpload = true;
      } else {
        this.enableUpload = false;
      }
    }
  }
}
