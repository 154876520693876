import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, startWith, map, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { EditServiceDialogComponent } from '../../dialogs/edit-service-dialog/edit-service-dialog.component';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditVisualDialogComponent } from './dialogs/edit-visual/edit-visual.component';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'create-report-services',
  templateUrl: './create-report-services.component.html',
  styleUrls: ['./create-report-services.component.scss']
})
export class CreateReportServicesComponent implements OnInit, OnDestroy {
  @Input() roles: any;
  @Input() workareaRoles: any;
  formDataSubscription!: Subscription;
  servicesUpdate!: Subscription;
  servicesControl =  new FormControl('');
  @ViewChild('serviceInput')
  serviceInput!: ElementRef<HTMLInputElement>;
  @ViewChild('activeOption')
  activeOption!: any;
  displayedColumns: string[] = ['name', 'alerts', 'actions'];
  showActions = false;
  isLoading = true;
  noResults = false;
  filteredOptions: Observable<any[]> | undefined;
  dataSource: any;
  availableServices: any = [];
  usedOpts:any = [];
  lastAddedService: any;
  services: any = [];  
  serviceDatapointValues: any = [];
  servicesChecked = 0;
  workareaId: any;
  taskId: any;

  constructor(
    public rolesService: RolesService,
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private servicesService: ApiServicesService,
    private apiService: ApiService) {
  }
  ngOnInit() {
    this.taskId = localStorage.getItem('taskId');
    this.workareaId = localStorage.getItem('workareaId');
    this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

      if(data && data.id && !this.workareaId) {
        this.workareaId = data.workArea.id;
        this.getData();
      } 
    }); 

    this.servicesUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the reports list
      if(name == 'update_services') {
        this.getData();
      }
    }); 
  }
  
  ngOnDestroy(){
    this.servicesUpdate.unsubscribe();
    this.formDataSubscription.unsubscribe();
  }

  getData() {
    this.isLoading = true;
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = this.workareaId; 
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : ''
    };
    this.dataSource = [];
    // draws the already used services
    this.servicesService.getTaskServices(params).subscribe(
      (result: any) => {

        if(result.length > 0) {          
          this.dataSource = [...result];
          this.usedOpts = [...result];
        } else {
          // if no services are available, we retrieve all them for further adding
          this.dataSource = [...this.services];
        }     
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoading = false;
    });
          
    this.apiService.getLookupLists('services').subscribe(
      (result: any) => {

        if(result.lookups.length > 0) {
          this.availableServices = [...result.lookups];
          this.filteredOptions = <any>[...result.lookups];
        } 
        this.filteredOptions = this.servicesControl.valueChanges.pipe(
          startWith(''),
          map((item: string | null) => (item ? this._filter(item) : this.availableServices.slice())),
        );
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
    });

  }

  // TODO USE WHEN EDITING A SERVICE
  //Get SERVICES DataPoint Values of one Task, Asset and Service
  getServicesDatapointValues() {
    const tenantId = localStorage.getItem('taskTenantId');
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      taskId: localStorage.getItem('taskId'),      
      serviceId: this.lastAddedService.id
    };
    //Get SERVICES DataPoint Values of one Task, Asset and Service
    this.servicesService.getServicesFormsFirstTime(params).subscribe(
      (result: any) => {
        if(result && result.length > 0) {          
          this.serviceDatapointValues = [...result];    
          
          //once getting the datapoint values, saves the service

        } 
      },
      // error
      (msg) => {
        console.log('error retrieving service datapoint values' + msg);
    });
  }

  private _filter(element: any): string[] {    
    const searchValue = element.toLowerCase();         
    let returnElems = this.availableServices.filter(
      (item:any) => item.name.toLowerCase().includes(searchValue));
  
    return returnElems;
  }

  /**
   * checks if an element exists before displaying it
   * @param elem selected element
   * @returns returns if it exists
   */
  checkIfExists(elem: any){        
    const foundObj = this.usedOpts.filter((option:any) => option.name == elem.name);
    const exists = foundObj && foundObj.length > 0 ? true : false;
    return exists;
  }

  addService(event:any, element?: any): void {
    this.isLoading = true;
    if (event.isUserInput) {
      const value = (element.name || '').trim();
      this.serviceInput.nativeElement.value = '';
      const availableOpt = this.availableServices.filter((option:any) => option.name == value);
      const alreadyExists = this.usedOpts.filter((option:any) => option.name == value);

      if (value && availableOpt.length > 0 && alreadyExists.length == 0) {
        this.usedOpts.push(element);
        this.lastAddedService = element;
      }
      this.saveServices(); 
    }
  }

  saveServices() {
    this.isLoading = true;
    const body = {
      id: localStorage.getItem('taskId'), 
      updatedBy : localStorage.getItem('username'), 
      servicesSet: this.usedOpts
    };
    
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');  
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      workareaId: workareaId ? workareaId : '',
      body: body
    }
    this.servicesService.saveServices(params).subscribe(
      (result: any) => {    
        this.serviceInput.nativeElement.value = '';
        const workareaId = localStorage.getItem('taskWorkareaId');     
        const params = {
          workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
          serviceName: this.lastAddedService.name,
          tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
          idProcess: localStorage.getItem('idProcess')
        }
        //***** Get Service Form from Flowable: SERVICES First time
        //***** task/datapointValue/getServiceForm/{tenantId}/{id_process}/{serviceName}
        this.servicesService.getServicesFormsFirstTime(params).subscribe(
          (service:any) => {
    
            if (service) {
              service.createdBy = localStorage.getItem('username');         
              const workareaId = localStorage.getItem('taskWorkareaId');     
              const params = {
                workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
                tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
                taskId: localStorage.getItem('taskId'),
                serviceId: this.lastAddedService.id,
                body: service
              }
              //***** Create EMPTY DataPointValues For Tab Services
              //***** task/datapointValue/create/{tenantId}/{taskId}/service/{serviceId}
              this.servicesService.createServiceDatapoint(params).subscribe(
                (result:any) => {
                  console.log( 'service datapoint created');
                  this.componentsCommunication.setUpdateList('update_services');
                  this.componentsCommunication.setUpdateList('update_results');
              },
                // error
              (msg) => {
                if (msg.status == '200') {
                  console.log( 'service datapoint created');        
                }
            });  
          } else {
            //updates services and results, even if the added service is not editable
            this.componentsCommunication.setUpdateList('update_services');
            this.componentsCommunication.setUpdateList('update_results');
          }
        },
          // error
          (msg) => {
            this.isLoading = false;
            console.log('error loading the result json ' + msg.statusText);
        }); 

      },
      // error
      (msg) => {
        this.isLoading = false;
        console.log('error updating the services ' + msg);
    });
  }

  deleteService(element: any) {
    this.isLoading = true;
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',      
      taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : '',
      serviceId: element.id
    }

    this.serviceInput.nativeElement.value = '';
    let index = this.usedOpts.findIndex((item:any) => item.name == element.name);
    this.usedOpts.splice(index, 1);

    this.servicesService.deleteService(params).subscribe(
      (result: any) => {
        console.log('service deleted ');
       this.componentsCommunication.setUpdateList('update_services');
       this.componentsCommunication.setUpdateList('update_results');
      },
      // error
      (msg) => {
      if (msg.status == 200) {
        console.log('service deleted ');
        this.componentsCommunication.setUpdateList('update_services');
        this.componentsCommunication.setUpdateList('update_results');
      } else {
        console.log('error deleting the service ' + msg);
      }        
    });
  }

  edit(element: any){
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      taskId: localStorage.getItem('taskId'),
      serviceId: element.id,
      serviceName: element.name,
      element: element
    }
    if (element.name != 'Visual') {
      this.dialog.open(EditServiceDialogComponent, {
        height: '90%',
        width: '94%',
        disableClose: true,
        autoFocus: false,
        data: params
      });
    }
    if (element.name == 'Visual') {
      this.dialog.open(EditVisualDialogComponent, {
        height: 'auto',
        width: '300px',
        disableClose: true,
        autoFocus: false,
        data: params
      });
    }
  }
  confirmation(action: string, elem?: any) {    
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.deleteService(elem);
        }
      }
    ); 
  }

  handleArrowNavigation (event: any){
    let value: any = '';
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }
    this.serviceInput.nativeElement.value = value;
  }

}
