<div class="dialogform-container">
  <div class="v-window-header">
    <span>Edit Service - {{ dialogData.service?.name }}</span>
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <mat-card-content *ngIf="!isLoading">

    <div mat-dialog-content *ngIf="dialogData?.service?.name != 'DIM'">
      <div class="columns" *ngFor="let element of elements">
        <div class="row three-columns" *ngFor="let elem of element">
          <!--*****DROPDOWN TYPE*****-->
          <select-elem-services-results
            class="full-width"
            *ngIf="
              elem?.datapointCatalog?.datapointType?.name == 'Dropdown' &&
              elem?.datapointCatalog?.lookupName == 'AcceptReject'"
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></select-elem-services-results>

          <!--*****CHIPLIST TYPE*****-->
          <chiplist-services-results
            class="full-width"
            *ngIf="
              elem?.datapointCatalog?.datapointType?.name == 'Dropdown' &&
              elem?.datapointCatalog?.isMultiselect
            "
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></chiplist-services-results>

          <div
            class="observations-container"
            *ngIf="elem.datapointCatalog.name == 'Observations'"
          >
            <h4>{{ elem.datapointCatalog?.name }}</h4>
            <app-result-observations-observations
              [element]="elem"
            ></app-result-observations-observations>
          </div>

          <div
            class="attachments-container"
            *ngIf="elem.datapointCatalog.name == 'Attachments'"
          >
            <h4>{{ elem.datapointCatalog?.name }}</h4>
            <app-result-service-attachments
              [element]="elem"
            ></app-result-service-attachments>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-content *ngIf="dialogData?.service?.name == 'DIM'">
      <div class="columns" *ngFor="let element of elements; let i = index">
        <h4 *ngIf="i == 1">Connection 1 : {{ this.taskConnection1 }}</h4>
        <h4 *ngIf="i == 2">Connection 2 : {{ this.taskConnection2 }}</h4>
        
        <div class="row four-columns" *ngFor="let elem of element">
          <!--*****MEASUREMENT TYPE*****-->
          <select-measurements
            class="full-width"
            *ngIf="
            elem?.datapointCatalog?.datapointType?.name == 'Measurement'"
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></select-measurements>

          <!--*****INPUT TYPE*****-->
          <input-services-results
            class="full-width"
            *ngIf="
              elem?.datapointCatalog?.datapointType?.name == 'Text' &&
              elem?.datapointCatalog?.name != 'Fishing Neck'"
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></input-services-results>

          <!--*****Fishing neck TYPE*****-->
          <input-services-results
            class="full-width"
            *ngIf="elem?.datapointCatalog?.name == 'Fishing Neck' &&
            elem?.datapointCatalog?.datapointType?.name != 'Measurement'"
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></input-services-results>

          <!--*****DROPDOWN TYPE*****-->
          <select-elem-services-results
            class="full-width"
            *ngIf="
              allowedLookups.includes(elem?.datapointCatalog?.lookupName) &&              
              elem?.datapointCatalog?.datapointType?.name != 'Measurement' 
            "
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></select-elem-services-results>

          <!--*****CHIPLIST TYPE*****-->
          <chiplist-services-results
            class="full-width"
            *ngIf="
              elem?.datapointCatalog?.datapointType?.name == 'Dropdown' &&
              elem?.datapointCatalog?.multiple == true &&
              elem?.datapointCatalog?.lookupName != 'YesNo' &&
              elem?.datapointCatalog?.lookupName != 'Condition' &&
              elem?.datapointCatalog?.lookupName != 'ThreadType'
            "
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></chiplist-services-results>

          <!--*****CHIPLIST TYPE CONDITION*****-->
          <chiplist-services-results
            class="full-width"
            *ngIf="
              elem?.datapointCatalog?.datapointType?.name == 'Dropdown' &&
              elem?.datapointCatalog?.lookupName == 'Condition'
            "
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></chiplist-services-results>

          <!--*****YESNO TYPE*****-->
          <yesno-select-services-results
            class="full-width"
            *ngIf="elem?.datapointCatalog?.lookupName == 'YesNo'"
            (elementEvent)="addSelectValue($event, elem)"
            [element]="elem"
          ></yesno-select-services-results>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button
        mat-raised-button
        [ngClass]="{ disabled: !validForm }"
        color="primary"
        (click)="saveChanges()"
      >
        Save
        <mat-icon class="icon action">save</mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="closePopup()">
        Close
        <mat-icon class="icon action">close</mat-icon>
      </button>
    </div>
  </mat-card-content>
</div>
