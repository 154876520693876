
<h4> {{element?.datapointCatalog?.name}} 

  <span *ngIf="!invalidMultiselect && element?.datapointCatalog?.required" class="required_item"> *</span>
  <span class="required_item" *ngIf="invalidMultiselect && element?.datapointCatalog?.required">
     can't have ACP with other conditions * !</span>  
</h4>
<mat-form-field [ngClass]="{'invalid': invalidMultiselect}"   class="filter-type" appearance="fill">

  <input 
  (blur)="checkContent()"
  (keyup)="valueChange($event.target.value)"
  [matChipInputFor]="chipListElements"
  #elementsInput
  [formControl]="elementCtrl"
  [matAutocomplete]="auto" 
  [disabled]="element?.datapointCatalog.readOnly"
  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  (matChipInputTokenEnd)="addElem($event); selectValue($event, element)"/>
  <mat-icon
  class="select-arrow"
  matSuffix>keyboard_arrow_down</mat-icon>
  <mat-chip-grid 
  [disabled]="element?.readOnly"
  #chipListElements>
    <mat-chip-row
    *ngFor="let item of selectedElems"
    (removed)="removeElem(item); selectValue(item, element, 'remove')">
      <span *ngIf="item.name">{{item.name}}</span>
      <span *ngIf="!item.name">{{item}}</span>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedElem($event); selectValue($event, element)">
    <ng-container *ngFor="let elem of filteredElements | async" >
      <mat-option *ngIf="!checkIfExists(elem)" [value]="elem">
        {{elem.name}}
      </mat-option>
    </ng-container>
  </mat-autocomplete>

</mat-form-field>
