<h4> {{element?.datapointCatalog?.name}} 
  <span class="required_item" *ngIf="element?.datapointCatalog?.required"> *</span>
</h4>
<mat-form-field [ngClass]="{'disabled': element.readOnly, 'invalid': invalid}" class="filter-type" appearance="fill">
  <mat-label> {{element.name}}</mat-label>
  <input
  aria-expanded="false"
  [ngModel]="prefilledValue?.name"  
  [readonly]="element.readOnly"
  type="text"
  matInput
  [formControl]="myControl"
  [matAutocomplete]="auto">
  <mat-icon
  class="select-arrow"
  matSuffix>keyboard_arrow_down</mat-icon>

  <mat-autocomplete
   #auto="matAutocomplete">
   <mat-option (onSelectionChange)="selectValue('', element, $event)"></mat-option>
    <mat-option *ngFor="let option of filteredOptions | async"    
    [value]="option.name"  
    (onSelectionChange)="selectValue(option, element, $event)">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>