
<div class="attachments-container">
  <div class="v-window-header">
    <span>Confirm</span>    
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>

  <br />
  
  <h3 *ngIf="dialogData?.confirmationString">{{dialogData.confirmationString}}</h3>    
  <h3 *ngIf="dialogData.action == 'delete' && !dialogData?.confirmationString"> Are you sure you want to <strong> delete </strong> the selected element?</h3>
  <h3 *ngIf="dialogData.action == 'clone' && !dialogData?.confirmationString"> Are you sure you want to <strong> clone </strong> the selected element?</h3>

  <div mat-dialog-actions>
    <button  mat-raised-button 
    class="upload right" color="primary" (click)="dialogData.confirmation = true; closePopup()">
     Confirm
     <mat-icon class="icon action">done</mat-icon>
   </button>
  </div>

</div>