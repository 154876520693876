import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { SharedFunctionsService } from './shared-functions.service';
import { ComponentsCommunicationService } from './components-communication.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseURL: string;

  constructor(
    private matDialog: MatDialog,
    private componentsCommunication: ComponentsCommunicationService,
    private router: Router,
     private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  getHeadersWithTenantAndRoles(tenantId: string, roleId: string): HttpHeaders {
    if (typeof tenantId  == 'undefined'){
      console.log('el tenant no esta definido!!!');
    }
    if (!localStorage.getItem('username')){
      this.logout();
    }

    const token: any = localStorage.getItem('token');    
    const headers = new HttpHeaders({
      //avoids to prompt the auth popup
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'application/json',
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Accept': 'application/json, */*',      
      'X-TenantID': tenantId ? tenantId : '',
      'X-ModuleRoles': roleId
    });
    return headers;
   }

  getHeadersWithTenant(xtenantId: string, params?: any): HttpHeaders {
    if (typeof xtenantId  == 'undefined'){
      console.log('el tenant no esta definido!!!');
    }
    if (!localStorage.getItem('username')){
      this.logout();
    }    
    let token: any = localStorage.getItem('token');
    let headers: any;
    if (!params) {
      headers = new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json',
        'Authorization': token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Accept': 'application/json, */*',
        'X-TenantID':  xtenantId ? xtenantId : '',
        'X-WorkAreaID': params ? params: ''
      });
   } else {
      headers = new HttpHeaders({
        Authorization: token,
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Accept: 'application/json, */*',
        'X-TenantID': xtenantId,
        'X-WorkAreaID': params ? params : ''
      });
    }
    //'X-TenantID': xtenantId.length > 0 ? xtenantId : ''
    return headers;
   }

   getHeadersWithoutTenant(): HttpHeaders {

    if (!localStorage.getItem('username')){
      this.logout();
    }
    let token: any = localStorage.getItem('token');
    
    const headers = new HttpHeaders({
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'application/json',
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Accept': 'application/json, */*'
    });

    return headers;
   }

   getHeadersNoAuth(params: any): HttpHeaders { 
    if (typeof params.tenantId == 'undefined'){
      console.log('el tenant no esta definido!!!');
    }
    if (!localStorage.getItem('username')){
      this.logout();
    }
    const tenantId = params.tenantId ? params.tenantId : ''; 
      const headers = new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Accept': 'application/json, */*',
      'X-TenantID': tenantId,
    });

    return headers;
   }

  isAuthenticated():boolean{
    return true;
  }

  getHeaders(params?: any, tenantId?: string): HttpHeaders {
    if (!localStorage.getItem('username')){
      this.logout();
    }
    let token = localStorage.getItem('token');
    let authorizationString: any = '';
    let headers;
    if (token) {
      authorizationString = token;
    } else if (params && params.username && params.password) {
      authorizationString =
        'Basic ' + btoa(params.username + ':' + params.password);
    } else {
      authorizationString = null;
      console.error('*****UNAUTHORIZED*****');
      this.router.navigateByUrl('/login');
    }
    if (params && params == 'uploadFile') {
      headers = new HttpHeaders({
        Authorization: authorizationString,
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Accept': 'application/json, */*',
        'X-TenantID': tenantId ? tenantId : '',
        'X-WorkAreaID': params?.workareaId ? params?.workareaId: ''
      });
    } else {
      headers = new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json',
        Authorization: authorizationString,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Accept: 'application/json, */*',
        'X-TenantID': '',
        'X-WorkAreaID': ''
      });
    }

    return headers;
  }
  getHeadersUploadFile(params: any): HttpHeaders {
    if (typeof params.tenantId  == 'undefined'){
      console.log('el tenant no esta definido!!!');
    }
    if (!params.workareaId){
      console.log('el workarea no esta definido!!!');
    }

    if (!localStorage.getItem('username')){
      this.logout();
    }

    let token = localStorage.getItem('token');
    let authorizationString: any = '';
    let headers: HttpHeaders ;
    if (token) {
      authorizationString = token;
    } else if (params && params.username && params.password) {
      authorizationString =
        'Basic ' + btoa(params.username + ':' + params.password);
    } else {
      authorizationString = null;
      console.error('*****UNAUTHORIZED*****');
      this.router.navigateByUrl('/login');
    }
  
    headers = new HttpHeaders({
      Authorization: authorizationString,
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Accept': 'application/json, */*',
      'X-TenantID': params?.tenantId ? params?.tenantId : '',
      'X-WorkAreaID': params?.workareaId ? params?.workareaId: ''
    });
    return headers;
  }

  getHeadersNoToken(): HttpHeaders { 
    const headers = new HttpHeaders({
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Accept': 'application/json, */*'
    });
    return headers;
  }

  logout(): void {    
    this.matDialog.closeAll();
    localStorage.clear();
    //forces the roles broadcast to empty everything
    this.componentsCommunication.setRolesList([]);
    this.componentsCommunication.setRolesWorkareas([]);
    this.router.navigateByUrl('/login');
  }

  //api/sys/login/roles/list
  //"evolve-editor",
  //"evolve-reader",
  //"evolve-sys-admin",
  //"evolve-tenant-admin",
  //"module-role-system-admin"
  performLogin(params: any) {
    const headers = this.getHeaders(params);
    const endpoint = `/api/sys/login/modules`;
    return this.http.get(this.baseURL + endpoint, { headers: headers });
  }
  
  getRoles(params: any) {
    const headers = this.getHeaders(params);
    const endpoint = `/api/sys/login/roles/list`;
    return this.http.get(this.baseURL + endpoint, { headers: headers });
  }

  getWorkareaRoles(params: any) {
    const headers = this.getHeaders(params);
    const endpoint = `/api/sys/login/roles/workareas`;
    return this.http.get(this.baseURL + endpoint, { headers: headers });
  }

  //GET /user/{token}/check
  userTokenCheck(params: any) {
    const headers = this.getHeadersNoToken();
    const endpoint = `/api/sys/user/${params.token}/check`;
    return this.http.get(this.baseURL + endpoint, { headers: headers });
  }
  
  //PUT /user/{token}/set/password    
  setNewPassword(params: any) {
    const headers = this.getHeadersNoAuth(params);
    const endpoint = `/api/sys/user/${params.token}/set/password`;
    return this.http.put(this.baseURL + endpoint, params.body, { headers: headers });
  }

  //PUT /user/{token}/set/password    
  adminResetPassword(params: any) {
    const headers = this.getHeadersWithTenant(params.tenantId);
    const endpoint = `/api/sys/user/${params.userId}/request/password`;
    return this.http.put(this.baseURL + endpoint, {}, { headers: headers });
  }

  //PUT /user/{userId}/change/password
  changePassword(params: any) {
    const headers = this.getHeadersWithTenant(params.tenantId);
    const endpoint = `/api/sys/user/${params.userId}/change/password`;
    return this.http.put(this.baseURL + endpoint, params.body, { headers: headers });
  }
  
  //PUT /user/{email}/forget/password
  sendEmail(params: any) {
    const headers = this.getHeadersNoAuth(params);
    const endpoint = `/api/sys/user/${params.userId}/forget/password`;
    return this.http.put(this.baseURL + endpoint, {}, { headers: headers });
  }

  //PUT /user/{userId}/request/password
  resetPassword(params: any) {
    const headers = this.getHeadersWithTenant(params.tenantId);
    const endpoint = `/api/sys/user/${params.userId}/request/password`;
    return this.http.put(this.baseURL + endpoint, {}, { headers: headers });
  }
}
