import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiDefectsService } from 'src/app/services/api.defects.service';
import { ApiResultsService } from 'src/app/services/api.results.service';
import { ApiService } from 'src/app/services/api.service';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';

export interface DialogData {
  origin: string;
  idProcess: string;
  id: string;
  action: string
  workArea: any;
  tenantId: string;
}

@Component({
  selector: 'app-release-to-qc-dialog',
  templateUrl: './release-to-qc-dialog.component.html',
  styleUrls: ['./release-to-qc-dialog.component.scss']
})
export class ReleaseToQcDialogComponent {
  isLoading = false;
  isLoadingGeneral = false;
  pdfUrl = '';
  displayedColumns = ['number','area', 'condition','description', 'images'];
  dataSource: any;
  results: any;
  statusOk = true;
  reportItems: any;
  taskId: any;
  idProcess: any;
  invalidTabs: any;
  constructor(
    private dialog: MatDialog,
    private apiResultsService: ApiResultsService,
    private defectsService: ApiDefectsService,
    public dialogRef: MatDialogRef<ReleaseToQcDialogComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {}

  ngOnInit(){

    if (this.dialogData.origin == 'task' || this.dialogData.action == 'preview') {
      this.taskId = this.dialogData.id;
      this.idProcess = this.dialogData.idProcess;
    } else {
      this.taskId = localStorage.getItem('taskId');
      this.idProcess = localStorage.getItem('idProcess');
    }
    if ((this.dialogData.origin == 'details' || this.dialogData.origin == 'task') && !this.dialogData.action) {
      const workareaId = this.dialogData.workArea.id;
      this.releaseToQC(workareaId);
      this.getData();
      this.getResults();        
    }
    if (this.dialogData.action == 'preview') {
      this.getPdfPreview();
    }

  }

  getResults() {
    this.isLoading = true;    
    const tenantId = this.dialogData.tenantId;   
    const workareaId = localStorage.getItem('taskWorkareaId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      taskId: this.taskId,
      idProcess: this.idProcess
    }

    this.apiResultsService.getAllResults(params).subscribe(
      (result: any) => {
        if (result && result.length > 0){
          this.results = result;
        } else {
          this.isLoading = false;   
        }
      },
      // error
      (msg) => {
        console.log('error retrieving result list ' + msg);
        this.isLoading = false;
    });
    
  }

  getData() {
    this.isLoading = true;
    const tenantId = this.dialogData.tenantId;   
    const workareaId = localStorage.getItem('taskWorkareaId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      taskId: this.taskId,
    }

    this.defectsService.getTaskDefects(params).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.dataSource = result;
        if (result && result.length == 0) {
          this.dataSource = [];
        }
      },
      // error
      (msg) => {
        console.log('error retrieving defects ' + msg);
        this.dataSource = [];
        this.isLoading = false;
    });
  }

  preview(element: any){
    const tenantId = this.dialogData.tenantId;
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      taskId: this.taskId,
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }

  getPdfPreview(){
    this.isLoadingGeneral = true;
    const tenantId = this.dialogData.tenantId;   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      taskId: this.taskId
    }

    this.apiService.getPdfPreview(params).subscribe(
      (result:any) => {
        this.statusOk = true;
        this.reportItems = result;
        this.invalidTabs = [];  
    },
    // error
    (msg) => {
      
      if (msg.status == '200' || msg.status == '201') {
        this.statusOk = true;
        //console.log( 'calling release to QC');  
        this.pdfUrl = 'data:application/pdf;base64,' + msg.error.text;
  
      } else {
        console.log( msg.statusText);
        this.statusOk = false;
      }
      this.isLoadingGeneral = false;
    }); 
  }

  releaseToQC(workareaId: string){
    this.isLoadingGeneral = true;
    const tenantId = this.dialogData.tenantId;       
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      taskId: this.taskId
    }

    this.apiService.releaseToQC(params).subscribe(
      (result:any) => {
        this.statusOk = true;
        this.reportItems = result;
        this.invalidTabs = [];
        Object.entries(result).forEach((key: any) => {
          if (key) {
            this.statusOk = false;
            let saveKey = [];
            if (typeof key[1] == 'object' && key[0] == 'services') {
              Object.entries( key[1]).forEach(([key, value]: any) => {
                if (key) {
                  saveKey.push({key: Object.keys(value)[0], value: Object.values(value)[0]});
                }
              });
            } else {
              saveKey = key[1];
            }
            this.invalidTabs.push({key: key[0], content: saveKey});
          }
        });

        if (result.reportItems) {
          this.statusOk = false;
        }
        this.isLoadingGeneral = false;    
        //console.log('invalid tabs----'+ this.invalidTabs);
    },
      // error
    (msg) => {
      
      if (msg.status == '200' || msg.status == '201') {
        this.statusOk = true;
        //console.log( 'calling release to QC');  
        this.pdfUrl = 'data:application/pdf;base64,' + msg.error.text;
  
      } else {
        console.log( msg.statusText);
        this.statusOk = false;
      }
      this.isLoadingGeneral = false;
    }); 
  }

  closePopup(): void {
    this.dialogRef.close();
  }


}
