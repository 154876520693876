export class ModelSite {
    id = null;
    tenantId = null;
    name = '';
    description = null;
    email = '';
    licenseLevel = null
    licenseStartDate = null;
    licenseEndDate = null;
    companyTitle = '';
    telephone = '';
    url = '';
    addressLine1 = '';
    addressLine2 = '';
    addressLine3 = '';
    city = '';
    postCode = '';
    country = '';
    active = true;
    isDeleted = false
}

