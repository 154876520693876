<h4> {{element?.datapointCatalog?.name}} 
  <span class="required_item" *ngIf="!invalid && element?.datapointCatalog?.required"> *</span>
  <span class="required_item" *ngIf="invalid">
    is invalid * !</span> 
</h4>

<mat-form-field [ngClass]="{'disabled': element.datapointCatalog.readOnly, 'invalid': invalid}" class="filter-type" appearance="fill">
  <input
  aria-expanded="false"
  [ngModel]="prefilledValue?.name"
  type="text"
  matInput
  #textInput
  (keydown.arrowup)="handleArrowNavigation($event)" 
  (keydown.arrowdown)="handleArrowNavigation($event)"
  (focusout)="checkIfEmpty($event)"
  (keyup)="getResults($event)"
  [formControl]="myControl"
  [matAutocomplete]="auto">
  <mat-icon
  class="select-arrow"
  matSuffix>keyboard_arrow_down</mat-icon>

  <mat-autocomplete
   #auto="matAutocomplete">
   <ng-container *ngFor="let option of filteredOptions | async" >
    <mat-option [value]="option.name"  
    (onSelectionChange)="selectValue(option, element, $event)">
      {{option.name}}
    </mat-option>
   </ng-container>
  </mat-autocomplete>
</mat-form-field>