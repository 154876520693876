<mat-toolbar class="sites-toolbar">
  <button
    mat-raised-button
    (click)="siteSearch()"
    class="green custom-button search"
  >
    <mat-icon class="icon">search</mat-icon>
  </button>

  <form class="form-search full-width">
    <mat-form-field
      class="example-full-width searchbar full-width"
      appearance="outline"
    >
      <mat-label>Enter search term</mat-label>
      <input
        #searchInput
        (keyup.enter)="siteSearch()"
        matInput
        autocomplete="off"
      />
    </mat-form-field>
  </form>

  <button
    *ngIf="
      roles.isAdmin ||
      rolesService.systemAdmin(roles.systemRoles)
    "
    mat-raised-button
    (click)="createSite()"
    class="last search-menu-button custom-button"
    color="primary"
  >
    <mat-icon class="icon">add</mat-icon>
  </button>
</mat-toolbar>

<mat-spinner *ngIf="contentLoading || isLoading"></mat-spinner>

<div class="content-container">
  <div
    class="custom-width"
    *ngIf="!contentLoading && dataSource?.length > 0"
    [ngClass]="{ 'full-width': displayDetails }"
  >
    <table
      *ngIf="displayDetails == true"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 full-width details-table"
    >
      <!-- date Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          mat-cell
          *matCellDef="let element"
          (click)="loadDetails(element)"
        >
          {{ element?.name }}
        </td>
      </ng-container>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th width="50px" mat-header-cell *matHeaderCellDef>Active</th>
        <td
          width="50px"
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.active && element.active">
            <mat-icon class="icon">done</mat-icon>
          </span>
          <span
            *ngIf="!element?.active || (element?.active && !element.active)"
          >
            <mat-icon class="icon">close</mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell class="last" width="40px" *matHeaderCellDef>
          <button
            *ngIf="
              (
                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles)) &&
              displayDetails &&
              detailsLoading == false &&
              contentLoading == false
            "
            mat-raised-button
            (click)="resetShowDetails()"
            class="right hide-details search-menu-button custom-button blue"
            color="primary"
          >
            <mat-icon class="icon">arrow_forward</mat-icon>
          </button>
        </th>
        <td
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          mat-cell
          class="last"
          width="40px"
          *matCellDef="let element"
        >
          <!--  actions menu-->
          <button
            *ngIf="
              roles.isAdmin ||
              rolesService.systemAdmin(roles.systemRoles)
            "
            class="user_menu custom"
            mat-button
            [mat-menu-trigger-for]="menu"
            fxHide="false"
            fxHide.gt-sm
          >
            <mat-icon>expand_more</mat-icon>
          </button>

          <mat-menu class="menu" x-position="before" #menu="matMenu">
            <button
              *ngIf="
                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles)
              "
              mat-menu-item
              (click)="editSite(element)"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              (click)="editLogo(element, 'sitelogo')"
            >
              <mat-icon>account_box</mat-icon>
              Logo
            </button>
            <button
              *ngIf="
                (
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                element?.active &&
                element.active
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>delete</mat-icon>Disable
            </button>
            <button
              *ngIf="
                ((
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                  !element?.active) ||
                (element?.active && !element.active)
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>recycling</mat-icon>Enable
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table
      *ngIf="displayDetails == false"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 full-width details-table"
    >
      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.name">
            {{ element?.name }}</span
          >
        </td>
      </ng-container>

      <!-- License Level Column -->
      <ng-container matColumnDef="licenseLevel">
        <th mat-header-cell *matHeaderCellDef>License Level</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.licenseLevel?.name">
            {{ element?.licenseLevel?.name }}</span
          >
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.email"> {{ element?.email }}</span>
        </td>
      </ng-container>

      <!-- Telephone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Telephone</th>
        <td
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.telephone">
            {{ element?.telephone }}
          </span>
        </td>
      </ng-container>

      <!-- URL Column -->
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef>URL</th>
        <td
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.url">
            {{ element?.url }}
          </span>
        </td>
      </ng-container>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th width="50px" mat-header-cell *matHeaderCellDef>Active</th>
        <td
          width="50px"
          mat-cell
          class="tddescription"
          *matCellDef="let element"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          (click)="loadDetails(element)"
        >
          <span *ngIf="element?.active && element.active">
            <mat-icon class="icon">done</mat-icon>
          </span>
          <span
            *ngIf="!element?.active || (element?.active && !element.active)"
          >
            <mat-icon class="icon">close</mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell class="last" width="40px" *matHeaderCellDef></th>
        <td
          mat-cell
          class="last"
          width="40px"
          [ngClass]="{
            'active-row': element?.id == activeRowId && activeRowId.length > 0
          }"
          *matCellDef="let element"
        >
          <!-- list actions menu-->
          <button
            *ngIf="
              roles.isAdmin ||
              rolesService.systemAdmin(roles.systemRoles)
            "
            class="user_menu custom"
            mat-button
            [mat-menu-trigger-for]="menu"
            fxHide="false"
            fxHide.gt-sm
          >
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu class="menu" x-position="before" #menu="matMenu">
            <button
              *ngIf="
                roles.isAdmin ||
                rolesService.systemAdmin(roles.systemRoles)
              "
              mat-menu-item
              (click)="editSite(element)"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button
              *ngIf="roles.isAdmin"
              mat-menu-item
              (click)="editLogo(element, 'sitelogo')"
            >
              <mat-icon>account_box</mat-icon>
              Logo
            </button>
            <button
              *ngIf="
                (
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                element?.active &&
                element.active
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>delete</mat-icon>Disable
            </button>
            <button
              *ngIf="
                ((
                  roles.isAdmin ||
                  rolesService.systemAdmin(roles.systemRoles)) &&
                  !element?.active) ||
                (element?.active && !element.active)
              "
              mat-menu-item
              (click)="confirmation('disable', element)"
            >
              <mat-icon>recycling</mat-icon>Enable
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      *ngIf="!contentLoading"
      (page)="handlePage($event)"
      [length]="paginatorConfig.length"
      [pageIndex]="paginatorConfig.pageIndex"
      [pageSize]="paginatorConfig.pageSize"
      [pageSizeOptions]="paginatorConfig.pageSizeOptions"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>

  <div
    class="no-results"
    *ngIf="dataSource?.length == 0 && !contentLoading && !isLoading"
  >
    <h1>No results to show...</h1>
  </div>

  <div
    *ngIf="displayDetails && !contentLoading && !isLoading"
    class="sidebar-container"
  >
    <h4
      class="item-title"
      *ngIf="
        !detailsLoading &&
        !selectedItem?.serialNumber &&
        !selectedItem?.consumableType
      "
    >
      <strong>
        <!--{{type2Elem?.consumableType?.name}}-->
      </strong>
    </h4>
    <h4
      class="item-title"
      *ngIf="displayDetails && selectedItem?.serialNumber && !contentLoading"
    >
      <strong>
        {{ selectedItem?.type }} - {{ selectedItem?.model }} -
        {{ selectedItem?.serialNumber }}
      </strong>
    </h4>

    <div class="my-tabs-container custom">
      <mat-tab-group
        animationDuration="0ms"
        (selectedTabChange)="tabChanged($event)"
        mat-stretch-tabs
        [selectedIndex]="detailsTab"
        class="example-stretched-tabs mat-elevation-z4 transparent"
        mat-stretch-tabs
      >
        <mat-tab>
          <ng-template mat-tab-label> Details </ng-template>
          <mat-spinner *ngIf="detailsLoading"></mat-spinner>
          <div class="details-container" *ngIf="!detailsLoading">
            <div class="item-details">
              <div>
                <strong>Title: </strong> {{ selectedItem?.name }}
              </div>
              <div>
                <strong>Licenses: </strong>
                {{ selectedItem?.licenseLevel?.name }}
              </div>
              <div></div>
              <div>
                <strong>Company Title: </strong>{{ selectedItem?.companyTitle }}
              </div>
              <div><strong>Email: </strong>{{ selectedItem?.email }}</div>
              <div>
                <strong>Telephone: </strong>{{ selectedItem?.telephone }}
              </div>
              <div><strong>URL: </strong>{{ selectedItem?.url }}</div>
              <div>
                <strong>Address: </strong>{{ selectedItem?.addressLine1 }}
                {{ selectedItem?.addressLine2 }}
                {{ selectedItem?.addressLine3 }} {{ selectedItem?.postCode }}
              </div>
              <div><strong>Country: </strong>{{ selectedItem?.country }}</div>
              <br />
            </div>

            <div class="item-preview">
              <!--*ngIf="selectedItem?.urlAttachment"-->
              <mat-icon
                *ngIf="!selectedItem?.urlLogo?.fileString"
                class="default_avatar"
                >person</mat-icon
              >
              <img
                *ngIf="selectedItem?.urlLogo?.fileString"
                class="preview"
                (click)="preview(selectedItem)"
                onContextMenu="return false;"
                [src]="
                  'data:' +
                  selectedItem.urlLogo.type +
                  ';base64,' +
                  selectedItem.urlLogo.fileString
                "
              />
              <!--[src]="'data:image/'+ selectedItem.typeFile +';base64,' + selectedItem.urlAttachment" -->
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label> Users </ng-template>
          <mat-spinner
            *ngIf="isLoadingUsers && !contentLoading && !isLoading"
          ></mat-spinner>
          <div class="details-container" *ngIf="!isLoadingUsers">
            <br />

            <div
              class="no-results"
              *ngIf="
                dataSourceUsers &&
                dataSourceUsers?.length == 0 &&
                !isLoadingUsers &&
                !isLoading
              "
            >
              <h1>No results to show...</h1>
            </div>

            <table
              mat-table
              *ngIf="dataSourceUsers && dataSourceUsers?.length > 0"
              [dataSource]="dataSourceUsers"
              class="mat-elevation-z8 full-width"
            >
              <!-- Username Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.userDto?.firstName">
                    {{ element?.userDto?.firstName }} {{ element?.userDto?.lastName }}</span
                  >
                </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td class="align-left" mat-cell *matCellDef="let element">
                  <span *ngIf="element?.userDto?.email">
                    {{ element?.userDto?.email }}</span
                  >
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Roles</th>
                <td class="align-left" mat-cell *matCellDef="let element">
                  <span *ngIf="element?.moduleRoleDtos[0]?.displayName">
                    <span *ngFor="let role of element?.moduleRoleDtos">
                      {{ role.displayName }}<br
                    /></span>
                  </span>
                </td>
              </ng-container>

              <!-- Role Column -->
              <!-- <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>Role</th>
                        <td mat-cell class="tddescription" *matCellDef="let element"
                        [ngClass]="{'active-row': element?.id == activeRowId && activeRowId.length > 0}"
                        (click)="displayDetails=true; loadDetails(element)">
                          <span *ngIf="element?.privilege?.name">
                          {{element?.privilege?.name}}
                          </span>
                        </td>
                    </ng-container>-->

              <!-- Location Column -->
              <!--  <ng-container matColumnDef="location">
                      <th mat-header-cell *matHeaderCellDef>Location</th>
                      <td mat-cell class="tddescription" *matCellDef="let element"
                      [ngClass]="{'active-row': element?.id == activeRowId && activeRowId.length > 0}"
                      (click)="displayDetails=true; loadDetails(element)">
                        <span *ngIf="element?.tenant?.name">
                        {{element?.tenant?.name}}
                        </span>
                      </td>
                  </ng-container>-->

              <!-- Active Column -->
              <!--  <ng-container matColumnDef="active">
                      <th width="50px" mat-header-cell *matHeaderCellDef>Active</th>
                      <td width="50px" mat-cell class="tddescription" *matCellDef="let element"
                      [ngClass]="{'active-row': element?.id == activeRowId && activeRowId.length > 0}"
                      (click)="displayDetails=true; loadDetails(element)">
                        <span *ngIf="!element?.deleted && !element.deleted">
                          <mat-icon class="icon">done</mat-icon>
                        </span>
                        <span *ngIf="element?.deleted || element?.deleted && element.deleted">
                          <mat-icon class="icon">close</mat-icon>
                        </span>              
                      </td>
                  </ng-container> -->

              <!-- Actions Column -->
              <!-- <ng-container matColumnDef="actions">
                      <th mat-header-cell class="last" width ="40px" *matHeaderCellDef>
                      </th>
                      <td mat-cell class="last" width ="40px"
                      [ngClass]="{'active-row': element?.id == activeRowId && activeRowId.length > 0}"
                      *matCellDef="let element">
            
                  list actions menu-->
              <!--
                    <button class="user_menu custom" mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
                      <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu class="menu" x-position="before" #menu="matMenu">
            
                      <button mat-menu-item (click)="editUser(element)">
                        <mat-icon>edit</mat-icon>
                        Edit
                      </button>                         
                  </mat-menu>
                 
                  </td>
                  </ng-container>
                    -->
              <tr mat-header-row *matHeaderRowDef="displayedColumnsUsers"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsUsers"
              ></tr>
            </table>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label> Work Areas </ng-template>
          <mat-spinner
            *ngIf="isLoadingWorkareas && !contentLoading && !isLoading"
          ></mat-spinner>
          <div class="details-container" *ngIf="!isLoadingWorkareas">
            <br />

            <div
              class="no-results"
              *ngIf="
                dataSourceWorkareas &&
                dataSourceWorkareas?.length == 0 &&
                !isLoadingWorkareas &&
                !isLoading
              "
            >
              <h1>No results to show...</h1>
            </div>

            <table
              mat-table
              *ngIf="dataSourceWorkareas && dataSourceWorkareas?.length > 0"
              [dataSource]="dataSourceWorkareas"
              class="mat-elevation-z8 full-width"
            >
              <!-- title Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.name"> {{ element?.name }}</span>
                </td>
              </ng-container>

              <!-- Site Column -->
              <ng-container matColumnDef="site">
                <th mat-header-cell *matHeaderCellDef>Client</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.tenantDto?.companyTitle">
                    {{ element?.tenantDto?.companyTitle }}</span
                  >
                </td>
              </ng-container>

              <!-- Site Supplier Column -->
              <ng-container matColumnDef="siteSupplier">
                <th mat-header-cell *matHeaderCellDef>Supplier</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.siteSupplier?.companyTitle">
                    {{ element?.siteSupplier?.companyTitle }}</span
                  >
                </td>
              </ng-container>

              <!-- Client Acceptance Column -->
              <ng-container matColumnDef="clientAcceptance">
                <th width="120px" mat-header-cell *matHeaderCellDef>Client Acceptance</th>
                <td width="120px" mat-cell class="align-center" *matCellDef="let element">
                  <span
                    *ngIf="
                      element?.clientAcceptance && element.clientAcceptance
                    "
                  >
                    <mat-icon class="icon">done</mat-icon>
                  </span>
                  <span
                    *ngIf="
                      !element?.clientAcceptance ||
                      (element?.clientAcceptance && !element.clientAcceptance)
                    "
                  >
                    <mat-icon class="icon">close</mat-icon>
                  </span>
                </td>
              </ng-container>

              <!-- Active Column -->
              <ng-container matColumnDef="active">
                <th width="50px" mat-header-cell *matHeaderCellDef>Active</th>
                <td
                  width="50px"
                  mat-cell
                  class="tddescription"
                  *matCellDef="let element"
                >
                  <span *ngIf="element?.deleted && element.deleted">
                    <mat-icon class="icon">close</mat-icon>
                  </span>
                  <span
                    *ngIf="
                      !element?.deleted ||
                      (element?.deleted && !element.deleted)
                    "
                  >
                    <mat-icon class="icon">done</mat-icon>
                  </span>
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th
                  mat-header-cell
                  class="last"
                  width="40px"
                  *matHeaderCellDef
                ></th>
                <td
                  mat-cell
                  class="last"
                  width="40px"
                  *matCellDef="let element"
                >
                  <!--  list actions menu-->
                  <!-- 
                    <button class="user_menu custom" mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
                      <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu class="menu" x-position="before" #menu="matMenu">
            
                      <button mat-menu-item (click)="editWorkarea(element)">
                        <mat-icon>edit</mat-icon>
                        Edit
                      </button>                         
                  </mat-menu>
                --></td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsWorkareas"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsWorkareas"
              ></tr>
            </table>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label> Roles </ng-template>
          <mat-spinner
            *ngIf="isLoadingRoles && !contentLoading && !isLoading"
          ></mat-spinner>
          <div class="details-container" *ngIf="!isLoadingRoles">
            <br />

            <div
              class="no-results"
              *ngIf="
                dataSourceRoles &&
                dataSourceRoles?.length == 0 &&
                !isLoadingRoles &&
                !isLoading
              "
            >
              <h1>No results to show...</h1>
            </div>

            <table
              mat-table
              *ngIf="dataSourceRoles && dataSourceRoles?.length > 0"
              [dataSource]="dataSourceRoles"
              class="mat-elevation-z8 full-width"
            >
              <!-- Role Column -->
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Roles</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.role"> {{ element?.role }}</span>
                </td>
              </ng-container>

              <!-- Users Column -->
              <ng-container matColumnDef="users">
                <th mat-header-cell *matHeaderCellDef>Users</th>
                <td class="align-left" mat-cell *matCellDef="let element">
                  <div *ngFor="let user of element?.users; let i = index">
                    <span> {{ user }}</span>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsRoles"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsRoles"
              ></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
