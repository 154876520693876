import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment?.production) {
  //TODO STUFF WHEN PROD
}
if (environment?.test) {
  //TODO STUFF WHEN test  
}
if (environment?.development) {
  //TODO STUFF WHEN dev
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
