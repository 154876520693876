
<div class="dialogform-container" *ngIf="!isLoading">
  <div class="v-window-header">
    <span *ngIf="dialogData.action == 'create_observation' || dialogData.action == 'update_observation'">Observation</span>
    <span *ngIf="dialogData.action == 'create_attachment' || dialogData.action == 'update_attachment'">Attachment</span>
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>

<mat-spinner *ngIf="isLoading"></mat-spinner>

<div  *ngIf="!isLoading" class="attachments-container">

    <mat-form-field *ngIf="dialogData.action == 'create_attachment'">
        <mat-label class="file-textlabel">Choose a file</mat-label>
        <button class="blue" (click)="input.click()" mat-raised-button matPrefix >
          <mat-icon>attach_file</mat-icon>
        </button>
        <input (click)="input.click()" class="file-input" type="text" readonly matInput [formControl]="edit" />
        <input 
          type="file"
          multiple="false"
          hidden
          #input
          accept="image/png, image/jpeg"
          (change)="checkFile(input.files)"/>
        <mat-error>this field is required</mat-error>
      </mat-form-field>

      <span class="span-label"> Title
        <span class="required_item"> *</span>
      </span>
      <mat-form-field appearance="outline" >
        <input matInput type="text"        
        [value]="prefilledTitle"
        (keyup)="title = $event.target.value.toString(); validateFormdata()"
        color="primary">
      </mat-form-field>

      <span class="span-label"> Description
          <span class="required_item"> *</span>
      </span>
    <mat-form-field>
        <textarea
        [value]="prefilledDescription"        
        rows="5"
        matInput        
        cdkTextareaAutosize
        (keyup)="description = $event.target.value.toString(); validateFormdata()"
        #autosize="cdkTextareaAutosize"></textarea>
    </mat-form-field>   

  <div mat-dialog-actions>

    <button  mat-raised-button *ngIf="dialogData.action == 'create_observation' ||
    dialogData.action == 'update_observation'" [ngClass]="{'disabled': !isValid}"
    class="upload right" color="primary" (click)="saveObservation();">
     Save
     <mat-icon class="icon action">save</mat-icon>
    </button>
      
    <button  mat-raised-button *ngIf="dialogData.action == 'create_attachment'" [ngClass]="{'disabled': !isValid}"
    class="upload right" color="primary" (click)="uploadFile();">
     Upload
     <mat-icon class="icon action">save</mat-icon>
    </button>

    <button  mat-raised-button *ngIf="dialogData.action == 'update_attachment'"  [ngClass]="{'disabled': !isValid}"
      class="upload right" color="primary" (click)="updateAttachmentsFile();">
      Update
      <mat-icon class="icon action">save</mat-icon>
    </button>
    <button mat-raised-button color="primary" (click)="closePopup()">
      Close
      <mat-icon class="icon action">close</mat-icon>
    </button>
  </div>

</div>