<div class="main-menu">
<mat-toolbar color="primary" class="logged-user">
    <span *ngIf="loggedUserName">
      <button mat-button class="header-text" routerLink="/main" (click)="updateMaintab()">{{title}}</button>
      <!--<button mat-button class="header-text" (click)="gotoHomepage()">{{title}}</button>-->
      <!--<button mat-button routerLink="/main"><mat-icon>home</mat-icon></button>-->
    </span>
    <div *ngIf="loggedUserName"
     class="form-sites-workareas">
     
      <mat-form-field class="menu-dropdown" appearance="outline">
        <mat-select [disabled]="taskWorkareaId" panelClass="menu-dropdown-styles"  placeholder="-" [(ngModel)]="selectedSite.companyTitle">
          <mat-option (mousedown)="selectSite('')">
            - 
          </mat-option>
          <mat-option (mousedown)="selectSite(elem)" *ngFor="let elem of sitesList"
            [value]="elem.companyTitle">
            {{elem.companyTitle}}
          </mat-option>
        </mat-select>
      </mat-form-field>
        
      <mat-form-field class="menu-dropdown" appearance="outline">
        <mat-select [disabled]="taskWorkareaId || !selectedSite.id" panelClass="menu-dropdown-styles" placeholder="-" [(ngModel)]="selectedWorkarea.name">
          <mat-option (mousedown)="selectWorkarea('')">
            - 
          </mat-option>
          <mat-option (mousedown)="selectWorkarea(elem)" *ngFor="let elem of workareasList"
          [value]="elem.name">
          {{elem.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
    </div>

    <button *ngIf="loggedUserName"
    class="user_menu header-text" mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
      <span class="logged-user">{{loggedUserName}}</span>
      <mat-icon>manage_accounts</mat-icon>    
      <mat-icon class="right custom">expand_more</mat-icon>
    </button>
  </mat-toolbar>
  <mat-menu class="menu main-menu" x-position="before" #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>help</mat-icon>
      Help - version {{version}}
    </button>
    <!--routerLink="/reset-password"-->
    <button mat-menu-item
    (click)="resetPassword()">
      <mat-icon>lock</mat-icon>
      Reset Password
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>directions_walk</mat-icon>
      Logout
    </button>
  
  </mat-menu>
</div>