import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { startWith, map, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ModelUser } from 'src/app/models/model.user';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

import { ApiSitesService } from 'src/app/services/api.sites.service';

export interface DialogData {
  action: string;
  userId: string;
  firstLastName: string;
}

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})

export class EditUserDialogComponent {
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  rolesUpdate!: Subscription;
  roleControl =  new FormControl('');
  locationControl =  new FormControl('');  
  @ViewChild('roleInput')
  roleInput!: ElementRef<HTMLInputElement>;
  @ViewChild('locationInput')
  locationInput!: ElementRef<HTMLInputElement>;
  readonly = false;  
  userDetails: any = new ModelUser;
  validForm = false;
  isLoading = false;
  noResults = false;
  filteredRoles: Observable<any[]> | undefined;
  availableRoles: any = [];
  filteredLocations: Observable<any[]> | undefined;
  availableLocations: any = [];
  userName: any = '';
  userEmail: any = '';
  usernameExists = false;
  emailExists = false;
  validEmail = true;
  paginatorConfig: any = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
  };
  constructor(
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditUserDialogComponent>,
    private servicesService: ApiServicesService,
    private snackBar: MatSnackBar,
    private usersService: ApiUsersService,    

    private sitesService: ApiSitesService,

    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) { }

  ngOnInit() {

    if (this.dialogData.action == 'edit') {
      const tenantId = localStorage.getItem('selectedSiteId');  
      const params = {
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        userId: this.dialogData.userId
      }
      this.getUserDetails(params);
    }

    this.getRoles();
    this.getLocations();
    this.rolesUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the roles list
      if(name == 'update_roles') {
        this.getRoles();
      }   
      // updates the locations list
      if(name == 'update_locations') {
        this.getLocations();
      }
    }); 
  }

  ngOnDestroy(){
    this.rolesUpdate.unsubscribe();
  }
  getUserDetails(params: any) {

    this.usersService.getUserDetails(params).subscribe(
      (result: any) => {
        if (result) {
          this.userDetails = result;
        } else {
          this.userDetails = [];
        }
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.userDetails = [];        
        this.isLoading = false;
    });
  }

  getRoles() {
    this.isLoading = true;
    const type = 'privilege';
    const params = {
      lookupName: type
    };


    this.usersService.getLookupList(params).subscribe(
      (result: any) => {
       
        if(result && result.lookup && result.lookups.length > 0) {
          this.availableRoles = [...result.lookups];
          this.filteredRoles = <any>[...result.lookups];
        } 
        this.filteredRoles = this.roleControl.valueChanges.pipe(
          startWith(''),
          map((item: string | null) => (item ? this._filterRoles(item) : this.availableRoles.slice())),
        );   
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoading = false;
    });
          
  }

  getLocations() {
    this.isLoading = true;
    const tenantId = localStorage.getItem('selectedSiteId');  
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      first: this.paginatorConfig.pageIndex,
      max: this.paginatorConfig.pageSize,
      sort: 'asc',
      sortColumn: 'dateCreated'
    };
    this.sitesService.getTenantsList(params).subscribe(
      (result: any) => {
       
        if(result.content && result.content.length > 0) {
          this.availableLocations = [...result.content];
          this.filteredLocations = <any>[...result.content];
        } 
        this.filteredLocations = this.locationControl.valueChanges.pipe(
          startWith(''),
          map((item: string | null) => (item ? this._filterLocations(item) : this.availableLocations.slice())),
        );   
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving site list ' + msg);
        this.isLoading = false;
    });
          
  } 

  private _filterRoles(element: any): string[] {    
    const searchValue = element.toLowerCase();             
    let returnElems = this.availableRoles.filter(
      (item:any) => item.name.toLowerCase().includes(searchValue));
  
    return returnElems;
  }
  
  private _filterLocations(element: any): string[] {    
    const searchValue = element.toLowerCase();             
    let returnElems = this.availableLocations.filter(
      (item:any) => item.name.toLowerCase().includes(searchValue));
  
    return returnElems;
  }

  validateFormdata(){
    this.validForm = false;
    // role currently removed - this.userDetails.privilege.id.trim().length > 0 
    if (this.validEmail && !this.usernameExists && !this.emailExists && 
       this.userDetails.user?.id.length > 0 && this.userDetails.user?.email.length > 0 && 
      this.userDetails.user.lastName?.trim().length > 0 && this.userDetails.user.firstName?.trim().length > 0 &&
      this.userDetails.jobTitle?.trim().length > 0 && this.userDetails.qualification?.trim().length > 0 &&
      this.userDetails.tenant.id?.trim().length > 0) {
      this.validForm = true;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveChanges() {
    const properties = {
      name: 'update_users',
      action: this.dialogData.action
    } 
    //*******currently hardcodes the evolve reader role by default  
    this.userDetails.privilege.id = this.availableRoles[this.availableRoles.length-1].id;

    this.userDetails.user.displayName = this.userDetails.user.firstName + ' ' + this.userDetails.user.lastName;
    this.userDetails.user.tenantId = this.userDetails.tenant.id;
    this.usersService.createUser(this.userDetails).subscribe(
      (result: any) => {                                       
        this.snackBar.open('user created', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateUsers(properties);
        this.closePopup();
    },
      // error
      (msg) => {
        console.log( msg);
    }); 

  }

  updateChanges() {
    const properties = {
      name: 'update_users',
      action: this.dialogData.action
    } 
    this.userDetails.user.displayName = this.userDetails.user.firstName + ' ' + this.userDetails.user.lastName;
    this.usersService.updateUser(this.userDetails).subscribe(
      (result: any) => {                                       
        this.snackBar.open('user updated', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateUsers(properties);
        this.closePopup();
    },
      // error
      (msg) => {
        console.log( msg);
    }); 

  }

  handleArrowNavigation(event: any, type: string){
    let value: any = '';
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }
    if (type == 'role') {
      this.roleInput.nativeElement.value = value;
      this.addRole(event, value);
    } else {
      this.locationInput.nativeElement.value = value;
      this.addLocation(event, value);
    }        
  }

  addRole(event:any, value?: any): void {
    if (event.isUserInput) { 
      let element: any;
      if (typeof value.id == 'undefined'){
        element = this.availableRoles.find(
          (item: any) => item.name.trim().toLowerCase() == value.trim().toLowerCase()
        );     
      } else {
        element = value;
      }
      this.userDetails.privilege.id = element.id;
      this.validateFormdata();
    }
  }

  addLocation(event:any, value?: any): void {   
    if (event.isUserInput) { 
      let element: any;
      if (typeof value.id == 'undefined'){
        element = this.availableLocations.find(
          (item: any) => item.name.trim().toLowerCase() == value.trim().toLowerCase()
        );     
      } else {
        element = value;
      }
      this.userDetails.tenant.id = element.id;
      this.validateFormdata();
    }
  }

  checkIfExists(value: string, type: string){
    this.validForm = false;
    this.validEmail = true;
    if (type == 'username') {
      this.userName = value.trim();
      this.userDetails.user.id = this.userName;
    } else {
      this.userEmail = value.trim();    
      this.validEmail = this.regexp.test(this.userEmail);
      this.userDetails.user.email = this.userEmail;
    }
        
    if((type == 'username' && this.userName && this.userName.length > 0) ||
     (type == 'email' && this.userEmail && this.userEmail.length > 0 && this.validEmail)){
      //this.usernameExists = false;
      /*if (this.taskNumber == this.dialogData.element.taskNumber) {
        this.taskNumberExists = true;
      }*/
      if (this.userName == '') {
        this.userName = null;
      }
      if (this.userEmail == '') {
        this.userEmail = null;
      }      
      const params = {        
        username: this.userName,
        email: this.userEmail
      };
      
      this.usersService.checkIfExists(params).subscribe(
        (result: any) => {
          if (type == 'username') {
            this.usernameExists = result;
           
          } else {
            this.emailExists = result;

          }
          
          this.validateFormdata();
      },
        // error
        (msg) => {
          console.log( msg);
      }); 
    }
  }
}
