import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ApiResultsService } from 'src/app/services/api.results.service';
import { ModelReportObservable } from 'src/app/models/model.report.observable';
import { ModelDatapointAttachment } from 'src/app/models/model.datapoint.attachment';
export interface DialogData {
  data: any;
  assetType: string;
  name: string;
  id: string;
  action: string;
  element: any;
  selectedElement: any;
  
}
@Component({
  selector: 'result-observations-attachments',
  templateUrl: './result-observations-attachments.html',
  styleUrls: ['./result-observations-attachments.scss']
})
export class ResultObservationsAttachmentsComponent implements OnInit {
  fileToUpload: any;
  prefilledTitle = '';
  prefilledName = '';
  prefilledSupplier = '';
  prefilledDescription = '';
  edit: FormControl = new FormControl("", Validators.required);
  isValid = false;
  newModel: any = {};
  name: string = '';
  title: string = '';
  description: string = '';

  isLoading = false;
  requiredDescription = false;
  newAttachment:any = new ModelDatapointAttachment();
  constructor(
    private dialogRef: MatDialogRef<ResultObservationsAttachmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private apiResultsService: ApiResultsService,
    private apiFilesService: ApiFilesService
    ){}
  
    ngOnInit(){    
      
    if (this.dialogData.action == 'create_observation') {
      this.newModel = new ModelReportObservable();
    }
    if (this.dialogData.action == 'create_attachment') {
      this.newModel = new ModelDatapointAttachment();
    }

    if (this.dialogData.action.includes('update_observation')) {
      this.newModel.model = this.dialogData.selectedElement;
      this.prefilledTitle = this.dialogData.selectedElement.title;      
      this.prefilledDescription = this.dialogData.selectedElement.description;
    }
    if (this.dialogData.action.includes('update_attachment')) {
      this.newModel = this.dialogData.selectedElement;
      this.prefilledTitle = this.dialogData.selectedElement.title;      
      this.prefilledDescription = this.dialogData.selectedElement.description;
    }


  }  

  checkFile(files: any): void {
    
    if (files.length) {
      this.fileToUpload = files[0];
      this.validateFormdata();
      this.edit.patchValue(`${this.fileToUpload.name}`);
    } else {
      this.edit.patchValue('');
      this.validateFormdata();
    }
  }
 
  updateAttachmentsFile(){
    this.isLoading = true;
    this.isValid = false;
    this.newAttachment.id = this.dialogData.selectedElement.id;
    this.newAttachment.datapointValuesDto = {id: this.dialogData.element.id};
    this.newAttachment.createdBy = this.dialogData.selectedElement.createdBy;
    this.newAttachment.updatedBy = localStorage.getItem('username');
    this.newAttachment.name = this.dialogData.selectedElement.name;
    this.newAttachment.title = this.title ? this.title : this.dialogData.selectedElement.title;
    this.newAttachment.description = this.description ? this.description : this.dialogData.selectedElement.description;
    this.newAttachment.hash = this.dialogData.selectedElement.hash;
    this.newAttachment.type = this.dialogData.selectedElement.type;
    
    const tenantId = localStorage.getItem('taskTenantId');
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      body: this.newAttachment
    }
    this.apiFilesService.updateResultServiceAttachment(params).subscribe(
      (result) => {

        console.log( 'file updated');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open( 'file updated ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_results_attachments');
    },
      // error
      (msg) => {
  
        if (msg.status == '200') {
          console.log( 'file updated');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open( 'file updated ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_results_attachments');
        }
        console.log( msg.statusText);
    });
  }

  /**
   * uploads the result service attachments
   */
  uploadFile(){
    this.isLoading = true;
    this.isValid = false;
    const formData = new FormData();  
    formData.append('file', this.fileToUpload);   

    this.newAttachment.datapointValuesDto = {id: this.dialogData.element.id};
    this.newAttachment.createdBy = localStorage.getItem('username');
    this.newAttachment.name = this.fileToUpload.name;
    this.newAttachment.title = this.title ? this.title :  this.dialogData.selectedElement.title;
    this.newAttachment.description = this.description ? this.description : this.dialogData.selectedElement.description;
    this.newAttachment.type = this.fileToUpload.type;
    
    formData.append('datapointAttachment', new Blob([
      JSON.stringify( this.newAttachment)
    ], {
      type: "application/json"
    }));

    const tenantId = localStorage.getItem('taskTenantId');
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      taskId: localStorage.getItem('taskId'),
      form: formData
    }

    this.apiFilesService.uploadResultServiceAttachment(params).subscribe(
      (result) => {

        console.log( 'utprobe uploaded');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open( 'utprobe uploaded ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_results_attachments');
    },
      // error
      (msg) => {
  
        if (msg.status == '200') {
          console.log( 'image uploaded');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open( 'image uploaded ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_results_attachments');
        } else {
          console.log( msg.statusText);
        }
        
    });

  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveObservation(){

    if ( this.dialogData.action == 'update_observation') {
      this.newModel.model.id = this.dialogData.selectedElement.id;
    }
    if ( this.dialogData.action == 'create_observation') {
      this.newModel.model.createdBy = localStorage.getItem('username');
    }

    const datapointValueId = this.dialogData.element.id;    
    this.newModel.model.datapointValuesDto = {id: datapointValueId};
    this.newModel.model.title = this.title ? this.title : this.dialogData.selectedElement.title;
    this.newModel.model.description = this.description ? this.description : this.dialogData.selectedElement.description;
    const tenantId = localStorage.getItem('taskTenantId');       
    const workareaId = localStorage.getItem('taskWorkareaId');
    const params = {      
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      datapointValueId: datapointValueId,
      body: this.newModel.model
    }
    if ( this.dialogData.action == 'create_observation') {
      //Create DataPoint Catalog Observation
      this.apiResultsService.createDatapointCatalogObservation(params).subscribe(
        (result) => {
          console.log( 'observation added');
          this.closePopup();
          this.snackBar.open( 'observation added ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_results_observations');
      },
        // error
        (msg) => {
          if (msg.status == '200') {
            console.log( 'image updated');
            this.closePopup();
            this.snackBar.open( 'image updated ', '', {duration: 3000});
            // sends an update
            this.componentsCommunication.setUpdateAssets('update_results_observations');
          }
          console.log( msg.statusText);
      });
    }

    if ( this.dialogData.action == 'update_observation') {
      //update DataPoint Catalog Observation
      this.apiResultsService.updateDatapointCatalogObservation(params).subscribe(
        (result) => {
          console.log( 'observation updated');
          this.closePopup();
          this.snackBar.open( 'observation updated ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_results_observations');
      },
        // error
        (msg) => {
          if (msg.status == '200') {
            console.log( 'image updated');
            this.closePopup();
            this.snackBar.open( 'image updated ', '', {duration: 3000});
            // sends an update
            this.componentsCommunication.setUpdateAssets('update_results_observations');
          }
          console.log( msg.statusText);
      });
    }

  }

  validateFormdata(){
    this.isValid = false;
    if (this.dialogData.action == 'create_observation' ) {
      if ((this.title && this.title.length > 0) && (this.description && this.description.length > 0)) {
        this.isValid = true;
      }
    }

    if (this.dialogData.action == 'update_observation' ||
     this.dialogData.action == 'update_attachment') {
      const title = this.title ? this.title.trim() : this.dialogData.selectedElement.title.trim();
      const description = this.description ? this.description.trim() : this.dialogData.selectedElement.description.trim();
      if (title.length > 0 && description.length > 0) {
        this.isValid = true;
      }
    }
    if (this.fileToUpload && this.dialogData.action == 'create_attachment') {
      if (this.title.trim().length > 0 && this.description.trim().length > 0) {
        this.isValid = true;
      }
    }

  }
}
