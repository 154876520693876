import { Injectable } from '@angular/core';
import { AvailableRolesModel } from '../models/model.available.roles';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  rolesModel = new AvailableRolesModel();
  constructor() {}

  /**
   * seeks for the roles for a specific tenantId
   * @param allRoles receives all the available roles
   * @param tenantId specific tenant
   * @returns returns the roles for the selected tenant
   */
  checkTenantRoles(allRoles: any, tenantId: string): any{
    let tenantRoles: any = [];
    if (tenantId && tenantId.length > 0) { 
      tenantRoles = allRoles.find((elem:any) => elem?.id == tenantId || elem?.tenantId == tenantId);
    }
    return tenantRoles;
  }

  
  /**
   * seeks for the roles for a specific workareaId
   * @param allRoles receives all the available roles
   * @param workareaId specific tenant
   * @returns returns the roles for the selected workarea
   */
  checkWorkareaRoles(allRoles: any, workareaId: string): any{
    let workareaRoles: any = [];
    if (workareaId && workareaId.length > 0) { 
      workareaRoles = allRoles.find((elem:any) => elem?.id == workareaId || elem?.workAreaId == workareaId);
    }
    return workareaRoles;
  }


  /**generic admin */
  isReader(userRoles: any): boolean{
    let isReader = false;
    if (userRoles && userRoles.length > 0) {   
      if (userRoles.includes(this.rolesModel.system[0])) {
        isReader = true;
      }
    }
    return isReader;
  }

  isOperator(userRoles: any): boolean{
    let isOperator = false;
    if (userRoles && userRoles.length > 0) {   
      if (userRoles.includes(this.rolesModel.system[1])) {
        isOperator = true;
      }
    }
    return isOperator;
  }

  supervisor(userRoles: any): boolean{
    let supervisor = false;
    if (userRoles && userRoles.length > 0) {   
      if (userRoles.includes(this.rolesModel.system[2])) {
        supervisor = true;
      }
    }
    return supervisor;
  }

  systemAdmin(userRoles: any): boolean{
    let sysAdmin = false;
    if (userRoles && userRoles.length > 0) {   
      if (userRoles.includes(this.rolesModel.system[3])) {
        sysAdmin = true;
      }
    }
    return sysAdmin;
  }



  /*****module role system *****/
  
  //module-role-system-reader
  mSysReader(userRoles: any): boolean{
    let sysReader = false;
    if (userRoles && userRoles.length > 0) {
      const tenantId = localStorage.getItem('selectedSiteId'); 
      const selectedTenantRoles = userRoles.find((elem:any)=> elem.tenantId == tenantId);
      if (selectedTenantRoles?.roles?.length > 0 && selectedTenantRoles.roles.includes(this.rolesModel.model[0])) {
        sysReader = true;
      }
    }
    return sysReader;
  }

  //module-role-system-editor
  mSysEditor(userRoles: any): boolean{
    let mSysEditor = false;
    if (userRoles && userRoles.length > 0) {    
      const tenantId = localStorage.getItem('selectedSiteId'); 
      const selectedTenantRoles = userRoles.find((elem:any)=> elem.tenantId == tenantId);
      if (selectedTenantRoles?.roles?.length > 0 && selectedTenantRoles.roles.includes(this.rolesModel.model[1])) {
        mSysEditor = true;
      }
    }
    return mSysEditor;
  }

  //module-role-system-supervisor
  mSysSupervisor(userRoles: any): boolean{
    let sysSupervisor = false;
    if (userRoles && userRoles.length > 0) {    
      const tenantId = localStorage.getItem('selectedSiteId'); 
      const selectedTenantRoles = userRoles.find((elem:any)=> elem.tenantId == tenantId);
      if (selectedTenantRoles?.roles?.length > 0 && selectedTenantRoles.roles.includes(this.rolesModel.model[2])) {
        sysSupervisor = true;
      }
    }
    return sysSupervisor;
  }

  //module-role-system-qa
  mSysQAAdmin(userRoles: any): boolean{
    let sysQAAdmin = false;
    if (userRoles && userRoles.length > 0) {   
      const tenantId = localStorage.getItem('selectedSiteId'); 
      const selectedTenantRoles = userRoles.find((elem:any)=> elem.tenantId == tenantId);
      if (selectedTenantRoles?.roles?.length > 0 && selectedTenantRoles.roles.includes(this.rolesModel.model[3])) {
        sysQAAdmin = true;
      }
    }
    return sysQAAdmin;
  }



  /*****IM role system *****/
  
  //module-role-im-reader
  IMReader(userRoles: any): boolean{
    let sysReader = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {  
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.inventory[0])) {
        sysReader = true;
      }
    }
    return sysReader;
  }

  //module-role-im-editor
  IMEditor(userRoles: any): boolean{
    let mSysEditor = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {  
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.inventory[1])) {
        mSysEditor = true;
      }
    }
    return mSysEditor;
  }

  //module-role-im-supervisor
  IMSupervisor(userRoles: any): boolean{
    let sysSupervisor = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {  
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.inventory[2])) {
        sysSupervisor = true;
      }
    }
    return sysSupervisor;
  }

  //module-role-im-qa
  IMQAAdmin(userRoles: any): boolean{
    let sysQAAdmin = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {  
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.inventory[3])) {
        sysQAAdmin = true;
      }
    }
    return sysQAAdmin;
  }




 /*****WM role system *****/
  
  //module-role-wm-reader
  WMReader(userRoles: any): boolean{
    let sysReader = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {  
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.work[0])) {
        sysReader = true;
      }
    }
    return sysReader;
  }

  //module-role-wm-editor
  WMEditor(userRoles: any): boolean{
    let mSysEditor = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {  
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.work[1])) {
        mSysEditor = true;
      }
    }
    return mSysEditor;
  }

  //module-role-wm-supervisor
  WMSupervisor(userRoles: any): boolean{    
    let sysSupervisor = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {   
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.work[2])) {
        sysSupervisor = true;
      }
    }
    return sysSupervisor;
  }

  //module-role-wm-qa
  WMQAAdmin(userRoles: any): boolean{
    let sysQAAdmin = false;
    const selectedSiteId = localStorage.getItem('selectedSiteId') ? localStorage.getItem('selectedSiteId') : '';
    const rolesArray = userRoles.find((element:any) => element.tenantId == selectedSiteId);
    if (userRoles && rolesArray?.roles?.length > 0) {  
      if (rolesArray?.roles?.length > 0 && rolesArray.roles.includes(this.rolesModel.work[3])) {
        sysQAAdmin = true;
      }
    }
    return sysQAAdmin;
  }
   




  /******************************************      WORKAREAS     ******************************************************/


  /*****module role system *****/
  
  //module-role-system-reader
  workareaSysReader(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let reader = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.model[0])) {
          reader = true;
        }
      }
    }
    return reader;
  }

  //module-role-system-editor
  workareaSysEditor(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let editor = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.model[1])) {
          editor = true;
        }
      }
    }
    return editor;
  }

  //module-role-system-supervisor
  workareaSysSupervisor(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let spervisor = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.model[2])) {
          spervisor = true;
        }
      }
    }
    return spervisor;
  }

  //module-role-system-qa
  workareaSysQAAdmin(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let sysQAAdmin = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.model[3])) {
          sysQAAdmin = true;
        }
      }
    }
    return sysQAAdmin;
  }



  /*****IM role system *****/
  
  //module-role-im-reader
  workareaIMReader(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let reader = false;    
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.inventory[0])) {
          reader = true;
        }
      }
    }
    return reader;
  }

  //module-role-im-editor
  workareaIMEditor(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let editor = false;    
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.inventory[1])) {
          editor = true;
        }
      }
    }
    return editor;
  }

  //module-role-im-supervisor
  workareaIMSupervisor(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let supervisor = false;    
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.inventory[2])) {
          supervisor = true;
        }
      }
    }
    return supervisor;
  }

  //module-role-im-qa
  workareaIMQAAdmin(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let qaAdmin = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.inventory[3])) {
          qaAdmin = true;
        }
      }
    }
    return qaAdmin;
  }




 /*****WM role system *****/
  
  //module-role-wm-reader
  workareaWMReader(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let reader = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.work[0])) {
          reader = true;
        }
      }
    }
    return reader;
  }

  //module-role-wm-editor
  workareaWMEditor(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let editor = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.work[1])) {
          editor = true;
        }
      }
    }
    return editor;
  }

  //module-role-wm-supervisor
  workareaWMSupervisor(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let supervisor = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.work[2])) {
          supervisor = true;
        }
      }
    }
    return supervisor;
  }

  //module-role-wm-qa
  workareaWMQAAdmin(userRoles: any, useSelectedWorkarea?: boolean): boolean{
    let qaAdmin = false;
    let taskWorkareaId: any; 
    if (userRoles && userRoles.length > 0) {
      taskWorkareaId = localStorage.getItem('taskWorkareaId') ? localStorage.getItem('taskWorkareaId') : '';
      if (useSelectedWorkarea && useSelectedWorkarea == true){
        taskWorkareaId = localStorage.getItem('selectedWorkareaId') ? localStorage.getItem('selectedWorkareaId') : '';
      }
      const rolesArray = userRoles.find((element:any) => element.workAreaId == taskWorkareaId)
      if (rolesArray && rolesArray?.roles?.length > 0) {  
        if (rolesArray?.roles?.length > 0 && rolesArray?.roles.includes(this.rolesModel.work[3])) {
          qaAdmin = true;
        }
      }
    }
    return qaAdmin;
  }
   

}
