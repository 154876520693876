import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ResultObservationsAttachmentsComponent } from '../dialogs/result-observations-attachments/result-observations-attachments';
import { ApiResultsService } from 'src/app/services/api.results.service';
import { FilePreviewDialogComponent } from 'src/app/components/dialogs/file-preview-dialog/file-preview-dialog.component';


@Component({
  selector: 'app-result-service-attachments',
  templateUrl: './result-service-attachments.component.html',
  styleUrls: ['./result-service-attachments.component.scss']
})
export class ResultServiceAttachmentsComponent {
  attachmentsUpdate!: Subscription;
  @Input() element:any;
  displayedColumns: string[] = ['name', 'title', 'description', 'actions'];
  showActions = false;
  isLoading = false;
  noResults = false;
  addNewEnabled = false;
  taskId: any;
  dataSource: any = [];
  constructor(
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private apiResultsService: ApiResultsService) {}

  ngOnInit() {      
    this.taskId = localStorage.getItem('taskId');
        
    this.attachmentsUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the list
      if(name == 'update_results_attachments') {
        this.getData();
      }
    }); 
    if (this.element.id) {
      this.getData();
    } else {
      console.log('no id detected');
    }
  }

  ngOnDestroy(){
    this.attachmentsUpdate.unsubscribe();
  }
  addResults() {
  }
  preview(element: any){
    const tenantId = localStorage.getItem('taskTenantId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      taskId: this.taskId,
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }

  getData() {
    this.isLoading = true;
    const tenantId = localStorage.getItem('selectedSiteId');   
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      datapointValueId: this.element.id
    }
    
    this.apiResultsService.getresultServiceAttachment(params).subscribe(
      (result: any) => {
        if(result) {
          this.isLoading = false;
          this.dataSource = result;          
        } else {
          this.dataSource = [];
        }
      },
      // error
      (msg) => {       
        this.isLoading = false; 
        console.log('error retrieving data from endpoint ' + msg.error);
    });
  }
  
  addNew(action: string) {
    const tenantId = localStorage.getItem('taskTenantId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',    
      taskId: this.taskId,
      element: this.element,
      action: action
    }

    this.dialog.open(ResultObservationsAttachmentsComponent, {
      height: 'auto',
      width: '800px',
      disableClose: true,
      data: params
    });
  }

  editDetails (element: any) {
    const tenantId = localStorage.getItem('taskTenantId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',   
      taskId: this.taskId,
      action: 'update_attachment',
      element: this.element,
      selectedElement: element
    }      

    this.dialog.open(ResultObservationsAttachmentsComponent, {
      height: 'auto',
      width: '400px',
      disableClose: true,
      data: params
    });
  }

  confirmation(action: string, elem?: any) {    
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.deleteElem(elem);
        }
      }
    ); 
  }
  
  /**
   * deletes the selected service from the list
   * @param element selected element to be removed
   */
  deleteElem(element: any){
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');     
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      hashName: element.hash,
      datapointValueId: element.datapointValuesDto.id,
      taskId: localStorage.getItem('taskId')
    }
      
    //api/tenant/utils/datapoints/document/{tenantId}/{hashName}/{taskId}/dt/{datapointValueId}/delete
    this.apiResultsService.deleteResultServiceAttachment(params).subscribe(
      (result) => {
        console.log( 'Element deleted');
        this.snackBar.open( 'Element deleted ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_results_attachments');
    },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log( 'Element deleted');
          this.snackBar.open( 'Element deleted ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_results_attachments');
        }
        console.log( msg.statusText);
    }); 
  }
  
}
