import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditAssetDialogComponent } from '../edit-asset-dialog/edit-asset-dialog.component';
export interface DialogData {
  assetType: string;
  type: number;
  serialNumber: string;
  model: string;
  department: string;
  description: string;
 manufacturer: string;
  action: string;
  tenantId: string;
  consumableType: string;
  id: string;
  confirmation: boolean;
  confirmationString: string;
}
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {}

    closePopup(): void {
      this.dialogRef.close(this.dialogData);
    }
}
