export class ModelAttachment {
    id = null;
    task = {};
    title = '';
    description = '';
    name = '';
    type = '';
    hash = '';
    deleted = false;
    createdBy = '';
    updatedBy = ''; 
    lastUpdated = null; 
    dataCreated = null; 
}

