import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRepairsService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  //api/tenant/task/taskRepairField/{tenantId}/{taskId}/list
  getAllrepairsByType(params: any) {
    const endpoint = `/api/wm/taskrepair/${params.type}/task/${params.taskId}/list`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/equipments/{tenantId}/{taskId}/list
  getTaskEquipment(params: any) {
    const filters = `?firstResult=${params.first}&maxResults=${params.max}&sort=${params.sortField}&sort=${params.sort}`;
    const endpoint = `/api/wm/task/${params.taskId}/equipments/list` + filters;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/task/RepairRequired/{tenantId}/{taskRepairRequiredId}
  deleteRepair(params: any) {
    const endpoint = `/api/wm/taskrepair/${params.type}/${params.elemId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/task/taskRepairField/{tenantId}/{taskId}/create
  createRepair(params: any) {
    const endpoint = `/api/wm/taskrepair/${params.type}/task/${params.taskId}/create`;
    //const endpoint = `/api/wm/taskrepair/field/task/${params.taskId}/create`;
    return this.http.post(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/tenant/task/RepairRequired/{tenantId}
  updateRepair(params: any) {
    const endpoint = `/api/wm/taskrepair/${params.type}/update`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }
}
