import { Component } from '@angular/core';
import {FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent {
  validForm = false;
  invalidConfirmedPassword = false;
  invalidNewPassword = false;
  oldPassword = '';
  newPassword = '';
  confirmedNewPassword = '';
  resetPassForm: FormGroup;
  isLoading = false;
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService) {

  }
  getUserToken(){
    const params = {};
    this.authService.userTokenCheck(params).subscribe(
      (data: any) => {
      //ok
      },
      error => {
        this.snackBar.open('Reset password failed', '', {duration: 5000});
      console.log(error);
    });       
  }

  setNewPassword(){
    this.validForm = false;
    this.isLoading = true;
    const tenantId = localStorage.getItem('selectedSiteId');  
    const username = localStorage.getItem('username');  
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      userId: username,
      body:  btoa(this.newPassword)
    };
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmedNewPassword = '';
    this.authService.changePassword(params).subscribe(
      (data: any) => {
      
      this.isLoading = false;
      this.snackBar.open('The password was changed', '', {duration: 5000});
      this.logout();
      },
      error => {
        if (error.status == '200') {
          this.snackBar.open('The password was changed', '', {duration: 5000});
          console.log('The password was changed');                    
        }else {
          this.snackBar.open('Reset password failed', '', {duration: 5000});
          console.log(error);
        }

    });       
  }
  logout(): void {
    // todo
    localStorage.clear()  
    this.router.navigateByUrl('/login');
  }
  validateForm(){

    const regexp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[a-zA-Z0-9]{8,}$');
    const matches = regexp.test(this.newPassword);
    this.validForm = false;
    this.invalidConfirmedPassword = false;
    this.invalidNewPassword = false;
    if (this.newPassword.length > 0 && !matches) {
      this.invalidNewPassword = true;
    }
    if (this.oldPassword && this.newPassword.length > 0 && 
      this.newPassword == this.confirmedNewPassword){
      this.validForm = true;
    }
    if (this.confirmedNewPassword.length > 0 && 
      this.newPassword != this.confirmedNewPassword){
      this.invalidConfirmedPassword = true;
      this.validForm = false;
    }

  }
}
