import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MMM/YYYY "
  },
  display: {
    dateInput: "DD/MMM/YYYY ",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "YYYY-MM-DD HH:mm:ss",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
@Component({
  selector: 'datepicker-elem',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ]
})

export class DatepickerComponent {
  @Input() element:any;
  @Output() elementEvent = new EventEmitter<any>();
  @Input() readonlyFields:any;
  @Input() formData:any;   
  prefilledValue: any;
  invalid = false;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private sharedFunctions: SharedFunctionsService){
      this.dateAdapter.setLocale('en-GB');
    }

  ngOnInit(){    
    // disables readonly when no fields are defined
    if(!this.readonlyFields){
      this.element.readOnly = false;
    }    
    // checks if the form contains any stored data to retrieve and display
    if (this.formData && this.element.id) {
      this.prefilledValue = this.sharedFunctions.retrieveFormData(this.formData, this.element.id);
      if (this.element.id){
        this.prefilledValue = this.formData[this.element.id];
      }
     
    }
    if ( this.prefilledValue) {
      this.prefilledValue = new Date(parseInt(this.prefilledValue));
    }
  }

  valueChange(value: any) {
    let isValidDate = Date.parse(value);

    if (isNaN(isValidDate)) {
      // when is not valid date logic
      this.invalid = true;            
      this.elementEvent.emit(null);
    }
    
  }

  /**
   * 
   * @param value entered date value
   * @param element the form element
   */
  selectValue(value: any, element: any) {    
    let isValidDate = Date.parse(value);

      if (!isNaN(isValidDate)) {
        const timeStamp = typeof value === 'number' ? value : Date.parse(value);
      //const timeStamp = Math.floor( value.getTime() / 1000 );
      this.invalid = false;
      const item = {
        value: timeStamp,
        name: element.id
      };
      
      this.elementEvent.emit(item);
    }
  }

}
