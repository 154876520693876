export class ModelUser {
    id = null;
    tenant = {
        id: ''
    };
    privilege = {
        id: ''
    };
    qualification = '';
    jobTitle = '';
    active = null;
    deleted = false;
    user = {
        id: '',
        password: '',
        firstName:'',
        lastName: '',
        displayName: '',
        email: '',
        tenantId: '',
        revision: 0
    };

}


