<h4> {{element?.name}} 
  <span class="required_item" *ngIf="element?.required"> *</span>
</h4>
<mat-form-field [ngClass]="{'disabled': element.readOnly, 'invalid': invalid}" class="filter-type" appearance="fill">
  <input

  #selectInput
  aria-expanded="false"
  [ngModel]="prefilledValue?.name"
  type="text"
  matInput
  [readonly]="element.readonly"
  [formControl]="myControl"
  (keydown.arrowup)="handleArrowNavigation($event)" 
  (keydown.arrowdown)="handleArrowNavigation($event)"
  [matAutocomplete]="autoComplete">
  <mat-icon
  class="select-arrow"
  matSuffix>keyboard_arrow_down</mat-icon>

  <mat-autocomplete
   #autoComplete="matAutocomplete">
    <ng-container *ngFor="let option of filteredOptions | async" >
      <mat-option 
      (onSelectionChange)="selectValue(option, element, $event)" [value]="option.name" >
        {{option.name}}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>