
<mat-card class="results-card">
    <mat-card-header>
  
      <mat-card-title>
  
      </mat-card-title>
    </mat-card-header>
  
    <mat-card-content>  
      <div class="my-content-container">

        <mat-toolbar color="primary" class="results-toolbar">

            <button 
            *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
            mat-raised-button (click)="addNew('create')" class="last right search-menu-button custom-button blue" color="primary">
              <mat-icon class="icon">add</mat-icon>
            </button>

        </mat-toolbar>

      <table mat-table *ngIf="allTaskConsumables && !isLoading" [dataSource]="dataSource" class="mat-elevation-z8">
        <br>
  
        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell  *matCellDef="let element"> {{element?.consumable?.consumableType?.name}} </td>
        </ng-container>
  
        <!-- Model Number Column -->
        <ng-container matColumnDef="bNumber">
          <th mat-header-cell *matHeaderCellDef>Batch Number</th>
          <td mat-cell *matCellDef="let element">
            {{element.batchNumber}}
        </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">
                {{element?.consumable?.description}}
            </td>
          </ng-container>
          
        <!-- services Column -->
        <ng-container matColumnDef="services">
            <th mat-header-cell *matHeaderCellDef>Services</th>
            <td mat-cell *matCellDef="let element">
                {{element.personnelServicesStr}}
            </td>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell width ="40px" *matHeaderCellDef> </th>
          <td mat-cell width ="40px" *matCellDef="let element">
  
            <!-- list actions menu-->
            <button
            *ngIf="roles.isAdmin || rolesService.workareaWMSupervisor(workareaRoles.allWorkareaRoles)"
            class="user_menu custom" mat-raised-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
              <mat-icon>expand_more</mat-icon>
            </button>
  
            <mat-menu class="menu" x-position="before" #menu="matMenu">

                <button mat-menu-item (click)="editItemDetails(element)">
                <mat-icon>edit</mat-icon>
                    Edit 
                </button>              
                <button mat-menu-item (click)="confirmation(element, 'delete')">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>

            </mat-menu>
  
        </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


      <div class="no-results" *ngIf="noResults && !isLoading">
        <h1>No results</h1>
      </div>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <br>
    </div>
  
    </mat-card-content>
  