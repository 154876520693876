import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'select-measurements',
  templateUrl: './select-measurements.component.html',
  styleUrls: ['./select-measurements.component.scss'],
})
export class SelectMeasurementsComponent implements OnInit {
  @Input() element: any;
  @Input() readonlyFields: any;
  @Input() formData: any;
  @ViewChild('textInput')
  textInput!: ElementRef<HTMLInputElement>;
  prefilledValue: any = {};
  selectedElement: any = [];
  @Output() elementEvent = new EventEmitter<string>();
  myControl = new FormControl('');
  lookupsList: any;
  autocomplete = 'on';
  invalid = false;
  lookupName: any;
  filteredOptions: Observable<any>;
  constructor(
    private sharedFunctions: SharedFunctionsService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    //prefill
     if ( this.element?.multiValue) {
      this.prefilledValue = this.element.multiValue[0];
    }
    if ( this.element?.values) {
      this.prefilledValue.name = this.element.values;
    }
  }

  private _filter(value: any): string[] {    
    const filterValue = value.name ? value.name.toLowerCase() : value.toString().toLowerCase();

    return this.element.options.filter(
      (option: any) =>
        option.name && option.name.toLowerCase().includes(filterValue)
    );
  }

  /**
   * sets the value for the selected element
   * @param elem with value to set
   * @param element current element
   * @param event to ensure when is only user input
   */
  selectValue(elem: any, element: any, event: any) {
    if (event.isUserInput) {
      this.selectedElement = elem;
      this.invalid = false;

      if (this.lookupName) {
        elem.lookupName = this.lookupName;
      }
      this.elementEvent.emit(elem);      
      this.prefilledValue = elem;      
      //this.textInput.nativeElement.value = elem.name;
    }
  }

/**
 * checks if the input was left empty
 * @param target clicked target
 */
  checkIfEmpty(target: any) {

    if (target.code != 'ArrowUp' && target.code != 'ArrowDown'){
      this.invalid = false;
      const value = target.target.value.trim();
      let elem: any;
      if (this.lookupsList && this.lookupsList.length > 0) {
        const selectedOpt = this.lookupsList.find((elem:any) => elem.name.includes(value.trim()));                     
        this.selectedElement = selectedOpt;
      }
      if (!this.selectedElement) {
        // when value is entered but no valid options are selected
        // shows invalid styles
        if (value.length > 0) {
          this.invalid = true;
          elem = {
            name: null,
            id:  null,
            removeItem: this.element.datapointCatalog.datapointType.name
          };
          // when value is 0 and no option selected
          // checks the N/A
        } else {        
          elem = {"name":"N/A","type":"na","value":0.0};
          this.prefilledValue = elem;     
          this.selectedElement = elem;
          target.target.value = elem.name;
          this.lookupsList = [];
          this.element.options = [];    
        }     
        this.elementEvent.emit(elem);
      } else {
        if (this.lookupsList) {
          const selectedOpt = this.lookupsList.find((elem:any) => elem.name.includes(value.trim()));   
          this.selectedElement = selectedOpt;
          this.invalid = false;
          this.elementEvent.emit(selectedOpt);
        }
      }
    } else {
      // the case when you select with arrows and use tab to jump to next field
      this.selectedElement = this.prefilledValue;
      this.invalid = false;
      this.elementEvent.emit(this.prefilledValue);
    }

  }

  /**
   * gets the results based on the inserted value
   * @param target the inserted value
   */
  getResults(target: any) {
    if (target.code != 'ArrowUp' && target.code != 'ArrowDown'){
      this.selectedElement = null;
      let value = target.target.value.trim();
      //value = encodeURIComponent(value);
      
      if (value.length > 0) {

        const size = this.element.datapointCatalog.size ? this.element.datapointCatalog.size : '5';
        const tenantId = localStorage.getItem('selectedSiteId');   
        const params = {
          tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
          startsLike: value,
          min: this.element.datapointCatalog.min,
          max: this.element.datapointCatalog.max,
          size: size,
        };
        this.lookupsList = [];

        this.apiService.getMeasurements(params).subscribe(
          (result: any) => {

            if (result.measurements && result.measurements.length > 0) {
              this.element.options = result.measurements;
              this.lookupsList = result.measurements;
              this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filter(value || '')),
              );
            }

          },
          // error
          (msg) => {
            console.log('error retrieving measurements ' + msg);
        }); 

      }
    }
  }

  handleArrowNavigation (event: any){
    let value: any = '';
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }
    this.textInput.nativeElement.value = value;
    const selectedOpt = this.lookupsList.find((elem:any) => elem.name.includes(value.trim()));
    this.selectedElement = selectedOpt;
    this.invalid = false;
    this.elementEvent.emit(selectedOpt);
  }  
  
}
