import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, startWith, map } from 'rxjs';
import { ModelSite } from 'src/app/models/model.site';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

export interface DialogData {
  action: string;
  id: string;
}

@Component({
  selector: 'app-edit-site-dialog',
  templateUrl: './edit-site-dialog.component.html',
  styleUrls: ['./edit-site-dialog.component.scss']
})
export class EditSiteDialogComponent {

  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  rolesUpdate!: Subscription;
  roleControl =  new FormControl('');
  locationControl =  new FormControl('');  
  @ViewChild('roleInput')
  roleInput!: ElementRef<HTMLInputElement>;
  @ViewChild('licenseLevelInput')
  licenseLevelInput!: ElementRef<HTMLInputElement>;
  
  readonly = false;  
  siteDetails: any = new ModelSite;
  validForm = false;
  isLoading = false;
  isLoadingLicenses = false;
  isLoadingRoles = false;
  noResults = false;
  filteredRoles: Observable<any[]> | undefined;
  availableRoles: any = [];
  filteredLocations: Observable<any[]> | undefined;
  availableLocations: any = [];
  userName: any = '';
  userEmail: any = '';
  usernameExists = false;
  emailExists = false;
  validEmail = true;
  validPhone = true;
  validPhoneFormat = true;
  prefilledStartDate: any;
  prefilledEndDate: any;
  validStartDate = true;
  validStartDateFormat = true;
  requiredStartDateFormat = false;
  validEndDate = true;
  validEndDateFormat = true;
  requiredEndDateFormat = false;
  startDateIsGreater = false;
  endDateIsLower = false;
  licenseLevelTypes:any = [];
  constructor(
    private componentsCommunication: ComponentsCommunicationService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditSiteDialogComponent>,
    private apiSitesService: ApiSitesService,
    private snackBar: MatSnackBar,
    private usersService: ApiUsersService,    
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) { }

  ngOnInit() {

    if (this.dialogData.action == 'edit') {
      this.getDetails(this.dialogData.id);
    }

    this.getLicenses();
 
    this.rolesUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the roles list
      if(name == 'update_roles') {
        this.getRoles();
      }   
    }); 
  }

  ngOnDestroy(){
    this.rolesUpdate.unsubscribe();
  }

  getDetails(siteId: string){
    this.isLoading = true; 
    this.apiSitesService.getSiteDetails(siteId).subscribe(
      (result: any) => {
        if (result && result) {
          this.siteDetails = result;
          this.prefilledStartDate = new Date(parseInt(result.licenseStartDate));
          this.prefilledEndDate = new Date(parseInt(result.licenseEndDate));
        } else {
          this.siteDetails = [];
        }
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoading = false;
    });
  }

  getLicenses() {
    this.isLoadingLicenses = true;
    const params = {
      lookupName: 'LicenseLevel'
    };
    this.apiSitesService.getLookupList(params).subscribe(
      (result: any) => {
        if (result && result.lookups) {
          this.licenseLevelTypes = result.lookups;
          this.licenseLevelTypes.unshift({name:' - '});
        } else {
          this.licenseLevelTypes = [];
        }
        this.isLoadingLicenses = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoadingLicenses = false;
    });
  }

  getRoles() {
    this.isLoadingRoles = true;
    const type = 'privilege';
    const params = {
      lookupName: type
    };


    this.usersService.getLookupList(params).subscribe(
      (result: any) => {
       
        if(result.lookups.length > 0) {
          this.availableRoles = [...result.lookups];
          this.filteredRoles = <any>[...result.lookups];
        } 
        this.filteredRoles = this.roleControl.valueChanges.pipe(
          startWith(''),
          map((item: string | null) => (item ? this._filterRoles(item) : this.availableRoles.slice())),
        );   
        this.isLoadingRoles = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoadingRoles = false;
    });
          
  }


  private _filterRoles(element: any): string[] {    
    const searchValue = element.toLowerCase();             
    let returnElems = this.availableRoles.filter(
      (item:any) => item.name.toLowerCase().includes(searchValue));
  
    return returnElems;
  }
  
  private _filterLocations(element: any): string[] {    
    const searchValue = element.toLowerCase();             
    let returnElems = this.availableLocations.filter(
      (item:any) => item.name.toLowerCase().includes(searchValue));
  
    return returnElems;
  }

  validateFormdata(){
    this.validForm = false;
    if (this.validPhone && this.validEmail && this.siteDetails.name.trim().length > 0 && 
    this.siteDetails.telephone.trim().length > 0 && this.siteDetails.url.trim().length > 0 &&
    (!this.endDateIsLower && !this.startDateIsGreater && 
      (this.siteDetails.licenseStartDate && this.siteDetails.licenseEndDate) ||
    !this.siteDetails.licenseStartDate && !this.siteDetails.licenseEndDate)) {
      this.validForm = true;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveChanges() {

    const properties = {
      name: 'update_sites',
      action: this.dialogData.action
    }
    if (this.siteDetails.licenseLevel && this.siteDetails.licenseLevel.id.length == 0) {
      this.siteDetails.licenseLevel = null;
    }
    this.siteDetails.companyTitle = this.siteDetails.name;
    this.apiSitesService.createSite(this.siteDetails).subscribe(
      (result: any) => {                                       
        this.snackBar.open('sites created', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateSites(properties);
        this.closePopup();
    },
      // error
      (msg) => {
        console.log( msg);
    }); 

  }

  /**
   * validates and stores the date using timestamp
   * @param value date to be checked
   * @param elemName the date field name
   */
  selectDate(value: string, elemName: string) {
    this.validStartDateFormat = true;
    this.requiredEndDateFormat = false;
    this.startDateIsGreater = false;
    this.validEndDateFormat = true;
    this.requiredStartDateFormat = false;
    this.endDateIsLower = false;
    
    const storeValue = Date.parse(value);
    this.siteDetails[elemName] = storeValue;

    if (elemName == 'licenseStartDate'){
      this.prefilledStartDate = new Date(value);
      if (isNaN(storeValue)){
        this.validStartDateFormat = false;
      }

      if (!this.siteDetails.licenseEndDate || this.siteDetails.licenseEndDate == 0) {
        this.requiredEndDateFormat = true;
      }
      if (this.siteDetails.licenseEndDate && this.siteDetails.licenseStartDate && (this.siteDetails.licenseStartDate > this.siteDetails.licenseEndDate)) {
        this.startDateIsGreater = true;
      }
    }
    if (elemName == 'licenseEndDate'){
      this.prefilledEndDate = new Date(value);
      if (isNaN(storeValue)){
        this.validEndDateFormat = false;
      }
      if (!this.siteDetails.licenseStartDate || this.siteDetails.licenseStartDate == 0) {
        this.requiredStartDateFormat = true;
      }
      if (this.siteDetails.licenseEndDate && this.siteDetails.licenseStartDate && (this.siteDetails.licenseStartDate > this.siteDetails.licenseEndDate)) {
        this.endDateIsLower = true;
      }
    }

  }

  updateChanges() {       
    const properties = {
      name: 'update_sites',
      action: this.dialogData.action
    } 
    if (this.siteDetails.licenseLevel && (this.siteDetails?.licenseLevel?.id?.length == 0 || !this.siteDetails?.licenseLevel?.id)) {
      this.siteDetails.licenseLevel = null;
    }
    this.apiSitesService.updateSite(this.siteDetails).subscribe(
      (result: any) => {                                       
        this.snackBar.open('sites updated', '', { duration: 3000 });
        // sends an update
        this.componentsCommunication.setUpdateSites(properties);
        this.closePopup();
    },
      // error
      (msg) => {
        console.log( msg);
    }); 

  }

  handleArrowNavigation(event: any, type: string){
    let value: any = '';
    if (document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0]) {
      value = document.querySelectorAll('.mat-mdc-autocomplete-panel .mat-mdc-option-active')[0].textContent;
    }
    if (type == 'licenseLevel') {
      this.licenseLevelInput.nativeElement.value = value;
    }       
  }

  validateEmail(value: string, type: string){
    this.validForm = false;
    this.validEmail = true;

    this.userEmail = value.trim();    
    this.validEmail = this.regexp.test(this.userEmail);
    this.siteDetails.email = this.userEmail;
    this.validateFormdata();
  }

  validatePhone(value: string, type: string){
   /* this.validForm = false;
    this.validPhone = false;
    this.validPhoneFormat = false;
    if (value.trim().length > 8) {
      this.validPhone = true;
      this.validForm = true;
    } 
    let numberPattern = '^[0-9]*$';
    let regEx = new RegExp(numberPattern);   
    this.validPhoneFormat = regEx.test(value);
    */
    this.siteDetails.phone = value.trim();
    this.validateFormdata();
  }

  selectLicenseLevel(event: any, value?: any){
    if (event.isUserInput) { 
      let element: any;
      if (value && value.name == ' - '){
        element = value;
      } else if (typeof value.id == 'undefined'){
        element = this.licenseLevelTypes.find(
          (item: any) => item.name.trim().toLowerCase() == value.trim().toLowerCase()
        );     
      } else {
        element = value;
      }

      if (!this.siteDetails.licenseEndDate){
        this.requiredEndDateFormat = true;
      }
      if (!this.siteDetails.licenseStartDate){
        this.requiredStartDateFormat = true;
      }
      this.siteDetails.licenseLevel = element;
      this.validateFormdata();
    }
  }

}
