import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditAssetDialogComponent } from 'src/app/components/dialogs/edit-asset-dialog/edit-asset-dialog.component';
import { ApiResultsService } from 'src/app/services/api.results.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';

export interface DialogData {
  name: string;
  id: string;
  value: string;
  service: any;
  assetDto: any;
  element: any;
}

@Component({
  selector: 'app-edit-result-service',
  templateUrl: './edit-result-service.component.html',
  styleUrls: ['./edit-result-service.component.scss']
})
export class EditResultServiceComponent {
  @Input() element:any;
  constructor(    
    private componentsCommunication: ComponentsCommunicationService,
    private snackBar: MatSnackBar,
    private apiResultsService: ApiResultsService,
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData){}
    boxSpecifics = ['Box Depth', 'Box Counter Bore Length', 'Box Counter Bore Diameter', 'Float/Bore Back Length',
    'Float/Bore Back Diameter', 'Box Counter Bore Wall Thickness', 'Box Elevator Groove', 'Box Slip Groove',
    'Shoulder Width', 'Seal Width', 'Box Donut', 'Box Shoulder Width', 'Box Seal Width'];
    pinSpecifics = ['Pin length', 'Pin SRG Width', 'Pin SRG Diameter', 'Pin Neck length', 'Pin Neck Diameter',
    'Pin Nose Diameter', 'Seal Width', 'Pin Seal Width'];
    
    allowedLookups = ['ThreadType', 'Thread Type', 'DressingType', 'BladeType', 'StabilizerType'];
    attachmentsDisplayColumns = ['name', 'title', 'description', 'actions'];
    isLoading = false;
    serviceForm: any;
    validForm = false;
    elements: any = [];
    idProcess: any;
    taskId: any;
    serviceId: any;
    serviceDatapointValues: any = [];
    allFormFields:any = [];
    taskConnection1: any;
    taskConnection2: any;
    itemExists = null;
    ngOnInit() {
   
      this.isLoading = true;
      const workareaId = localStorage.getItem('taskWorkareaId');
      const tenantId = localStorage.getItem('taskTenantId');   
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        taskId: localStorage.getItem('taskId'),
        idProcess: localStorage.getItem('idProcess'),
        serviceId: this.dialogData.service.id,
        assetId: this.dialogData.assetDto.id
      }
   
      //Get RESULTS DataPoint Values of one Task
      this.apiResultsService.getResultDatapointValues(params).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.allFormFields = result;
          const bodyConnections:any = localStorage.getItem('taskBodyConnections');
          this.taskConnection1 = localStorage.getItem('taskConnection1');
          this.taskConnection2 = localStorage.getItem('taskConnection2');
          const serviceName = this.dialogData.service.name;
          result.forEach((elem:any) => {
            //*********CHECK COLUMNS
            //Body Thread 1 - Type
            //Body Thread 1 - Condition"
            for (let i = 1; i < 4; i++){
              if (elem?.datapointCatalog?.place == 'col'+ i && elem?.datapointCatalog?.tab == 'results') {
                if(!this.elements[i-1]) {
                  this.elements[i-1] = [];
                }
                if (serviceName != 'DIM') {
                  this.elements[i-1].push(elem);
                } else {

                  if (i == 1 || (this.taskConnection1 != 'N/A' && i == 2) || (this.taskConnection2 != 'N/A' && i == 3)) {

                    const name = elem.datapointCatalog.name;
                    // if is Body Thread
                    if (name.includes('Body Thread')) {
                      const connectionNumber = name.charAt(12);
                      // only allows items that are below or equal to number of body connections
                      if (connectionNumber <= bodyConnections && bodyConnections != 'null') {
                        this.elements[i-1].push(elem);
                      }
                    } else {

                      if ((i == 2 && this.taskConnection1 == 'Box'|| i == 3 && this.taskConnection2 == 'Box') && 
                      !this.pinSpecifics.includes(elem.datapointCatalog.name)) {
                        this.elements[i-1].push(elem);
                      }
                      if ((i == 2 && this.taskConnection1 == 'Pin'|| i == 3 && this.taskConnection2 == 'Pin') && 
                      !this.boxSpecifics.includes(elem.datapointCatalog.name)) {
                        this.elements[i-1].push(elem);
                      }
                       else if(i == 1 ){
                        this.elements[i-1].push(elem);
                      }
                    }
                  }
                }
              }         
            }
          });
          
          //To order items inside columns
          this.elements.forEach((column: any) => {
            column.sort((a:any, b:any) => a.datapointCatalog.position > b.datapointCatalog.position ? 1 : -1);
          });
        },
        // error
        (msg) => {
          this.isLoading = false;        
          console.log(msg.statusText);
      });
  
    }
    /**
   * stores the value
   * @param selectValue value that was selected to be stored
   * @param element selected element
   */
    addSelectValue(selectValue: any, element: any) {
  
      if (selectValue && (selectValue.name || selectValue.value || selectValue.removeItem)) {
        
        this.allFormFields.filter((item: any) => {
          if (item.datapointCatalog.id == element.datapointCatalog.id){
            // to remove item
            if (selectValue.removeItem) {
              if ( item.multiValue){
                const index = item.multiValue.findIndex((item: any) => item.name == selectValue.removeItem);
                item.multiValue.splice(index, 1);
              }
              if (item.values && item.values.length > 0){
                item.values = '';
              }              
            } else {
              if(element.datapointCatalog.isMultiselect || selectValue.lookupName) {
                if (!item.multiValue) {
                  item.multiValue = [];
                }
                if (element.datapointCatalog.isMultiselect) {
                  item.multiValue.push(selectValue);
                } else {
                  item.multiValue = [selectValue];
                }
                
              } else {
                // when is measurement case
                if (element?.datapointCatalog?.datapointType?.name?.toLowerCase() == 'measurement') {
                  item.values = selectValue.name;
                } else {
                  item.values = selectValue.value;
                }
                
              }
            }
          }
 
        });        
      }
      this.validateForm();
    
    }
  
    validateForm(){
      let validForm = true;
      let dataList = <any>this.allFormFields;
    
      for (let data in dataList) {        
        this.itemExists = null;
        //needs to seek for the item to validate, if it exists in the displayed columns
        this.elements.forEach((column: any) => {
          column.forEach((item: any) => {
            if (item.id == dataList[data].id) {
              this.itemExists = item;
            }
          });
        });
        if (dataList[data].datapointCatalog.required && this.itemExists) {
          let dataValue = dataList[data];
          let value = dataValue.multiValue && dataValue.multiValue.length > 0 ? dataValue.multiValue : dataValue.values;
          
          //when the lookuptype is condition
          const isCondition = dataValue.datapointCatalog.lookupName && dataValue.datapointCatalog.lookupName == 'condition' ?
           dataValue.datapointCatalog.lookupName : null;
          if ((typeof value == 'string' || typeof value == 'number') && value.toString().trim().length == 0) {
            validForm = false;
            break;
          } else if (!value || (typeof value == 'object' && Object.keys(value).length == 0)){
            validForm = false;
            break;
          } else if (isCondition) {
            if (typeof value == 'object'){
              const containsAccept = value.filter((elem:any) => elem.name.includes('Accept')) 
              // when accept is selected and contains more options
              if (containsAccept.length > 0 && value.length > 1) {
                validForm = false;
                break;
              }
            } else if ( dataValue.multiValue.length == 0){
              validForm = false;
              break;
            }
          }
        }
      }
      this.validForm = validForm;
    }
  
    closePopup(): void {
      this.dialogRef.close();
    }
  
    saveChanges(){
      this.isLoading = true;
      const tenantId = localStorage.getItem('taskTenantId');   
      const workareaId = localStorage.getItem('taskWorkareaId');     
      const params = {
        workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        serviceId: this.dialogData.service.id,
        taskId: localStorage.getItem('taskId'),
        body: this.allFormFields,
        assetId: localStorage.getItem('assetId'),
      };

      this.apiResultsService.saveResultService(params).subscribe(
        () => {
          console.log( 'services changes saved');
          this.closePopup();
          this.snackBar.open( 'Changes were saved!', '', {duration: 3000});        
          this.isLoading = false;  
          this.componentsCommunication.setUpdateList('update_results');
          this.componentsCommunication.setUpdateList('update_defects');
      },
        // error
        (msg) => {
          this.isLoading = false;  
          console.log( msg);
      }); 
    }
  }
  