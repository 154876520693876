import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModelCreateAssetConsumable } from 'src/app/models/model.create.asset.consumable';
import { ModelCreateAssetEquipment } from 'src/app/models/model.create.asset.equipment';
import { ApiService } from 'src/app/services/api.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { AttachmentsDialogComponent } from '../attachments-dialog/attachments-dialog.component';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ApiResultsService } from 'src/app/services/api.results.service';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { ApiSitesService } from 'src/app/services/api.sites.service';

export interface DialogData {
  assetType: any;
  type: number;
  serialNumber: string;
  model: string;
  department: string;
  description: string;
  manufacturer: string;
  assetManufacturer: string;
  action: string;
  tenantId: string;
  consumableType: string;
  id: string;
  assetCategory: any;
  tenant: any;
}
@Component({
  selector: 'app-edit-asset-dialog',
  templateUrl: './edit-asset-dialog.component.html',
  styleUrls: ['./edit-asset-dialog.component.scss']
})
export class EditAssetDialogComponent {
  @ViewChild('assetsInput')
  assetsInput!: ElementRef<HTMLInputElement>;
  assetsControl =  new FormControl('');
  getUpdateAssets!: Subscription;
  newConsumable: any = new ModelCreateAssetConsumable;
  newEquipment: any = new ModelCreateAssetEquipment;
  selectedDepartmentIndex: any;
  selectedManufacturerIndex: any;
  validForm = false;
  assetTypeList: any;
  assetModel: any = [];
  assetModelsList: any;
  prefilledAssets:any;
  filteredOptionsAssets: Observable<any[]> | undefined;
  selectedAsset: any;
  availableAssets: any;
  equipmentRequiredFields:any = ['tenantId', 'tenant', 'assetType', 'serialNumber', 'model'];
  consumibleRequiredFields:any = ['tenantId','tenant', 'consumableType', 'manufacturer'];
  tenantName: any;
  prefilledSerialNumber: any;
  prefilledModel: any;
  prefilledDepartment: any;
  prefilledDescription: any;
  types = [{id: 1, value: 'equipment'}, {id: 2, value: 'consumable'}];
  departments = [{id: null, name:'department 1'}, {id: null, name:'department 2'}, {id: null, name:'department 3'}];
  displayedColumns: string[] = ['number', 'name', 'description', 'actions'];
  consumableTypes: any;
  allDepartments: any;
  manufacturer: any;
  isLoading = false;
  itemDetails: any;
  dataSource: any;
  display: FormControl = new FormControl("", Validators.required);
  enableUpload = false;
  fileToUpload: any;
  selectedTabIndex = 0;
  assetModelType: any = {};
  assetModelCategory: any = {};
  consumableType: any = {};
  assetManufacturer: any = {};
  readonly = false;
  allTenants: any = [];
  editTitle: string = '';
  readonlyFields = [ 'assettype', 'manufacturer', 'consumabletype'];
  updatedTenant: any;
  constructor(
    private apiSitesService: ApiSitesService,
    private apiResultsService: ApiResultsService,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<EditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private dialog: MatDialog,
    private apiFilesService: ApiFilesService
  ){}
  ngOnInit(){

    // creating a new result for the results tab
    if(this.dialogData.action == 'create_result' ){
      this.readonly = true;
      this.getAssetsForResults();
    }

    this.getTenants();

    this.getUpdateAssets = this.componentsCommunication.getUpdateAssets().subscribe(params => {
      // updates the reports list
      if(params === 'update_attachments') {
        this.isLoading = true;
        this.getData(this.dialogData.id);
        this.selectedTabIndex = 1;
      }
    })

    if(this.dialogData.action == 'edit' || this.dialogData.action == 'clone'){
      //used when loading on init
      const event = {isUserInput: true};
      this.loadModel(this.dialogData.type, event);
      // needs to know what item is updated
      this.assetModel = {...this.dialogData};
      this.tenantName = this.assetModel?.tenant?.name;
      this.prefilledModel = this.assetModel.model;
      this.prefilledSerialNumber = this.assetModel.serialNumber;
      this.prefilledDepartment = this.assetModel.department?.name;
      this.prefilledDescription = this.assetModel.description;
      // Add information in the popup title when inventory is editing.
      if (this.dialogData.action == 'edit') {
        if(this.assetModel.type == '1') {
          this.editTitle = ' ' + this.assetModel?.assetType?.assetCategory?.name + ' - ' + this.assetModel?.serialNumber;
        } else if(this.assetModel.type == '2') {
          this.editTitle = ' ' + this.assetModel?.consumableType?.name;
        }
      }

      // doesn't check the data, images and attachment. cloned items don't have any attachments
      if (this.dialogData.action != 'clone') {
        this.getData(this.assetModel.id);
      }        
      
      if(this.dialogData.type) {
        this.assetModel.type = this.dialogData.type;
      }
      if (!this.assetModel.type){
        console.log("asset type is not set*****");
      }
      
      this.prefillData(this.dialogData);
  
    }
    
    if(this.dialogData.action == 'create') {
      this.assetModelType.action = this.dialogData.action;
      this.assetManufacturer.action = this.dialogData.action;
      this.consumableType.action = this.dialogData.action;
    }

    this.apiService.getLookupLists('consumableType').subscribe(
      (result: any) => {
        if(result) {
          this.consumableTypes = result.lookups;
        } else {
          this.consumableTypes = [];
        }
      },
      // error
      (msg) => {        
        console.log('error retrieving data from endpoint ' + msg.error);
    });

    this.apiService.getLookupLists('department').subscribe(
      (result: any) => {
        if(result) {
          this.allDepartments = result.lookups;
        } else {
          this.allDepartments = this.departments ;
        }
      },
      // error
      (msg) => {        
        console.log('error retrieving data from endpoint ' + msg.error);
    });    
    this.apiService.getLookupLists('assetType').subscribe(
      (result: any) => {
        if(result) {
          this.assetTypeList = result.lookups;
        } else {
          this.assetTypeList = [];
        }
      },
      // error
      (msg) => {        
        console.log('error retrieving data from endpoint ' + msg.error);
    });

  }

  getTenants(){

    this.isLoading = true;
    this.dataSource = [];
    const type = 'privilege';
    const tenantId = localStorage.getItem('selectedSiteId');   
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      lookupName: type,
      first: 0,
      max: 100,
      sort: 'asc',
      sortColumn: 'dateCreated'
    };

    this.apiSitesService.getTenantsList(params).subscribe(
      (result: any) => {
        
        if(result.content.length > 0) {
          this.allTenants = result.content;

        } 
        this.isLoading = false; 
      },
      // error
      (msg) => {
        console.log('error retrieving lookup list ' + msg);
        this.isLoading = false;
    });
  
  }

  getAssetsForResults() {
        
    const tenantId = localStorage.getItem('selectedSiteId');
    const workareaId = localStorage.getItem('taskWorkareaId');
    const supplierId = localStorage.getItem('taskSupplierId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',    
      supplierId: supplierId && supplierId.length > 0 ? supplierId : '',    
      first: 0,
      max: 9999,
      sort: 'desc',
    };
    
    this.apiService.getAssetsForResults(params).subscribe(
      (result:any) => {
        this.isLoading = false;
        if(result && result.content) {
          this.availableAssets = result.content;
                      
          this.filteredOptionsAssets = this.assetsControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '', this.availableAssets)),
          ); 
        }
        
      },
      // error
      (msg) => {
        this.isLoading = false;
        this.dataSource = [];
        console.log('error retrieving data from endpoint ' + msg.error);
    });
  }
  ngOnDestroy () {
    this.componentsCommunication.setAssetUpdate(null);
    this.getUpdateAssets.unsubscribe();
  }

  resetValues(event:any) {   
    event.isUserInput = true;
    this.loadModel(this.assetModel.type, event);
    this.assetModel.type = '';
  }

  prefillData(data: any) {
    this.assetModel.description = data.description;  
    const action = this.dialogData.action;
    if(this.assetModel.type == 1) {
      this.assetManufacturer.manufacturer = data.manufacturer;
      this.assetManufacturer.lookupName = 'manufacturer';
      this.assetManufacturer.name = 'Manufacturer';
      this.assetManufacturer.type = 1;
      this.assetManufacturer.readOnly = false;
      this.assetManufacturer.required = false;
      this.assetManufacturer.action = data.action;

      this.assetModel.model = data.model;
      this.assetModel.department =  data.department ? data.department : {};      
      this.assetModel.serialNumber = data.serialNumber;
      this.assetModel.manufacturer = data.manufacturer ?  data.manufacturer : {};
      this.assetModel.assetType = data.assetType;
      this.assetModel.assetCategory = data.assetType.assetCategory;

      this.assetModelType.assetType = data.assetType;
      this.assetModelType.name = 'Asset Type';
      this.assetModelType.type = 1;
      this.assetModelType.readOnly = false;
      this.assetModelType.required = true;   
      this.assetModelType.action = data.action;   
      
      this.assetModelCategory.assetCategory = data.assetType.assetCategory;
      this.assetModelCategory.name = 'Asset Category';
      this.assetModelCategory.type = 1;
      this.assetModelCategory.readOnly = true;
      this.assetModelCategory.disabled = true;
      this.assetModelCategory.required = true;        
      this.assetModelCategory.action = data.action;
    }
    if(this.assetModel.type == 2) {
      this.consumableType.type = 2;
      this.consumableType.name = 'Consumable Type';
      this.consumableType.readOnly = true;
      this.consumableType.disabled = true;

      this.assetManufacturer.manufacturer = data.manufacturer;
      this.assetManufacturer.lookupName = 'manufacturer';
      this.assetManufacturer.name = 'Manufacturer';
      this.assetManufacturer.type = 2;
      this.assetManufacturer.readOnly = false;
      this.assetManufacturer.required = true;
      this.assetManufacturer.action = data.action;

      this.consumableType.required = true;        
      this.consumableType.consumableType = data.consumableType;
      this.consumableType.action = data.action;        
      
      this.assetModel.consumableType = data.consumableType;
      this.assetModel.manufacturer = data.manufacturer;
    }
  }

 addAsset(event:any, element?: any): void {    
  if (event.isUserInput) {   
      this.selectedAsset = element; 

      if (element.assetType) {
        this.prefilledAssets = element.model + ' | ' + element.assetType.name;
      }
      if (element.consumableType) {
        this.prefilledAssets = element.consumableType.name;
      }
    this.loadModel(element.type, event);
    element.action = 'create_result';
    
    this.prefillData(element);
    this.addReadonly(element.type);
    }
  }

  addReadonly(type: number){
    if(type == 2) {
      this.assetModelType.readOnly = true;
      this.assetModelType.disabled = true;
      this.consumableType.readOnly = true;
      this.consumableType.disabled = true;
      this.assetManufacturer.readOnly = true;
      this.assetManufacturer.disabled = true;
    }
    if(type == 1) {
      this.assetModel.readOnly = true;
      this.assetModel.disabled = true;
      this.assetModelType.readOnly = true;
      this.assetModelType.disabled = true;
      this.assetModelCategory.readOnly = true;      
      this.assetModelCategory.disabled = true;      
      this.assetModel.department.readOnly = true;
      this.assetModel.department.disabled = true;
      this.consumableType.readOnly = true;
      this.consumableType.disabled = true;
      this.assetManufacturer.readOnly = true;
      this.assetManufacturer.disabled = true;
    }
    this.readonly = true;
  }

  private _filter(element: any, availableItems:any): string[] {    
    const searchValue = element.name ? element.name.element.toLowerCase() : element.toLowerCase();         
    let returnElems = availableItems.filter(
      (item:any) => (item.assetType && item.assetType.name.toLowerCase().includes(searchValue)) ||
      (item.model && item.model.toLowerCase().includes(searchValue)) || 
      (item.consumableType && item.consumableType.name.toLowerCase().includes(searchValue) ||
      (item.manufacturer && item.manufacturer.name.toLowerCase().includes(searchValue)))
      );
  
    return returnElems;
  }

  getData(id: string) {
    this.isLoading = true;
    const tenantId = localStorage.getItem('selectedSiteId');  
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      assetId: this.dialogData.id
    }
    this.apiService.getUploadedImages(params).subscribe(
      (result: any) => {
        if(result) {
          this.isLoading = false;
          this.dataSource  = result;
        } else {
          this.dataSource = [] ;
        }
      },
      // error
      (msg) => {       
        this.isLoading = false; 
        console.log('error retrieving data from endpoint ' + msg.error);
    });
  }

  getItemDetails(element: any){
    this.isLoading = true;
    this.apiService.getItemDetails(element).subscribe(
      (result: any) => {
        if(result) {
          this.isLoading = false;
          this.itemDetails = result.lookups;
        } else {
          this.isLoading = false;
          this.itemDetails = [];
        }
      },
      // error
      (msg) => {        
        this.isLoading = false;
        console.log('error retrieving data from endpoint ' + msg.error);
    });

  }

updateTenant(tenant:any, event:any){
  if (event.isUserInput) {  
    this.assetModel.tenant = tenant; 
    this.assetModel.tenantId = tenant.id; 
    this.updatedTenant = tenant;
    this.validateFormdata();
  }
}

  loadModel( index: number, event?:any) {
    if (event.isUserInput) {  
      this.assetModel = index == 1 ? this.newEquipment : this.newConsumable;
      // when loading model, resets assettype in case you have values prefilled
      this.assetModel.type = index;
      if (index == 1) {
        this.assetModel.controller = {id: null};
        this.assetModel.owner = {id: 'admin'};
        this.assetModel.tenant = {id: null};

        this.assetModelType.lookupName = 'assetType';
        this.assetModelType.name = 'Asset Type';
        this.assetModelType.type = 1;
        this.assetModelType.readOnly = false;
        this.assetModelType.required = true;

        this.assetModelCategory.lookupName = 'assetCategory';
        this.assetModelCategory.name = 'Asset Category';
        this.assetModelCategory.type = 1;
        this.assetModelCategory.readOnly = true;
        this.assetModelCategory.disabled = true;
        this.assetModelCategory.required = true;

        this.assetManufacturer.lookupName = 'manufacturer';
        this.assetManufacturer.name = 'Manufacturer';
        this.assetManufacturer.type = 2;
        this.assetManufacturer.readOnly = false;
        this.assetManufacturer.required = false;      
      }
      if (index == 2) {
        this.assetModel.tenant = {id: null};
        this.assetModelType.name = 'Asset Type';
        this.assetModelType.type = 2;
        this.assetModelType.readOnly = false;
        this.assetModelType.required = true;
        this.assetModelType.lookupName = 'assetType';

        this.assetManufacturer.lookupName = 'manufacturer';
        this.assetManufacturer.name = 'Manufacturer';
        this.assetManufacturer.type = 2;
        this.assetManufacturer.readOnly = false;
        this.assetManufacturer.required = true;

        this.consumableType.lookupName = 'consumableType';
        this.consumableType.name = 'Consumable Type';
        this.consumableType.type = 2;
        this.consumableType.readOnly = false;
        this.consumableType.required = true;
        this.assetModel.controller =  {id: null};
        this.assetModel.owner = {id: 'admin'};
        this.assetModel.manufacturer = {};
        this.assetModel.department = {};
        this.assetModel.consumableType = {};
        this.assetModel.dateManufacture = new Date();
        this.assetModel.dateDisposal = new Date();
        this.assetModel.dateCommission = new Date();   
        this.assetModel.dateDecommission = new Date();         
      }    
      this.assetModel.dateCreated = new Date();      
    }
  }

  validateFormdata(){
    let validForm = true;
    let data: String;
    let dataList = <any>this.assetModel;
    let requiredFields =this.assetModel.type == 1 ? this.equipmentRequiredFields : this.consumibleRequiredFields;
    
    for (let item in requiredFields) {
      data = dataList[requiredFields[item]];
     
      if(data){        
        if ((typeof data == 'string' || typeof data == 'number') && data.toString().trim().length == 0) {
          validForm = false;
          break;
        } else if (!data || (typeof data == 'object' && Object.keys(data).length == 0))     {
          validForm = false;
          break;
        }
      } else {
         validForm = false;
      }
    }
    this.validForm = validForm;

  }

  saveChanges(){
    if(this.validForm){
      this.validForm = false;
      if (this.dialogData.action == 'create' || this.dialogData.action == 'clone') {
        const tenantId = localStorage.getItem('selectedSiteId');  
        const assetModelTenant = this?.assetModel?.tenant ? this?.assetModel?.tenant?.id : '';
        const params = {
          tenantId: tenantId && tenantId.length > 0 ? tenantId :assetModelTenant,
          body: this.assetModel
        }
        this.apiService.createInventoryAsset(params).subscribe(
          (result) => {
            this.closePopup();
            this.snackBar.open( 'item was successfully created ', '', {duration: 3000});
            // sends an update
            this.componentsCommunication.setUpdateAssets('update_assets');
        },
          // error
          (msg) => {
            console.log( msg);
        });

      }
      if (this.dialogData.action == 'edit') {    
        if (this.updatedTenant){
          this.assetModel.tenant = this.updatedTenant;
        }
      
        this.assetModel.tenantId = this.assetModel.tenant.id,
        this.apiService.updateInventoryAsset(this.assetModel).subscribe(
          (result) => {
            console.log( 'item updated');
            this.closePopup();
            this.snackBar.open( 'item was successfully created ', '', {duration: 3000});
            // sends an update
            this.componentsCommunication.setUpdateAssets('update_assets');
        },
          // error
          (msg) => {
            console.log( msg);
        });
      }
    }
  }

  checkFile(files: any) {
    
    if (files.length) {
      this.fileToUpload = files[0];
      this.enableUpload = true;
      this.display.patchValue(`${this.fileToUpload.name}`);
    } else {
      this.display.patchValue('');
      this.enableUpload = false;
    }
  }

  openUploads(action: string, element?:any){    

    const data = element ? element : this.dialogData;
    this.dialog.open(AttachmentsDialogComponent, {
      height: 'auto',
      width: '800px',
      disableClose: true,
      data: {data: data, action: action}
    });
  }
  closePopup(): void {
    this.dialogRef.close({'item_toload': this.dialogData});    
  }
  actions(action:string, element: any) {

    if(action =='update') {
      element.assetType = this.dialogData.type;
      this.openUploads(action, element);
    }
    if (action == 'delete'){

      this.deleteFile(element);
    }
  }

  preview(element: any){
    const tenantId = localStorage.getItem('selectedSiteId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',  
      taskId: localStorage.getItem('taskId'),
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }
  
  confirmation(action: string, elem?: any) {
      
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.actions(action, elem);
        }
      }
    ); 
  }

  deleteFile(element: any) {
    let tenantId;

    if(element.action == 'delete') {
      tenantId = this.dialogData.tenant.id  
    } else {
      tenantId = localStorage.getItem('selectedSiteId');  
    }

    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      hashName: element.hash,
      assetId: element.asset.id
    }

    this.apiFilesService.deleteImage(params).subscribe(
      (result) => {
        console.log( 'image deleted');
        this.snackBar.open( 'image deleted ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateAssets('update_attachments');
    },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log( 'image deleted');
          this.snackBar.open( 'image deleted ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateAssets('update_attachments');
        }
        console.log( msg.statusText);
    });    
  }

  /**
 * stores the value
 * @param selectValue value that was selected to be stored
 * @param element selected element
 */
  addSelectValue(selectValue: any, element: any) {
    if (element.type == 1) {
      if (selectValue.category == 'assetcategory') {
        this.componentsCommunication.setAssetUpdate(selectValue);
        this.assetModel.assetCategory.id = selectValue.id;
        } if (selectValue.category && selectValue.category.toLowerCase() == 'manufacturer'){
          if (this.assetModel.manufacturer == null) {
            this.assetModel.manufacturer = {};
          }
        this.assetModel.manufacturer.id = selectValue.id;          
      } else if (!selectValue.category){
        this.assetModel.assetType.id = selectValue.id;
      }
    } else {
      if (selectValue.category){
        if (selectValue.category.toLowerCase() == 'consumable type'){
        this.assetModel.consumableType.id = selectValue.id;
      } if (selectValue.category.toLowerCase() == 'manufacturer'){
        this.assetModel.manufacturer.id = selectValue.id;          
      }
    }
  }
    this.validateFormdata();
  }

  /**
   * creates the new result based on asset
   */
  createResult() {
    this.isLoading = true;    
    const workareaId = localStorage.getItem('taskWorkareaId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: this.selectedAsset.tenant.id,
      taskId: localStorage.getItem('taskId'),
      idProcess: localStorage.getItem('idProcess')
    }         
    //***** Get all Forms of all Task's Services from Flowable: RESULT First time
    //***** /api/tenant/task/datapointValue/getResultForms/{tenantId}/{id_process}/{taskId}
    this.apiResultsService.getResultForms(params).subscribe(
      (json:any) => {
        // adds the createdBy
        json.forEach((element:any) => {
          element.createdBy = localStorage.getItem('username');
        });
        
      const tenantId = this.selectedAsset.tenant.id;   
      const workareaId = localStorage.getItem('taskWorkareaId');
      const params = {
        workareaId: workareaId ? workareaId : '',
        tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
        taskId: localStorage.getItem('taskId'),
        assetId: this.selectedAsset.id,
        body: json
      }

      //***** Create EMPTY DataPointValues for Tab Results
      //***** api/tenant/task/datapointValue/create/{tenantId}/{taskId}/result/{assetId}
      this.apiResultsService.createResultsDatapoint(params).subscribe(
        (result:any) => {
          console.log( 'result created');
          this.componentsCommunication.setUpdateList('update_results');
          this.isLoading = false;
          this.closePopup();
      },
        // error
        (msg) => {
          if (msg.status == '200') {
            console.log( 'result created');          
            // sends an update
            this.componentsCommunication.setUpdateList('update_results');       
          }
          this.isLoading = false;
          console.log( msg.statusText);
      });  

    },
      // error
      (msg) => {
        this.isLoading = false;
        console.log('error loading the result json ' + msg.statusText);
    });  

  }
}

