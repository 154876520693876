<div class="dialogform-container personnel">

    <div class="v-window-header">
        <span *ngIf="dialogData.action == 'create'">Add Consumables</span>
        <span *ngIf="dialogData.action == 'edit'">Edit Consumable</span>
        <div [mat-dialog-close]="true" class="v-window-closebox">
          <mat-icon class="icon action">close</mat-icon>
        </div>
      </div>    
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <mat-card-content *ngIf="!isLoading">

        <div mat-dialog-content [ngClass]="{'min-content': dialogData.action == 'edit'}">
            <div class="columns">
                <div class="column" *ngIf="dialogData.action == 'create'">

                    <h4>Consumables 
                        <span class="required_item"> *</span>
                    </h4>
                    <mat-form-field class="filter-type" appearance="fill" *ngIf="allConsumables">
                        <input 
                        (blur)="checkContent('consumables')"
                        (keyup)="valueChange($event.target.value, 'consumables')"
                        [matChipInputFor]="chipListConsumables"
                        #consumablesInput
                        [formControl]="ctrlConsumables"
                        [matAutocomplete]="autoConsumables" 
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addElem($event, 'consumables');"/>
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-chip-grid 
                        required="required" 
                        #chipListConsumables aria-label="element.name">
                            <mat-chip-row
                            *ngFor="let item of selectedConsumables"
                            (removed)="removeElem(item, 'consumables');">
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <span *ngIf="item.consumableType">{{item?.consumableType?.name + ' - ' + item.description}}</span>
                            
                            </mat-chip-row>
                        </mat-chip-grid>
                        <mat-autocomplete #autoConsumables="matAutocomplete" (optionSelected)="selectedElem($event, 'consumables');">
                            <ng-container *ngFor="let elem of allConsumables | async" >
                                <mat-option *ngIf="!checkIfExists(elem, 'consumables')" [value]="elem">
                                    {{elem?.consumableType?.name + ' - ' + elem.description}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="column2">

                    <h4>Batch Number 
                        <span class="required_item"> *</span>
                    </h4>
                    <mat-form-field class="filter-type" appearance="fill">
               
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'batchNumber'); validateForm()"
                        type="text"                    
                        [value]="newConsumablesModel?.batchNumber"
                        color="primary"/>    
                    </mat-form-field>

                    <h4>Expiry Date </h4>
                    <mat-form-field class="filter-type" appearance="fill">
           
                        <input matInput
                        type="datetime"
                        [matDatepicker]="picker"
                        [value]="prefilledValueDate"
                        (dateInput)="selectValue($event.target.value, 'expiryDate');  validateForm()"

                        (keyup)="selectValue($event.target?.value.toString(), 'expiryDate');  validateForm()">
                        
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <h4> Services 
                        <span class="required_item"> *</span>
                    </h4>
                    <mat-form-field class="filter-type" appearance="fill">
                        <input 
                        required="true"
                        (blur)="checkContent('services')"
                        (keyup)="valueChange($event.target.value, 'services')"    
                        [matChipInputFor]="chipListServices"
                        #servicesInput
                        [formControl]="ctrlServices"
                        [matAutocomplete]="autoServices" 
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addElem($event, 'services'); "/>
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-chip-grid 
                        required="required" 
                        #chipListServices aria-label="element.name">
                            <mat-chip-row
                            *ngFor="let item of selectedServices"
                            (removed)="removeElem(item, 'services');">
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <span *ngIf="item.name">{{item.name}} </span>
                            <span *ngIf="!item.name">{{item}}</span> 
                            </mat-chip-row>
                        </mat-chip-grid>
                        <mat-autocomplete #autoServices="matAutocomplete" (optionSelected)="selectedElem($event, 'services'); ">
                            <ng-container *ngFor="let elem of allServices | async" >
                                <mat-option *ngIf="!checkIfExists(elem, 'services')" [value]="elem">
                                    {{elem.name}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                </div>
      
            </div>
        </div>
    
        <div mat-dialog-actions>
    
        <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    