export class AvailableRolesModel {
  
  system = [
    'evolve-reader',
    'evolve-editor',
    'evolve-tenant-admin',    
    'module-role-system-admin'
  ];
  model = [
    'module-role-system-reader',
    'module-role-system-editor',
    'module-role-system-supervisor',
    'module-role-system-qa'
  ];
  inventory = [
    'module-role-im-reader',
    'module-role-im-editor',
    'module-role-im-supervisor',
    'module-role-im-qa'
  ];
  work = [
    'module-role-wm-reader',
    'module-role-wm-editor',
    'module-role-wm-supervisor',
    'module-role-wm-qa'
  ];
  
  /*
  //System
  String MODULE_ROLE_SYSTEM_READER = "module-role-system-reader";
  String MODULE_ROLE_SYSTEM_EDITOR = "module-role-system-editor";
    String MODULE_ROLE_SYSTEM_SUPERVISOR = "module-role-system-supervisor";
  String MODULE_ROLE_SYSTEM_QA = "module-role-system-qa";
  String MODULE_ROLE_SYSTEM_ADMIN = "module-role-system-admin"; 

  //Inventory Management Role
  String MODULE_ROLE_IM_READER = "module-role-im-reader";
  String MODULE_ROLE_IM_EDITOR = "module-role-im-editor";
    String MODULE_ROLE_IM_SUPERVISOR = "module-role-im-supervisor";
  String MODULE_ROLE_IM_QA = "module-role-im-qa";
  String MODULE_ROLE_IM_ADMIN = "module-role-im-admin";

  //Work Management Roles
  String MODULE_ROLE_WM_READER = "module-role-wm-reader";
  String MODULE_ROLE_WM_EDITOR = "module-role-wm-editor";
  String MODULE_ROLE_WM_SUPERVISOR = "module-role-wm-supervisor";
  String MODULE_ROLE_WM_QA = "module-role-wm-qa";
  String MODULE_ROLE_WM_ADMIN = "module-role-wm-admin";
    
  System  Admin  = "evolve-sys-admin";
  Administrator = "evolve-tenant-admin";
  Guest = "evolve-reader";
  Operator = "evolve-editor";
  QC = "evolve-qa";
  Supervisor = "evolve-supervisor";

  "roles": [
    "evolve-editor", - operator
    "evolve-reader", - guest
    "evolve-sys-admin", admin generico, system,
    "evolve-tenant-admin", - supervisor
    "module-role-system-admin" - qc
  ]
  */
}
