<div class="dialog-container">
    
    <div class="v-window-header">
        <span *ngIf="dialogData.action == 'create'">Create site</span>
        <span *ngIf="dialogData.action == 'edit'">Edit site - {{siteDetails.companyTitle}}</span>
        <div [mat-dialog-close]="true" class="v-window-closebox">
        <mat-icon class="icon action">close</mat-icon>
        </div>
    </div>
    <mat-spinner *ngIf="isLoading"> loading...</mat-spinner>
    
    <div class="dialogform-container" *ngIf="!isLoading && !isLoadingLicenses && !isLoadingRoles">
    <!---->
        <div class="no-results" *ngIf="!isLoading && !isLoadingLicenses && !isLoadingRoles && licenseLevelTypes && licenseLevelTypes.length == 0">
            <h1>No results to show...</h1>
        </div>

        <div mat-dialog-content class= "dialog-content" *ngIf="!isLoading && !isLoadingLicenses && !isLoadingRoles" >
            <div  class="row three-columns">
        
                <div  class="three-cols">
                    <h4>Title                     
                        <span class="required_item"> *</span>                    
                    </h4> 
                    
                    <mat-form-field>
                        <input 
                        tabindex="1"
                        [readonly]="readonly"
                        [disabled]="readonly"
                        [ngModel]="siteDetails.name"
                        matInput required
                        type="text"
                        (keyup)="siteDetails.name = $event.target.value.toString(); validateFormdata()"
                        color="primary">
                    </mat-form-field>
        
                </div>

                <h4>Email                 
                    <span class="required_item" *ngIf="!validEmail && siteDetails?.email?.length > 0">has an invalid format * !</span>
                    <span class="required_item"> *</span>
                </h4> 
                
                <mat-form-field [ngClass]="{'invalid': !validEmail && siteDetails?.email?.length > 0}">
                    <input 
                    tabindex="4"
                    [readonly]="readonly"
                    [disabled]="readonly"
                    [ngModel]="siteDetails.email" matInput required type="text"
                    (keyup)="validateEmail($event.target?.value.toString(), 'email');"
                    color="primary">
                </mat-form-field>

                <div  class="two-cols">
                    <h4>Address Line 1                     
                    </h4> 
                    
                    <mat-form-field>
                        <input 
                        tabindex="7"
                        [readonly]="readonly"
                        [disabled]="readonly"
                        [ngModel]="siteDetails.addressLine1"
                        matInput 
                        type="text"
                        (keyup)="siteDetails.addressLine1 = $event.target.value.toString(); validateFormdata()"
                        color="primary">
                    </mat-form-field>

                </div>
                <div  class="two-cols">
                    <h4>City                     
                    </h4> 
                    
                    <mat-form-field>
                        <input 
                        tabindex="10"
                        [readonly]="readonly"
                        [disabled]="readonly"
                        [ngModel]="siteDetails.city"
                        matInput
                        type="text"
                        (keyup)="siteDetails.city = $event.target.value.toString(); validateFormdata()"
                        color="primary">
                    </mat-form-field>
        
                </div>                                    

                <div  class="two-cols">
                    <h4>License Starts
                        <span class="required_item" *ngIf="!validStartDateFormat && siteDetails?.licenseLevel?.id"> invalid format !</span>    
                        <span class="required_item" *ngIf="requiredStartDateFormat && siteDetails?.licenseLevel?.id"> is also required * !</span>   
                        <span class="required_item" *ngIf="startDateIsGreater && siteDetails?.licenseLevel?.id"> must be lower than the end date !</span>   
                    </h4>
                    <mat-form-field class="filter-type" appearance="fill" [ngClass]="{'invalid': (!validStartDate || !validStartDateFormat || requiredStartDateFormat || startDateIsGreater) && siteDetails?.licenseLevel?.id}">
        
                        <input matInput
                        tabindex="13"
                        type="datetime"
                        [matDatepicker]="pickerStartDate"
                        [value]="prefilledStartDate"
                        (dateInput)="selectDate($event.target.value, 'licenseStartDate');  validateFormdata()"

                        (keyup)="selectDate($event.target?.value.toString(), 'licenseStartDate');  validateFormdata()">
                        
                        <mat-datepicker-toggle matIconSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                        <!--<mat-datepicker-toggle *ngIf="prefilledStartDate" matSuffix (click)="prefilledStartDate = null; siteDetails.licenseStartDate = null;">
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        -->
                        <mat-datepicker #pickerStartDate></mat-datepicker>
                    </mat-form-field>  
                </div>

            </div>

                <!-- COL 2*********-->
            <div class="col-2 three-columns" >
            
                <div  class="two-cols">
                    <h4>License Level                     
                    </h4> 
                    <!--
                    <mat-form-field class="filter-type  full-width" appearance="outline">
                        <mat-select #selectInput [placeholder]="siteDetails.licenseLevel?.name" [ngModel]="siteDetails.licenseLevel?.name">
                            <mat-option (mousedown)="selectLicenseLevel(elem);" *ngFor="let elem of licenseLevelTypes"
                            [value]="elem">
                            {{elem.name}}
                            </mat-option>
                        </mat-select>
                        </mat-form-field>
                    -->

                    <mat-form-field class="filter-type" appearance="fill">              
                        <input
                        tabindex="2"
                        type="text"
                        matInput
                        #licenseLevelInput
                        [formControl]="locationControl"
                        [matAutocomplete]="autoLicense"
                        [placeholder]="siteDetails.licenseLevel?.name"
                        [ngModel]="siteDetails.licenseLevel?.name"
                        (keydown.arrowup)="handleArrowNavigation($event,'licenseLevel')" 
                        (keydown.arrowdown)="handleArrowNavigation($event,'licenseLevel')"
                        (matChipInputTokenEnd)="selectLicenseLevel($event)">
                        <mat-icon
                        class="select-arrow"
                        matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #autoLicense="matAutocomplete">
                        <ng-container *ngFor="let option of licenseLevelTypes" >
                            <mat-option 
                            (onSelectionChange)="selectLicenseLevel($event, option)"
                            [value]="option.name">
                            {{option.name}}
                            </mat-option>
                        </ng-container>
        
                        </mat-autocomplete>
                    </mat-form-field>

                    </div>

                    <div  class="two-cols">
                        <h4>Telephone
                            <span class="required_item"> *</span>
                            <span class="required_item" *ngIf="!validPhone && validPhoneFormat && siteDetails?.phone?.length > 0">Must have at least 9 digits !</span>
                            <span class="required_item" *ngIf="!validPhoneFormat && siteDetails?.phone?.length > 0">Invalid format !</span>
                        </h4> 
                        
                        <!--<mat-form-field [ngClass]="{'invalid': (!validPhone || !validPhoneFormat) && siteDetails?.phone?.length > 0}">-->
                        <mat-form-field>
                            <input 
                            tabindex="5"
                            [readonly]="readonly"
                            [disabled]="readonly"
                            [ngModel]="siteDetails.telephone"
                            matInput required
                            type="text"
                            (keyup)="
                            siteDetails.phone = $event.target.value.toString();
                            siteDetails.telephone = $event.target.value.toString(); validateFormdata()"
                            color="primary">
                        </mat-form-field>
            
                    </div>
                    
                    <div  class="two-cols">
                        <h4>Address Line 2                         
                        </h4> 

                        <mat-form-field>
                            <input 
                            tabindex="8"
                            [readonly]="readonly"
                            [disabled]="readonly"
                            [ngModel]="siteDetails.addressLine2"
                            matInput
                            type="text"
                            (keyup)="siteDetails.addressLine2 = $event.target.value.toString(); validateFormdata()"
                            color="primary">
                        </mat-form-field>
            
                    </div>
                    
                    <div  class="two-cols">
                        <h4>Postcode                        
                        </h4> 
                        
                        <mat-form-field>
                            <input 
                            tabindex="11"
                            [readonly]="readonly"
                            [disabled]="readonly"
                            [ngModel]="siteDetails.postCode"
                            matInput
                            type="text"
                            (keyup)="siteDetails.postCode = $event.target.value.toString(); validateFormdata()"
                            color="primary">
                        </mat-form-field>
            
                    </div>      
                    
                    
                <div  class="two-cols">

                    <h4>License Ends
                        <span class="required_item" *ngIf="!validEndDateFormat && siteDetails?.licenseLevel?.id"> invalid format !</span>    
                        <span class="required_item" *ngIf="requiredEndDateFormat && siteDetails?.licenseLevel?.id"> is also required * !</span>                                               
                        <span class="required_item" *ngIf="endDateIsLower && siteDetails?.licenseLevel?.id"> must be greater than the start date !</span>  
                    </h4>
                    <mat-form-field class="filter-type" appearance="fill" [ngClass]="{'invalid': (!validEndDate || !validEndDateFormat || requiredEndDateFormat || endDateIsLower) && siteDetails?.licenseLevel?.id}">
        
                        <input matInput
                        tabindex="14"
                        type="datetime"
                        [matDatepicker]="pickerEndDate"
                        [value]="prefilledEndDate"
                        (dateInput)="selectDate($event.target.value, 'licenseEndDate');  validateFormdata()"
        
                        (keyup)="selectDate($event.target?.value, 'licenseEndDate');  validateFormdata()">
                        
                        <mat-datepicker-toggle matIconSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                        <!--
                            <mat-datepicker-toggle *ngIf="prefilledEndDate" matSuffix (click)="prefilledEndDate = null; siteDetails.licenseEndDate = null;">
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        -->
                        <mat-datepicker #pickerEndDate></mat-datepicker>
                    </mat-form-field>        
                </div>

            </div>  
            
            

            <!-- COL 3********-->
            <div class="col-3 three-columns" >
        
                <div  class="two-cols">
                    <h4>Company Title 
                    </h4> 
                    
                    <mat-form-field>
                        <input 
                        tabindex="3"
                        [readonly]="readonly"
                        [disabled]="readonly"
                        [ngModel]="siteDetails.companyTitle"
                        matInput
                        type="text"
                        (keyup)="siteDetails.companyTitle = $event.target.value.toString(); validateFormdata()"
                        color="primary">
                    </mat-form-field>
            
                    </div>

                    <div  class="two-cols">
                        <h4>URL
                            <span class="required_item"> *</span>
                        </h4> 
                        
                        <mat-form-field>
                            <input 
                            tabindex="6"
                            [readonly]="readonly"
                            [disabled]="readonly"
                            [ngModel]="siteDetails.url"
                            matInput required
                            type="text"
                            (keyup)="siteDetails.url = $event.target.value.toString(); validateFormdata()"
                            color="primary">
                        </mat-form-field>
            
                    </div>
                    
                    <div  class="two-cols">
                        <h4>Address Line 3 
                        </h4> 
                        
                        <mat-form-field>
                            <input 
                            tabindex="9"
                            [readonly]="readonly"
                            [disabled]="readonly"
                            [ngModel]="siteDetails.addressLine3"
                            matInput
                            type="text"
                            (keyup)="siteDetails.addressLine3 = $event.target.value.toString(); validateFormdata()"
                            color="primary">
                        </mat-form-field>
            
                    </div>
                    
                    <div  class="two-cols">
                        <h4>Country
                        </h4> 
                        
                        <mat-form-field>
                            <input 
                            tabindex="12"
                            [readonly]="readonly"
                            [disabled]="readonly"
                            [ngModel]="siteDetails.country"
                            matInput 
                            type="text"
                            (keyup)="siteDetails.country = $event.target.value.toString(); validateFormdata()"
                            color="primary">
                        </mat-form-field>
            
                    </div>            
            </div>          
        </div>
    
        <div mat-dialog-actions>
    
            <button
            tabindex="15"
            *ngIf="dialogData.action == 'create'"
            mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
            </button>
            <button
            tabindex="16"
            *ngIf="dialogData.action == 'edit'"
            mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="updateChanges();">
            Update
            <mat-icon class="icon action">save</mat-icon>
            </button>        

            
            <button
            tabindex="17"
            mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
            </button>
        </div>
    
    </div>
  
</div>