import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ComponentsCommunicationService {
  updateAssetsObs = new BehaviorSubject<string>('');
  contentLoadingObs = new BehaviorSubject<any>([]);
  updateLoggedUserObs = new BehaviorSubject<any>([]);
  updateTasksObs = new BehaviorSubject<any>([]);
  updateAssetCategoryObs = new BehaviorSubject<any>([]);
  formDataObs = new BehaviorSubject<any>([]);
  assetUpdateObs = new BehaviorSubject<any>([]);
  tabObs = new BehaviorSubject<any>([]);
  updateListObs = new BehaviorSubject<any>([]);
  updateListUsersObs = new BehaviorSubject<any>([]);
  updateListSitesObs = new BehaviorSubject<any>([]);
  updateListWorkAreas = new BehaviorSubject<any>([]);
  rolesListObs = new BehaviorSubject<any>([]);
  rolesWorkareasObs = new BehaviorSubject<any>([]);

  setRolesList(rolesList: any) {
    this.rolesListObs.next(rolesList);
  }

  setRolesWorkareas(rolesWorkareas: any) {
    this.rolesWorkareasObs.next(rolesWorkareas);
  }
  
  setUpdateTab(tab: any) {
    this.tabObs.next(tab);
  }

  setUpdateUsers(listUsers: any) {
    this.updateListUsersObs.next(listUsers);
  }

  setUpdateSites(listSites: any) {
    this.updateListSitesObs.next(listSites);
  }

  setUpdateWorkAreas(listWorkAreas: any) {
    this.updateListWorkAreas.next(listWorkAreas);
  }

  setUpdateList(listName: any) {
    this.updateListObs.next(listName);
  }

  setFormData(formData: any) {
    this.formDataObs.next(formData);
  }

  setUpdateAssets(reportsParam: any) {
    this.updateAssetsObs.next(reportsParam);
  }

  setAssetUpdate(asset: any) {
    this.assetUpdateObs.next(asset);
  }

  setUpdateTasks(updateTasks: any) {
    this.updateTasksObs.next(updateTasks);
  }


  getRolesWorkareas(): Observable<any> {
    return this.rolesWorkareasObs;
  }
  
  getRolesList(): Observable<any> {
    return this.rolesListObs;
  }
  
  getUpdateUsers(): Observable<any> {
    return this.updateListUsersObs;
  }
  
  getUpdateSites(): Observable<any> {
    return this.updateListSitesObs;
  }
  
  getUpdateWorkAreas(): Observable<any> {
    return this.updateListWorkAreas;
  }

  getUpdatedList(): Observable<any> {
    return this.updateListObs;
  }

  getUpdateTab(): Observable<any> {
    return this.tabObs;
  }

  getFormData(): Observable<any> {
    return this.formDataObs;
  }

  getUpdateAssets(): Observable<any> {
    return this.updateAssetsObs;
  }

  getAssetUpdate(): Observable<any> {
    return this.assetUpdateObs;
  }

  getUpdateTasks(): Observable<any> {
    return this.updateTasksObs;
  }
  getContentLoading(): Observable<any> {
    return this.contentLoadingObs;
  }

  getLoggedUser(): Observable<any> {
    return this.updateLoggedUserObs;
  }
}
