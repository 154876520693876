import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, startWith, map } from 'rxjs';
import { ModelCreateEquipment } from 'src/app/models/model.create.equipment';
import { ApiEquipmentService } from 'src/app/services/api.equipment.service';
import { ApiPersonnelService } from 'src/app/services/api.personnel.service';
import { ApiServicesService } from 'src/app/services/api.services.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export interface DialogData {
  name: string;
  taskId: string;
  allTaskEquipment: any;
  element: any;
  action: string;
}

@Component({
  selector: 'app-add-equipment',
  templateUrl: './add-equipment.component.html',
  styleUrls: ['./add-equipment.component.scss'],
})
export class AddEquipmentComponent {
  isLoading = false;
  validForm = false;
  @ViewChild('equipmentInput')
  equipmentInput!: ElementRef<HTMLInputElement>;
  @ViewChild('servicesInput')
  servicesInput!: ElementRef<HTMLInputElement>;

  @Output() elementEvent = new EventEmitter<any>();

  newEquipmentModel: any = new ModelCreateEquipment();
  selectedEquipment = <any>[];
  selectedServices = <any>[];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ctrlEquipment = new UntypedFormControl();
  ctrlServices = new UntypedFormControl();
  allEquipment: Observable<any[]> | undefined;
  allServices: Observable<any[]> | undefined;
  availableEquipment: any = [];
  availableServices: any = [];
  invalid = false;
  lookupsList: any;

  equipment: any = [];

  services: Observable<any[]> | undefined;

  constructor(
    private servicesService: ApiServicesService,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<AddEquipmentComponent>,
    private equipmentService: ApiEquipmentService,
    private personnelService: ApiPersonnelService
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const tenantId = localStorage.getItem('taskTenantId');   
    const supplierId = localStorage.getItem('taskSupplierId');
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: localStorage.getItem('taskId'),
      supplierId: supplierId && supplierId.length > 0 ? supplierId : '',
      first: 0,
      max: 50,
      sort: 'desc',
      sortField: 'dateCreated',
    };

    this.servicesService.getTaskServices(params).subscribe(
      (result: any) => {
        if (result && result.length) {
          this.processResults(result);
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log('error retrieving task services ' + msg);
      }
    );
    this.availableEquipment = this.equipment;
    this.allEquipment = this.equipment;
    this.equipmentService.getAllEquipments(params).subscribe(
      (result: any) => {
        if (result.content && result.content.length > 0) {          
          this.availableEquipment = [...result.content];
        }
        this.isLoading = false;
        this.allEquipment = this.updateFormControl('equipment', this.availableEquipment, this.ctrlEquipment);
      },
      // error
      (msg) => {
        console.log('error retrieving task personnel ' + msg);
        this.isLoading = false;
      }
    );
    // retrieves and stores the personnel when is edit mode
    if (this.dialogData.action == 'edit') {
      this.newEquipmentModel.equipment = this.availableEquipment;
    }
    this.allEquipment = this.updateFormControl('equipment', this.availableEquipment, this.ctrlEquipment);
  }

  /**
   * processes the results to display
   * @param result data array of results
   */
  processResults(result: any) {
    if (result.length > 0) {
      this.allServices = <any>[...result];
      this.availableServices = [...result];
    } else {
      // if no services are available, we retrieve all them for further adding
      this.allServices = <any>{...this.services};
      this.availableServices = {...this.services};
    }
    // when is edit mode, retrieves the selected elements to display them as already existing
    if (this.dialogData.action == 'edit') {
      this.dialogData.element.personnelServices.forEach((item: any) => {
        const entryFound = result.filter((option: any) => option.name == item);
        this.selectedServices.push(entryFound[0]);
        const selectedIndex = this.availableServices.findIndex((option: any) => option.name == item);
        this.availableServices.splice(selectedIndex, 1);
      });
    }
    if (Object.keys(this.availableServices).length > 0) {
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }
    this.isLoading = false;
  }

  /**
   * the form control in charge of an element, has to be updated to handle the content
   * @param type the type of element
   * @param availableElems the available array of elements
   * @param ctrl the control itself
   * @returns returns the updated control
   */
  updateFormControl(type: string, availableElems: any, ctrl: UntypedFormControl) {
    return ctrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filterElem(item, type) : availableElems.slice()))
    );
  }
  /**
   * adds an element to the list, when it's clicked
   * @param event event triggered when clicking an element
   */
  addElem(event: MatChipInputEvent, name: string): void {
    const value = (event.value || '').trim();
    const availableElems = name == 'equipment' ? this.availableEquipment : this.availableServices;
    let selectedElems = name == 'equipment' ? this.selectedEquipment : this.selectedServices;
    // Add our service if it exists
    if (value && availableElems.includes(value) && !selectedElems.includes(value)) {
      selectedElems.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.ctrlServices.setValue(null);
    this.ctrlEquipment.setValue(null);
  }

  /**
   * removes an element from the list
   * @param elem element to be removed
   */
  removeElem(elem: any, name: string): void {
    let availableElems = name == 'equipment' ? this.availableEquipment : this.availableServices;
    let selectedElems = name == 'equipment' ? this.selectedEquipment : this.selectedServices;
    let selectedIndex;
    let availableIndex;
    if (name == 'equipment') {
      selectedIndex = selectedElems.findIndex((item: any) =>
        item.serialNumber.includes(elem.serialNumber)
      );
      availableIndex = availableElems.findIndex((item: any) =>
        item.serialNumber.includes(elem.serialNumber)
      );
    } else {
      selectedIndex = selectedElems.findIndex((item: any) => item.name == elem.name);
      availableIndex = availableElems.findIndex((item: any) => item.name == elem.name);
    }

    if (selectedIndex >= 0) {
      selectedElems.splice(selectedIndex, 1);
    }
    if (availableIndex == -1) {
      availableElems.push(elem);
    }

    if (name == 'equipment') {
      this.selectedEquipment = selectedElems;
      this.availableEquipment = availableElems;
      this.allEquipment = availableElems;
      this.newEquipmentModel.equipment = [...selectedElems];
      this.allEquipment = this.updateFormControl('personnel', this.availableEquipment, this.ctrlEquipment);
    } else {
      this.selectedServices = selectedElems;
      this.availableServices = availableElems;
      this.allServices = availableElems;
      this.newEquipmentModel.servicesSet = [...selectedElems];
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }

    this.validateForm();
  }

  /**
   * handles the selected element when clicking it
   * @param event event that is triggered while clicking it
   */
  selectedElem(event: MatAutocompleteSelectedEvent, name: string): void {
    let availableElems = name == 'equipment' ? this.availableEquipment : this.availableServices;
    let selectedElems = name == 'equipment' ? this.selectedEquipment : this.selectedServices;
    let availableOpt = [];
    let alreadyExists = [];
    if (name == 'equipment') {
      const searchValue = event.option.value.id;
      availableOpt = availableElems.filter((option: any) => option.id.includes(searchValue));
      alreadyExists = selectedElems.filter((option: any) => option.id.includes(searchValue));

      // Add our service if it exists
      if (availableOpt.length > 0 && alreadyExists.length == 0) {
        selectedElems.push(event.option.value);
        let index = availableElems.findIndex((item: any) => item.id.includes(searchValue));
        availableElems.splice(index, 1);
      }

      this.equipmentInput.nativeElement.value = '';
      this.ctrlEquipment.setValue(null);
    } else {
      const value = event.option.value.name;
      this.servicesInput.nativeElement.value = '';
      this.ctrlServices.setValue(null);
      availableOpt = availableElems.filter((option: any) => option.name == value);
      alreadyExists = selectedElems.filter((option: any) => option.name == value);

      // Add our service if it exists
      if (availableOpt.length > 0 && alreadyExists.length == 0) {
        selectedElems.push(event.option.value);
        let index = availableElems.findIndex((item: any) => item.name == value);
        availableElems.splice(index, 1);
      }
    }

    if (name == 'equipment') {
      this.selectedEquipment = selectedElems;
      this.availableEquipment = availableElems;
      this.allEquipment = availableElems;
      this.newEquipmentModel.equipment = [...selectedElems];
      this.allEquipment = this.updateFormControl('personnel', this.availableEquipment, this.ctrlEquipment);
    } else {
      this.selectedServices = selectedElems;
      this.availableServices = availableElems;
      this.allServices = availableElems;
      this.newEquipmentModel.servicesSet = [...selectedElems];
      this.allServices = this.updateFormControl('services', this.availableServices, this.ctrlServices);
    }

    this.validateForm();
  }

  /**
   * filters by the elements that already exist
   * @param value element value to filter
   * @returns returns if is included
   */
  private _filterElem(element: any, name: string): string[] {
    let availableElems = name == 'equipment' ? this.availableEquipment : this.availableServices;
    let returnElems = [];
    if (name == 'equipment') {
      const searchValue =  element.serialNumber ? element.serialNumber: element;
      returnElems = availableElems.filter((item: any) => item.serialNumber.includes(searchValue) ||
       item.description.includes(searchValue));
    }
    if (name == 'services') {
      const name = element.name ? element.name : element;
      returnElems = availableElems.filter((item: any) => item.name.includes(name));
    }
    return returnElems;
  }

  /**
   * checks if an element exists before displaying it
   * @param elem selected element
   * @returns returns if it exists
   */
  checkIfExists(elem: string, name: string) {
    let selectedElems = name == 'equipment' ? this.selectedEquipment : this.selectedServices;
    return selectedElems.includes(elem);
  }



  /**
   * handles the value change and in case it's not valid, shows the incorrect styles
   * @param value passed value
   */
  valueChange(value: any, name: string) {
    let availableElems = name == 'equipment' ? this.availableEquipment : this.availableServices;
    if (!availableElems.includes(value.trim())) {
      this.invalid = true;
      const elem: any = {};
      // when the entered value doesn't match any valid options, it resets the previous values
      this.elementEvent.emit(elem);
    } else {
      this.invalid = false;
    }
  }

  /**
   * if the inserted text does not match any available entries, shows incorrect styles
   */
  checkContent(name: string) {
    let selectedElems = name == 'equipment' ? this.selectedEquipment : this.selectedServices;
    this.invalid = true;
    if (Object.keys(selectedElems).length > 0) {
      this.invalid = false;
    }
  }

  validateForm() {
    this.validForm = false;
    if (this.dialogData.action == 'create' && this.newEquipmentModel.equipment.length > 0 && this.newEquipmentModel.servicesSet.length > 0) {
      this.validForm = true;
    }
    if (this.dialogData.action == 'edit' && this.newEquipmentModel.servicesSet.length > 0) {
      this.validForm = true;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  saveChanges() {
    this.isLoading = true;
    const user = localStorage.getItem('username') ? localStorage.getItem('username') : 'admin';
    const taskId = this.dialogData.taskId ? this.dialogData.taskId : localStorage.getItem('taskId');
    this.newEquipmentModel.createdBy = user;
    this.newEquipmentModel.updatedBy = user;
    this.newEquipmentModel.task = { id: taskId };
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId');   
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: taskId
    };

    if (this.dialogData.action == 'create') {
      let newForm: any = [];
      let equipmentForm: any = this.newEquipmentModel;
      this.newEquipmentModel.equipment.forEach((element: any) => {
        equipmentForm.equipment = { id: element.id };
        newForm.push(equipmentForm);
      });
      newForm.dateCreated = <any>Date.now();
      this.createEquipment(newForm, params);
    } else if (this.dialogData.action == 'edit') {
      delete this.newEquipmentModel.equipment;
      this.newEquipmentModel.equipment = { id: this.dialogData.element.equipment.id };
      this.newEquipmentModel.id = this.dialogData.element.id;
      this.newEquipmentModel.dateCreated = <any>Date.now();
      this.newEquipmentModel.lastUpdated = <any>Date.now();
      this.updateEquipment(this.newEquipmentModel, params);
    }
  }

  updateEquipment(newEquipmentModel: any, params: any) {
    newEquipmentModel.lastUpdated = <any>Date.now();
    this.equipmentService.updateEquipment(newEquipmentModel, params).subscribe(
      (result) => {
        this.isLoading = false;
        this.closePopup();
        this.componentsCommunication.setUpdateList('equipment_mainlist');
        this.snackBar.open( 'Equipment updated ', '', {duration: 3000});
      },
      // error
      (msg) => {
        if (msg.status == 201 || msg.status == 200) {
          this.closePopup();
          this.componentsCommunication.setUpdateList('equipment_mainlist');
        } else {
          console.log('error creating the equipment' + msg);
        }
        this.isLoading = false;
      }
    );
  }

  createEquipment(newEquipmentModel: any, params: any) {
    this.equipmentService.createTaskEquipment(newEquipmentModel, params).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.closePopup();
        this.componentsCommunication.setUpdateList('equipment_mainlist');
        this.snackBar.open( 'Equipment created ', '', {duration: 3000});
      },
      // error
      (msg) => {
        if (msg.status == 201 || msg.status == 200) {
          this.closePopup();
          this.componentsCommunication.setUpdateList('equipment_mainlist');
        } else {
          console.log('error creating the equipment' + msg);
        }
        this.isLoading = false;
      }
    );
  }
}
