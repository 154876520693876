import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiDefectsService } from 'src/app/services/api.defects.service';
import { CreateManualDefectComponent } from '../create-report-results/dialogs/create-manual-defect/create-manual-defect.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilePreviewDialogComponent } from '../../dialogs/file-preview-dialog/file-preview-dialog.component';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-create-report-defects',
  templateUrl: './create-report-defects.component.html',
  styleUrls: ['./create-report-defects.component.scss']
})

export class CreateReportDefectsComponent {
  defectsUpdate!: Subscription;
  formDataSubscription!: Subscription;
  @Input() roles: any;
  @Input() workareaRoles: any; 
  displayedColumns: string[] = ['number', 'area', 'condition', 'description', 'images', 'actions'];
  showActions = false;
  isLoading = false;
  noResults = false;
  dataSource: any = [];
  workareaId: any;
  addNewEnabled = false;
  taskId: any;
  constructor (
    public rolesService: RolesService,
    private defectsService: ApiDefectsService,    
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private componentsCommunication: ComponentsCommunicationService) {}

  ngOnInit() {    
    this.taskId = localStorage.getItem('taskId');
    this.workareaId = localStorage.getItem('workareaId');
    this.defectsUpdate = this.componentsCommunication.getUpdatedList().subscribe(name => {
      // updates the reports list
      if(name == 'update_defects') {
        this.getData();
      }
    });
    
    this.formDataSubscription = this.componentsCommunication.getFormData().subscribe(data => {

      if(data && data.id && !this.workareaId) {
        this.workareaId = data.workArea.id;
        this.getData();
      } 
    }); 
  }
  
  ngOnDestroy() {
    this.defectsUpdate.unsubscribe();
    this.formDataSubscription.unsubscribe();
  }  
  
  preview(element: any){
    const tenantId = localStorage.getItem('taskTenantId');
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '', 
      taskId: this.taskId,
      element: element
    }

    this.dialog.open(FilePreviewDialogComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: params
    });
    
  }

  getData() {
    this.isLoading = true;
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = this.workareaId;
    const params = {
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      taskId: this.taskId  && this.taskId.length > 0 ? this.taskId : ''
    }
    this.defectsService.getTaskDefects(params).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.dataSource = [...result];
        if (result && result.length == 0) {
          this.dataSource = new MatTableDataSource<any>([]);  
        }
      },
      // error
      (msg) => {
        console.log('error retrieving defects ' + msg);
        this.dataSource = [];
        this.isLoading = false;
    });
  }

  editItemDetails (element: any) {

    element.action = 'edit';
    this.dialog.open(CreateManualDefectComponent, {
      height: 'auto',
      width: '90%',
      disableClose: true,
      autoFocus: false,
      data: element
    });
  
  }

/**
 * action needed a confirmation
 * @param action action to be performed
 * @param elem selected element
 */
  confirmation(action: string, elem?: any) {
    
    elem.action = action;
    elem.confirmation = false;
    if (action == 'delete') {
      elem.confirmationString = 'Delete the selected element?';
    } 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '440px',
      disableClose: true,
      autoFocus: false,
      data: elem
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.confirmation) {
          this.deleteFile(elem);
        }
      }
    ); 
  }
  
  deleteFile(element: any) {
    const tenantId = localStorage.getItem('taskTenantId');   
    const workareaId = localStorage.getItem('taskWorkareaId'); 
    const params = {
      tenantId: tenantId && tenantId.length > 0 ? tenantId : '',
      workareaId: workareaId && workareaId.length > 0 ? workareaId : '',
      taskId: element.id
    }

    this.defectsService.deleteDefect(params).subscribe(
      () => {
        console.log( 'defect deleted');
        this.snackBar.open( 'defect deleted ', '', {duration: 3000});
        // sends an update
        this.componentsCommunication.setUpdateList('update_defects');
        this.componentsCommunication.setUpdateList('update_results');
    },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log( 'defect deleted');
          this.snackBar.open( 'defect deleted ', '', {duration: 3000});
          // sends an update
          this.componentsCommunication.setUpdateList('update_defects');
          this.componentsCommunication.setUpdateList('update_results');
        }
        console.log( msg.statusText);
    });    
  }
}
