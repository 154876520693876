<div class="dialogform-container personnel">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <mat-card-content *ngIf="!isLoading">
        <h2  *ngIf="dialogData.action == 'create'">Create</h2>
        <h2  *ngIf="dialogData.action == 'update_utprobes'">Edit - {{title}}</h2>
        
        <div mat-dialog-content >
            <div class="columns">
                <div class="column">

                    <h4>Serial Number
                        <span class="required_item"> *</span>
                    </h4>                      
                    <mat-form-field class="filter-type" appearance="fill">
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'serialNumber'); validateForm()"
                        type="text"                     
                        [value]="newModel.model.serialNumber"
                        color="primary"/>    
                    </mat-form-field>

                    <h4>Angle
                        <span class="required_item"> *</span>
                    </h4>                    
                    <mat-form-field class="filter-type" appearance="fill">
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'angle'); validateForm()"
                        type="text"                    
                        [value]="newModel.model.angle"
                        color="primary"/>    
                    </mat-form-field>

                    <h4>Frequency (mHz)
                        <span class="required_item"> *</span>
                    </h4>  
                    <mat-form-field class="filter-type" appearance="fill">
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'frequency'); validateForm()"
                        type="text"                     
                        [value]="newModel.model.frequency"
                        color="primary"/>    
                    </mat-form-field>

                    <h4>Transfer Correction (dB)
                        <span class="required_item"> *</span>
                    </h4>                      
                    <mat-form-field class="filter-type" appearance="fill">
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'transferCorrection'); validateForm()"
                        type="text"                     
                        [value]="newModel.model.transferCorrection"
                        color="primary"/>    
                    </mat-form-field>                    
                </div>

                <div class="column2">
                    <h4>Type
                        <span class="required_item"> *</span>
                    </h4>  
                    <mat-form-field class="filter-type" appearance="fill">
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'type'); validateForm()"
                        type="text"                     
                        [value]="newModel.model.type"
                        color="primary"/>    
                    </mat-form-field>

                    <h4>Crystal Size (mm)
                        <span class="required_item"> *</span>
                    </h4>                      
                    <mat-form-field class="filter-type" appearance="fill">
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'crystalSize'); validateForm()"
                        type="text"                                   
                        [value]="newModel.model.crystalSize"
                        color="primary"/>    
                    </mat-form-field>

                    <h4>Reference Sensitivity Level (dB)
                        <span class="required_item"> *</span>
                    </h4>                      
                    <mat-form-field class="filter-type" appearance="fill">                        
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'referenceSensitivityLevel'); validateForm()"
                        type="text"                                   
                        [value]="newModel.model.referenceSensitivityLevel"
                        color="primary"/>    
                    </mat-form-field>

                    <h4>Scanning Sensitivity (dB)
                        <span class="required_item"> *</span>
                    </h4>                      
                    <mat-form-field class="filter-type" appearance="fill">                        
                        <input matInput
                        (keyup)="selectValue($event.target?.value.toString(), 'scanningSensitivity'); validateForm()"
                        type="text"                
                        [value]="newModel.model.scanningSensitivity"
                        color="primary"/>    
                    </mat-form-field>                    

                </div>
      
            </div>
        </div>
    
        <div mat-dialog-actions>
    
        <button mat-raised-button [ngClass]="{'disabled': !validForm}" color="primary" (click)="saveChanges();">
            Save
            <mat-icon class="icon action">save</mat-icon>
        </button>        
        <button mat-raised-button color="primary" (click)="closePopup()">
            Close
            <mat-icon class="icon action">close</mat-icon>
        </button>
        </div>
    </mat-card-content>
</div>
    