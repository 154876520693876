import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiFilesService } from 'src/app/services/api.files.service';
import { ApiService } from 'src/app/services/api.service';
import { ApiSitesService } from 'src/app/services/api.sites.service';
import { ApiUsersService } from 'src/app/services/api.users.service';
import { ComponentsCommunicationService } from 'src/app/services/components-communication.service';
export interface DialogData {
  id: any;
  urlAvatar: any;
  urlSignature: any;
  urlLogo: any;
  action: string;
  type: string;
  tenant: any;
  user: any;
  companyTitle: string;
}
@Component({
  selector: 'app-avatars-dialog',
  templateUrl: './avatars-signatures-dialog.component.html',
  styleUrls: ['./avatars-signatures-dialog.component.scss']
})
export class AvatarsSignaturesDialogComponent {
  acceptFormats = 'image/png, image/jpeg, image/gif, image/jpg, image/jfif, image/pipeg, image/svg';
  display: FormControl = new FormControl("", Validators.required);
  isLoading = false;
  urlImage: any;
  fileToUpload: any;
  validForm = false;
  imgType = '';
  constructor(
    private componentsCommunication: ComponentsCommunicationService,
    private snackBar: MatSnackBar,
    private apiFilesService: ApiFilesService,
    private apiUsersService: ApiUsersService,
    private apiSitesService: ApiSitesService,
    public dialogRef: MatDialogRef<AvatarsSignaturesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
  ){}

  ngOnInit() {      
    const type = this.dialogData.type;
    if (type) {
      //this.isLoading = true;      

      if (this.dialogData.action == 'edit') {
/*
        if (this.dialogData.type == 'avatar') {
          this.loadAvatar(this.dialogData)      
        } else if (this.dialogData.type == 'signature') {
          this.loadSignature(this.dialogData)
        } else if(this.dialogData.type == 'sitelogo') {
          this.getLogo(this.dialogData);
        }*/
      }
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  loadSignature(element: any) {
    const params = {
      itemId: element.id,
      tenantId: element.tenant.id,
      type: 'signature',
    };
    this.apiUsersService.getAvatarSignature(params).subscribe(
      (result: any) => {
        if (result && result?.fileString) {          
          this.urlImage = 'data:' + result.type +';base64,' + result.fileString;        
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log(msg);
        this.isLoading = false;
      }
    );
  }

  loadAvatar(element: any) {
    const params = {
      itemId: element.id,
      tenantId: element.tenant.id,
      type: 'avatar',
    };
    this.apiUsersService.getAvatarSignature(params).subscribe(
      (result: any) => {
        if (result && result?.fileString) {   
          this.urlImage = 'data:' + result.type +';base64,' + result.fileString;   
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log(msg);
        this.isLoading = false;
      }
    );
  }

  getLogo(element: any) {
    const params = {
      tenantId: element.id,
    };
    this.apiSitesService.getSiteLogo(params).subscribe(
      (result: any) => {
        if (result && result?.fileString) {   
          this.urlImage = 'data:' + result.type +';base64,' + result.fileString;   
        }
        this.isLoading = false;
      },
      // error
      (msg) => {
        console.log(msg);
        this.isLoading = false;
        if (msg.status == '400') {
          console.log('no logo found');
        }
      }
    );
  }

  checkFile(files: any): void {    
    if (files.length) {
      this.fileToUpload = files[0];
      this.validForm = true;
      this.display.patchValue(`${this.fileToUpload.name}`);
      const reader = new FileReader();

      reader.readAsDataURL(files[0]); // read file as data url

      reader.onload = (event:any) => { // called once readAsDataURL is completed
        this.urlImage = event.target.result;
      }

    } else {
      this.display.patchValue('');
      this.validForm = false;      
    }
  }

  upload(){
    this.isLoading = true;      
    const formData = new FormData();
    const uploadType = this.dialogData.type == 'sitelogo'? 'logo': this.dialogData.type;
    const infoType = this.dialogData.type == 'sitelogo' ? 'tenantLogoDto' : this.dialogData.type + 'Info';

    let newFile = {};

    formData.append(uploadType, this.fileToUpload);
    if (this.dialogData.type == 'avatar') {
      newFile = {
        "userDataDto": {
          "id": this.dialogData.id
        },
        "user": null,
        "name": this.fileToUpload.name,
        "type":  this.fileToUpload.type,
        "description": "",
        "hashName": null,
        "fileString": null,
        "isDeleted": false
      }
    } else if (this.dialogData.type == 'signature') {
        newFile = {
          "userDataDto": {
            "id": this.dialogData.id
          },
          "name": this.fileToUpload.name,
          "type":  this.fileToUpload.type,
          "hashName": null,
          "fileString": null,
          "isDeleted": false
        }
        
    } else {
      // when is site logo
      newFile = {  
      "id": null,
      "isDeleted": false,
      "name": this.fileToUpload.name,
      "type":  this.fileToUpload.type,
      "tenantDto": {
        "id": this.dialogData.id
      }
    }
      
  }    
    formData.append(
      infoType,
      new Blob([JSON.stringify(newFile)], {
        type: 'application/json',
      })
    );

    const tenantId = this.dialogData?.tenant?.id ? this.dialogData.tenant.id : this.dialogData.id;    
    const params = {
      tenantId: tenantId,
      form: formData,
      type: this.dialogData.type,
      imgType: this.dialogData.type == 'sitelogo' ? 'tenant' : 'user'
    };
    const properties = {
      name: this.dialogData.type == 'sitelogo' ? 'update_sites' : 'update_users',
      action: this.dialogData.action,
      type: this.dialogData.type
    } 
    this.apiFilesService.uploadAvatarsSignatures(params).subscribe(
      (result) => {
        console.log('file uploaded');
        this.closePopup();
        this.isLoading = false;
        this.snackBar.open('file uploaded ', '', { duration: 3000 });
        // sends an update
        if ( this.dialogData.type == 'sitelogo'){
          this.componentsCommunication.setUpdateSites(properties);      
        }else {
          this.componentsCommunication.setUpdateUsers(properties);      
        }
        
      },
      // error
      (msg) => {
        if (msg.status == '200') {
          console.log('file uploaded');
          this.closePopup();
          this.isLoading = false;
          this.snackBar.open('file uploaded ', '', { duration: 3000 });
          // sends an update
          if ( this.dialogData.type == 'sitelogo'){
            this.componentsCommunication.setUpdateSites(properties);      
          }else {
            this.componentsCommunication.setUpdateUsers(properties);      
          }      
        }
        console.log(msg.statusText);
      }
    );

  }

}
