
<div class="attachments-container"  *ngIf="!isLoading">
  <div class="v-window-header">
    Edit Service - Visual
    <div [mat-dialog-close]="true" class="v-window-closebox">
      <mat-icon class="icon action">close</mat-icon>
    </div>
  </div>

  <br/>
  <h4> Comments </h4>
  <mat-form-field>
  
    <textarea
    [(ngModel)]="prefilledComments"
    rows="10"
    matInput        
    [required]="false"
    cdkTextareaAutosize
    (keyup)="comments = $event.target.value.toString(); validateFormdata()"
    #autosize="cdkTextareaAutosize"></textarea>
  </mat-form-field>  
 
<div mat-dialog-actions>
    <button  mat-raised-button [ngClass]="{'disabled': !validForm}"
    class="upload right" color="primary" (click)="saveComments()">
     Save
     <mat-icon class="icon action">done</mat-icon>
   </button>
    <button mat-raised-button color="primary" (click)=" closePopup()">
      Cancel
      <mat-icon class="icon action">close</mat-icon>
    </button>
  </div>

</div>
<mat-spinner *ngIf="isLoading"></mat-spinner>