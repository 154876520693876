export class ModelCreateAssetEquipment {    
  id = null;
  dateCreated = '';
  createdBy = 'admin';
  lastUpdated = '';
  updatedBy = 'admin';
  active = true;
  description = '';
  dateCommission = '';
  dateDecommission = '';
  dateDisposal =  '';
  assetImages = '';
  model = '';
  serialNumber = '';
  assetType = {};
  assetCategory = {};
  owner = {};
  controller = {};
  department = {}; 
  manufacturer = {};
  type = 1;
}
