import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiPersonnelService {
  baseURL: string;

  constructor(private authService: AuthService, private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.baseURL = 'http://localhost';
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  //api/wm/taskpersonnel/workarea/task/{taskId}/list
  getTaskPersonnel(params: any) {
    const endpoint = `/api/wm/taskpersonnel/task/${params.taskId}/list?firstResult=0&maxResults=9999&sort=dateCreated&sort=desc`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //GET /{personnelId}/get
  getPersonnelServices(params: any) {
    const endpoint = `/api/wm/taskpersonnel/${params.personnelId}/get`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/wm/taskpersonnel/task/{taskId}/services/list
  getAllAvailableServices(params: any) {
    const endpoint = `/api/wm/taskpersonnel/task/${params.taskId}/services/list`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //api/wm/taskpersonnel/workarea/task/{taskId}/users/list?firstResult=0&maxResults=20&sort=dateCreated&sort=desc
  getAvailablePersonnel(params: any) {
    const endpoint = `/api/wm/taskpersonnel/workarea/task/${params.taskId}/users/list?firstResult=0&maxResults=9999&sort=dateCreated&sort=desc`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/taskPersonnel/{tenantId}/remove/{personnelId}
  deleteTaskPersonnel(params: any) {
    const endpoint = `/api/wm/taskpersonnel/${params.personnelId}/delete`;
    return this.http.delete(this.baseURL + endpoint, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/taskPersonnel/{tenantId}/signature
  setReportSignatory(params: any) {
    const endpoint = `/api/wm/taskpersonnel/signature/add`;
    return this.http.put(this.baseURL + endpoint, params.body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }

  //task/taskPersonnel/create/{tenantId}
  createTaskPersonnel(body: any, params: any) {
    const endpoint = `/api/wm/taskpersonnel/create`;
    return this.http.post(this.baseURL + endpoint, body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }
  
  //api/wm/taskpersonnel/update
  updateTaskPersonnel(body: any, params: any) {
    const endpoint = `/api/wm/taskpersonnel/update`;
    return this.http.put(this.baseURL + endpoint, body, {
      headers: this.authService.getHeadersWithTenant(params.tenantId, params.workareaId)
    });
  }
}
